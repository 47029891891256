/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';

import { Dialog, Paper, useMediaQuery, Tooltip, Grid } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import axios from 'axios';
import format from 'date-fns/format';
import Draggable from 'react-draggable';
import { Link } from 'react-router-dom';
import PullToRefresh from 'react-simple-pull-to-refresh';

import InputNumber from 'components/Inputs/ManufactureInputs/InputNumber';
import { PrintLabel } from 'components/PrintLabel';
import EngineIcon from 'images/icons/configsAndSetups/configIconBlue.svg';
import EngineIconGrey from 'images/icons/configsAndSetups/configIconGrey.svg';
import ListIconBlue from 'images/icons/objects/clipboards/listIconBlue.svg';
import VirtualProduction from 'images/icons/signals/alerts/virtualProductionIcon.svg';
import deleteWhite from 'images/icons/signals/closesAndCancels/deleteBlanc2.svg';
import Environment from 'utils/environments';
import { increaseDate } from 'utils/formatDate';
import numberToReal from 'utils/numberToReal';
import ValidationLength from 'utils/utils';

import MobileCardDetail from './ProductionDialog/index';
import {
  CardsContainer,
  CardTitleWrapper,
  CardInfoHeader,
  CardPlayWrapper,
  CardContainer,
  CardInfoContent,
  CardContentTitle,
  CardContentElement,
  ProductionPlay,
  ProductionLabel,
  CardModal
} from './style';
import { setPopoverDataStatus } from './utils';

import { handleFields, productionCardStatus } from '../utils';
import moment from 'moment/moment';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-card-production-dialog"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function MobileCard({
  handleItemDiscard,
  setModalStates,
  mainModalStates,
  updateQuantityInitialValue,
  stores,
  companyActive,
  companyGroup,
  manufactures,
  setManufactures,
  originId,
  currentTransformationCardItems,
  setCurrentTransformationCardItems,
  setManufacture,
  index,
  description,
  state,
  manufactureYield,
  newDuration,
  limiteProd,
  manufactureQuantity,
  manufactureTotalYield,
  manufactureStatus,
  dateStatus,
  items,
  actions,
  manufacture,
  optionsDiscard,
  manufactureStock,
  manufactureDuration,
  lossManufactured,
  manufactured,
  totalItemsQuantity,
  cardYield,
  type,
  optionsCards,
  updatedManufacture,
  setCardDate,
  forceEnable,
  setForceEnable,
  setCheckSumeItems,
  setModalSettings,
  modalSettings
}) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const StatusIcon = productionCardStatus[manufactureStatus]?.icon;
  const [openDrillDown, setOpenDrillDown] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [transformationInfos, setTransformationInfos] = useState();
  const isPhone = useMediaQuery('(max-width: 767px)');
  const manufactureStatusAction = manufacture.actions?.filter(
    (item) => item.type === 'START'
  );
  const manufactureDefaultStartAction = [
    {
      date: manufacture?.created_at
    }
  ];
  const manufactureStartActionsOrdered = manufactureStatusAction?.length
    ? manufactureStatusAction
    : manufactureDefaultStartAction?.sort((a, b) => (a.date > b.date ? 1 : -1));

  const manufactureFinishActionsOrdered =
    manufacture.actions
      ?.filter((item) => item.type === 'FINISH')
      ?.sort((a, b) => (a.date > b.date ? 1 : -1)) || [];
  
  const manufacturePauseActionsOrdered =
    manufacture.actions
      ?.filter((item) => item.type === 'PAUSE')
      ?.sort((a, b) => (a.date > b.date ? 1 : -1)) || [];

  const manufactureCanceledActionsOrdered =
    manufacture.actions
      ?.filter((item) => item.type === 'CANCELED')
      ?.sort((a, b) => (a.date > b.date ? 1 : -1)) || [];

  const handleOpenModal = () => {
    if (manufacture.type === 'TRANSFORMATION') {
      axios
        .get(
          `${environments.catalog}/products/manufactures/transformation/${manufacture?.productId}?originId=${originId}`
        )
        .then((response) => {
          setTransformationInfos(response?.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    setManufacture(manufacture);
    setCurrentTransformationCardItems({
      ...manufacture,
      items: manufacture?.items?.map((el) => ({
        ...el,
        quantity: null
      }))
    });
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setForceEnable(false);
    setCheckSumeItems(false);
    setTransformationInfos();
  };

  const handleDeleteButton = () => {
    const { deleteButton } = actions;
    deleteButton.handleDialogClickOpen();
    deleteButton.setDeleteData(manufacture);
  };

  const handleStatusButton = (e) => {
    const { statusButton } = actions;
    const { type, status } = manufacture;
    statusButton.setManufacture(manufacture);
    setManufacture(manufacture);
    setCurrentTransformationCardItems({
      ...manufacture,
      items: manufacture?.items?.map((el) => ({
        ...el,
        quantity: null
      }))
    });
    statusButton.setPopoverData(setPopoverDataStatus(type, status));
    statusButton.handlePopoverClick(e, { isMobile: true });
  };

  const handleQuantity = (value) => {
    actions.handleCardQuantity(
      index,
      parseFloat(value.replace(',', '.')),
      undefined,
      undefined,
      undefined
    );
  };

  const handleQuantityField = (status, value, type) =>
    value
      ? parseFloat(value).toLocaleString('pt-br', { minimumFractionDigits: 3 })
      : '0,000';

  const performance = handleQuantityField(
    manufacture.status,
    manufacture.initialYield ||
      manufacture.status === 'FINISH' ||
      manufacture.status === 'CANCELED'
      ? manufacture.yield
      : type.toLowerCase() === 'recipe'
      ? manufacture.yield
      : (manufacture.quantity || 1) -
        (manufacture.quantity || 1) * (manufacture.loss / 100),
    'yield'
  );

  const produced =
    manufacture.status === 'FINISH' && manufacture?.manufactured !== null
      ? `${manufacture.manufactured?.toLocaleString('pt-br', {
          minimumFractionDigits: 3
        })}`
      : manufacture.status === 'FINISH'
      ? handleFields(manufacture).manufactured
      : null;

  const total = manufacture.items.reduce(
    (total, value) =>
      value
        ? parseFloat(total) + parseFloat(value.quantity)
        : parseFloat(total),
    0
  );
  const lossManufacturedCalc = manufacture?.manufactured - manufacture?.yield;

  const handlePreparationClick = (url) => {
    const encodedUrl = btoa(url);
    localStorage.setItem('preparationUrl', encodedUrl);
  };

  useEffect(() => {
    setTimeout(() => {
      if (openModal && document?.querySelector('#yieldCardField input')) {
        document?.querySelector('#yieldCardField input')?.select();
      }
    }, 100);
  }, [openModal, optionsCards]);

  return (
    <CardsContainer type={type}>
      {/* <ProductionLabel
        style={{
          borderRadius: '4px 4px 0px 0px',
          padding: '0px',
          height: '4px'
        }}
        backgroundColor={productionCardStatus[manufactureStatus]?.statusColor}
      /> */}

      <CardTitleWrapper
        backgroundColor={productionCardStatus[manufactureStatus]?.statusColor}
      >
        {StatusIcon?.$$typeof ? (
          <StatusIcon
            id="MobilePlayProductionButton"
            disabled={
              manufacture.status === 'FINISH' ||
              manufacture.status === 'CANCELED'
            }
            style={{ color: '#000', width: '25px', height: '25px' }}
          />
        ) : (
          <>
            <div
              className="dFlex alignCenter justifyCenter pRelative mr8"
              style={{
                border: '1px solid #000000',
                borderRadius: '8px',
                width: 40,
                height: 40,
                zIndex: 1
              }}
            />

            <ProductionPlay
              marginRight="0px"
              src={StatusIcon}
              onClick={handleOpenModal}
              // onClick={manufacture.status === "FINISH" || manufacture.status === "CANCELED" ? null : handleStatusButton}
              id="MobilePlayProductionButton"
              style={{
                boxShadow: '5px 5px 4px rgba(0, 0, 0, 0.3)',
                position: 'absolute',
                left: '7.5px'
              }}
            />
          </>
        )}

        <span
          className="childTdFontSize18"
          style={{
            color: '#010311',
            fontWeight: 'bold',
            fontSize: isPhone ? '14px' : '18px',
            lineHeight: 1.2
          }}
          onClick={handleOpenModal}
        >
          {ValidationLength(description, 35)}
        </span>

        <div className="dFlex flexColumn alignEnd">
          <div
            className="dFlex"
            style={{
              width: 100,
              justifyContent: 'space-between'
            }}
          >
            <div className="dFlex">
              <Grid
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                {manufacture?.engine?.quantity && manufacture?.engine?.quantity === manufacture?.quantity ? (
                  <Tooltip
                    title={
                      <p style={{ padding: '3px 8px', margin: 0 }}>
                        <b>Sugestão Copiloto:</b>
                        <br />
                        <b>Qtde de acordo com a sugestão</b>
                      </p>
                    }
                    aria-label="filters"
                    placement="right-start"
                  >
                    <img src={EngineIcon} style={{ width: 16, height: 15 }} />
                  </Tooltip>
                ) : (manufacture.origin === 'S' && manufacture?.engine?.quantity !== manufacture?.quantity) || manufacture.origin === 'S' ? (
                  <Tooltip
                    title={
                      <p style={{ padding: '3px 8px', margin: 0 }}>
                        <b>Sugestão Copiloto:</b>
                        <br />
                        <b>Qtde sugerida alterada pelo usuário</b>
                      </p>
                    }
                    aria-label="filters"
                    placement="right-start"
                  >
                    <img
                      src={EngineIconGrey}
                      style={{ width: 16, height: 15 }}
                    />
                  </Tooltip>
                ) : manufacture.origin === 'M' ? (
                  <Tooltip
                    title={
                      <div style={{ padding: '4px' }}>
                        <p style={{ margin: '0' }}>
                          Produção fora da programação
                        </p>
                      </div>
                    }
                    aria-label="filters"
                    placement="right-start"
                  >
                    <img
                      src={VirtualProduction}
                      style={{ width: 16, height: 15 }}
                    />
                  </Tooltip>
                ) : null}
              </Grid>

              {manufacture?.preparationMethod?.url ? (
                <Link
                  to={`/preparation/${manufacture?.preparationMethod?.name}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    style={{
                      width: '18px',
                      marginRight: '3px'
                    }}
                    src={ListIconBlue}
                    alt="Preparation Method"
                    onClick={() =>
                      handlePreparationClick(
                        manufacture?.preparationMethod?.url
                      )
                    }
                  />
                </Link>
              ) : null}
            </div>

            <img
              src={deleteWhite}
              alt="DeleteWhite"
              onClick={() => {
                if (manufacture.status === 'CANCELED') return;

                handleDeleteButton();
              }}
              id="MobileDeleteCard"
            />
          </div>

          <div
            className="dFlex justifyCenter alignCenter mt5"
            style={{
              width: 100,
              background: type === 'RECIPE' ? '#75C7F0' : '#F7D56E',
              borderRadius: '4px',
              height: 20
            }}
          >
            <p
              style={{
                fontSize: 10,
                color: '#1F2445'
              }}
              className="mb0 bold uppercase"
            >
              {type === 'RECIPE' ? `RECEITA` : 'TRANSFORMAÇÃO'}
            </p>
          </div>
        </div>
      </CardTitleWrapper>

      <CardPlayWrapper onClick={handleOpenModal} style={{ marginTop: '0px' }}>
        <ProductionLabel
          backgroundColor={
            productionCardStatus[manufactureStatus]?.borderBackgroundColor
          }
          fontColor={productionCardStatus[manufactureStatus]?.labelStatusColor}
          justifyContent={manufacture.status === 'CANCELED' && 'flex-start'}
        >
          <div
            className="bold uppercase withStroke"
            style={{ fontSize: isPhone ? '14px' : '18px' }}
          >
            {`${productionCardStatus[manufactureStatus]?.statusText}`}
          </div>

          {manufacture?.cancellationReason && (
            <span
              className="dFlex bold uppercase"
              style={{ marginLeft: 5, fontSize: isPhone ? '14px' : '18px' }}
            >
              <span style={{ marginRight: 4 }}> - </span>{' '}
              {ValidationLength(manufacture?.cancellationReason, 15)}
            </span>
          )}

          {dateStatus && (
            <div
              className="bold"
              style={{
                margin: '0 0 0 auto',
                fontSize: isPhone ? '14px' : '12px'
              }}
            >
              {manufacture.status === 'START' && 'INICIADO'}

              {manufacture.status === 'FINISH' &&
                manufactureStartActionsOrdered.length &&
                moment(new Date(manufactureStartActionsOrdered[0]?.date)).format('LT')
              }{' '}

              às{' '}

              {manufacture.status === 'START' &&
                manufactureStartActionsOrdered.length &&
                moment(new Date(manufactureStartActionsOrdered[0]?.date)).format('LT')
              }{' '}

              {manufacture.status === 'FINISH' &&
                manufactureFinishActionsOrdered.length &&
                moment(new Date(manufactureFinishActionsOrdered[0]?.date)).format('LT')
              }{' '}

              {manufacture?.status === 'PAUSE' &&
                manufacturePauseActionsOrdered?.length &&
                moment(new Date(manufacturePauseActionsOrdered[0]?.date)).format('LT')
              }{' '}

              {manufacture?.status === "CANCELED" &&
                manufactureCanceledActionsOrdered?.length &&
                moment(new Date(manufactureCanceledActionsOrdered[0]?.date)).format('LT')
              }{' '}
              
              {dateStatus.user ? `(${dateStatus.user})` : ''}
            </div>
          )}
        </ProductionLabel>
      </CardPlayWrapper>

      <CardInfoHeader
        style={{ display: 'flex', flexDirection: 'row' }}
        onClick={handleOpenModal}
      >
        {manufactureStatus !== 'FINISH' ? (
          <CardInfoContent width="18%">
            <CardContentTitle
              className="mb5"
              style={{
                fontSize: isPhone ? '10px' : '12px',
                lineHeight: '12px',
                color: '#454A67'
              }}
            >
              QUANTIDADE
            </CardContentTitle>

            <CardContentElement
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '81px'
              }}
            >
              <InputNumber
                minimumValue="0"
                maximumValue="999999999"
                initialValue={parseFloat(manufactureQuantity).toFixed(3)}
                disabled
                handleValue={handleQuantity}
                updateQuantityInitialValue={updateQuantityInitialValue}
              />
            </CardContentElement>
          </CardInfoContent>
        ) : (
          <CardInfoContent width={isPhone ? '25%' : '35%'}>
            <CardContentTitle
              className="mb5"
              style={{
                fontSize: isPhone ? '10px' : '12px',
                lineHeight: '12px',
                textAlign: 'end',
                color: '#454A67',
                width: manufacture.status === 'FINISH' ? '100%' : 'auto'
              }}
            >
              RENDIMENTO
            </CardContentTitle>

            <CardContentElement
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: manufacture.status === 'FINISH' ? '100%' : 'auto'
              }}
            >
              <div
                className="bold"
                style={{ fontSize: isPhone ? '12px' : '18px' }}
              >
                {handleQuantityField(
                  manufacture.status,
                  type === 'RECIPE' ||
                    manufacture?.status === 'FINISH' ||
                    manufacture?.status === 'CANCELED'
                    ? manufacture.yield
                    : (manufacture.quantity || 1) -
                        (manufacture.quantity || 1) * (manufacture.loss / 100),
                  'yield'
                )}
              </div>

              <span
                className="bold"
                style={{
                  fontSize: isPhone ? '12px' : '20px',
                  lineHeight: '20px',
                  color: '#454A67'
                }}
              >
                {manufacture?.un?.abbreviation || ''}
              </span>
            </CardContentElement>
          </CardInfoContent>
        )}

        {!openDrillDown && manufactureStatus !== 'FINISH' ? (
          <CardInfoContent
            width={manufacture.status === 'FINISH' ? '45%' : '35%'}
            style={{
              marginLeft: manufacture.status === 'FINISH' ? '28px' : null
            }}
          >
            <CardContentTitle
              className="mb5"
              style={{
                fontSize: isPhone ? '10px' : '12px',
                lineHeight: '12px',
                textAlign: 'end',
                color: '#454A67',
                width: manufacture.status === 'FINISH' ? '100%' : 'auto'
              }}
            >
              RENDIMENTO{manufacture.status === 'FINISH' ? '/PRODUZIDO' : null}
            </CardContentTitle>

            <CardContentElement
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: manufacture.status === 'FINISH' ? '100%' : 'auto'
              }}
            >
              <div
                className="bold"
                style={{ fontSize: isPhone ? '12px' : '18px' }}
              >
                {handleQuantityField(
                  manufacture.status,
                  type === 'RECIPE' ||
                    manufacture?.status === 'FINISH' ||
                    manufacture?.status === 'CANCELED'
                    ? manufacture.yield
                    : (manufacture.quantity || 1) -
                        (manufacture.quantity || 1) * (manufacture.loss / 100),
                  'yield'
                )}

                <span
                  className="bold"
                  style={{
                    color:
                      manufacture.status !== 'FINISH'
                        ? '#454A67'
                        : lossManufacturedCalc < 0
                        ? '#F53D4C'
                        : lossManufacturedCalc == 0
                        ? '#0E1337'
                        : '#664C00'
                  }}
                >
                  {produced}
                </span>
              </div>

              <span
                className="bold"
                style={{
                  fontSize: isPhone ? '12px' : '20px',
                  lineHeight: '20px',
                  color:
                    manufacture.status !== 'FINISH'
                      ? '#454A67'
                      : lossManufacturedCalc < 0
                      ? '#F53D4C'
                      : lossManufacturedCalc === 0
                      ? '#0E1337'
                      : '#664C00'
                }}
              >
                {manufacture?.un?.abbreviation || ''}
              </span>
            </CardContentElement>
          </CardInfoContent>
        ) : (
          <CardInfoContent width="35%">
            <CardContentTitle
              className="mb5"
              style={{
                fontSize: isPhone ? '10px' : '12px',
                lineHeight: '12px',
                textAlign: 'end',
                color: '#454A67',
                width: manufacture.status === 'FINISH' ? '100%' : 'auto'
              }}
            >
              PRODUZIDO
            </CardContentTitle>

            <CardContentElement
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: manufacture.status === 'FINISH' ? '100%' : 'auto'
              }}
            >
              <div
                className="bold"
                style={{ fontSize: isPhone ? '12px' : '18px' }}
              >
                <span
                  className="bold"
                  style={{
                    color:
                      manufacture.status !== 'FINISH'
                        ? '#454A67'
                        : lossManufacturedCalc < 0
                        ? '#F53D4C'
                        : lossManufacturedCalc == 0
                        ? '#0E1337'
                        : '#664C00'
                  }}
                >
                  {produced}
                </span>
              </div>

              <span
                className="bold"
                style={{
                  fontSize: isPhone ? '12px' : '20px',
                  lineHeight: '20px',
                  color:
                    manufacture.status !== 'FINISH'
                      ? '#454A67'
                      : lossManufacturedCalc < 0
                      ? '#F53D4C'
                      : lossManufacturedCalc === 0
                      ? '#0E1337'
                      : '#664C00'
                }}
              >
                {manufacture?.un?.abbreviation || ''}
              </span>
            </CardContentElement>
          </CardInfoContent>
        )}

        <CardInfoContent
          width="45%"
          style={{ display: 'none' }}
          open={!openDrillDown}
        >
          <CardContentTitle
            className="mb5"
            style={{
              fontSize: isPhone ? '10px' : '12px',
              lineHeight: '12px',
              color: '#454A67'
            }}
          >
            ESTOQUE/DURAÇÃO
          </CardContentTitle>

          <CardContentElement className="bold">
            {numberToReal(manufactureStock)} / {manufactureDuration} dias
          </CardContentElement>
        </CardInfoContent>

        <CardInfoContent
          className="limitProdCard"
          style={{ display: 'none' }}
          width="26.5%"
        >
          <CardContentTitle
            className="mb5"
            style={{
              fontSize: isPhone ? '10px' : '12px',
              lineHeight: '12px',
              color: '#454A67'
            }}
          >
            LIMITE PROD.
          </CardContentTitle>

          <CardContentElement className="bold" backGroundOn>
            {limiteProd || '-----'}
          </CardContentElement>
        </CardInfoContent>

        <CardInfoContent className="limitProdCard" width="26.5%">
          <CardContentTitle
            className="mb5"
            style={{
              fontSize: isPhone ? '10px' : '12px',
              lineHeight: '12px',
              textAlign: 'end',
              color: '#454A67'
            }}
          >
            DATA
          </CardContentTitle>

          <CardContentElement
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <div
              className="bold"
              style={{
                fontSize: isPhone ? '12px' : '18px',
                lineHeight: '20px'
              }}
            >
              {increaseDate(manufacture.date)}
            </div>
          </CardContentElement>
        </CardInfoContent>
      </CardInfoHeader>

      <CardInfoHeader className="openedInfoHeader">
        {openDrillDown && (
          <CardInfoContent className="yieldAndNewDurationCard" width="35%">
            <CardContentTitle
              className="mb5"
              style={{
                fontSize: isPhone ? '10px' : '12px',
                lineHeight: '12px',
                textAlign: 'end'
              }}
            >
              RENDIMENTO
            </CardContentTitle>

            <CardContentElement
              style={{ display: 'flex', justifyContent: 'end' }}
            >
              <div
                className="bold"
                style={{ fontSize: isPhone ? '12px' : '18px' }}
              >
                {performance}
              </div>

              <span className="bold">
                {manufacture?.un?.abbreviation || ''}
              </span>
            </CardContentElement>
          </CardInfoContent>
        )}
        <CardInfoContent
          className="lossAndProducedCard"
          width="50%"
          open={!openDrillDown}
        >
          <CardContentTitle
            className="mb5"
            style={{ fontSize: isPhone ? '10px' : '12px', lineHeight: '12px' }}
          >
            PERDA/PRODUZIDO
          </CardContentTitle>

          <CardContentElement>
            {numberToReal(lossManufactured)} /
            <span
              className="bold"
              style={{
                color:
                  manufactureQuantity && totalItemsQuantity
                    ? parseFloat(manufactureQuantity) - parseFloat(cardYield) <
                      parseFloat(manufactureQuantity) -
                        parseFloat(totalItemsQuantity)
                      ? '#F53D4C'
                      : '#010311'
                    : '#010311'
              }}
            >
              {numberToReal(manufactured)}{' '}
              {manufacture?.type === 'TRANSFORMATION'
                ? manufacture?.items?.[0]?.un?.abbreviation
                : manufacture?.abbreviation}
            </span>

            <span
              className="bold"
              style={{
                marginLeft: 10,
                color:
                  parseFloat(100 * (manufactureQuantity - totalItemsQuantity)) /
                    parseFloat(manufactureQuantity) >
                  parseFloat(lossManufactured)
                    ? '#F53D4C'
                    : '#010311'
              }}
            >
              {totalItemsQuantity === 0 ? (
                <>&nbsp;</>
              ) : (
                <>
                  {`${(
                    parseFloat(
                      100 * (manufactureQuantity - totalItemsQuantity)
                    ) / parseFloat(manufactureQuantity)
                  ).toLocaleString('pt-br', { minimumFractionDigits: 3 })}%`}
                </>
              )}
            </span>
          </CardContentElement>
        </CardInfoContent>
      </CardInfoHeader>

      <CardInfoContent>
        {manufacture?.type !== 'TRANSFORMATION' || transformationInfos ? (
          <Dialog
            className="modalProducao"
            open={openModal}
            fullWidth
            maxWidth="sm"
            onClose={handleCloseModal}
            PaperComponent={PaperComponent}
          >
            <CardModal cardType={manufacture?.type}>
              <div className="leftFakeBorder" />
              <div className="rightFakeBorder" />

              <MobileCardDetail
                handleItemDiscard={handleItemDiscard}
                setCheckSumeItems={setCheckSumeItems}
                setCurrentTransformationCardItems={
                  setCurrentTransformationCardItems
                }
                forceEnable={forceEnable}
                updateQuantityInitialValue={updateQuantityInitialValue}
                setModal={setModalStates}
                mainModalStates={mainModalStates}
                setCardDate={setCardDate}
                manufactures={manufactures}
                setManufactures={setManufactures}
                isOpen={openModal}
                transformationInfos={transformationInfos}
                index={index}
                description={description}
                state={state}
                manufactureYield={manufactureYield}
                newDuration={newDuration}
                limiteProd={limiteProd}
                manufactureQuantity={manufactureQuantity}
                manufactureTotalYield={manufactureTotalYield}
                manufactureStatus={manufactureStatus}
                dateStatus={dateStatus}
                items={items}
                actions={actions}
                manufacture={manufacture}
                optionsDiscard={optionsDiscard}
                manufactureStock={manufactureStock}
                manufactureDuration={manufactureDuration}
                lossManufactured={lossManufactured}
                manufactured={manufactured}
                totalItemsQuantity={totalItemsQuantity}
                cardYield={cardYield}
                type={type}
                loss={manufacture.loss}
                income={performance}
              />
            </CardModal>
          </Dialog>
        ) : null}
      </CardInfoContent>
    </CardsContainer>
  );
}

function MobileProductionCards({
  handleItemDiscard,
  setModal,
  mainModalStates,
  updateQuantityInitialValue,
  originId,
  currentTransformationCardItems,
  setCurrentTransformationCardItems,
  setManufacture,
  manufactures,
  setManufactures,
  state,
  optionsDiscard,
  actions,
  loading,
  optionsCards,
  setCardDate,
  forceEnable,
  setForceEnable,
  setCheckSumeItems,
  modalSettings,
  setModalSettings
}) {
  const { cards } = state;

  const loadingCard = () => (
    <CardsContainer className="loading-card">
      <div className="div-loading-title-btnPlay">
        <Skeleton variant="rect" width="44px" height="44px" />
        <Skeleton variant="rect" width="85%" height="22px" />
      </div>

      <div className="div-loading-status">
        <Skeleton variant="rect" width="100%" height="32px" />
      </div>

      <div className="div-loading-info">
        <div className="div-loading-quantity">
          <Skeleton
            variant="rect"
            width="100%"
            height="12px"
            className="loading-info-title"
          />
          <Skeleton variant="rect" width="100%" height="20px" />
        </div>

        <div className="div-loading-yield">
          <Skeleton
            variant="rect"
            width="100%"
            height="12px"
            className="loading-info-title"
          />
          <Skeleton variant="rect" width="100%" height="20px" />
        </div>
        
        <div className="div-loading-date">
          <Skeleton
            variant="rect"
            width="100%"
            height="12px"
            className="loading-info-title"
          />
          <Skeleton variant="rect" width="100%" height="20px" />
        </div>
      </div>
    </CardsContainer>
  );

  return (
    <PullToRefresh
      className="customPullToRefresh"
      onRefresh={() =>
        new Promise(() => {
          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
      }
    >
      <CardContainer>
        {manufactures.map((manufacture, index) => {
          const {
            description,
            _id,
            quantity,
            status,
            items,
            stock,
            duration,
            loss,
            manufactured,
            type,
            date
          } = manufacture;
          const fields = handleFields(manufacture);
          const dateStatus =
            status === 'CANCELED'
              ? manufacture
              : manufacture.actions.find(
                  (el) => el.type === status && el.type !== 'WAITING'
                );
          const exists = cards ? cards.find((x) => x?.id === index) : null;

          if (cards && cards.length < manufactures.length && !exists) {
            cards.push({
              id: index,
              open: false,
              quantity: manufacture.quantity,
              type: type.toLowerCase(),
              startTime: manufacture.actions
                .reverse()
                .find((x) => x.type === 'START')
                ? manufacture.actions.reverse().find((x) => x.type === 'START')
                    .date
                : null,
              pauseTime: manufacture.actions
                .reverse()
                .find((x) => x.type === 'PAUSE')
                ? manufacture.actions.reverse().find((x) => x.type === 'PAUSE')
                    .date
                : null,
              finishTime: manufacture.actions
                .reverse()
                .find((x) => x.type === 'FINISH')
                ? manufacture.actions.reverse().find((x) => x.type === 'FINISH')
                    .date
                : null,
              manufactured: manufacture.manufactured,
              totalLoss: null,
              loss: manufacture.loss ? manufacture.loss : 0,
              totalYield:
                type.toLowerCase() === 'recipe'
                  ? manufacture.yield * 1
                  : 1 - 1 * (manufacture.loss / 100),
              initialYield:
                type.toLowerCase() === 'recipe'
                  ? manufacture.yield * 1
                  : 1 - 1 * (manufacture.loss / 100),
              items: []
            });
          }

          const cardYield =
            manufacture.type === 'recipe'
              ? manufacture.yield * (manufacture.quantity || 1)
              : (manufacture.quantity || 1) -
                (manufacture.quantity || 1) * (manufacture.loss / 100);
          const total = manufacture.items.reduce(
            (total, value) =>
              value
                ? parseFloat(total) + parseFloat(value.quantity)
                : parseFloat(total),
            0
          );

          return (
            <>
              {!loading ? (
                <MobileCard
                  handleItemDiscard={handleItemDiscard}
                  setCheckSumeItems={setCheckSumeItems}
                  forceEnable={forceEnable}
                  setForceEnable={setForceEnable}
                  updateQuantityInitialValue={updateQuantityInitialValue}
                  setModalStates={setModal}
                  mainModalStates={mainModalStates}
                  setCardDate={setCardDate}
                  manufactures={manufactures}
                  setManufactures={setManufactures}
                  originId={originId}
                  currentTransformationCardItems={
                    currentTransformationCardItems
                  }
                  setCurrentTransformationCardItems={
                    setCurrentTransformationCardItems
                  }
                  setManufacture={setManufacture}
                  index={index}
                  key={_id}
                  id={`MobileProductionCard${_id}`}
                  description={description}
                  state={state}
                  manufactureYield={fields.manufactureYield}
                  newDuration={fields.newDuration}
                  limiteProd={fields.manufactureLimit}
                  manufactureQuantity={manufacture.quantity || 1}
                  manufactureTotalYield={
                    manufacture.initialYield
                      ? manufacture.yield
                      : type.toLowerCase() === 'recipe'
                      ? manufacture.yield * (manufacture.quantity || 1)
                      : (manufacture.quantity || 1) -
                        (manufacture.quantity || 1) * (manufacture.loss / 100)
                  }
                  manufactureStatus={status}
                  manufactureStock={stock}
                  manufactureDuration={duration}
                  lossManufactured={loss}
                  manufactured={
                    (manufacture.quantity || 1) && total
                      ? (
                          parseFloat(manufacture.quantity || 1) -
                          parseFloat(total)
                        ).toLocaleString('pt-br', { minimumFractionDigits: 3 })
                      : (
                          parseFloat(manufacture.quantity || 1) -
                          parseFloat(cardYield)
                        ).toLocaleString('pt-br', { minimumFractionDigits: 3 })
                  }
                  type={type}
                  dateStatus={dateStatus}
                  items={items}
                  actions={actions}
                  manufacture={manufacture}
                  optionsDiscard={optionsDiscard}
                  totalItemsQuantity={total}
                  cardYield={cardYield}
                  optionsCards={optionsCards}
                  modalSettings={modalSettings}
                  setModalSettings={setModalSettings}
                />
              ) : (
                loadingCard()
              )}
            </>
          );
        })}
      </CardContainer>
    </PullToRefresh>
  );
}

export default MobileProductionCards;
