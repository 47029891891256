/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useMemo } from 'react';

import {
  Fade,
  Tooltip,
} from '@material-ui/core';
import moment from 'moment';
import { useParams } from 'react-router';

import ConfigColumnsIcon from 'images/icons/columns/collumnsWhiteOutline.svg';
import ConfigIcon from 'images/icons/configsAndSetups/configIconWhite.svg';
import DownloadIcon from 'images/icons/downloadUpload/downloadIcon.svg';

import {
  TrTablet,
  TrDesk,
} from './styles';

import { handleSelectedRealDiff } from '../MainFunctions';

function DiaryCountTableHead({
  countGroupInfos,
  handleProductDialog,
  periodicityDescription,
  periodicityId,
  storageLocationDescription,
  isSelectedCost,
  products,
  countDate,
  parametersInfos,
  differenceBetweenCountAndMenuAlert,
  downloadReportConsolidated,
  handlePopoverClick,
  showColumns,
  smallScreen
}) {
  const { id: paramsID } = useParams();
  const reportTabObj = countGroupInfos?.countGroup?.counts?.find(
    (el) => el?.type === 'report'
  );
  const hasOnlyOneCount =
    countGroupInfos?.countGroup?.counts?.filter(
      (el) => el?.type !== 'report' && el
    ).length === 1;

  const canShowTheoreticalAlert = useMemo(() => {
    const isSameDayOrAfter = moment(countDate).isSameOrAfter(moment(), 'day');

    return (
      countDate && isSameDayOrAfter && !parametersInfos?.closure?.runManually
    );
  }, [parametersInfos, countDate]);

  const colspan = () => {
    if (showColumns?.filter((el) => el.includes('DIFERENÇA')) && showColumns?.find((el) => el === 'USUÁRIO') && !reportTabObj?.active) {
      return showColumns?.filter((el) => !el.includes('DIFERENÇA'))?.length + 3
    } 
    if (showColumns?.filter((el) => el.includes('DIFERENÇA')) && !showColumns?.find((el) => el === 'USUÁRIO') && !reportTabObj?.active) {
      return showColumns?.filter((el) => !el.includes('DIFERENÇA'))?.length + 4
    }
    if (showColumns?.filter((el) => el.includes('DIFERENÇA')) && showColumns?.find((el) => el === 'USUÁRIO') && reportTabObj?.active) {
      return showColumns?.filter((el) => !el.includes('DIFERENÇA'))?.length 
    }
    if (showColumns?.filter((el) => el.includes('DIFERENÇA')) && !showColumns?.find((el) => el === 'USUÁRIO') && reportTabObj?.active) {
      return showColumns?.filter((el) => !el.includes('DIFERENÇA'))?.length + 1
    }
  }

  return (
    <thead
      style={{
        position: 'sticky',
        top:
          countGroupInfos?.countGroup?.counts?.length &&
            countGroupInfos.isCountGroup
            ? smallScreen 
              ? differenceBetweenCountAndMenuAlert
                ? '268px' 
                : '225px' 
              : differenceBetweenCountAndMenuAlert 
                ? '135px'
                : '111px'
            : '62px',
        backgroundColor: 'white',
        zIndex: '990'
      }}
    >
      <TrDesk>
        <th style={{ width: '16%' }} className="subtitle">
          <div className="dFlex alignCenter">
            <span
              onClick={() =>
                handleProductDialog(
                  periodicityDescription,
                  storageLocationDescription
                )
              }
              className="cursorPointer white"
            >
              {products?.length} PRODUTOS
            </span>

            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={
                <p
                  className="dFlex mb0"
                  style={{
                    padding: '2px 5px'
                  }}
                >
                  Gerenciar os Produtos desta Contagem
                </p>
              }
            >
              <img
                style={{ width: '15px' }}
                src={ConfigIcon}
                alt="ConfigIcon"
                className="ml10 cursorPointer"
                onClick={() =>
                  handleProductDialog(
                    periodicityDescription,
                    storageLocationDescription
                  )
                }
              />
            </Tooltip>

            {countGroupInfos.isCountGroup && !hasOnlyOneCount && (
              <p
                className="m0 ml5 cursorPointer dFlex justifyEnd"
                style={{
                  color: 'rgba(80,98,240,1)',
                  textDecoration: 'underline',
                  padding: '0 6px'
                }}
                onClick={() =>
                  downloadReportConsolidated(periodicityId, countDate)
                }
              >
                <Tooltip
                  placement="top"
                  title={
                    <p className="mb0" style={{ padding: '4px 2px' }}>
                      Download do relatório
                    </p>
                  }
                >
                  <img src={DownloadIcon} alt="Report" />
                </Tooltip>
              </p>
            )}

            <p
              className="m0 cursorPointer dFlex justifyEnd"
              style={{
                color: 'rgba(80,98,240,1)',
                textDecoration: 'underline',
              }}
              onClick={(e) => handlePopoverClick(e)}
            >
              <Tooltip
                placement="top"
                title={
                  <p className="mb0" style={{ padding: '4px 2px' }}>
                    Exibir/Ocultar colunas
                  </p>
                }
              >
                <img src={ConfigColumnsIcon} alt="Config Show Columns" />
              </Tooltip>
            </p>
          </div>
        </th>

        {!reportTabObj?.active && (
          <>
            <th style={{ width: '9%' }} className="subtitle pr8">
              SKU
            </th>

            <th style={{ width: '2%' }} className="subtitle">
              EMBALAGEM
            </th>

            <th
              style={{ width: '7%', borderRight: 'solid 1px #BFC0CB' }}
              className="subtitle right p8"
            >
              CONTAGEM
            </th>
          </>
        )}

        <th
          style={{ width: '8%' }}
          className={`subtitle p8 ${!showColumns?.includes('OUTROS ESTOQUES') && 'dNone'
            }`}
        >
          {reportTabObj?.active ? 'ESTOQUES' : 'OUTROS ESTOQUES'}
        </th>

        <th
          style={{ width: '5%' }}
          className={`subtitle pl8 pr8 right ${!showColumns?.includes('REAL') && 'dNone'
            }`}
        >
          REAL
        </th>

        <th
          style={{ width: '5%' }}
          className={`subtitle p8 right ${!showColumns?.includes('TEÓRICO') && 'dNone'
            }`}
        >
          TEÓRICO
        </th>

        <th
          style={{ width: '5%' }}
          className={`subtitle p8 right ${!showColumns?.includes('ÚLTIMA') && 'dNone'
            }`}
        >
          ÚLTIMA
        </th>

        <th
          className={`subtitle p8 right ${!showColumns?.includes('CONSUMO') && 'dNone'
            }`}
          style={{ width: '5%', borderLeft: 'solid 1px #BFC0CB' }}
        >
          CONSUMO
        </th>

        {/* <th
            style={{ width: '5%', borderLeft: 'solid 1px #BFC0CB' }}
            className="subtitle right p8"
          >
            PREÇO R$
          </th>

          <th style={{ width: '5%' }} className="subtitle right p8">
            TOTAL R$
          </th> */}

        <th
          style={{ width: '5%', borderLeft: 'solid 1px #BFC0CB' }}
          className={`subtitle p8 right ${!showColumns?.includes('DIFERENÇA EM QTDE / R$') && 'dNone'
            } ${!showColumns?.includes('DIFERENÇA EM %') ? 'p8' : 'pl8'}`}
        >
          EM {isSelectedCost ? 'R$' : 'QTDE'}
        </th>

        <th
          style={{ width: '5%',  borderLeft: 'solid 1px #BFC0CB' }}
          className={`subtitle p8 right ${!showColumns?.includes('DIFERENÇA EM %') && 'dNone'
            }`}
        >
          % / CONSUMO
        </th>

        <th
          style={{ width: '5%', borderLeft: 'solid 1px #BFC0CB' }}
          className={`subtitle p8 ${!showColumns?.includes('USUÁRIO') && 'dNone'
            }`}
        >
          USUÁRIO
        </th>
      </TrDesk>

      <TrTablet>
        <th className="subtitle">
          <div className="dFlex alignCenter">
            <span
              onClick={() =>
                handleProductDialog(
                  periodicityDescription,
                  storageLocationDescription
                )
              }
              className="cursorPointer white"
            >
              {products?.length} PRODUTOS
            </span>

            <Tooltip
              TransitionComponent={Fade}
              TransitionProps={{ timeout: 600 }}
              title={
                <p
                  className="dFlex mb0"
                  style={{
                    padding: '2px 5px'
                  }}
                >
                  Gerenciar os Produtos desta Contagem
                </p>
              }
            >
              <img
                style={{ width: '15px' }}
                src={ConfigIcon}
                alt="ConfigIcon"
                className="ml10 cursorPointer"
                onClick={() =>
                  handleProductDialog(
                    periodicityDescription,
                    storageLocationDescription
                  )
                }
              />
            </Tooltip>

            <p
              className="m0 ml5 cursorPointer dFlex justifyEnd"
              style={{
                color: 'rgba(80,98,240,1)',
                textDecoration: 'underline',
                padding: '0 6px'
              }}
              onClick={(e) => handlePopoverClick(e)}
            >
              <Tooltip
                placement="top"
                title={
                  <p className="mb0" style={{ padding: '4px 2px' }}>
                    Exibir/Ocultar colunas
                  </p>
                }
              >
                <img src={ConfigColumnsIcon} alt="Config Show Columns" />
              </Tooltip>
            </p>
          </div>
        </th>

        {!reportTabObj?.active && (
          <>
            <th style={{ width: '9%' }} className="subtitle pr8">
              SKU
            </th>

            <th style={{ width: '10%' }} className="subtitle">
              EMB./QTDE
            </th>
          </>
        )}

        <th
          style={{ width: '9%' }}
          className={`subtitle p8 ${!showColumns?.includes('OUTROS ESTOQUES') && 'dNone'
            }`}
        >
          {reportTabObj?.active ? 'EST' : 'OUTROS'}
        </th>

        <th
          style={{ width: '7%' }}
          className={`subtitle pl8 pr8 right ${!showColumns?.includes('REAL') && 'dNone'
            }`}
        >
          REAL
        </th>

        <th
          style={{ width: '1%' }}
          className={`subtitle right p8 ${!showColumns?.includes('TEÓRICO') && 'dNone'
            }`}
        >
          TEÓR.
        </th>

        <th
          style={{ width: '7%' }}
          className={`subtitle p8 right ${!showColumns?.includes('ÚLTIMA') && 'dNone'
            }`}
        >
          ÚLT.
        </th>

        <th
          className={`subtitle p8 right ${!showColumns?.includes('CONSUMO') && 'dNone'
            }`}
          style={{ width: '7%' }}
        >
          CONS.
        </th>

        <th
          style={{ width: '5%' }}
          className={`subtitle center ${!showColumns?.includes('DIFERENÇA EM QTDE / R$') && 'dNone'
            } ${!showColumns?.includes('DIFERENÇA EM %') ? 'p8' : 'pl8'}`}
        >
          {isSelectedCost ? 'R$' : 'QTDE'}
        </th>

        <th
          width="5%"
          className={`subtitle center ${!showColumns?.includes('DIFERENÇA EM %') && 'dNone'
            }`}
        >
          %
        </th>

        <th
          width="5%"
          className={`subtitle center ${!showColumns?.includes('USUÁRIO') && 'dNone'
            }`}
        >
          USER
        </th>
      </TrTablet>
    </thead>
  );
}

export default DiaryCountTableHead;
