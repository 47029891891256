/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-expressions */
import React, {
  useState,
  useEffect,
  useCallback,
  Suspense,
  useRef,
  useMemo
} from 'react';

import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Fade,
  IconButton,
  LinearProgress,
  Paper,
  Snackbar,
  Tooltip,
  Typography
} from '@material-ui/core';
import { useTheme, withStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Add, DeleteOutline, Delete } from '@material-ui/icons';
import { Alert, Pagination } from '@material-ui/lab';
import axios from 'axios';
import { format } from 'date-fns';
import moment, { now } from 'moment';
import Draggable from 'react-draggable';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useParams, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import CostTimeline from 'components/Dialogs/CostTimeline';
import DialogCards from 'components/Dialogs/DialogCards';
import DialogQuotes from 'components/Dialogs/DialogQuotes';
import Discard from 'components/Dialogs/Discard';
import ProductSkus from 'components/Dialogs/ProductSkus';
import ProductTransference from 'components/Dialogs/ProductTransference';
import ProvidersDialog from 'components/Dialogs/ProvidersDialog';
import SkuModal from 'components/Dialogs/SkuModal/SkuModal';
import TechnicalSheetDialog from 'components/Dialogs/TechnicalSheetDialog/TechnicalSheetDialog';
import UnifyProducts from 'components/Dialogs/UnifyProducts';
import ImageWrapper from 'components/ImageWrapper';
import ItemsPerPage from 'components/ItemsPerPage';
import OrderBy from 'components/OrderBY';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TableIcon from 'components/TableIcon';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import TransferIcon from 'images/icons/arrows/sendIconLightGray.svg';
import ExtractIcon from 'images/icons/combinations/historyTime.svg';
import DownloadIcon from 'images/icons/downloadUpload/download.svg';
import UploadIcon from 'images/icons/downloadUpload/upload.svg';
import ProductSetupIcon from 'images/icons/objects/pencils/productSetup.svg';
import ProductSetupBlue from 'images/icons/objects/pencils/productSetupBlueOutline.svg';
import ProductSetupGray from 'images/icons/objects/pencils/productSetupGrayOutline.svg';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import DialogProducts from 'pages/catalog/products/dialogs/dialogProducts'
import DialogTags from 'pages/catalog/products/dialogs/dialogTags';
import { InputFile } from 'pages/quotesUpload/style';
import getOptions from 'services/getOptions';
import axiosInstanceCatalog from 'services/middlewares/apis/catalog_validate';
import axiosInstanceOrder from 'services/middlewares/apis/orders_validate';
import * as ProductsActions from 'store/actions/products';
import { changeFilter, clearFilter } from 'store/reducers/productsFilterSlice';
import { Container, GlobalStyle } from 'styles/general';
import convertToBase64 from 'utils/convertToBase64';
import Environment from 'utils/environments';
import formatDocumentNumber from 'utils/formatDocumentNumber';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import handleQuotations from 'utils/quotations/handleQuotations';
import hasMainTags from 'utils/tags/hasMainTags';
import ValidationLength from 'utils/utils';

const environments = Environment(process.env.REACT_APP_ENV);

const TheoryStockDialog = React.lazy(() =>
  import('components/Dialogs/ExtractsDialog')
);
const ProductSetupsDialog = React.lazy(() =>
  import('pages/catalog/products/dialogs/ProductSetups')
);

export const countProviders = (product) => {
  const formattedProviders = product?.skus?.map((sku) =>
    sku?.skus?.providers?.map((provider) => ({
      ...provider,
      skuInfo: sku?.skus?.unitsMeasurements
    }))
  );

  const uniqueProviders = [];
  formattedProviders.flat().map((provider) => {
    if (uniqueProviders.some((el) => el.providerId === provider.providerId)) {
      return null;
    }

    uniqueProviders.push(provider);
  });

  return [...new Set(uniqueProviders)].length;
};

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function Fabricantes({ userInfo, createProducts, setEditProducts }) {
  const dispatch = useDispatch();
  const { id: paramsID } = useParams();
  const history = useHistory();
  const inputRef = useRef();
  const theme = useTheme();
  const prevPath = history?.location?.state?.prevPath;
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const [loading, setLoading] = useState(false);
  const [loadingProviders, setLoadingProviders] = useState(false);
  const [alertErr, setAlertErr] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [severity, setSeverity] = useState();
  const [statusCode, setStatusCode] = useState();
  const [idRest, setIdRest] = useState()
  const [validId, setValidId] = useState(0)
  const [updateAutocomplete, setUpdateAutocomplete] = useState(false)
  const isAdminPage = userInfo.profile[0][0]?.name === 'admin';
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const profile = userInfo?.profile[0][0]?.name;
  const stores = useState(
    userInfo.companies.groups
      .reduce((acc, group) => [...acc, group.subGroups], [])
      .flat()
      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
      .flat()
  );
  const storedProductsFilter = useSelector(
    (currState) => currState.productsFilter.filters
  );
  const [checkConsume, setCheckConsume] = useState(false);

  const shouldUseStoredFilters = useMemo(
    () => storedProductsFilter && prevPath,
    [prevPath, storedProductsFilter]
  );

  const [callSettings, setCallSettings] = useState({
    getId: paramsID,
    mainLoading: false,
    page: 1,
    totalPages: 0,
    size: 100,
    filters: shouldUseStoredFilters ? storedProductsFilter : [],
    numberOfElements: 0,
    totalElements: 0
  });

  const {
    getId,
    mainLoading,
    page,
    size,
    totalPages,
    numberOfElements,
    totalElements
  } = callSettings;
  const [filtersChanged, setFiltersChanged] = useState(callSettings?.filters);
  const [requestStorages, setRequestStorages] = useState(false)
  const [openModalMenu, setOpenModalMenu] = useState(false);

  const cancelTokenSource = axios.CancelToken.source();  
  const [modalSettings, setModalSettings] = useState([
    {
      id: 1,
      name: 'providersAssociation',
      open: false,
      fullScreen: false,
      maxWidth: 'md',
      product: null
    },
    {
      id: 2,
      name: 'productsAssociation',
      open: false,
      fullScreen: false,
      maxWidth: 'md',
      product: null
    },
    {
      id: 3,
      name: 'cardsAssociation',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      product: null,
      items: null
    },
    { id: 4, name: 'filters', open: false, fullScreen: null, maxWidth: null },
    { id: 5, name: 'skuModal', open: false, fullScreen: false, maxWidth: 'sm' },
    {
      id: 6,
      name: 'technicalSheet',
      open: false,
      fullScreen: false,
      maxWidth: 'sm'
    },
    { id: 7, name: 'productEdit', open: false, fullScreen: false, maxWidth: 'lg'},
    { id: 8, name: 'productTransference', open: false, fullScreen: false, maxWidth: 'lg'},
    { id: 9, name: 'costTimeline', open: false, fullScreen: false, maxWidth: 'lg', product: ''}
  ]);
  const [products, setProducts] = useState([]);
  const [periodicities, setPeriodicities] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [providers, setProviders] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState();
  const [loadingDiscard, setLoadingDiscard] = useState(false);
  const [currentDiscardReason, setCurrentDiscardReason] = useState('');
  const [hasChangeForm, setHasChangeForm] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [unifyProductsPopover, setUnifyProductsPopover] = useState({
    open: false,
    products: [],
    refProduct: ''
  });
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });
  const [cardDataTable, setCardDataTable] = useState([]);
  const [cardTransformation, setCardTransformation] = useState({
    type: 'product',
    productId: null,
    stock: 0,
    consumptionUnitsPrimaryId: null,
    consumptionUnitsPrimary: null,
    cost: 0,
    averageCost: 0,
    lastCost: 0,
    quantity: 0
  });

  const [selectedCard, setSelectedCard] = useState();
  const [isGroupUnification, setIsGroupUnification] = useState(false);
  const [productsToUnifyGroupMainInfos, setProductsToUnifyGroupMainInfos] =
    useState({
      sameType: false,
      description: ''
    });
  const [
    selectedProductsToUnifyGroupInfos,
    setSelectedProductsToUnifyGroupInfos
  ] = useState([]);
  const [ucPrimarySelectedId, setUcPrimarySelectedId] = useState();
  const [deleteError, handleDeleteError] = useState(false);
  const [deleteData, setDeleteData] = useState();
  const [deleteSuccess, handleDeleteSuccess] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [productSetupFilter, setProductSetupFilter] = useState();
  const [hasMenuModalChange, setHasMenuModalChange] = useState(false)
  const [salesAverageDiscount, setSalesAverageDiscount] = useState()
  const [modalStates, setModalStates] = useState({
    modalProduct: false,
    modalProductSetups: false,
    modalTypeOfUnification: false,
    modalDiscard: false
  });
  const [dialogQuotesSettings, setDialogQuotesSettings] = useState({
    open: false,
    sku: {}
  });
  const [dialogTagsSettings, setDialogTagsSettings] = useState({
    open: false,
    productName: '',
    productId: null
  });

  const [productExtractDescription, setProductExtractDescription] =
    useState('-');
  const [parameters, setParameters] = useState('');
  const [selectedSku, setSelectedSku] = useState([]);
  const [productModal, setProductModal] = useState({});
  const [quotationProduct, setQuotationProduct] = useState();
  const [dialogProvidersList, setDialogProvidersList] = useState([]);
  const [toastProps, setToastProps] = useState({});
  const [toastOpen, setToastOpen] = useState(false);
  const [brands, setBrands] = useState([]);
  const [storageLocations, setStorageLocations] = useState([]);
  const [unitsMeasurements, setUnitsMeasurements] = useState([]);
  const [consumptionUnits, setConsumptionUnits] = useState([]);
  const [productDataModal, setProductDataModal] = useState({data: {}})
  const [newData, setNewData] = useState({
    card: null,
    data: {
      description: null,
      originId: getId,
      unitsMeasurementsId: null,
      categoryId: null,
      cardsId: null,
      period: null,
      startDateCurrent: null,
      endDateCurrent: null,
      user: userInfo.user,
      pdv: {
        integrated: false,
        code: null,
        description: null
      }
    },
    discard: []
  });
  const isGroupManage = getId?.includes(',');

  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'Produto',
        filterName: 'productDescription',
        filterNameSecond: 'productId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        key: 'products',
        optionReference: 'description',
        urlPath: `${environments.catalog}/products`,
        options: [],
        size: 'medium',
        searchByTermMode: true,
        storedValue:
          (shouldUseStoredFilters &&
            storedProductsFilter.find(
              (filter) => filter.filterName === 'productDescription'
            )?.productDescription) ||
          storedProductsFilter.find(
            (filter) => filter.filterName === 'productId'
          )?.textValue
      },
      {
        label: 'SKU',
        filterName: 'skuDescription',
        filterNameSecond: 'skuId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/skus`,
        key: 'skus',
        optionReference: 'description',
        options: [],
        size: 'medium',
        searchByTermMode: true,
        storedValue:
        (shouldUseStoredFilters &&
          storedProductsFilter.find(
            (filter) => filter.filterName === 'skuDescription'
          )?.skuDescription) ||
        storedProductsFilter.find((filter) => filter.filterName === 'skuId')
          ?.textValue
      },
      {
        label: 'Categoria',
        filterName: 'category',
        placeHolder: 'Selecione...',
        type: 'newTreeSelector',
        urlPath: `${environments.catalog}/categories`,
        key: 'categoryDescription',
        page: 1,
        optionReference: 'description',
        options: [],
        size: 'medium_to_plus',
        storedValue:
          shouldUseStoredFilters &&
          storedProductsFilter.find(
            (filter) => filter.filterName === 'category'
          )?.textValue
      },
      profile !== 'admin' && {
        label: 'Curva',
        type: 'multipleCheckbox',
        checkBoxInputs: [{ name: 'A' }, { name: 'B' }, { name: 'C' }],
        storedValue:
          shouldUseStoredFilters &&
          storedProductsFilter
            .find((filter) => filter.filterName === 'curve')
            ?.curve?.split(',')
      },
      {
        label: 'Características',
        type: 'multipleCheckbox',
        checkBoxInputs:
          profile !== 'admin'
            ? [
                {
                  name: 'Produto interno',
                  referenceName: 'internalProduct'
                },
                {
                  name: 'Controlar produção',
                  referenceName: 'controlProduction'
                },
                { name: 'Produtos sem SKU', referenceName: 'noSkuProduct' },
                {
                  name: 'Inventário não configurado',
                  referenceName: 'notHasInventory'
                }
              ]
            : [{ name: 'Produtos sem SKU', referenceName: 'noSkuProduct' }],
        storedValue:
          shouldUseStoredFilters &&
          storedProductsFilter.map((item) => {
            const valueKey = item.filterName;

            return item[valueKey] ? valueKey : '';
          })
      },
      profile === 'admin' && {
        label: 'Tag',
        type: 'multipleCheckbox',
        checkBoxInputs: [
          {
            name: 'Com tags comparáveis',
            referenceName: 'hasTag',
            keepFilter: true
          },
          {
            name: 'Sem tags comparáveis',
            referenceName: 'missingTag',
            keepFilter: true
          }
        ],
        storedValue:
          shouldUseStoredFilters &&
          storedProductsFilter.map((item) => {
            const valueKey = item.filterName;

            return item[valueKey] ? valueKey : '';
          })
      }
    ]
  ]);

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  function handleCloseAll(handle) {
    handle(false);
  }

  function handleDelete() {
    return new Promise((resolve, reject) => {
      const baseUrl = `${environments.catalog}/products`;
      const data = products;
      const index = data.indexOf(deleteData);
      products.splice(index, 1);

      axios
        .delete(`${baseUrl}/${deleteData.id}`, config)
        .then(() => {
          setAlertErr(true);
          setAlertMessage('Produto deletado com sucesso!');
          setSeverity('success');
          resolve();
          handleDialogClose();
        })
        .catch((error) => {
          setAlertErr(true);
          setAlertMessage(error?.response?.data?.message);
          setStatusCode(error?.response?.status);
          reject();
        });
    });
  }

  const clearFilterValues = (param) => {
    const FILTER_FIELDS = filterFields.map((filterLine, index) => {
      if (index === 0) {
        return filterLine.map((item) => {
          if (item?.filterName === param) {
            return {
              ...item,
              storedValue: ''
            };
          }

          return item;
        });
      }

      return filterLine;
    });

    setFilterFields(FILTER_FIELDS);
  };

  const handleFilter = useCallback(
    (param, value, filter, paramSecond) => {
      const hasFilterWithFirstName = filtersChanged?.filter(
        (el) => el?.param === param
      )?.length;
      const hasFilterWithSecondName = filtersChanged?.filter(
        (el) => el?.param === paramSecond
      )?.length;

      let formattedFilters = hasFilterWithSecondName
        ? filtersChanged?.filter((el) => el?.param !== paramSecond)
        : filtersChanged;

      if (!value) {
        clearFilterValues(param);

        dispatch(clearFilter());

        if (hasFilterWithFirstName || hasFilterWithSecondName) {
          formattedFilters = filtersChanged?.filter(
            (el) => el?.param !== paramSecond && el?.param !== param
          );
        }
      }

      dispatch(
        changeFilter({
          param,
          value,
          textValue: filter?.title
        })
      );

      cancelTokenSource.cancel();

      handleFilterOptions(param, value, formattedFilters, setFiltersChanged);
    },
    [dispatch, filtersChanged]
  );

  const removeProductOfSelectedProducts = (product) => {
    setSelectedProductsToUnifyGroupInfos(
      selectedProductsToUnifyGroupInfos.filter((el) => el?.id !== product?.id)
    );
  };

  const getProductGroupInfo = (product) => {
    axios
      .get(`${environments.catalog}/products/${product?.id}/groups`)
      .then((response) => {
        const newProduct = {
          id: product?.id,
          isGroup: response?.data?.length > 1
        };

        setSelectedProductsToUnifyGroupInfos([
          ...selectedProductsToUnifyGroupInfos,
          newProduct
        ]);
      })
      .catch((error) => {
        setAlertErr(true);
        setAlertMessage(error?.response?.message);
        setStatusCode(error?.response?.status);
      });
  };

  const handleSelectProductToUnify = (product) => {
    let enabledUnits = [];
    const consumptionIsUnity =
      product?.consumptionUnitsPrimary?.abbreviation === 'un';
    const productUnity = product?.consumptionUnitsPrimary?.abbreviation;
    const isAlreadySelected = unifyProductsPopover?.products?.find(
      (item) => item.id === product.id
    );
    const filteredProducts = unifyProductsPopover.products.filter(
      (item) => item.id !== product.id
    );
    const alreadyLiquidUnitsSelected = filteredProducts?.filter((el) => {
      const elUnity = el?.consumptionUnitsPrimary?.abbreviation;

      return elUnity === 'lt' || elUnity === 'ml';
    });
    const alreadyWeightUnitsSelected = filteredProducts?.filter((el) => {
      const elUnity = el?.consumptionUnitsPrimary?.abbreviation;

      return elUnity === 'kg' || elUnity === 'mg' || elUnity === 'g';
    });

    if (
      productUnity === 'kg' ||
      productUnity === 'g' ||
      productUnity === 'mg'
    ) {
      enabledUnits = ['kg', 'g', 'mg', 'un'];
    } else if (productUnity === 'lt' || productUnity === 'ml') {
      enabledUnits = ['lt', 'ml', 'un'];
    }

    if (isAlreadySelected) {
      setUnifyProductsPopover({
        ...unifyProductsPopover,
        products: filteredProducts
      });

      setProducts(
        products.map((el) => {
          const elUnity = el?.consumptionUnitsPrimary?.abbreviation;
          const isLiquidUnit = elUnity === 'lt' || elUnity === 'ml';
          const isWeightUnit =
            elUnity === 'kg' || elUnity === 'mg' || elUnity === 'g';

          if (alreadyLiquidUnitsSelected.length && isWeightUnit) {
            return {
              ...el,
              disableSelection: true
            };
          }

          if (alreadyWeightUnitsSelected.length && isLiquidUnit) {
            return {
              ...el,
              disableSelection: true
            };
          }

          return {
            ...el,
            disableSelection: false
          };
        })
      );
    } else {
      setUnifyProductsPopover({
        ...unifyProductsPopover,
        products: [...unifyProductsPopover.products, product]
      });

      setProducts(
        products.map((el) => {
          const elUnity = el?.consumptionUnitsPrimary?.abbreviation;

          if (!consumptionIsUnity && !enabledUnits.includes(elUnity)) {
            return {
              ...el,
              disableSelection: true
            };
          }

          return {
            ...el
          };
        })
      );
    }
  };

  const handleTypeOfUnification = () => {
    const isEveryProductRestaurant = selectedProductsToUnifyGroupInfos.every(
      (el) => !el?.isGroup
    );
    const isEveryProductGroup = selectedProductsToUnifyGroupInfos.every(
      (el) => el?.isGroup
    );
    const isSameTypeOfProducts =
      isEveryProductRestaurant || isEveryProductGroup;

    setProductsToUnifyGroupMainInfos({
      sameType: isSameTypeOfProducts,
      description: isSameTypeOfProducts
        ? isEveryProductRestaurant
          ? 'Restaurante'
          : 'Grupo'
        : ''
    });

    if (isSameTypeOfProducts) {
      setIsGroupUnification(!isEveryProductRestaurant);
    }
  };

  const handleSelectTypeOfUnification = (value) => {
    setIsGroupUnification(value);
  };

  const handleClosePopover = () => {
    setUnifyProductsPopover({
      ...unifyProductsPopover,
      open: false
    });
  };

  const handleOrdinationFilter = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  };

  // GET PRODUCTS
  const getMainContentByGroup = useCallback(
    (currPage, currSize) => {
      (async () => {
        setProducts([]);
        setCallSettings((prevState) => ({ ...prevState, mainLoading: true }));
        setUpdateAutocomplete(!updateAutocomplete)

        let params = [];

        if (shouldUseStoredFilters && shouldUseStoredFilters !== 'menuHeader') {
          const parsedFilters = storedProductsFilter
            .map((filter) => {
              const key = Object.keys(filter)[0];

              if (filter[key]) {
                return `${key}=${filter[key]}&`;
              }
              return null;
            })
            .join('');

          params = parsedFilters;
        } else {
          params = filtersChanged.map((filter) => filter.urlParam).join('&');
        }

        try {
          const response = await axiosInstanceCatalog.get(
            `${environments.catalog}/products?page=${currPage}&size=${currSize}&originId=${getId}&${params}`,
            { cancelToken: cancelTokenSource.token }
          );

          const productsResponse = await response.data;
          setProducts(productsResponse.content || []);
          setCallSettings((prevState) => ({
            ...prevState,
            totalPages: productsResponse.totalPages,
            mainLoading: false,
            totalElements: productsResponse.totalElements,
            numberOfElements: productsResponse.numberOfElements
          }));
        } catch (error) {
          if (axios.isCancel(error)) {
            console.log('Requisição cancelada:', error.message);
          } else {
            console.error('Erro na requisição:', error);
          }
        }
      })();
    },
    [shouldUseStoredFilters, getId, storedProductsFilter, filtersChanged]
  );

  const handleUnifyProducts = () => {
    setCallSettings((prevState) => ({ ...prevState, mainLoading: true }));

    const body = {
      products:
        unifyProductsPopover?.products?.map((product) => ({
          id: product?.id,
          reference: unifyProductsPopover?.refProduct === product?.id
        })) || [],
      user: isAdminPage ? 0 : userInfo?.user,
      isGroup: isGroupUnification,
      restaurantId: getId
    };

    axios
      .put(`${environments.catalog}/products/unify`, body, config)
      .then(() => {
        const removedProductIds = body.products
          ?.filter((el) => !el.reference)
          ?.map((item) => item?.id);
        setToastOpen(true);
        setToastProps({
          message: 'Produtos unificados com sucesso.',
          severity: 'success',
          timeOutOff: true
        });

        setUnifyProductsPopover({
          open: false,
          products: [],
          refSku: ''
        });

        setProducts(
          products?.filter((el) => !removedProductIds.includes(el?.id))
        );

        setCallSettings((prevState) => ({ ...prevState, mainLoading: false }));
      })
      .catch((error) => {
        setCallSettings((prevState) => ({ ...prevState, mainLoading: false }));
        setAlertErr(true);
        setAlertMessage(error?.response?.message);
        setStatusCode(error?.response?.status);
      });
  };

  const putDiscard = () => {
    setLoadingDiscard(true);
    axios
      .post(`${environments.catalog}/extracts`, newData.discard)
      .then(() => {
        setToastOpen(true);
        setToastProps({
          message: 'Descarte lançado com sucesso',
          severity: 'success'
        });

        setModalStates({
          ...modalStates,
          modalDiscard: false
        });

        setTimeout(() => {
          getMainContentByGroup(page, size)
        }, 100);
      })
      .catch((error) => {
        setModalStates({
          ...modalStates,
          modalDiscard: false
        });
        setAlertErr(true);
        setAlertMessage(error?.response?.message);
        setStatusCode(error?.response?.status);
      })
      .finally(() => setLoadingDiscard(false));
  };

  const handleSelectedCard = (card) => {
    setUcPrimarySelectedId(
      card.consumptionUnits
        ? card.consumptionUnits
        : card.unitsMeasurementsMenu
        ? card.unitsMeasurementsMenu.unitsMeasurements
        : null
    );
    const arrayProduct = [];
    let notHasSkus = false;

    if (card.type !== 'TRANSFORMATION') {
      card.frequencies = card.frequencies
        ? {
            _id: card.frequencies.frequencyId,
            description: card.frequencies.description
          }
        : null;

      card.storageLocation = card.storageLocation
        ? {
            _id: card.storageLocation.storageLocationId,
            description: card.storageLocation.description
          }
        : null;

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(
            notHasSkus === false && product.products.approximateCost
          );

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              consumptionUnitsPrimary: product.unitsMeasurements
                ? product.unitsMeasurements.abbreviation
                : null,
              consumptionUnitsPrimaryId: product.unitsMeasurements
                ? product.unitsMeasurements.id
                : null,
              listQuantity: product.quantity?.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity?.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStockStock?.toLocaleString(
                      'pt-br',
                      { minimumFractionDigits: 3 }
                    )
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCostStock &&
                !product.products.approximateCost
                  ? product.products.lastCostStock?.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost?.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock?.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCostStock &&
                !product.products.approximateCost
                  ? product.products.lastCostStock?.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost?.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock?.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost?.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost?.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    if (card.type === 'TRANSFORMATION') {
      setCardTransformation({
        type: 'PRODUCT',
        productId: card.itemsTransformations.productId,
        stock: card.itemsTransformations.products.realStock,
        consumptionUnitsPrimaryId:
          card.itemsTransformations.unitsMeasurements.id,
        consumptionUnitsPrimary:
          card.itemsTransformations.unitsMeasurements.abbreviation,
        cost: card.itemsTransformations.cost,
        averageCost: card.itemsTransformations.products.averageCostStock,
        lastCost: card.itemsTransformations.products.lastCostStock
          ? card.itemsTransformations.products.lastCostStock
          : card.itemsTransformations.products.approximateCost
          ? card.itemsTransformations.products.approximateCost
          : '0,000',
        quantity: card.itemsTransformations.quantity,
        product: card.itemsTransformations.products
      });

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(notHasSkus === false && product.products.skus.length);

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              listQuantity: product.quantity?.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity?.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock?.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock?.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost?.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock?.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock?.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product.products.approximateCost
                  ? product.products.approximateCost?.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock?.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost?.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost?.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    setCardDataTable(arrayProduct.sort((a, b) => (a.id > b.id ? 1 : -1)));

    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'technicalSheet' ? { ...modal, open: true } : modal
      )
    );
  };

  // Extracts Modal
  function handleOpenExtract(product) {
    setProductExtractDescription(product.description);
    setProductModal(product);
    setModalStates({ ...modalStates, modalProduct: true });
  }

  const handleClose = (confirmClose = false) => {
    if (hasChangeForm && !confirmClose) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
      setModalStates({
        modalProduct: false,
        modalProductSetups: false,
        modalDiscard: false
      });
      setHasChangeForm(false);
    }

    setProductSetupFilter();
  };

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      getId,
      '',
      page
    );
  };

  const downloadSpreadsheet = () => {
    const url = `${environments.catalog}/products/spreadsheet/export?originId=${getId}`;

    const link = document.createElement('a');
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const uploadSpreadsheet = async (inputRef, event) => {
    const endpoint = `${environments.catalog}/products/spreadsheet/import`;
    const spreadsheetBase64 = await convertToBase64(event.target.files[0]);

    const body = {
      originId: getId,
      user: userInfo.user,
      spreadsheet: spreadsheetBase64
    };

    axios
      .put(endpoint, body)
      .then(() => {
        setToastProps({
          severity: 'success',
          message:
            'Sua planilha foi importada. O processamento pode levar algum tempo. Volte em breve!'
        });

        setToastOpen(true);
      })
      .catch((err) => {
        if (err.response.data.type == 'Incorrect format') {
          setToastProps({
            severity: 'error',
            message:
              'A planilha não está no formato correto para ser lida. As colunas necessárias para o sucesso da importação podem estar faltando.'
          });
        } else if (err.response.data.type == 'Invalid restaurant') {
          setToastProps({
            severity: 'error',
            message:
              'A planilha que está sendo importada pertence a outro restaurante.'
          });
        } else if (err.response.data.type === 'Missing information') {
          const lineErrors = err.response.data.lineErrors || [];
          const lines = lineErrors.toString().replaceAll(',', ', ');

          setToastProps({
            severity: 'error',
            message: 'Faltam algumas informações obrigatórias no produto.',
            lineErrors: lines
          });
        } else {
          setToastProps({
            severity: 'error',
            message:
              'Não foi possível importar a planilha. Tente novamente mais tarde.'
          });
        }

        setToastOpen(true);
      });

    const { current: { value } = {} } = inputRef;

    if (value) {
      inputRef.current.value = null;
    }
  };

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      boxShadow: theme.shadows[1],
      fontSize: 11,
      fontFamily: 'Lato'
    }
  }))(Tooltip);

  const productSetupsButton = () => (
    <Tooltip title="Setups de Produto" aria-label="filters">
      <span
        className="ml10 cursorPointer"
        onClick={() => {
          if (isGroupManage) return null;

          setModalStates({ ...modalStates, modalProductSetups: true })
        }}
      >
        <img
          style={{ width: '25px' }}
          src={ProductSetupIcon}
          alt="Setup de Produtos"
        />
      </span>
    </Tooltip>
  );

  const addProductButton = () => (
    <Tooltip title="Cadastro de Produtos" aria-label="add">
      <Link
        className="ml10 cursorPointer"
        id="catalogProductAddButton"
        to={`/catalogo/produtos/cadastro/${paramsID}`}
        onClick={() => createProducts()}
      >
        <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
      </Link>
    </Tooltip>
  );

  const downloadSpreadsheetButton = () => (
    <Tooltip title="Baixar planilha de produtos" aria-label="add">
      <IconButton
        className="p0"
        color="primary"
        aria-label="add"
        component="span"
        onClick={() => {
          downloadSpreadsheet();
        }}
      >
        <img style={{ width: '25px' }} src={DownloadIcon} alt="DownloadIcon" />
      </IconButton>
    </Tooltip>
  );

  const uploadSpreadsheetButton = () => (
    <Tooltip title="Importar planilha de produtos" aria-label="add">
      <IconButton
        className="p0 ml10 cursorPointer"
        color="primary"
        aria-label="add"
        component="span"
      >
        <img
          className="cursorPointer"
          style={{ width: '25px' }}
          src={UploadIcon}
          alt="UploadIcon"
        />

        <InputFile
          type="file"
          id="spreadsheetImport"
          name="spreadsheetImport"
          onChange={(event) => uploadSpreadsheet(inputRef, event)}
          accept=".xlsx"
          ref={inputRef}
          aria-label="upload"
        />
      </IconButton>
    </Tooltip>
  );

  const handleCloseToast = (_, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastOpen(false);
  };

  const getProvidersByGroup = useCallback((product) => {
    (async () => {
      const response = await axiosInstanceOrder.get(
        `${environments.orders}/cache/products/uniqueproviders/${product?._id}/${getId}`
      );

      const providersResponse = await response?.data?.content;

      const formattedProviders = providersResponse
        ?.map((provider) => provider.sku)
        .map((sku) => ({
          ...sku.skus.providers,
          skuInfo: {
            unitsMeasurements: sku.skus.unitsMeasurements,
            quantity: sku.skus.quantity
          }
        }));

      const uniqueProviders = [];
      formattedProviders.flat().map((provider) => {
        if (
          uniqueProviders.some((el) => el.providerId === provider.providerId)
        ) {
          return null;
        }

        uniqueProviders.push(provider);
      });

      setDialogProvidersList(formattedProviders);
    })();
  }, []);

  const getProductSkusByGroup = useCallback((product) => {
    (async () => {
      const response = await axiosInstanceOrder.get(
        `${environments.orders}/cache/products/uniqueskus/${product?._id}/${getId}`
      );

      const productSkusResponse = await response?.data?.content;

      setSelectedProduct({
        skus: productSkusResponse?.map((item) => item?.sku)
      });
    })();
  }, []);


  const handleClickCards = (product) => {
    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'cardsAssociation'
          ? {
              ...modal,
              open: true,
              product: product.description,
              items: product?.items || []
            }
          : modal
      )
    );
    setSelectedProduct(product);
  };

  const handleClickSkus = (product) => {
    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'productsAssociation'
          ? {
              ...modal,
              open: true,
              product: product.description
            }
          : modal
      )
    );

    isGroupManage
      ? getProductSkusByGroup(product)
      : setSelectedProduct(product);
  };

  const getLastReceivedProduct = (product) => {
    return product?.images?.length ? product?.images[0]?.image?.url :  product?.images ?  product?.image?.url : ''
  };

  const handleClickProviders = (product) => {
    const formattedProviders = product.skus.map((sku) =>
      sku.skus.providers.map((provider) => ({
        ...provider,
        skuInfo: {
          unitsMeasurements: sku.skus.unitsMeasurements,
          quantity: sku.skus.quantity
        }
      }))
    );

    const uniqueProviders = [];
    formattedProviders.flat().map((provider) => {
      if (uniqueProviders.some((el) => el.providerId === provider.providerId)) {
        return null;
      }

      uniqueProviders.push(provider);
    });

    isGroupManage
      ? getProvidersByGroup(product)
      : setDialogProvidersList([...new Set(uniqueProviders)]);

    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'providersAssociation'
          ? {
              ...modal,
              open: true,
              product: product.description
            }
          : modal
      )
    );
    setSelectedProduct(product);
    // handleUniqueProviders(product?.skus);
  };

  useEffect(() => {
    setCallSettings((prevState) => ({ ...prevState, getId: paramsID }));
  }, [paramsID]);

  useEffect(() => {
    document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
  }, [callSettings.page]);

  useEffect(() => {
    if (!prevPath) {
      dispatch(clearFilter());
    }
  }, [dispatch, prevPath]);

  useEffect(() => {
    if (
      !storedProductsFilter?.filter((el) => el?.filterName?.includes('Id'))
        .length
    )
      getMainContentByGroup(page, size)
  }, [
    getId,
    filtersChanged,
    page,
    size,
    storedProductsFilter?.filter((el) => el?.filterName?.includes('Id'))?.length
  ]);

  useEffect(() => {
    if (idRest !== getId) {
        setFiltersChanged([])
        setValidId(validId + 1)
        setIdRest(getId)
    } else {
        setIdRest(getId)
    }
  }, [getId])

  useEffect(() => {
    if (
      storedProductsFilter?.filter((el) => el?.filterName?.includes('Id'))
        ?.length
    )
      getMainContentByGroup(page, size)
  }, [storedProductsFilter]);

  useEffect(() => {
    if (!modalSettings.find((modal) => modal.name === 'skuModal').open) return;

    !packageTypes.length &&
      getOptions(
        `${environments.catalog}/typePackages`,
        'packageTypes',
        packageTypes,
        setPackageTypes,
        isGroupManage ? getId?.split(',')[0] : getId,
      );
    !unitsMeasurements.length &&
      getOptions(
        `${environments.catalog}/unitsMeasurements`,
        'unitsMeasurements',
        unitsMeasurements,
        setUnitsMeasurements,
        isGroupManage ? getId?.split(',')[0] : getId,
      );
    !brands.length &&
      getOptions(
        `${environments.catalog}/brands`,
        'brands',
        brands,
        setBrands,
        isGroupManage ? getId?.split(',')[0] : getId,
      );
    !providers.length &&
      getOptions(
        `${environments.providers}/providers`,
        'providers',
        providers,
        setProviders,
        isGroupManage ? getId?.split(',')[0] : getId,
        null,
        null,
        10
      );
    !consumptionUnits.length &&
      getOptions(
        `${environments.catalog}/consumptionUnits`,
        'consumptionUnits',
        consumptionUnits,
        setConsumptionUnits,
        isGroupManage ? getId?.split(',')[0] : getId,
      );
    !storageLocations.length &&
      getOptions(
        `${environments.catalog}/storageLocations`,
        'storageLocations',
        storageLocations,
        setStorageLocations,
        isGroupManage ? getId?.split(',')[0] : getId,
      );
    !categories.length &&
      getOptions(
        `${environments.catalog}/categories`,
        'categories',
        categories,
        setCategories,
        isGroupManage ? getId?.split(',')[0] : getId,
        null,
        null,
        10
      );
  }, [getId, modalSettings.find((modal) => modal.name === 'skuModal').open]);

  useEffect(() => {
    selectedCard && handleSelectedCard(selectedCard);
  }, [selectedCard]);

  useEffect(() => {
    (async () => {
      const currentId = isGroupManage ? getId?.split(',')[0] : getId
      const response = await fetch(
        `${environments.restaurants}/companies/${currentId}/parameters`
      );
      const parametersResponse = await response.json();
      if (parametersResponse) {
        const { product: { type = '' } = {} } = parametersResponse;
        setParameters(type);
      }
    })();
  }, [getId]);

  // Periodicidade
  useEffect(() => {
    (async () => {
      const currentId = isGroupManage ? getId?.split(',')[0] : getId
      const response = await fetch(
        `${environments.catalog}/periodicities?page=1&size=100&originId=${currentId}`
      );
      const items = await response.json();
      setPeriodicities(items.content);
    })();
  }, [userInfo]);

  useEffect(() => {
    setCallSettings((prevState) => ({
      ...prevState,
      filters: [
        ...prevState.filters,
        {
          param: 'productId',
          value: '',
          urlParam: ''
        }
      ]
    }));

    const newArr = [...filterFields];
    if (newArr[0][1]?.options?.length < 1) {
      newArr[0][1].options = getFilterOptions(
        `${environments.catalog}/products`,
        'Produto',
        'products',
        filterFields,
        setFilterFields,
        'description',
        isGroupManage ? getId : filtersChanged?.find((el) => el.param === 'restaurantId')?.value ||
          stores[0]?.find((el) => el.name === userInfo.companiesActive.name)?.id,
        null,
        page
      );
    }

    setFilterFields(newArr);
  }, [
    filtersChanged?.find((filter) => filter.param === 'restaurantId')?.value, updateAutocomplete
  ]);
  return (
    <Layout>
      <TitleHead title="Produtos" />
      <GlobalStyle />

      <Container>
        <PageTitleAndFilter
          title="Produtos"
          activeFilterFilds={filterFields}
          handleFilter={handleFilter}
          additionalbutton={addProductButton}
          extrabutton={profile !== 'admin' ? productSetupsButton : null}
          getFilterhandle={getFilterhandle}
          supplementarybutton={
            profile !== 'admin' ? uploadSpreadsheetButton : null
          }
          complementarybutton={
            profile !== 'admin' ? downloadSpreadsheetButton : null
          }
          changeRest={validId}
        />
        <table className="desktop" cellPadding="0" cellSpacing="0" border="0">
          <thead>
            <tr className="headerTitle">
              <th width="2%" className="subtitle pr0">
                &nbsp;
              </th>

              <th
                width={profile !== 'admin' ? '19%' : '32%'}
                className="subtitle"
              >
                PRODUTO{' '}
                <OrderBy
                  filterName="description"
                  handleFilter={handleOrdinationFilter}
                />
              </th>

              <th width="2%" className="subtitle center">
                &nbsp;
              </th>

              <th width="3%" className="subtitle center">
                UN.
              </th>

              <th
                width={profile !== 'admin' ? '10%' : '17%'}
                className="subtitle"
              >
                CATEGORIA{' '}
                <OrderBy
                  filterName="category"
                  handleFilter={handleOrdinationFilter}
                />
              </th>

              {profile !== 'admin' && (
                <th
                  colSpan="3"
                  width="16%"
                  className="subtitle left p12"
                  style={{
                    borderLeft: 'solid 2px #001373',
                    background: '#001373'
                  }}
                >
                  VENDAS
                </th>
              )}

              <th
                colSpan="3"
                width={profile !== 'admin' ? '17%' : '25%'}
                className="subtitle left p12"
                style={{
                  borderLeft: 'solid 2px #73004C',
                  borderRight: 'solid 1px #4D0073',
                  background: '#73004C'
                }}
              >
                CUSTOS
              </th>

              {profile !== 'admin' && (
                <th
                  colSpan="2"
                  width="15%"
                  className="subtitle left p12"
                  style={{
                    borderLeft: 'solid 1px #4D0073',
                    background: '#4D0073'
                  }}
                >
                  ESTOQUE
                </th>
              )}

              <th
                colSpan="4"
                width={profile !== 'admin' ? '15%' : '20%'}
                className="subtitle left p12"
                style={{ borderLeft: 'solid 2px #313347' }}
              >
                CATÁLOGO
              </th>

              <th colSpan={profile !== 'admin' ? '3' : '2'} />
            </tr>

            <tr className="headerSubtitle">
              <th colSpan="5">&nbsp;</th>

              {profile !== 'admin' && (
                <>
                  <th
                    width="7%"
                    className="subtitle right"
                    style={{ borderLeft: 'solid 2px #001373' }}
                  >
                    PREÇO{' '}
                    <OrderBy
                      filterName="discountPrice"
                      handleFilter={handleOrdinationFilter}
                      tinyArrow
                    />
                  </th>

                  <th width="7%" className="subtitle right">
                    R${' '}
                    <OrderBy
                      filterName="salePrice"
                      handleFilter={handleOrdinationFilter}
                      tinyArrow
                    />
                  </th>

                  <th width="6%" className="subtitle right">
                    MRG %{' '}
                    <OrderBy
                      filterName="margin"
                      handleFilter={handleOrdinationFilter}
                      tinyArrow
                    />
                  </th>
                </>
              )}

              <th
                width="7%"
                className="subtitle right"
                style={{ borderLeft: 'solid 2px #73004C' }}
              >
                MÉDIO{' '}
                <OrderBy
                  filterName="averageCost"
                  handleFilter={handleOrdinationFilter}
                  tinyArrow
                />
              </th>

              <th width="7%" className="subtitle right">
                ÚLTIMO{' '}
                <OrderBy
                  filterName="lastCost"
                  handleFilter={handleOrdinationFilter}
                  tinyArrow
                />
              </th>

              <th
                style={{ borderRight: 'solid 2px #4D0073' }}
                width="5%"
                className="subtitle right"
              >
                CMV{' '}
                <OrderBy
                  filterName="cmv"
                  handleFilter={handleOrdinationFilter}
                  tinyArrow
                />
              </th>

              {profile !== 'admin' && (
                <>
                  <th
                    width="7%"
                    className="subtitle right"
                    style={{ borderLeft: 'solid 2px #4D0073' }}
                  >
                    TEÓRICO{' '}
                    <OrderBy
                      filterName="theoreticalStock"
                      handleFilter={handleOrdinationFilter}
                      tinyArrow
                    />
                  </th>

                  <th
                    style={{ borderRight: 'solid 2px #313347' }}
                    width="7%"
                    className="subtitle right"
                  >
                    REAL{' '}
                    <OrderBy
                      filterName="realStock"
                      handleFilter={handleOrdinationFilter}
                      tinyArrow
                    />
                  </th>
                </>
              )}

              {profile === 'admin' && (
                <th
                  width="2%"
                  className="subtitle center"
                  style={{ borderLeft: 'solid 1px #ECEDF1' }}
                >
                  <TableIcon icon="tag" name="Tags" />
                </th>
              )}

              <th
                width="2%"
                className="subtitle center pl3"
                style={{
                  borderLeft: profile !== 'admin' ? 'solid 2px #313347' : 'none'
                }}
              >
                <TableIcon icon="sku" name="Skus" />
              </th>

              {profile !== 'admin' && (
                <th width="2%" className="subtitle center">
                  <TableIcon icon="card" name="Fichas" />
                </th>
              )}

              <th width="2%" className="subtitle center">
                <TableIcon icon="provider" name="Fornecedores" />
              </th>

              <th width="2%" className="subtitle center">
                <TableIcon icon="quote" name="Cotações" />
              </th>

              {profile !== 'admin' && (
                <th
                  width="2%"
                  className="subtitle center"
                  style={{ borderLeft: 'solid 1px #ECEDF1' }}
                />
              )}

              <th
                width="1%"
                className="subtitle center"
                style={{
                  borderLeft: profile !== 'admin' ? 'none' : 'solid 1px #ECEDF1'
                }}
              />

              <th width="1%" />
            </tr>
          </thead>

          <tbody>
            {mainLoading && (
              <tr>
                <th
                  colSpan={profile !== 'admin' ? '20' : '14'}
                  className="m10 textAlignCenter"
                >
                  <LinearProgress
                    style={{ height: '7px' }}
                    id="loading"
                    variant="query"
                  />
                </th>
              </tr>
            )}

            {products?.length ? (
              products.map((product) => {
                const categoriesDescriptions =
                  product?.categories?.description || '';

                return (
                  <>
                    <tr className="lineBorder">
                      <td
                        className={`dFlex alignCenter p0 ${
                          (product?.type === 'INTERNAL' ||
                            product?.disableSelection) &&
                          'disabledCheckbox'
                        }`}
                      >
                        <Checkbox
                          checked={unifyProductsPopover?.products?.some(
                            (el) => el.id === product.id
                          )}
                          disabled={
                            product?.type === 'INTERNAL' ||
                            product?.disableSelection ||
                            isGroupManage
                          }
                          size="small"
                          onChange={(_, checked) => {
                            if (checked) {
                              getProductGroupInfo(product);
                            } else {
                              removeProductOfSelectedProducts(product);
                            }

                            handleSelectProductToUnify(product);
                          }}
                        />
                      </td>

                      <td
                        className="description left pr8"
                        style={{ maxWidth: '195px', minWidth: '195px' }}
                      >
                        <div className="dFlex alignCenter w100">
                          <span
                            style={{
                              fontSize: '10px',
                              margin: '2px 6px 0px 1px',
                              marginRight:
                                product.curve != null ? '6px' : '19px'
                            }}
                          >
                            {product !== null &&
                              product !== undefined &&
                              product.curve != null && <>({product.curve})</>}
                          </span>

                          <div className="dFlex alignCenter justifyBetween w100">
                            <span
                              className="cursorPointer lightBlue"
                              onClick={() => {
                                setEditProducts()
                                setProductDataModal({data: product})
                                setModalSettings(
                                  modalSettings.map((modal) =>
                                    modal.name === 'productEdit' ? { ...modal, open: true } : modal
                                  )
                                );
                              }}
                            >
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                placement="right"
                                title={
                                  <span
                                    className="dBlock"
                                    style={{ padding: '4px 7px' }}
                                  >
                                    {product?.id} - {product?.description}
                                  </span>
                                }
                              >
                                <span>
                                  {product?.description?.length >
                                  (profile !== 'admin' ? 20 : 40)
                                    ? `${product?.description?.substring(
                                        0,
                                        profile !== 'admin' ? 20 : 40
                                      )}...`
                                    : product?.description}
                                </span>
                              </Tooltip>
                            </span>

                            <Tooltip title="Lançar descarte">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setModalStates((prevState) => ({
                                    ...prevState,
                                    modalDiscard: true
                                  }));
                                  setNewData((prevState) => ({
                                    ...prevState,
                                    data: {
                                      description: product?.description,
                                      originId: getId,
                                      unitsMeasurementsId:
                                        product?.consumptionUnitsPrimary?.id,
                                      unitsMeasurementsAbbreviation:
                                        product?.consumptionUnitsPrimary
                                          ?.abbreviation
                                    },
                                    discard: [
                                      {
                                        id: product?.id,
                                        typeEvent: 'DISCARD',
                                        cost: product?.lastCost,
                                        originId: getId,
                                        quantity: product?.quantity,
                                        eventDate: format(
                                          new Date(),
                                          'yyyy-MM-dd'
                                        ),
                                        un: product?.consumptionUnitsPrimary
                                          ?.abbreviation,
                                        user: userInfo.user
                                      }
                                    ]
                                  }));
                                }}
                              >
                                <Delete
                                  style={{
                                    width: 17,
                                    height: 17,
                                    color: '#313347'
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      </td>

                      <td className="right">
                        <div className="dFlex justifyCenter">
                          <ImageWrapper
                            width="30px"
                            height="28px"
                            url={getLastReceivedProduct(product)}
                            skuId={product?.skus?.length ? product?.skus[0].id : {}}
                            skusImageUrls={product?.images}
                            screen='product'
                          />
                        </div>
                      </td>

                      <td
                        className="description center"
                        style={{ maxWidth: '10px' }}
                      >
                        {product.consumptionUnitsPrimary.abbreviation}
                        {product?.consumptionUnitsSecondary?.id
                          ? `/${product.consumptionUnitsSecondary.abbreviation}`
                          : ''}
                      </td>

                      {product?.categories !== null ? (
                        <LightTooltip
                          title={
                            product !== null && product !== undefined
                              ? product?.categories?.family
                              : null
                          }
                          placement="top"
                          aria-label="Family"
                        >
                          <td
                            width="120"
                            style={{
                              maxWidth: profile !== 'admin' ? '120px' : '240px',
                              minWidth: '120px'
                            }}
                            className="description left"
                          >
                            {product !== null && product !== undefined
                              ? ValidationLength(
                                  categoriesDescriptions,
                                  profile !== 'admin' ? 12 : 25
                                )
                              : null}
                          </td>
                        </LightTooltip>
                      ) : (
                        <td
                          width="120"
                          style={{
                            maxWidth: profile !== 'admin' ? '120px' : '240px',
                            minWidth: '120px'
                          }}
                          className="description left"
                        />
                      )}

                      {profile !== 'admin' && (
                        <>
                          <td
                            className="description right"
                            style={{
                              maxWidth: '70px',
                              borderLeft: 'solid 2px #001373'
                            }}
                          >
                            {(product.averagePrice
                              ? product.averagePrice
                              : 0
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 2
                            })}
                          </td>

                          <td
                            className="description right"
                            style={{ maxWidth: '70px' }}
                          >
                            {(product.salePrice
                              ? product.salePrice
                              : 0
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 2
                            })}
                          </td>

                          <td
                            className="description right p12"
                            style={{ maxWidth: '70px' }}
                          >
                            {(product.margin != null
                              ? product.margin
                              : 0
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })}
                          </td>
                        </>
                      )}

                      <td
                        className="description right"
                        style={{
                          maxWidth: '70px',
                          borderLeft: 'solid 2px #73004C'
                        }}
                      >
                        {(product.averageCost
                          ? product.averageCost
                          : 0
                        ).toLocaleString('pt-br', { minimumFractionDigits: 3 })}
                      </td>

                      <td
                        className="description right"
                        style={{ maxWidth: '70px' }}
                      >
                        <Tooltip
                          title={
                            <div
                              className="dFlex flexColumn"
                              style={{ padding: '4px 2px' }}
                            >
                              <p className="mb0">
                                <b>DATA:</b>{' '}
                                {moment(
                                  product?.lastReceivedProvider?.provider
                                    ?.recivedDate
                                ).format('DD/MM/YYYY')}
                              </p>

                              <p className="mb0">
                                <b>SKU:</b>{' '}
                                {product?.lastReceivedProvider?.skuDescription}
                              </p>

                              <p className="mb0">
                                <b>FORNECEDOR:</b>{' '}
                                {product?.lastReceivedProvider?.provider?.name}
                              </p>

                              <p className="mb0">
                                <b>CNPJ:</b>{' '}
                                {formatDocumentNumber(
                                  product?.lastReceivedProvider?.provider
                                    ?.suppliers?.cnpjNumber
                                )}
                              </p>

                              <p className="mb0">
                                <b>EMBALAGEM:</b> E
                                {
                                  product?.lastReceivedProvider?.provider
                                    ?.quantity
                                }
                                x{product?.lastReceivedProvider?.skuQuantity}
                                {
                                  product?.lastReceivedProvider
                                    ?.unitsMeasurements?.abbreviation
                                }
                              </p>
                            </div>
                          }
                          disableHoverListener={
                            !product?.lastReceivedProvider?.provider
                          }
                          aria-label="add"
                        >
                          <span 
                            className="cursorPointer lightBlue"
                            onClick={() => {
                              setModalSettings(
                                modalSettings.map((modal) =>
                                  modal.name === 'costTimeline'
                                  ? {
                                      ...modal,
                                      open: true,
                                      product,
                                      items: []
                                    }
                                  : modal
                                )
                              );
                            }}
                          >
                            {(product.lastCost
                              ? product.lastCost
                              : product.approximateCost
                              ? product.approximateCost
                              : 0
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })}
                          </span>
                        </Tooltip>
                      </td>

                      <td
                        className="description right p12"
                        style={{ maxWidth: '70px' }}
                      >
                        {(product.cmv ? product.cmv : 0).toLocaleString(
                          'pt-br',
                          { minimumFractionDigits: 3 }
                        )}
                      </td>

                      {profile !== 'admin' && (
                        <>
                          <td
                            className="description right"
                            style={{
                              maxWidth: '70px',
                              borderLeft: 'solid 2px #4D0073'
                            }}
                          >
                            {(product.theoreticalStock
                              ? product.theoreticalStock
                              : 0
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })}
                          </td>

                          <td
                            className="description right p12"
                            style={{ maxWidth: '70px' }}
                          >
                            {(product.realStock
                              ? product.realStock
                              : 0
                            ).toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })}
                          </td>
                        </>
                      )}

                      {profile === 'admin' && (
                        <td
                          className="description center p12"
                          style={{
                            maxWidth: '10px',
                            borderLeft: 'solid 2px #313347'
                          }}
                        >
                          {hasMainTags(
                            product,
                            setDialogTagsSettings,
                            dialogTagsSettings
                          )}
                        </td>
                      )}

                      <td
                        className="description center p12"
                        style={{
                          maxWidth: '10px',
                          borderLeft:
                            profile !== 'admin' ? 'solid 2px #313347' : 'none'
                        }}
                      >
                        <span
                          className="lightBlue cursorPointer"
                          style={{
                            color: product?.editedFromPlatform
                              ? '#E22121'
                              : '#1890FF'
                          }}
                          onClick={() => handleClickSkus(product)}
                        >
                          {isGroupManage
                      ? product?.uniqueSkus || 0
                      : product.skus.length || 0}
                        </span>
                      </td>

                      {profile !== 'admin' && (
                        <td
                          className="description center"
                          style={{ maxWidth: '10px' }}
                        >
                          <span
                            className="lightBlue cursorPointer"
                            onClick={() => handleClickCards(product)}
                          >
                             {isGroupManage
                          ? product?.uniqueCards || 0
                          : product?.items?.filter(
                              (item) => item.cardsOrigin
                            )?.length || 0}
                          </span>
                        </td>
                      )}

                      <td className="description center p12">
                        <span className="lightBlue cursorPointer" onClick={() => handleClickProviders(product)}>
                          {isGroupManage
                            ? product?.uniqueProviders
                            : countProviders(product) || 0}
                        </span>
                      </td>

                      <td className="description">
                        {handleQuotations(
                          product,
                          setDialogQuotesSettings,
                          dialogQuotesSettings,
                          setQuotationProduct
                        )}
                      </td>

                      <td
                        colSpan="3"
                        className="description"
                        style={{
                          borderLeft: 'solid 1px #ECEDF1',
                          paddingLeft: '6px'
                        }}
                      >
                        <div className="dFlex alignCenter justifyCenter" style={{ gap: '8px' }}>
                          <IconButton
                            aria-label="delete"
                            onClick={() => handleOpenExtract(product)}
                            style={{ padding: 0, outline: 0 }}
                          >
                            <img
                              style={{ width: '17px' }}
                              src={ExtractIcon}
                              alt="ExtractIcon"
                            />
                          </IconButton>

                          <Tooltip title="Transferir" aria-label="filters">
                            <IconButton
                              aria-label="transfer"
                              onClick={() => {
                                setModalSettings(
                                  modalSettings.map((modal) =>
                                    modal.name === 'productTransference'
                                      ? {
                                          ...modal,
                                          open: true,
                                          product,
                                        }
                                      : modal
                                  )
                                );
                              }}
                              style={{ padding: 0, outline: 0 }}
                            >
                              <img
                                style={{
                                  width: 13,
                                }}
                                alt={TransferIcon}
                                src={TransferIcon}
                              />
                            </IconButton>
                          </Tooltip>
                          
                          <IconButton
                            onClick={() => {
                              setModalStates({
                                ...modalStates,
                                modalProductSetups: true
                              });
                              setProductSetupFilter({
                                ...productSetupFilter,
                                product
                              });
                            }}
                            disabled={isGroupManage}
                            style={{ padding: 0, outline: 0 }}
                          >
                            <img
                              style={{ width: '15px' }}
                              src={
                                product?.setups?.length
                                  ? ProductSetupBlue
                                  : ProductSetupGray
                              }
                              alt="ExtractIcon"
                            />
                          </IconButton>

                          <IconButton
                            aria-label="delete"
                            onClick={() => {
                              handleDialogClickOpen();
                              setDeleteData(product);
                            }}
                            style={{ padding: 0, outline: 0 }}
                          >
                            <DeleteOutline style={{ width: '21px' }} />
                          </IconButton>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <td
                        colSpan={profile !== 'admin' ? '20' : '14'}
                        className="m10 textAlignCenter"
                      >
                        <hr style={{ marginTop: '-1px' }} />
                      </td>
                    </tr>
                  </>
                );
              })
            ) : (
              <tr>
                <th
                  colSpan={profile !== 'admin' ? '20' : '14'}
                  className="m10 textAlignCenter"
                >
                  Não há nenhum produto
                </th>
              </tr>
            )}
          </tbody>
        </table>

        <div className="pagination">
          <Pagination
            count={totalPages}
            onChange={(event, nextPage) => {
              if (event) {
                setCallSettings({ ...callSettings, page: nextPage });
                getMainContentByGroup(nextPage, size)
              }
            }}
            variant="outlined"
            shape="rounded"
            size="small"
            color="primary"
          />

          <ItemsPerPage
            numberOfElements={numberOfElements}
            size={totalElements}
          />
        </div>

        <Tooltip
          className="floatButtonFirst"
          title={
            unifyProductsPopover?.products.length < 2
              ? 'Selecione ao menos 2 Produtos'
              : 'Unificar Produtos'
          }
        >
          <Fab
            color="primary"
            className={`${
              unifyProductsPopover?.products.length < 2
                ? 'cursorAuto'
                : 'cursorPointer'
            }`}
            aria-label="add"
            size="medium"
            style={{ outline: 0 }}
            onClick={() => {
              if (unifyProductsPopover?.products.length < 2 || isGroupManage) return;

              handleTypeOfUnification();
              setUnifyProductsPopover({
                ...unifyProductsPopover,
                open: !unifyProductsPopover?.open
              });
            }}
          >
            <Add style={{ color: 'white' }} id="unifyProductIcon" />
          </Fab>
        </Tooltip>

        {unifyProductsPopover?.open && (
          <UnifyProducts
            validationLength={ValidationLength}
            handleClosePopover={handleClosePopover}
            unifyProductsPopover={unifyProductsPopover}
            setUnifyProductsPopover={setUnifyProductsPopover}
            handleUnifyProducts={handleUnifyProducts}
            productsToUnifyGroupMainInfos={productsToUnifyGroupMainInfos}
            handleSelectTypeOfUnification={handleSelectTypeOfUnification}
            isLoading={mainLoading}
            handleClickSkus={handleClickSkus}
            handleClickCards={handleClickCards}
            handleClickProviders={handleClickProviders}
          />
        )}

        {dialogTagsSettings.open && profile === 'admin' && (
          <DialogTags
            open={dialogTagsSettings.open}
            PaperComponent={PaperComponent}
            dialogTagsSettings={dialogTagsSettings}
            setDialogTagsSettings={setDialogTagsSettings}
            originId={paramsID}
            setProducts={setProducts}
            products={products}
          />
        )}

        {dialogQuotesSettings.open && (
          <DialogQuotes
            open={dialogQuotesSettings.open}
            PaperComponent={PaperComponent}
            dialogQuotesSettings={dialogQuotesSettings}
            setDialogQuotesSettings={setDialogQuotesSettings}
            originId={paramsID}
            quotationProduct={quotationProduct}
          />
        )}

        {modalStates.modalProductSetups && (
          <Suspense fallback={<span />}>
            <ProductSetupsDialog
              openModal={modalStates}
              setModalState={setModalStates}
              handleClose={handleClose}
              userInfo={userInfo}
              periodicities={periodicities}
              refreshProducts={() => getMainContentByGroup(page, size)}
              productSetupFilter={productSetupFilter}
              hasChangeForm={hasChangeForm}
              setHasChangeForm={setHasChangeForm}
              isOpenConfirm={isOpen}
              setIsOpenConfirm={setIsOpen}
            />
          </Suspense>
        )}

        {modalSettings.find((modal) => modal.name === 'costTimeline')
          .open && (
          <CostTimeline
            open={
              modalSettings.find((modal) => modal.name === 'costTimeline')
                .open
            }
            product={
              modalSettings.find((modal) => modal.name === 'costTimeline')
                .product
            }
            onClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'costTimeline'
                    ? { ...modal, open: false, product: null, item: null }
                    : modal
                )
              );
            }}
            restaurantId={getId}
          />
        )}

        <Dialog
          fullScreen={fullScreen}
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            className="modalHeader navyBlue bold cursorMove"
            id="draggable-dialog-title"
          >
            <Typography>Deseja realmente excluir esse produto?</Typography>
          </DialogTitle>

          <DialogContent className="modalContent">
            <Typography>
              {deleteData?.nCards > 0
                ? `ATENÇÃO: Este produto está relacionado a ${
                    deleteData?.nCards
                  }  ${
                    deleteData?.nCards > 1
                      ? 'fichas'
                      : 'ficha'
                  }, Tem certeza que deseja efetuar a exclusão?`
                : 'Esta ação não pode ser desfeita. Deseja continuar ?'}
            </Typography>
          </DialogContent>

          <DialogActions className="justifyCenter">
            <Button
              className="defaultButton backButton"
              design="outlined"
              onClick={handleDialogClose}
              label="Cancelar"
              color="primary"
            />
            <Button
              className="defaultButton submitButton"
              autoFocus
              design="contained"
              label="Confirmar"
              onClick={() => handleDelete()}
              color="primary"
            />
          </DialogActions>
        </Dialog>

        <Snackbar
          open={deleteSuccess}
          autoHideDuration={2000}
          onClose={() => handleCloseAll(handleDeleteSuccess)}
        >
          <Alert
            onClose={() => handleCloseAll(handleDeleteSuccess)}
            severity="success"
          >
            Produto deletado com sucesso!
          </Alert>
        </Snackbar>

        <Snackbar
          open={deleteError}
          autoHideDuration={2000}
          onClose={() => handleCloseAll(handleDeleteError)}
        >
          <Alert
            onClose={() => handleCloseAll(handleDeleteError)}
            severity="error"
          >
            Ocorreu um erro ao salvar as informações.
          </Alert>
        </Snackbar>

        {modalSettings.find((modal) => modal.name === 'skuModal').open &&
        selectedSku &&
        unitsMeasurements.length &&
        brands.length ? (
          <SkuModal
            fullScreen={
              modalSettings.find((modal) => modal.name === 'skuModal')
                .fullScreen
            }
            maxWidth={
              modalSettings.find((modal) => modal.name === 'skuModal').maxWidth
            }
            open={modalSettings.find((modal) => modal.name === 'skuModal').open}
            data={selectedSku?.providers || []}
            getId={getId}
            editMode
            sku={selectedSku}
            userInfo={userInfo}
            brands={brands}
            packageTypes={packageTypes}
            categories={categories}
            providers={providers}
            storageLocations={storageLocations}
            periodicities={periodicities}
            unitsMeasurements={unitsMeasurements}
            consumptionUnits={consumptionUnits}
            skus={[]}
            onClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'skuModal'
                    ? {
                        ...modal,
                        open: false
                      }
                    : modal
                )
              );

              setSelectedSku('');
            }}
            adminCatalogModal={isAdminPage}
            onlyViewMode
          />
        ) : null}

        {/* MODAL SKUS  */}
        {modalSettings.find((modal) => modal.name === 'productsAssociation')
          .open && (
          <ProductSkus
            fullScreen={
              modalSettings.find(
                (modal) => modal.name === 'productsAssociation'
              ).fullScreen
            }
            maxWidth={
              modalSettings.find(
                (modal) => modal.name === 'productsAssociation'
              ).maxWidth
            }
            open={
              modalSettings.find(
                (modal) => modal.name === 'productsAssociation'
              ).open
            }
            onClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'productsAssociation'
                    ? { ...modal, open: false, product: null, item: null }
                    : modal
                )
              );

              setSelectedProduct(null);
            }}
            userInfo={userInfo}
            modalSettings={modalSettings}
            setModalSettings={setModalSettings}
            selectedSku={selectedSku}
            setSelectedSku={setSelectedSku}
            hasNewSku={selectedProduct?.editedFromPlatform}
            products={products}
            setProducts={setProducts}
            title={
              modalSettings.find(
                (modal) => modal.name === 'productsAssociation'
              ).product
            }
            product={selectedProduct}
          />
        )}

        {modalSettings.find((modal) => modal.name === 'providersAssociation')
          .open && (
          <ProvidersDialog
            fullScreen={
              modalSettings.find(
                (modal) => modal.name === 'providersAssociation'
              ).fullScreen
            }
            maxWidth={
              modalSettings.find(
                (modal) => modal.name === 'providersAssociation'
              ).maxWidth
            }
            open={
              modalSettings.find(
                (modal) => modal.name === 'providersAssociation'
              ).open
            }
            loading={loadingProviders}
            onClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'providersAssociation'
                    ? { ...modal, open: false, product: null, item: null }
                    : modal
                )
              );
              setDialogProvidersList([]);
              setSelectedProduct(null);
            }}
            title={
              modalSettings.find(
                (modal) => modal.name === 'providersAssociation'
              ).product
            }
            providers={dialogProvidersList}
          />
        )}

        {modalSettings.find((modal) => modal.name === 'cardsAssociation')
          .open && (
          <DialogCards
            fullScreen={
              modalSettings.find((modal) => modal.name === 'cardsAssociation')
                .fullScreen
            }
            maxWidth={
              modalSettings.find((modal) => modal.name === 'cardsAssociation')
                .maxWidth
            }
            open={
              modalSettings.find((modal) => modal.name === 'cardsAssociation')
                .open
            }
            onCloseFunc={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'cardsAssociation'
                    ? { ...modal, open: false, product: null, item: null }
                    : modal
                )
              );
            }}
            loading={loading}
            selectedCard={selectedCard}
            selectedProduct={selectedProduct}
            setSelectedCard={setSelectedCard}
            PaperComponent={PaperComponent}
            requestProps={{
              originId: getId,
              productDescription: modalSettings.find(
                (modal) => modal.name === 'cardsAssociation'
              ).product,
              items: modalSettings.find(
                (modal) => modal.name === 'cardsAssociation'
              ).items
            }}
            setRequestStorages={setRequestStorages}
            requestStorages={requestStorages}
            setOpenModalMenu={setOpenModalMenu}
          />
        )}

        {modalSettings.find((modal) => modal.name === 'productEdit')
          .open && (
          <Suspense fallback={<span />}>
           <DialogProducts
              fullScreen={
                modalSettings.find((modal) => modal.name === 'productEdit')
                  .fullScreen
              }
              maxWidth={
                modalSettings.find((modal) => modal.name === 'productEdit')
                  .maxWidth
              }
              open={modalSettings.find((modal) => modal.name === 'productEdit')
              .open}
              onCloseFunc={() => setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'productEdit' ? { ...modal, open: false } : modal
                )
              )}
              PaperComponent={PaperComponent}
              productData={productDataModal}
              setMessage={setMessage}
              message={message}
           />
          </Suspense>
        )}

        {modalSettings.find((modal) => modal.name === 'productTransference').open && (
          <ProductTransference
            refreshProducts={() => getMainContentByGroup(page, size)}
            setToastOpen={setToastOpen}
            setToastProps={setToastProps}
            product={modalSettings.find((modal) => modal.name === 'productTransference')?.product}
            userInfo={userInfo}
            modalSettings={modalSettings}
            setModalSettings={setModalSettings}
            onClose={() => {
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'productTransference'
                    ? { ...modal, open: false, product: null }
                    : modal
                )
              );
            }}
          />
        )}

        <Snackbar
          open={deleteSuccess}
          autoHideDuration={2000}
          onClose={() => handleCloseAll(handleDeleteSuccess)}
        >
          <Alert
            onClose={() => handleCloseAll(handleDeleteSuccess)}
            severity="success"
          >
            Produto deletado com sucesso!
          </Alert>
        </Snackbar>

        <Snackbar
          open={deleteError}
          autoHideDuration={2000}
          onClose={() => handleCloseAll(handleDeleteError)}
        >
          <Alert
            onClose={() => handleCloseAll(handleDeleteError)}
            severity="error"
          >
            Ocorreu um erro ao salvar as informações.
          </Alert>
        </Snackbar>

        {modalStates?.modalProduct && (
          <Suspense fallback={<span />}>
            <TheoryStockDialog
              getId={getId}
              checkConsume={checkConsume}
              setCheckConsume={setCheckConsume}
              openModal={modalStates}
              handleClose={handleClose}
              productExtractDescription={productExtractDescription}
              productModal={productModal}
              setProductModal={setProductModal}
              parameters={parameters}
              originPage="productPage"
            />
          </Suspense>
        )}

        {modalStates.modalDiscard && (
          <Discard
            fullScreen={null}
            maxWidth="sm"
            loading={loadingDiscard}
            open={modalStates.modalDiscard}
            onSubmit={() => putDiscard()}
            onClose={() => {
              handleClose('modalDiscard');
            }}
            item={newData}
            setItem={setNewData}
            currentDiscardReason={currentDiscardReason}
            setCurrentDiscardReason={setCurrentDiscardReason}
            initialDate={now}
          />
        )}

        {selectedCard && (
          <TechnicalSheetDialog
            maxWidth={null}
            open={
              modalSettings.find((modal) => modal.name === 'technicalSheet')
                .open
            }
            handleClose={() =>
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'technicalSheet'
                    ? { ...modal, open: false }
                    : modal
                )
              )
            }
            cardDataTable={cardDataTable}
            cardTransformation={cardTransformation}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            ucPrimarySelectedId={ucPrimarySelectedId}
            setRequestStorages={setRequestStorages}
            requestStorages={requestStorages}
            hasMenuModalChange={hasMenuModalChange}
            setHasMenuModalChange={setHasMenuModalChange}
            salesAverageDiscount={salesAverageDiscount}
            setSalesAverageDiscount={setSalesAverageDiscount}
            openModalMenu={openModalMenu}
            setOpenModalMenu={setOpenModalMenu}
          />
        )}
      </Container>

      <Snackbar
        open={toastOpen}
        autoHideDuration={6000}
        onClose={handleCloseToast}
      >
        <Alert onClose={handleCloseToast} severity={toastProps.severity}>
          {toastProps.message}
          {toastProps.lineErrors
            ? `\nLinha(s) com erro: ${toastProps.lineErrors}`
            : ''}
        </Alert>
      </Snackbar>

      <CommonAlert
        open={alertErr}
        autoHideDuration={2000}
        onClose={() => {
          setAlertErr(false);
        }}
        indexMessage={alertMessage}
        messagePersonal={alertMessage}
        statusCode={statusCode}
        severity={severity || ''}
      />

      <CommonAlert
        open={message?.description}
        onClose={() => setMessage({ description: '', status: '' })}
        severity={message?.status}
        indexMessage={message?.description}
        messagePersonal={message?.description}
        statusCode={statusCode}
      />
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProductsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Fabricantes);