import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { Link, useParams, useHistory } from 'react-router-dom';
import PullToRefresh from 'react-simple-pull-to-refresh';

import {
  DateWrapper,
  CountCard,
  DescriptionCard,
  CountPageWrapper
} from './index.style';
import PeriodicityCountPage from './periodicityCountPage';

function CountsMobile({
  countData,
  userInfo,
  setBackButtonConfig,
  inventories
}) {
  const [selectedPeriodicity, setSelectedPeriodicity] = useState();
  const [selectedPeriodDate, setSelectedPeriodDate] = useState();
  const { id: paramsID } = useParams();
  const history = useHistory();

  const query = new URLSearchParams(window.location.search);
  const selectedPeriodicityQuery = query.get('selectedPeriodicity');
  const periodicyDateQuery = query.get('periodicyDate');

  const handleBackButtonFilter = () => {
    setBackButtonConfig(null);
    setSelectedPeriodicity();
    history.push(`/inventario/contagens/${paramsID}`);
  };

  const handlePeriodicities = (period, date) => {
    setSelectedPeriodicity(period);
    setSelectedPeriodDate(date);

    const { periodicity: { description = '' } = {} } = period;
    setBackButtonConfig({
      action: handleBackButtonFilter,
      description: 'Voltar',
      pageTitle: `${moment(date).format('DD/MM')} - ${description}`
    });
  };

  useEffect(() => {
    if (!selectedPeriodicityQuery || !periodicyDateQuery || !countData.length)
      return;

    countData.map((count) => {
      const { periodicities = [], date = '' } = count;
      if (date === periodicyDateQuery) {
        const period = periodicities.find((period) => {
          if (
            period?.periodicity?.id === parseFloat(selectedPeriodicityQuery)
          ) {
            return period;
          }
        });
        if (period) {
          handlePeriodicities(period, date);
        }
      }
    });
  }, [selectedPeriodicityQuery, periodicyDateQuery, countData]);

  return (
    <PullToRefresh
      className="customPullToRefresh"
      onRefresh={() => window.location.reload()}
    >
      <CountPageWrapper>
        {selectedPeriodicity && (
          <PeriodicityCountPage
            periodicity={selectedPeriodicity}
            setPeriodicity={setSelectedPeriodicity}
            inventories={inventories}
            selectedPeriodDate={selectedPeriodDate}
          />
        )}

        <div>
          {!selectedPeriodicity &&
            countData.map((count) => {
              const { date, periodicities = [] } = count;
              return (
                <div>
                  <DateWrapper>
                    {moment(date).format('DD/MM/YYYY')}
                  </DateWrapper>

                  {periodicities.map((period) => {
                    const {
                      periodicity: { description = '', id: periodID } = {}
                    } = period;

                    return (
                      <Link
                        to={`/inventario/contagens/${paramsID}/?selectedPeriodicity=${periodID}&periodicyDate=${date}`}
                      >
                        <CountCard
                          onClick={() => handlePeriodicities(period, date)}
                        >
                          <DescriptionCard>
                            {description}
                          </DescriptionCard>
                        </CountCard>
                      </Link>
                    );
                  })}
                </div>
              );
            })}
        </div>
      </CountPageWrapper>
    </PullToRefresh>
  );
}

export default CountsMobile;
