import { createSlice, current } from '@reduxjs/toolkit';

const initialState = {
  configs: [
    {
      counts: {
        showColumns: [
          'OUTROS ESTOQUES',
          'REAL',
          'TEÓRICO',
          'ÚLTIMA',
          'CONSUMO',
          'DIFERENÇA EM QTDE / R$',
          'DIFERENÇA EM %',
          'USUÁRIO'
        ]
      }
    },
    {
      menus: {
        showColumns: [
          'CARD. R$',
          'DESC.',
          'QTDE.',
          'TOTAL R$',
          'CUSTO TOTAL R$',
          'CUSTO NOMINAL',
          'CUSTO % PROJ.',
          'CUSTO % REAL'
        ]
      }
    },
    {
      production: {
        currentSquare: ''
      }
    }
  ]
};

export const userConfigs = createSlice({
  name: 'userConfig',
  initialState,
  reducers: {
    handleCountColumns: (state, action) => {
      const { payload } = action;
      const currState = current(state);
      const { configs } = payload;

      const newConfigs = configs?.filter((config) => config.counts).length
        ? configs.find((config) => config?.counts)?.counts?.showColumns
        : currState.configs[0].counts.showColumns;

      localStorage.setItem('countConfigs', newConfigs);

      state.configs.find((config) => config.counts).counts.showColumns =
        newConfigs;
    },
    handleMenusColumns: (state, action) => {
      const { payload } = action;
      const currState = current(state);
      const { configs } = payload;

      const newConfigs = configs?.filter((config) => config.menus).length
        ? configs.find((config) => config?.menus)?.menus?.showColumns
        : currState.configs[0].menus.showColumns;

      localStorage.setItem('menusConfigs', newConfigs);

      state.configs.find((config) => config.menus).menus.showColumns =
        newConfigs;
    },
    handleProductionSquare: (state, action) => {
      const { payload } = action;
      const { configs } = payload;

      localStorage.setItem('productionConfigs', JSON.stringify(configs));

      state.configs.find((config) => config.production).production.currentSquare = configs;
    },
    clearAll: (state) => {
      state.products = [];
      state.selectedPages = [];
    },
  }
});

export const { handleCountColumns, handleMenusColumns, handleProductionSquare, clearAll } = userConfigs.actions;

export default userConfigs.reducer;
