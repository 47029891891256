/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

import {
  CircularProgress,
} from '@material-ui/core';
import Axios from 'axios';

import Environment from 'utils/environments';

import DialogInvoices from './DialogInvoices';
import DialogSingleInvoices from './DialogSingleInvoice';

export default function InvoiceModal({
  setRangeDate,
  rangeDate,
  rangeDateEx,
  restaurantId,
  setProductFilter,
  productFilter,
  productFilterEx,
  openModal,
  handleModalOpen,
  customMainValue = null,
  customParams
}) {
  const [invoiceModalState, setInvoiceModalState] = useState(false);
  const [invoiceModalData, setInvoiceModalData] = useState([]);
  const [singleInvoiceModalState, setSingleInvoiceModalState] = React.useState(false);
  const [singleInvoiceModalData, setSingleInvoiceModalData] = React.useState({
    id: 12345,
    code: 12345,
    emissionDateHour: '',
    totalValue: 0,
    data: []
  });
  const [countSales, setCountSales] = useState(0);
  const [countSalesInitial, setCountSalesInitial] = useState(null);
  const [salesTotalValues, setSalesTotalValues] = useState(0);

  const environments = Environment(process.env.REACT_APP_ENV);

  const getInvoiceModalFiltered = async (startDate, endDate, filter) => {
    const itemDescriptionFilter = filter?.itemDescription ? `&itemDescription=${filter?.itemDescription}` : ''
    const pdvFilterValue = customMainValue ? customParams?.pdv?.code : filter?.code ? filter?.code : ''
    const pdvCodeParam = customMainValue || filter?.code ? `&code=${pdvFilterValue}` : ''

    const resp = await Axios.get(`${environments.sales}/sales?originId=${restaurantId}&fromMainMenu=false&startDate=${startDate}&endDate=${endDate}${pdvCodeParam}${itemDescriptionFilter}`);
    const data = await resp.data;
    return data;
  };

  const getInvoiceModalTotal = async (startDate, endDate, filter) => {
    const itemDescriptionFilter = filter?.itemDescription ? `&itemDescription=${filter?.itemDescription}` : ''
    const pdvCodeFilter = filter?.code ? `&code=${filter?.code}` : ''
    const resp = await Axios.get(`${environments.sales}/sales/${restaurantId}/sumInvoices?startDate=${startDate}&endDate=${endDate}${itemDescriptionFilter}${pdvCodeFilter}`);
    const data = await resp.data[0];
    return data;
  };

  const handleInvoiceModalOpen = async () => {
    !customMainValue && setRangeDate({ ...rangeDateEx });

    setProductFilter('');
    setInvoiceModalState(true);
  };

  const handleInvoiceModalClose = () => {
    setInvoiceModalState(false);
  };

  const handleSingleInvoiceModalOpen = async (
    saleID,
    code,
    emissionDateHour,
    totalValue
  ) => {
    const resp = await Axios.get(
      `${environments.sales}/sales/${restaurantId}/notes/${saleID}/items`
    );
    const data = await resp.data;
    setSingleInvoiceModalData({
      id: saleID,
      code,
      emissionDate: emissionDateHour,
      totalValue,
      data
    });
    setSingleInvoiceModalState(true);
  };

  const handleSingleInvoiceModalClose = () => {
    setSingleInvoiceModalState(false);
  };

  useEffect(async () => {
    if (rangeDate.startDate === '' || rangeDate.startDate === undefined || rangeDate.endDate === '' || rangeDate.endDate === undefined) {
      return;
    }

    const [invoiceTotal, invoiceData] = await Promise.all(
      [
        getInvoiceModalTotal(
          rangeDate.startDate,
          rangeDate.endDate,
          productFilter
        ),
        getInvoiceModalFiltered(
          rangeDate.startDate,
          rangeDate.endDate,
          productFilter
        )
      ]
    )

    setInvoiceModalData(invoiceData);
    setCountSales(invoiceTotal?.count);
    setSalesTotalValues(invoiceTotal?.sales);

  }, [rangeDate, productFilter]);

  useEffect(async () => {
    if (rangeDateEx?.startDate === '' || rangeDateEx?.startDate === undefined || rangeDateEx?.endDate === '' || rangeDateEx?.endDate === undefined) {
      return;
    }

    const invoiceTotal = await getInvoiceModalTotal(
      rangeDateEx?.startDate,
      rangeDateEx?.endDate,
      productFilterEx
    );

    setCountSalesInitial(invoiceTotal?.count || 0);

  }, [rangeDateEx, productFilterEx, restaurantId]);

  return (
    <>
      <span
        className="dFlex alignRight fontSizeDefault cursorPointer"
        style={{
          color: '#5062F0',
          marginLeft: 2,
          marginTop: 3,
          justifyContent: 'end'
        }}
        onClick={() => {
          handleInvoiceModalOpen();
        }}
      >
        {customMainValue !== null ? (
          customMainValue
        ) : countSalesInitial !== null ? (
          `(${countSalesInitial})`
        ) : (
          <div className="dFlex justifyCenter ml5" style={{ width: 15, height: 15 }}>
            <CircularProgress className="w100 h100" />
          </div>
        )}
      </span>

      <DialogInvoices
        openState={invoiceModalState}
        onCloseProductDialog={handleInvoiceModalClose}
        handleOpenSingleModal={handleSingleInvoiceModalOpen}
        restaurantId={restaurantId}
        data={invoiceModalData}
        countSales={countSales}
        salesTotalValues={salesTotalValues}
        setRangeDate={setRangeDate}
        rangeDate={rangeDate}
        productFilter={productFilter}
        setProductFilter={setProductFilter}
      />

      <DialogSingleInvoices
        openState={singleInvoiceModalState}
        onCloseProductDialog={handleSingleInvoiceModalClose}
        data={singleInvoiceModalData}
      />
    </>
  );
}