import styled from 'styled-components';


export const Content = styled.div`
    width: auto !important;
`

export const GroupHeader = styled.div`
  display: flex;
  z-index: 999;
  position: relative;
  width: 74%;
  position: sticky !important;

  .MarginButton {
    position: absolute;
    right: -32px;
  }

  .noArrows {
    right: 21px;
  }

  .withArrows {
    right: -32px;
  }

  .limitCounts{
    right: 12px;
  }

  .ReportButton {
    cursor: pointer;
    border: 0.5px solid #1F2445;
    box-shadow: 0 -2px 4px 0px rgba(1, 3, 17, 0.25);
    border-radius: 4px 4px 0px 0px;
    padding: 0 6px;
    height: 20px;
    display: flex;
    align-items: center;

    &.active {
      background: #1F2445;
      border: 0.5px solid transparent;

      p {
        color: #FFF;
      }
    }
    }

    .countTabsContainer {
    position: relative;
    width: 764px;
    padding: 0 5px;

    @media (max-width: 1280px) {
      width: 712px;
    }

    &.sticky {
      position: sticky;
      top: 68px;
      z-index: 100;
      background: transparent;
    }

    .countTitle {
      display: flex;
      align-items: center;

      td {
        font-size: 14px !important;
        margin-left: 5px !important;
      }
    }

    .countTabItem {
      cursor: pointer;
      border: 0.5px solid #1F2445;
      padding: 4px 8px;
      box-shadow: 0px 1px 4px 1px rgba(1, 3, 17, 0.45);
      border-radius: 4px 4px 0px 0px;
      height: 20px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 200px;

      &.active {
        background: #1F2445;
        border: 0.5px solid transparent;

        p {
          color: #FFF;
        }
      }
    }

    .countTabItem:disabled {
      border: 0.5px solid transparent;
      img {
        opacity: 0.5;
      }
    }

    .onLoading{
      border-color:#b8bbcc !important;
      cursor: default;
      p{
        color:#b8bbcc !important;
      }
    }

    .arrowCustomButton {
      padding-left: 0;
      width: 20px;
      height: 20px;
      justify-content: center;
      align-items: center;
      background: #FFFFFF;
      box-shadow: 0px -2px 4px 0px rgba(1, 3, 17, 0.25);
      border-radius: 4px 4px 0px 0px;

      p {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        color: #1F2445;
        font-weight: bold;
        font-size: 12px;
        height: 100%;
        line-height: 1.6;
      }
    }

    .tabsCarouselContainer {
      display: flex;
      position: relative;
      padding-right: 5.1%;
    }

    .carousel-button-group {
      position: absolute;
      width: 102%;
      top: 0;
      right: -3px;
      justify-content: end;
    }

    .react-multi-carousel-list {
      z-index: 9;
      width: 100%;
      max-width: 100%;
      margin-left: 0;
      margin-right: 0;
      padding-left: 0;

      li {
        height: 20px;
        overflow: hidden;
        width: 200px !important;
        background: #FFFFFF;
        box-sizing: border-box;
        box-shadow: 0px 1px 4px 1px rgba(1, 3, 17, 0.45);
        border-radius: 4px 4px 0px 0px;
        margin-right: 5px;
        display: flex;
        align-items: center;

        &:last-of-type {
          margin-right: 0;
        }

        .countTabItem {
          width: 100%;
        }

        p {
          color: #1F2445;
          font-weight: bold;
          font-size: 12px;
        }

        @media (max-width: 1280px) {
          width: 130px !important;
        }
      }
    }
  }
`

export const CardLayout = styled.div`
    display: flex;
    flex-direction: column;
    width: ${props => props.widthCard ? props.widthCard : "auto"};
    padding: 13px;
    position: relative;
    gap: 13px 0;

    &.salesProj{
      padding: 0px;
    }

    .tableNegative {
        border-radius: 4px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        /* background-color: rgba(247, 212, 215, .5); */

        th {
            padding: 5px 6px 5px 13px;
            &:last-of-type{
                padding-left: 6px
            }
        }
    }

    .theadNegative {
      background-color: rgba(247, 212, 215, .5);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .tfootNegative {
      background-color: rgba(247, 212, 215, .5);
    }

    .tdNegative {
      background-color: rgba(247, 212, 215, .2) !important;
    }

    .tableDefault {
      border-radius: 4px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      background-color: rgba(218, 221, 243, 1);

      th {
          padding: 5px 6px 5px 13px;
          &:last-of-type{
              padding-left: 6px
          }
      }
  }

    .tablePositive {
      border-radius: 4px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

      th {
        padding: 5px 6px 5px 13px;
        &:last-of-type{
          padding-left: 6px;
        }
      }
    }

    .theadPositive {
      border-radius: 4px 4px 0 0;
      background-color: rgba(255, 242, 204, .5); 
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .tfootPositive {
      border-radius: 0 0 4px 4px;
      background-color: rgba(255, 242, 204, .5); 
    }

    .tdPositive {
        background-color: rgba(255, 242, 204, .2) !important;
    }

    .subtitle{
        font-size: 12px;
        font-weight: 700;
    }

    .totalNumber{
        display: flex;
        justify-content: center;
        background-color: rgba(245, 61, 76, .6);
        border-radius: 8px;
        padding: 3px 4px;
        /* margin-top: 2px; */
        min-width: 20px;
        font-size: 10px;
        color: #1F2445;
        font-weight: 700;
    }

    .totalValue {
        font-size: 12px;
        color: #000;
        font-weight: 700;
        margin: 0;
    }

    .lineLeft{
        border-left: 1px solid rgba(208, 211, 226, 1);
    }

    .gapItens {
        gap: 10px
    }

    .headerTitle{
        th {
            padding-bottom: 15px;
        }
    }

    .alignCollumn {
      padding-right: 18px !important;
    }

    .headerSubtitle{
        display: flex;
        border-radius: 4px;
        position: absolute;
        width: 97%;
        justify-content: center;
        top: 110px;
        
        th {
            background-color: rgba(245, 61, 76, 1);
            padding: 8px 6px;
            font-size: 9px;
            color: #fff;
            font-weight: 700;
            margin: 0;

            &:first-of-type{
                border-radius: 4px 0 0 4px;
            }

            &:last-of-type{
                border-radius: 0 4px 4px 0;
            }
        }
    }


    .headerSubtitleDefault{
      display: flex;
      border-radius: 4px;
      position: absolute;
      width: 100%;
      justify-content: center;
      top: 25px;
      
      th {
          background-color: rgba(80, 98, 240, 1);
          padding: 8px 6px;
          font-size: 9px;
          color: #fff;
          font-weight: 700;
          margin: 0;

          &:first-of-type{
              border-radius: 4px 0 0 4px;
          }

          &:last-of-type{
              border-radius: 0 4px 4px 0;
          }
      }
  }

    .subheader{
        border-radius: 4px;
        display: flex;
        justify-content: center;
        width: 98%;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.5);
    }

    .list {
        &:first-of-type{
            td {
                padding-top: 25px;
            }
        }

        td {
            background-color: #fff;
            padding: 5px 6px;
            &:first-of-type{
              padding-left: 15px;
            }

            &:last-of-type{
              padding-right: 18px;
            }
        }
    }

    .titleLink{
        color: rgba(80, 98, 240, 1);
        font-size: 12px;
    }

    .tdParagraph {
      font-size: 12px;
      color: rgba(31, 36, 69, 1);
    }

    .tdNegative {
      font-size: 12px;
      color: rgba(245, 61, 76, 1);
    }

    .tdPositive {
      font-size: 12px;
      color: rgba(170, 130, 8, 1);
    }

    .tdDefault {
      font-size: 12px;
      color: rgba(80, 98, 240, 1);
    }

    .backgroundTdNegative {
      background: rgba(0, 212, 215, .2) !important;
    }

    .backgroundTdPositive {
      background: rgba(255, 242, 204, .2) !important;
    }

    .headerSubtitlePositive{
      display: flex;
      border-radius: 4px;
      position: absolute;
      width: 99.8%;
      justify-content: center;
      top: 29px;
      th {
          background-color: rgba(242, 185, 13, 1);
          padding: 8px 6px;
          font-size: 9px;
          color: #1F2445;
          font-weight: 700;
          margin: 0;

          &:first-of-type{
              border-radius: 4px 0 0 4px;
          }

          &:last-of-type{
              border-radius: 0 4px 4px 0;
          }
      }
    }

    .totalNumberPositive{
      display: flex;
      justify-content: center;
      min-width: 20px;
      background-color: rgba(242, 185, 13, .6);
      border-radius: 8px;
      padding: 3px 4px;
      font-size: 10px;
      color: #1F2445;
      font-weight: 700;
    }

    .td0 {
      td {
        padding: 0 !important;
      }
    }
`

export const Filters = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 16px;
`

export const Indicator = styled.div`
    display: flex;
    margin-top: 24px;
    height: 24px;
    background-color: #5062F0;
    border-radius: 4px;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 3px 6px;

    p {
        font-size: 12px;
        color: #FFFFFF;
        font-weight: 700;
    }
`

export const Comparison = styled.div`
    margin-top: 26px;

    p{
        margin: 0;
    }
`

export const Totaldifferences = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 138px;
    margin: 24px 2px 0 0;
    height: 24px;
    background-color: ${(props) => props.backgroundColor};
    border-radius: 4px;
    box-shadow: 0px 4px 4px 0px #00000040;
    padding: 3px 16px 3px 4px;
    gap: 10px;

    div{
        display: flex;
        justify-content: center;
        background-color: ${(props) => props.backgroundDiv};
        border-radius: 8px;
        padding: 3px 4px;
        min-width: 20px;
        margin-top: 2px;
        font-size: 10px;
        color: #1F2445;
        font-weight: 700;

    }

    p {
        font-size: 12px;
        color: #000;
        font-weight: 700;
        margin: 0;
    }
`
