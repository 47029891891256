/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';

import { CircularProgress, Tooltip } from '@material-ui/core';
import ReactGA from 'react-ga4';

import DefaultImage from 'images/defaultAndEmpties/defaultImage.svg';
import BloomIcon from 'images/icons/logo/bloomIconLarge.svg';

import { ImageContainer } from './style';

function ImageWrapper({
  url = null,
  skuId = null,
  skusImageUrls = [],
  productsIds,
  setProductsIds,
  modalStates,
  setModalStates,
  action,
  showOnlyImages = true,
  width,
  height,
  solidBgColor,
  showWaterMark = false,
  waterMarkWidth = 20,
  customOnClick,
  screen = '',
  customClass = '',
  placementMain = 'right',
  placement = 'bottom'
}) {
  if (skusImageUrls && skusImageUrls.length > 0) {
    showOnlyImages = skusImageUrls.some((skuImage) => !skuImage.image);
  }
  const [loadingImage, setLoadingImage] = useState(true);
  const [show, setShow] = useState(false);
  const [crashImage, setCrashImage] = useState(false);
  const mainSku = skusImageUrls.filter(
    (skuImage) => skuImage.skuId === skuId
  )[0];
  const imageUrlsAnotherSkus = skusImageUrls.filter(
    (skuImage) => skuImage.skuId !== skuId
  );
  const imageUrlsAnotherSkusToShow = [...imageUrlsAnotherSkus.slice(0, 3)];
  const numberSkusNotShowed = imageUrlsAnotherSkus.length - 3;

  const handleShowImage = (status = false) => {
    ReactGA.event({
      category: 'Preview',
      action: 'Hover',
      label: 'ImagemProduto'
    });
    setShow(status);
  };

  useEffect(() => {
    setLoadingImage(true);
  }, [url]);

  return url == null && showOnlyImages ? (
    <img
      src={url || DefaultImage}
      alt={url}
      width={width || '56px'}
      height={height || '56px'}
      style={{ cursor: 'pointer' }}
      onClick={(e) => {
        customOnClick && customOnClick(e)
      }}
    />
  ) : (
    <Tooltip
      arrow={!crashImage}
      placement={placementMain}
      interactive
      open={show}
      PopperProps={{
        disablePortal: true,
        popperOptions: {
          positionFixed: true,
          modifiers: {
            preventOverflow: {
              enabled: true,
              boundariesElement: "window" // where "window" is the boundary
            }
          }
        }
      }}
      title={
        <div
          className={`tooltipMultipleImages ${customClass}`}
          onMouseEnter={() => handleShowImage(true)}
          onMouseLeave={() => handleShowImage(false)}
        >
          <Tooltip
            placement={placement}
            title={screen === 'product' ? mainSku?.image?.name.split(".")[0] : mainSku?.descriptionSku || null}
            disableHoverListener={mainSku === undefined}
          >
            <div className="mainSkuImage cropDataSmallBox mr0 bgWhite mediumSize pRelative">
              {showWaterMark ? (
                <img
                  src={BloomIcon}
                  alt="BloomIcon"
                  className="pAbsolute"
                  style={{ width: `${waterMarkWidth + 5}px`, top: 2, right: 2 }}
                />
              ) : null}

              <img
                className="cursorPointer"
                src={url || DefaultImage}
                alt={url}
                onError={(e) => {
                  e.target.src = DefaultImage;
                  setCrashImage(true);
                }}
                onClick={(e) => {
                  customOnClick && customOnClick(e)

                  setModalStates &&
                    setModalStates({ ...modalStates, [action]: true });
                  productsIds && setProductsIds(productsIds);

                  setShow(false);
                }}
              />
            </div>
          </Tooltip>

          {imageUrlsAnotherSkusToShow?.length > 0 ? (
            <div className="sideInfos">
              {imageUrlsAnotherSkusToShow?.map((sku) => (
                <Tooltip placement="bottom" title={screen === 'product' ? sku?.image?.name?.split(".")[0] : sku?.descriptionSku}>
                  <div className="additionalImage cropDataSmallBox mr0 bgWhite smallSize">
                    <img
                      className="cursorPointer"
                      src={screen === 'product' ? sku?.image?.url ? sku?.image?.url : DefaultImage : sku?.image || DefaultImage}
                      alt={screen === 'product' ? sku?.image?.url ? sku?.image?.url : DefaultImage : sku?.image }
                      onError={(e) => {
                        e.target.src = DefaultImage;
                        setCrashImage(true);
                      }}
                      onClick={(e) => {
                        customOnClick && customOnClick(e)

                        setModalStates &&
                          setModalStates({ ...modalStates, [action]: true });
                        productsIds && setProductsIds(productsIds);

                        setShow(false);
                      }}
                    />
                  </div>
                </Tooltip>
              ))}

              {numberSkusNotShowed > 0 ? (
                <div className="infoSkus">
                  {`+ ${numberSkusNotShowed} SKUS`}
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      }
    >
      <ImageContainer
        solidBgColor={solidBgColor}
        width={width}
        height={height}
        maxHeight={height}
        onClick={() => {
          handleShowImage(!show)
        }}
      >
        {loadingImage && <CircularProgress />}

        {showWaterMark ? (
          <img
            src={BloomIcon}
            alt="BloomIcon"
            className="pAbsolute"
            style={{ width: waterMarkWidth, top: 2, right: 2 }}
            onClick={(e) => {
              customOnClick && customOnClick(e)
            }}
          />
        ) : null}

        <img
          className={`cursorPointer ${loadingImage ? 'dNone' : 'dBlock'}`}
          src={url || DefaultImage}
          alt={url}
          onError={(e) => {
            e.target.src = DefaultImage;
          }}
          onLoad={() => {
            setLoadingImage(false);
          }}
          width={width || '56px'}
          height={height || '56px'}
          onMouseEnter={() => handleShowImage(true)}
          onMouseLeave={() => handleShowImage(false)}
          onClick={(e) => {
            customOnClick && customOnClick(e)
          }}
        />
      </ImageContainer>
    </Tooltip>
  );
}

export default ImageWrapper;
