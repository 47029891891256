/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  LinearProgress,
  Checkbox
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { format, addHours, isBefore } from 'date-fns';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ItemWrapper } from 'components/BloomTable/components/ItemSelect/style';
import SkuModal from 'components/Dialogs/SkuModal/SkuModal';
import ImageWrapper from 'components/ImageWrapper';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import PaperComponent from 'components/PaperComponent';
import BloomBaseLabel from 'images/icons/lettersAndWords/words/bloomBaseLabel.svg';
import LabelYourCatalog from 'images/icons/lettersAndWords/words/labelYourCatalog.svg';
import LabelYourSupplier from 'images/icons/lettersAndWords/words/labelYourSupplier.svg';
import SkuBloomLabel from 'images/icons/lettersAndWords/words/skuBloomLabel.svg';
import BloomModalSearchSkuLabel from 'images/icons/logo/bloomModalSearchSkuLabel.svg';
import CheckedIconSkuModal from 'images/icons/signals/checks/checkedIconSkuModal.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import DisableCheckbox from 'images/icons/signals/removesAndDeletes/disableCheckbox.svg';
import getOptions from 'services/getOptions';
import * as UserActions from 'store/actions/user';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions
} from 'utils/handleFilterActions';
import numberToReal from 'utils/numberToReal';
import ValidationLength from 'utils/utils';

function SearchModal(props) {
  const isQuoter = props.userInfo?.profile?.[0]?.[0]?.name === 'quoter';
  const [openModalProduct, setModalProduct] = useState(false);
  const handleCloseModalProduct = () => {
    setModalProduct(false);
  };
  const [callSettings, setCallSettings] = useState({
    getId: props.getId,
    page: 1,
    totalPages: 0,
    size: 10,
    loading: false,
    mainContent: [],
    filters: []
  });
  const { getId, page, size, totalPages, mainContent, loading } = callSettings;
  const [currentPage, setCurrentPage] = useState(page);
  const environments = Environment(process.env.REACT_APP_ENV);
  const [filtersChanged, setFiltersChanged] = useState([]);
  const [modalSettings, setModalSettings] = useState([
    {
      id: 1,
      name: 'filters',
      open: true,
      fullScreen: null,
      maxWidth: null,
      fullWidth: null
    },
    {
      id: 2,
      name: 'skuModal',
      open: false,
      fullScreen: true,
      maxWidth: null,
      fullWidth: true
    }
  ]);
  const [supplier, setSupplier] = useState([]);
  const [unitsMeasurement, setUnitsMeasurement] = useState('');
  const [skuName, setSkuName] = useState('');
  const [skuQuantity, setSkuQuantity] = useState();
  const [filterFields, setFilterFields] = useState([
    [
      {
        label: '',
        filterName: 'descriptionSku',
        placeHolder: '',
        type: 'defaultInputFieldSearch',
        urlPath: `${environments.catalog}/skus`,
        optionReference: 'description',
        options: [],
        size: 'large',
        focus: props.openModal
      }
    ]
  ]);
  const [items, setItems] = useState([]);
  const [providers, setProviders] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [unitsMeasurements, setUnitsMeasurements] = useState([]);
  const [brands, setBrands] = useState([]);
  const [consumptionUnits, setConsumptionUnits] = useState([]);
  const [storageLocations, setStorageLocations] = useState([]);
  const [periodicities, setPeriodicities] = useState([]);
  const [unifiedSkus, setUnifiedSkus] = useState(false);
  const [skus, setSkus] = useState([]);
  const [selectedSku, setSelectedSku] = useState([]);
  const [sku, setSku] = useState({
    ean: null,
    status: 'ANALYZE',
    description: '',
    specification: null,
    brandsId: null,
    restaurants: null,
    providers: selectedSku,
    product: null,
    categoryId: null,
    quantity: 1,
    unitsMeasurementsId: null,
    user: props.userInfo.user
  });
  const [open, setOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState([
    {
      name: 'Both',
      isSelected: true
    },
    {
      name: 'Restaurant',
      isSelected: false
    },
    {
      name: 'Bloom',
      isSelected: false
    }
  ]);

  useEffect(() => {
    setCallSettings({ ...callSettings, getId: props.getId });
  }, [props.userInfo]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filtersChanged]);

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${environments.restaurants}/companies/${getId}/parameters`
      );
      const parameters = await response.json();
      setUnifiedSkus(parameters.counts ? parameters.counts.unifiedSkus : false);
    })();
  }, [getId]);

  useEffect(() => {
    getOptions(
      `${environments.catalog}/products`,
      'products',
      products,
      setProducts,
      getId,
      null,
      null,
      10
    );
    getOptions(
      `${environments.catalog}/skus/providers`,
      'skus',
      items,
      setItems,
      getId,
      null,
      null,
      10
    );
    getOptions(
      `${environments.catalog}/categories`,
      'categories',
      categories,
      setCategories,
      getId,
      null,
      null,
      10
    );
    getOptions(
      `${environments.catalog}/typePackages`,
      'packageTypes',
      packageTypes,
      setPackageTypes,
      getId
    );
    getOptions(
      `${environments.catalog}/unitsMeasurements`,
      'unitsMeasurements',
      unitsMeasurements,
      setUnitsMeasurements,
      getId
    );
    getOptions(
      `${environments.catalog}/brands`,
      'brands',
      brands,
      setBrands,
      getId
    );
    getOptions(
      `${environments.catalog}/consumptionUnits`,
      'consumptionUnits',
      consumptionUnits,
      setConsumptionUnits,
      getId
    );
    getOptions(
      `${environments.catalog}/storageLocations`,
      'storageLocations',
      storageLocations,
      setStorageLocations,
      getId
    );
    getOptions(
      `${environments.catalog}/periodicities`,
      'periodicities',
      periodicities,
      setPeriodicities,
      getId
    );
    getOptions(
      `${environments.providers}/providers`,
      'providers',
      providers,
      setProviders,
      getId,
      null,
      null,
      10
    );
  }, [getId]);

  useEffect(() => {
    (async () => {
      await handleData();
    })();
  }, [getId, page, filtersChanged, open]);

  function getColorStatus(expirationDate) {
    const colorStatus = {
      backgroundColor: '#E6E7F0',
      color: '#9296AC',
      borderRadius: '8px',
      quotation: 'Sem cotação'
    };

    if (expirationDate) {
      const expDate = new Date(addHours(new Date(expirationDate), 3));
      const isValid = isBefore(new Date(), expDate);

      if (isValid) {
        colorStatus.backgroundColor = '#D2F9D6';
        colorStatus.color = '#089163';
        colorStatus.quotation = 'Cotação válida';
      } else {
        colorStatus.backgroundColor = '#FFF2CC';
        colorStatus.color = '#AA8208';
        colorStatus.quotation = 'Cotação vencida';
      }
    }

    return colorStatus;
  }

  function handleDesktopRows() {
    return (
      <>
        {mainContent?.length ? (
          mainContent.map((item, index) => {
            const { skuBloom, providers, restaurants } = item;

            const image = skuBloom?.images?.url;
            const isBloomFilterEnabled = selectedFilter?.find(
              (el) => el.name === 'Bloom'
            )?.isSelected;
            const isCatalogged =
              restaurants.some((restaurant) => restaurant.restaurantId > 0) ||
              (isBloomFilterEnabled ? item?.alreadyCatalogued !== 0 : false);
            const bestValue =
              providers.length &&
              providers.reduce((acc, loc) =>
                acc.purchaseLastUnitary < loc.purchaseLastUnitary ? acc : loc
              );
            const quotation = bestValue.quotations
              ? bestValue.quotations[0]
              : null;
            const otherProviders = providers.filter(
              (provider) => provider.name !== bestValue.name
            );

            return (
              <tr>
                <td className="description alignCenter dFlex mt10">
                  {isCatalogged ? (
                    <div
                      className="dFlex alignCenter justifyCenter"
                      style={{
                        width: '38px',
                        height: '38px'
                      }}
                    >
                      <img src={DisableCheckbox} alt="disable" />
                    </div>
                  ) : (
                    <Checkbox
                      checked={selectedSku.some((el) => el.id === item.id)}
                      size="small"
                      onChange={(event, checked) =>
                        handleSelectedSku(checked, item)
                      }
                      color="#5062F0"
                      checkedIcon={
                        <img
                          src={CheckedIconSkuModal}
                          style={{ padding: '2px' }}
                          alt="checked"
                        />
                      }
                    />
                  )}
                </td>
                <td>
                  <ImageWrapper url={image || null} />
                </td>
                <td className="description alignCenter sku-name">
                  {ValidationLength(item.description, 30)}
                </td>
                <td className="description pr12" style={{ fontSize: '9px' }}>
                  {isCatalogged ? (
                    <img src={SkuBloomLabel} alt="SKU Bloom label" />
                  ) : (
                    <img src={LabelYourCatalog} alt="label your catalog " />
                  )}
                </td>
                <td className="description pr12" style={{ textAlign: 'end' }}>
                  {!item.providers.length ||
                  item.providers === null ||
                  item.providers === undefined
                    ? '-'
                    : `R$${numberToReal(bestValue.purchaseLastUnitary)}/`}
                  {item?.unitsMeasurements
                    ? item.unitsMeasurements.abbreviation
                    : ''}
                </td>
                <td className="description pr12" style={{ textAlign: 'end' }}>
                  {!item.providers.length ||
                  item.providers === null ||
                  item.providers === undefined
                    ? '-'
                    : `E${bestValue.quantity}x${item.quantity}`}
                  {item?.unitsMeasurements
                    ? item.unitsMeasurements.abbreviation
                    : ''}
                </td>
                <td className="description pr12" style={{ textAlign: 'end' }}>
                  {!item.providers.length ||
                  item.providers === null ||
                  item.providers === undefined
                    ? '-'
                    : `R$${numberToReal(bestValue.purchaseLast)}`}
                </td>
                <td className="description pr12" style={{ textAlign: 'end' }}>
                  <Tooltip
                    TransitionProps={{ timeout: 600 }}
                    placement="right-end"
                    title={
                      <p className="mb0" style={{ padding: '2px 6px' }}>
                        {
                          getColorStatus(
                            quotation ? quotation.expirationDate : null
                          ).quotation
                        }
                      </p>
                    }
                  >
                    <ItemWrapper
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        display: 'block',
                        lineHeight: 1.2,
                        float: 'right'
                      }}
                      buttonLink={getColorStatus(
                        quotation ? quotation.expirationDate : null
                      )}
                    >
                      {quotation
                        ? format(
                            new Date(quotation.expirationDate).valueOf() +
                              14400000,
                            'dd/MM/yyyy'
                          )
                        : '-'}
                    </ItemWrapper>
                  </Tooltip>
                </td>
                <td className="description pl12">
                  <span className="supplier-name">
                    {ValidationLength(
                      bestValue.name ? bestValue.name : '-',
                      35
                    )}
                  </span>
                </td>
                <td className="description pr12" style={{ textAlign: 'end' }}>
                  {otherProviders.length ? (
                    <Tooltip
                      TransitionProps={{ timeout: 600 }}
                      placement="right-end"
                      title={
                        <p className="mb0" style={{ padding: '2px 6px' }}>
                          {`+${otherProviders.length} opções de fornecedores`}
                        </p>
                      }
                    >
                      <span
                        style={{
                          marginRight: '5px',
                          color: '#5062F0',
                          borderBottom: '1px solid #5062F0',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          setModalProduct(true);
                          setSupplier(otherProviders);
                          setUnitsMeasurement(
                            item.unitsMeasurements.abbreviation
                          );
                          setSkuName(item.description);
                          setSkuQuantity(item.quantity);
                        }}
                      >
                        {`+${otherProviders.length}`}
                      </span>
                    </Tooltip>
                  ) : null}
                  {item.providers.some((provider) => provider.isPlatform) ? (
                    <img
                      src={BloomModalSearchSkuLabel}
                      alt="Bloom Modal Search SKU label"
                    />
                  ) : null}
                </td>
              </tr>
            );
          })
        ) : (
          <tr>
            <th colSpan="15" style={{ margin: '10px', textAlign: 'center' }}>
              Nenhum catalogo registrado
            </th>
          </tr>
        )}

        <Dialog
          open={openModalProduct}
          onClose={handleCloseModalProduct}
          aria-labelledby="draggable-dialog-title"
          transitionDuration={0}
          PaperComponent={(props) => (
            <PaperComponent {...props} handleId="#modalProduct" />
          )}
        >
          <DialogTitle id="modalProduct" className="sku-search-title">
            <span>{skuName}</span>
            <IconButton
              edge="start"
              color="inherit"
              className="close-dialog-sku-header-search"
              onClick={handleCloseModalProduct}
              aria-label="close"
            >
              <img
                style={{ width: '13px' }}
                src={CloseIconNavy}
                alt="CloseIconNavy"
              />
            </IconButton>
          </DialogTitle>

          <DialogContent style={{ padding: '0px 0px 12px 0px' }}>
            <table
              className="desktop-table-search-sku desktop-table-sku-provider"
              width="100%"
              cellPadding="0"
              cellSpacing="0"
              border="0"
            >
              <thead>
                <tr className="header-title">
                  <th width="240px" className="subtitle">
                    FORNECEDOR
                  </th>
                  <th width="89px" className="subtitle" />
                  <th width="61px" className="subtitle">
                    EMB
                  </th>
                  <th width="70px" className="subtitle">
                    PREÇO R$
                  </th>
                  <th width="85px" className="subtitle">
                    VÁLIDO ATÉ
                  </th>
                </tr>
              </thead>

              <tbody>
                {supplier.length &&
                  supplier.map((provider, index) => {
                    const bestValue =
                      provider.quotations.length &&
                      provider?.quotations.reduce((acc, loc) =>
                        acc.unitaryValue < loc.unitaryValue ? acc : loc
                      );
                    return (
                      <tr>
                        <td className="description pl12">
                          <span className="sku-search-provider-modal">
                            {ValidationLength(provider.name, 30)}
                          </span>
                        </td>
                        <td className="description pl12">
                          {provider.isPlatform === true ? (
                            <img src={BloomBaseLabel} alt="Bloom base label" />
                          ) : (
                            <img
                              src={LabelYourSupplier}
                              alt="label your supplier "
                            />
                          )}
                        </td>
                        <td className="description pl12">
                          E{provider.quantity}x{skuQuantity}
                          {unitsMeasurement}
                        </td>
                        {provider.quotations.length ? (
                          <>
                            <td className="description pl12">{`R$${numberToReal(
                              bestValue.unitaryValue
                            )}`}</td>
                            <td className="description pl12">
                              <span
                                style={{
                                  backgroundColor: moment(
                                    bestValue.expirationDate
                                  ).isSameOrAfter(new Date())
                                    ? '#D2F9D6'
                                    : '#FFF2CC',
                                  padding: '4px',
                                  color: moment(
                                    bestValue.expirationDate
                                  ).isSameOrAfter(new Date())
                                    ? '#08AA15'
                                    : '#AA8208'
                                }}
                              >
                                {moment(bestValue.expirationDate).format(
                                  'DD/MM/YY'
                                )}
                              </span>
                            </td>
                          </>
                        ) : (
                          <td colSpan="2" className="description pl12">
                            Sem cotação
                          </td>
                        )}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </DialogContent>
        </Dialog>
      </>
    );
  }

  function handleFilter(param, value) {
    handleFilterOptions(
      param,
      value,
      filtersChanged,
      setFiltersChanged,
      null,
      false,
      '1',
      { callSettings, setCallSettings }
    );
  }

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      getId,
      filtersChanged,
      page,
      true
    );
  };

  function handleModal(title, value) {
    if (value) {
      const key = Object.keys(value)[0];
      const objValue = Object.values(value)[0];

      setModalSettings(
        modalSettings.map((x) =>
          x.name === title ? { ...x, [key]: objValue } : x
        )
      );
    } else {
      const { id } = modalSettings.find((x) => x.name === title);
      const { name } = modalSettings.find((x) => x.name === title);
      const { open } = modalSettings.find((x) => x.name === title);
      const { fullWidth } = modalSettings.find((x) => x.name === title);
      const { maxWidth } = modalSettings.find((x) => x.name === title);
      return { id, name, open, fullWidth, maxWidth };
    }

    if (title === 'skuModal' && value.open === false) {
      setOpen(false);
      setSelectedSku([]);
    }
  }

  function handleSelectedSku(checked, sku) {
    const currentIndex = selectedSku.indexOf(sku);
    const newChecked = [...selectedSku];

    if (checked) {
      if (!selectedSku.length) {
        setSku({
          ...sku,
          ean: sku.ean ? sku.ean : null,
          status: 'ANALYZE',
          description: sku.description ? sku.description : '',
          specification: '',
          quantity: sku.quantity ? sku.quantity : null,
          unitsMeasurementsId: sku.unitsMeasurements
            ? sku.unitsMeasurements.id
            : null,
          unitsMeasurements: sku.unitsMeasurements
            ? sku.unitsMeasurements
            : null
        });
      }
      newChecked.push(sku);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedSku(newChecked);
  }

  async function handleData() {
    setCallSettings({ ...callSettings, loading: true });

    const originIds = props.userInfo?.companies.groups
      .reduce((acc, group) => [...acc, group.subGroups], [])
      .flat()
      .reduce((acc, subGroup) => [...acc, subGroup.stores], [])
      .flat()
      .map((item) => item.id);

    const restaurantId = isQuoter ? originIds : getId;
    const params = filtersChanged?.map((filter) => filter.urlParam).join('&');

    if (params.length > 0) {
      await fetch(
        `${environments.catalog}/skus/search?page=${page}&size=${size}&originId=${restaurantId}&${params}`
      )
        .then(async (response) => {
          const items = await response.json();
          setCallSettings({
            ...callSettings,
            totalPages: items.totalPages,
            mainContent: items?.content || [],
            loading: false
          });
        })
        .catch(() => {
          setCallSettings({
            ...callSettings,
            totalPages: 1,
            mainContent: [],
            loading: false
          });
        });
    } else {
      setCallSettings({
        ...callSettings,
        totalPages: 1,
        mainContent: [],
        loading: false
      });
    }
  }

  const btnUseSkusColor = selectedSku.length > 0 ? '#fff' : '#9296AC';
  const btnUseSkusBackground =
    selectedSku.length > 0 ? 'rgb(80, 98, 240)' : '#D0D3E2';

  function handleClose() {
    props.closeModal();
    setFiltersChanged(false);
  }

  return (
    <div>
      <Dialog
        className="dialog-search-header-sku"
        open={props.openModal}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        transitionDuration={0}
      >
        <DialogTitle className="sku-search-title">
          <span id="draggable-dialog-title">Pesquisar SKU</span>
          <PageTitleAndFilter
            activeFilterFilds={filterFields}
            handleFilter={handleFilter}
            getFilterhandle={getFilterhandle}
          />

          <section id="draggable-dialog-title" className="dFlex ml20">
            <div
              className="dFlex alignCenter cursorPointer"
              onClick={() => {
                setSelectedFilter(
                  selectedFilter.map((item) => {
                    if (item.name === 'Both') {
                      return {
                        ...item,
                        isSelected: true
                      };
                    }
                    return {
                      ...item,
                      isSelected: false
                    };
                  })
                );
                handleFilter('base', '');
              }}
            >
              <div
                className="dFlex justifyCenter alignCenter"
                style={{
                  borderRadius: 50,
                  width: 20,
                  height: 20,
                  background: '#FFF'
                }}
              >
                <div
                  style={{
                    borderRadius: 50,
                    width: 10,
                    height: 10,
                    background: selectedFilter.find(
                      (filter) => filter.name === 'Both'
                    ).isSelected
                      ? '#0E1337'
                      : 'transparent'
                  }}
                />
              </div>
              <span className="fontSizeDefault white ml10">Ambas</span>
            </div>

            <div
              className="dFlex ml20 alignCenter cursorPointer"
              onClick={() => {
                setSelectedFilter(
                  selectedFilter.map((item) => {
                    if (item.name === 'Restaurant') {
                      return {
                        ...item,
                        isSelected: true
                      };
                    }
                    return {
                      ...item,
                      isSelected: false
                    };
                  })
                );
                handleFilter('base', 'RESTAURANT');
              }}
            >
              <div
                className="dFlex justifyCenter alignCenter"
                style={{
                  borderRadius: 50,
                  width: 20,
                  height: 20,
                  background: '#FFF'
                }}
              >
                <div
                  style={{
                    borderRadius: 50,
                    width: 10,
                    height: 10,
                    background: selectedFilter.find(
                      (filter) => filter.name === 'Restaurant'
                    ).isSelected
                      ? '#0E1337'
                      : 'transparent'
                  }}
                />
              </div>
              <span className="fontSizeDefault white ml10">Restaurante</span>
            </div>

            <div
              className="dFlex ml20 alignCenter cursorPointer"
              onClick={() => {
                setSelectedFilter(
                  selectedFilter.map((item) => {
                    if (item.name === 'Bloom') {
                      return {
                        ...item,
                        isSelected: true
                      };
                    }
                    return {
                      ...item,
                      isSelected: false
                    };
                  })
                );
                handleFilter('base', 'BLOOM');
              }}
            >
              <div
                className="dFlex justifyCenter alignCenter"
                style={{
                  borderRadius: 50,
                  width: 20,
                  height: 20,
                  background: '#FFF'
                }}
              >
                <div
                  style={{
                    borderRadius: 50,
                    width: 10,
                    height: 10,
                    background: selectedFilter.find(
                      (filter) => filter.name === 'Bloom'
                    ).isSelected
                      ? '#0E1337'
                      : 'transparent'
                  }}
                />
              </div>
              <span className="fontSizeDefault white ml10">Bloom</span>
            </div>
          </section>

          <IconButton
            edge="start"
            color="inherit"
            className="close-dialog-sku-header-search"
            onClick={() => {
              props.closeModal();
              setFiltersChanged(false);
            }}
            aria-label="close"
          >
            <img
              style={{ width: '13px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent style={{ padding: '0px' }}>
          {filtersChanged?.length ? (
            <>
              <table
                className="desktop-table-search-sku"
                width="100%"
                cellPadding="0"
                cellSpacing="0"
                border="0"
              >
                <thead>
                  <tr className="header-title">
                    <th width="50px" className="subtitle" />
                    <th width="100px" className="subtitle" />
                    <th width="230px" className="subtitle">
                      SKU
                    </th>
                    <th width="80px" className="subtitle" />
                    <th
                      width="100px"
                      className="subtitle"
                      style={{ textAlign: 'end' }}
                    >
                      MENOR PREÇO
                    </th>
                    <th
                      width="100px"
                      className="subtitle"
                      style={{ textAlign: 'end' }}
                    >
                      EMBALAGEM
                    </th>
                    <th
                      width="100px"
                      className="subtitle"
                      style={{ textAlign: 'end' }}
                    >
                      PREÇO EMB.
                    </th>
                    <th
                      width="100px"
                      className="subtitle"
                      style={{ textAlign: 'center' }}
                    >
                      VALIDADE
                    </th>
                    <th width="175px" className="subtitle">
                      FORNECEDORES
                    </th>
                    <th
                      width="100px"
                      className="subtitle"
                      style={{ textAlign: 'center' }}
                    >
                      OPÇÕES
                    </th>
                  </tr>
                </thead>

                <tbody>{handleDesktopRows()}</tbody>
              </table>
              <div className="pagination-and-btnCatalog">
                <div className="pagination div-pagination">
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={(event, page) => {
                      setCallSettings({ ...callSettings, page });
                      setCurrentPage(page);
                    }}
                    variant="outlined"
                    shape="rounded"
                    size="small"
                    color="primary"
                  />
                </div>
                <div className="div-btnCatalog">
                  <button
                    className="btn-catalog-sku"
                    style={{
                      color: btnUseSkusColor,
                      backgroundColor: btnUseSkusBackground
                    }}
                    disabled={!selectedSku.length}
                    onClick={() => handleModal('skuModal', { open: true })}
                  >
                    Utilizar SKUs
                  </button>
                </div>
              </div>
              <div style={{ height: '4px' }}>
                {loading && <LinearProgress id="loading" variant="query" />}
              </div>
            </>
          ) : null}
        </DialogContent>
      </Dialog>

      {handleModal('skuModal').open && (
        <SkuModal
          fullScreen={handleModal('skuModal').fullScreen}
          maxWidth={handleModal('skuModal').maxWidth}
          fullWidth={handleModal('skuModal').fullWidth}
          open={handleModal('skuModal').open}
          multipleSkuData={selectedSku}
          getId={getId}
          editMode={false}
          sku={sku}
          userInfo={props.userInfo}
          items={items}
          providers={providers}
          products={products}
          packageTypes={packageTypes}
          categories={categories}
          brands={brands}
          storageLocations={storageLocations}
          periodicities={periodicities}
          unitsMeasurements={unitsMeasurements}
          consumptionUnits={consumptionUnits}
          unifiedSkus={unifiedSkus}
          skus={skus}
          searchModal
          onClose={() => {
            handleModal('skuModal', { open: false });
            handleData();
          }}
          skuSearch
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SearchModal);
