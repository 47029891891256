/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  TextField,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ptLocale from 'date-fns/locale/pt-BR';
import moment from 'moment';
import { Link } from 'react-router-dom';

import PaperComponent from 'components/PaperComponent';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import getOptions from 'services/getOptions';
import axiosInstanceCatalog from 'services/middlewares/apis/catalog_validate';
import Environment from 'utils/environments';
import numberToReal from 'utils/numberToReal';

export default function CostTimeline({
  open,
  onClose,
  product,
  restaurantId,
}) {
  const environments = Environment(process.env.REACT_APP_ENV);
  const [mainList, setMainList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract('30', 'days').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [selectedProduct, setSelectedProduct] = useState(product);
  const [productOptions, setProductOptions] = useState([]);

  const getMainContent = async () => {
    setLoading(true);

    const currentFilters = [
      {
        value: moment(startDate)?.format('YYYY-MM-DD'),
        urlParam: `dateStart=${moment(startDate)?.format('YYYY-MM-DD')}`,
      },
      {
        value: moment(endDate)?.format("YYYY-MM-DD"),
        urlParam: `dateFinish=${moment(endDate)?.format("YYYY-MM-DD")}`,
      },
    ];

    const params = currentFilters
      ?.filter((filter) => filter)
      ?.map((filter) => filter?.urlParam)
      .join('&');
    const currentProductId = selectedProduct?.id || product?.id

    await axiosInstanceCatalog
      .get(`${environments.catalog}/superlog/products/${currentProductId}?restaurantId=${restaurantId}&view=costs&${params}`)
      .then((response) => {
        setMainList(response?.data?.content);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getMainContent()
  }, [selectedProduct, startDate, endDate])
  
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal mH80 costTimelineDialog"
      maxWidth="lg"
    >
      <DialogTitle
        className="modalHeader bold textAlignCenter cursorMove"
        id="draggable-dialog-title"
      >
        <div className="dFlex justifyBetween alignCenter w100">
          <Typography>HISTÓRICO DE CUSTOS</Typography>
        </div>

        <IconButton
          className="ml10"
          edge="start"
          color="inherit"
          onClick={() => onClose()}
        >
          <img
            style={{ width: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <DialogContent
        className="modalContent"
        style={{ padding: '16px' }}
      >
        <div className="cardDefault dFlex flexColumn pl16 pr16 pb16 mb16 overflowHidden" style={{ paddingTop: 29 }}>
          <div className={`mainInfosBox dFlex ${!mainList?.length && 'mb0'}`}>
            <Autocomplete
              style={{ width: '70%' }}
              options={productOptions}
              getOptionLabel={(option) => option?.description}
              className="autocompleteSmall mr16"
              value={selectedProduct}
              size="small"
              onFocus={() => {
                if (!productOptions || productOptions?.length === 0) {
                  getOptions(
                    `${environments.catalog}/products`,
                    'products',
                    productOptions,
                    setProductOptions,
                    restaurantId,
                    { autoComplete: true },
                    null,
                    2000
                  );
                }
              }}
              onChange={(_, value) => {
                setSelectedProduct(value || {});
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={params.id}
                  type="text"
                  name="Products"
                  variant="outlined"
                  label="Produto"
                  placeholder="Selecione..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
              renderOption={(params) => (
                <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
                  {params.description}
                </Typography>
              )}
            />

            <div className="datePicker dFlex" style={{ marginTop: "-30.5px" }}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={ptLocale}
              >
                <KeyboardDatePicker
                  className="dateSmall mt30 mr16"
                  size="small"
                  id="time-picker"
                  placeholder="00/00/0000"
                  format="dd/MM/yyyy"
                  ampm={false}
                  label="Início"
                  inputVariant="outlined"
                  clearable
                  fullWidth
                  autoOk
                  maxDate={moment(endDate).add(3, 'hours')}
                  value={moment(startDate).add(3, 'hours')}
                  onChange={(value) => {
                    if (value) {
                      setStartDate(value)
                    }
                  }}
                  InputProps={{
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                  KeyboardButtonProps={{
                    size: 'small',
                    'aria-label': 'change time',
                    style: { outline: 0 }
                  }}
                  PopoverProps={{ style: { outline: 0 } }}
                />

                <KeyboardDatePicker
                  className="dateSmall mt30"
                  size="small"
                  id="time-picker"
                  placeholder="00/00/0000"
                  format="dd/MM/yyyy"
                  ampm={false}
                  label="Fim"
                  inputVariant="outlined"
                  clearable
                  fullWidth
                  autoOk
                  minDate={moment(startDate).add(3, 'hours')}
                  value={moment(endDate).add(3, 'hours')}
                  onChange={(value) => {
                    if (value) {
                      setEndDate(value)
                    }
                  }}
                  InputProps={{
                    style: { fontFamily: 'Lato', fontSize: '12px' }
                  }}
                  KeyboardButtonProps={{
                    size: 'small',
                    'aria-label': 'change time',
                    style: { outline: 0 }
                  }}
                  PopoverProps={{ style: { outline: 0 } }}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
        </div>

        <table>
          <thead>
            <tr>
              <th width="15%" className="subtitle fontSizeDefault">
                DATA
              </th>

              <th width="40%" className="subtitle fontSizeDefault">
                FORNECEDOR
              </th>

              <th width="20%" className="subtitle fontSizeDefault textAlignRight">
                CUSTO
              </th>
            </tr>

            <tr>
              <td colSpan="2" className="p0">
                <hr className="titleLineBorder m0" />
              </td>
            </tr>
          </thead>

          <tbody>
            {loading && (
              <tr>
                <th colSpan="2" className="textAlignCenter">
                  <LinearProgress
                    id="loading"
                    variant="query"
                  />
                </th>
              </tr>
            )}

            {mainList?.length ? (
              mainList.map((item, skuIndex) => {
                const isLastElement = skuIndex === mainList?.length - 1;
                const costValue = item?.fields?.length ? parseFloat(item?.fields[0]?.afterUpdate)?.toFixed(3)?.replace(".", ",") : 0
                return (
                  <>
                    <tr>
                      <td className="description" style={{color: item.outOfInterval && '#AA8208'}}>
                        {moment(item?.date).format('DD/MM/YYYY')}
                      </td>

                      <td className="description">
                        {item?.details?.provider?.name ? item?.details?.provider?.name : '-'}
                      </td>

                      <td className="description textAlignRight">
                        {item.details.orderId ? 
                        <Link
                          className={
                            item?.count?.hasCount && 'disabledLink'
                          }
                          target="_blank"
                          to={`/recebimento/${restaurantId}&orderNumber=${item?.details?.orderId}`}
                        >
                          R$ {numberToReal(costValue, 3)}
                        </Link>
                        :
                          <>
                            R$ {numberToReal(costValue, 3)}
                          </>
                        }
                      </td>
                    </tr>

                    {!isLastElement && (
                      <tr>
                        <td colSpan="3" className="p0">
                          <hr
                            className="titleLineBorder m0"
                            style={{ height: '2px' }}
                          />
                        </td>
                      </tr>
                    )}
                  </>
                );
              })
            ) : (
              <tr>
                <td colSpan="2" className="description textAlignCenter">
                  <Typography className="fontSizeDefault" style={{ margin: '5px' }}>
                    Não há histórico de custo para este período.
                  </Typography>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </DialogContent>
    </Dialog>
  );
}
