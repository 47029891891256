import styled from 'styled-components';

export const ModalContent = styled.div`
  border: 1px solid #000000;
  border-radius: 14px;
  overflow: hidden;
  position: relative;

  .MuiTooltip-popper {
    top: 12% !important;
    left: 80% !important;
    transform: translate(-50%, -50%) !important;
  }

  .header {
    display: grid;
    grid-template-columns: auto;
    grid-gap: 10px;
    min-height: 60px;
  }

  .header #MobilePlayProductionButton {
    width: 40px;
    height: 40px;
  }

  .header h5 {
    font-size: 16px;
    line-height: 20px;
    word-break: break-all;
  }

  span {
    td {
      display: flex;
      align-items: center;
      font-size: 18px !important;

      @media only screen and (max-width: 480px) {
        font-size: 12px !important;
      }
    }
  }
`;

export const CardIngredientsContainer = styled.div`
  margin-top: 7px;
`;

export const CardInfoHeaderDrillDownContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ openDown }) => (openDown ? '5px 5px 0' : 0)};
  height: ${({ openDown }) => (openDown ? 'auto' : 0)};
  overflow: ${({ openDown }) => (openDown ? 'visible' : 'hidden')};
  font-family: Lato !important;

  @media only screen and (max-width: 360px) {
    flex-direction: column;
  }

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const CardIngredientsTitle = styled.div`
  margin-bottom: 6px;
  width: 100%;
  padding: ${({ paddingLeft }) =>
    paddingLeft ? `4px 4px 4px ${paddingLeft}` : '4px 4px 4px 8px'};
  font-size: 9px;
  color: #666770;
  background: #f2f2f4;
  text-transform: uppercase;
  font-weight: bold;
  font-family: Lato !important;

  @media only screen and (max-width: 1024px) {
    .special-title {
      color: #fff;
      background-color: #454a67;
      padding: 5px;
      border: 1px solid #454a67;
      border-radius: 12px 12px 0px 0px;
      width: 95px;
    }
  }
`;

export const CardIngredientsDrillDown = styled.div`
  border-bottom: 1px solid #d2d3d7;
  font-family: Lato !important;
`;

export const ArrowDown = styled.img`
  width: 13px;
  margin-right: 4px;
  transition: all 250ms ease-out;
  transform: ${({ openDrillDown }) =>
    openDrillDown ? 'rotate(180deg)' : 'none'};
  font-family: Lato !important;
`;

export const CardIngredientsDrillDownHead = styled.div`
  margin-left: 8px;
  padding-right: 8px;
  display: flex;
  justify-content: space-between;
  div {
    font-size: 12px;
    color: #0f1225;
  }
  font-family: Lato !important;
`;

export const CardIngredientsDrillDownContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 4px 4px 25px;
  transition: all 250ms ease-out;
  height: ${({ openDown }) => (openDown ? 'auto' : 0)};
  overflow: hidden;
  font-family: Lato !important;
`;

export const DrilDownContentTitle = styled.div`
  font-weight: bold;
  font-size: 9px;
  color: #666770;
  margin-top: 12px;
  font-family: Lato !important;
`;

export const DrilDownContentData = styled.div`
  font-weight: normal;
  font-size: 12px;
  color: #0f1225;
  margin-top: 4px;
  font-family: Lato !important;
`;

export const AutoCompleteWrapper = styled.div`
  font-family: Lato !important;
  div {
    margin-right: 0;
  }
  .MuiAutocomplete-inputRoot[class*='MuiInput-root']
    .MuiAutocomplete-input:first-child {
    padding: 3px 0;
  }
`;
