import React from 'react';

function TheoryStockHead({ extractItems }) {
  return (
    <thead style={{ position: 'sticky', top: '110px', zIndex: 1 }}>
      <tr>
        <th
          className="description white pt0 pb0 pr16 pl16 fontSizeDefault"
          style={{ width: '60px', top: '-16px' }}
        >
          DATA
        </th>

        <th
          style={{ width: '355px', top: '-16px' }}
          className="description white pt0 pb0 pl0 pr16 fontSizeDefault"
        >
          <div className="dFlex justifyBetween alignCenter">
            <span
              className={
                extractItems?.some((el) => el?.type === 'Diferença de estoque') && 'pr10 pl10'
              }
            >
              LANÇAMENTO
            </span>

            <span>QTDE</span>
          </div>
        </th>

        <th
          style={{ width: '140px', top: '-16px' }}
          className="description white pt0 pb0 pr16 textAlignRight fontSizeDefault"
        >
          CUSTO
        </th>

        <th
          style={{ width: '140px', top: '-16px' }}
          className="description white pt0 pb0 pr16 textAlignRight fontSizeDefault"
        >
          VALOR
        </th>

        <th
          style={{ width: '125px', top: '-16px' }}
          className="description white pt0 pb0 pr16 textAlignRight fontSizeDefault"
        >
          QTDE FINAL
        </th>

        <th
          style={{ width: '120px', top: '-16px' }}
          className="description white p0 textAlignRight fontSizeDefault"
        >
          VALOR FINAL
        </th>

        <th
          style={{ width: '6px', top: '-16px' }}
          className="description white pt0 pb0 pr8 textAlignRight fontSizeDefault"
        >
          &nbsp;
        </th>
      </tr>
    </thead>
  );
}

export default TheoryStockHead;
