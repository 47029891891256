/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

import { Dialog, DialogContent, DialogTitle, IconButton, LinearProgress, Tooltip, Typography } from '@material-ui/core';
import { default as MaterialButton } from '@material-ui/core/Button';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import axios from 'axios';
import moment from 'moment/moment';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import CommonAlert from 'components/CommonAlert/CommonAlert';
import RotateLeftIcon from 'images/icons/arrows/RotateLeftIcon.svg';
import calenderIcon from 'images/icons/objects/calendars/calenderHD.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeModal.svg';
import waterDropsIcon from 'images/icons/weathers/waterDrops.svg';
import wheaterIcon from 'images/icons/weathers/weatherIcon.svg';
import Environment from 'utils/environments';

import styles from './styles.module.scss';

const environments = Environment(process.env.REACT_APP_ENV);

function SalesProjectionTab({
  setSalesProjectionValue,
  modalSettings,
  setModalSettings
}) {
  const { id: getId } = useParams();
  const [lastFourSales, setLastFourSales] = useState([]);
  const [dailySale, setDailySale] = useState({
    date: '',
    weather: {
      city: '',
      condition: '',
      minimumTemperature: 0,
      maximumTemperature: 0,
      rainProbability: 0
    },
    projection: 0
  });
  const [averageProjection, setAverageProjection] = useState(0);
  const [averageSales, setAverageSales] = useState(0);
  const [confirmValue, setConfirmValue] = useState('');
  const [marginValue, setMarginValue] = useState();
  const [parameters, setParameters] = useState();
  const [salesDays, setSalesDays] = useState({});
  const [severity, setSeverity] = useState('error');
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };
  const [showAlert, setShowAlert] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [statusCode, setStatusCode] = useState();
  const [loading, setLoading] = useState(false);
  const [openModalCopilot, setOpenModalCopilot] = useState(false);

  const dayCopilotValid = salesDays?.values?.find((item) => item?.day === moment().date())?.canEdit

  const getParams = async () => {
    const response = await fetch(
      `${environments.restaurants}/companies/${getId}/parameters`
    );
    const paramsJson = await response.json();
    setParameters(paramsJson);
    setMarginValue(paramsJson?.salesProjection?.safetyMargin);
  };

  const getSalesProjectionDays = async () => {
    const baseDate = new Date();
    const month =
      baseDate.getMonth() + 1 < 10
        ? `0${baseDate.getMonth() + 1}`
        : baseDate.getMonth() + 1;
    const response = await fetch(
      `${environments.restaurants
      }/salesProjection?page=0&size=10&originId=${getId}&startAt=${baseDate.getFullYear()}-${month}&endAt=${baseDate.getFullYear()}-${month}`
    );
    const content = await response.json();
    setSalesDays(content.content[0]);
  };

  const getSalesProjection = () => {
    setLoading(true);
    axios
      .get(`${environments.engine}/forecast/daily?restaurantId=${getId}`)
      .then((response) => {
        const currentMonth = new Date().getMonth();
        const currentDay = new Date().getDate();
        const { data } = response;
        const newArray = data.filter(
          (item) => new Date(item.date).getDate() !== currentDay
        );
        const validSalesLength = newArray.filter((item) => item?.sales).length;
        const currentDailySales = data.filter(
          (item) =>
            new Date(item.date).getMonth() === currentMonth &&
            new Date(item.date).getDate() === currentDay
        )[0]
        setDailySale({
          ...currentDailySales,
          projection: currentDailySales.projection.toLocaleString('pt-br', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })
        });
        setConfirmValue(currentDailySales?.projection)
        setLastFourSales([...newArray]);
        setSalesProjectionValue(data[0].projection);
        setAverageProjection(
          (
            newArray.reduce(
              (total, value) =>
                value?.projection
                  ? parseFloat(total) + parseFloat(value?.projection)
                  : parseFloat(total),
              0
            ) / newArray.length
          ).toLocaleString('pt-br', {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          })
        );
        if (validSalesLength > 0) {
          setAverageSales(
            (
              newArray.reduce(
                (total, value) =>
                  value?.sales
                    ? parseFloat(total) + parseFloat(value?.sales)
                    : parseFloat(total),
                0
              ) / validSalesLength
            ).toLocaleString('pt-br', {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })
          );
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const sendConfirm = () => {
    setLoading(true);
    const todayDate = new Date().getDate();
    const todayIndex = salesDays.values.findIndex(
      (sale) => sale.day === todayDate
    );
    const newArray = salesDays.values;
    newArray[todayIndex].projection = parseFloat(confirmValue);
    const body = {
      ...salesDays,
      values: [...newArray]
    };
    if (parameters.salesProjection.safetyMargin !== marginValue) {
      axios
        .put(
          `${environments.restaurants}/parameters/${parameters._id}`,
          {
            method: 'PUT',
            ...parameters,
            salesProjection: {
              ...parameters.salesProjection,
              safetyMargin: marginValue
            }
          },
          config
        )
        .then(() => {
          setLoading(false);
          getParams();
          setModalSettings(
            modalSettings.map((modal) =>
              modal.name === 'copilotConfig' ? { ...modal, open: true } : modal
            )
          );
        })
        .catch((error) => {
          setSeverity('error');
          setMessageError(error?.response?.data?.message);
          setStatusCode(error?.response?.status);
          setShowAlert(true);
          setLoading(false);
        });
    }
    axios
      .put(
        `${environments.restaurants}/salesProjection/${body._id}`,
        body,
        config
      )
      .then(() => {
        setSeverity('success');
        setMessageError('Valor confirmado com sucesso');
        setStatusCode(200);
        setShowAlert(true);
        getSalesProjection();
        setConfirmValue(0);
        setLoading(false);
        setTimeout(() => {
          setModalSettings(
            modalSettings.map((modal) =>
              modal.name === 'copilotConfig' ? { ...modal, open: true } : modal
            )
          );
        }, 1000);
      })
      .catch((error) => {
        setSeverity('error');
        setMessageError(error?.response?.data?.message);
        setStatusCode(error?.response?.status);
        setShowAlert(true);
        setLoading(false);
      });

    setOpenModalCopilot(false);
  };

  const calcDifferencePercent = (value, newValue) => {
    let difference;
    let differencePercent;

    if (typeof value === 'string' && typeof newValue === 'string') {
      difference =
        parseFloat(value.replaceAll('.', '').replaceAll(',', '.')) -
        parseFloat(newValue.replaceAll('.', '').replaceAll(',', '.'));
      differencePercent =
        ((difference || 0) /
          Math.abs((value || 0).replaceAll('.', '').replaceAll(',', '.'))) *
        100;
    } else {
      difference = (value || 0) - (newValue || 0);
      differencePercent = ((difference || 0) / Math.abs(value || 0)) * 100;
    }

    return isNaN(differencePercent) || value === 0
      ? 0
      : !isFinite(differencePercent)
        ? 0
        : differencePercent;
  };

  const handleResetToOriginal = () => {
    const currentDay = new Date().getDate();
    const currentDailySales = salesDays?.values.filter((item) => item.day === currentDay)[0];
    setConfirmValue(currentDailySales?.forecast);
  };

  useEffect(() => {
    getSalesProjection();
    getParams();
    getSalesProjectionDays();
  }, [getId]);

  return (
    <>
      <div className={styles.headerTopInfo}>
        <p className={styles.weatherInfo}>
          <span>
            <img src={wheaterIcon} alt="Indicador de tempo" />{' '}
            {dailySale.weather.minimumTemperature}°/
            {dailySale.weather.maximumTemperature}°
          </span>
          <span>
            <img src={waterDropsIcon} alt="Indicador de imagens" />{' '}
            {dailySale.weather.rainProbability}%
          </span>
        </p>
        {dailySale?.holiday && (
          <p className={styles.holidayInfo}>
            <span>
              <img src={calenderIcon} alt="Feriado" />{' '}
              {dailySale?.holiday?.name}
            </span>
          </p>
        )}
      </div>

      {loading && <LinearProgress variant="query" />}

      <div className={styles.content}>
        <div className={styles.controllers}>
          <div className={styles.fixValue}>
            <span className={styles.label}>Projeção de vendas</span>
            <span className={styles.value} onClick={() => setOpenModalCopilot(true)}>
              R${' '}
              {dailySale.projection.toLocaleString('pt-br', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              })}
            </span>
          </div>
          <div
            className={styles.controllers}
          >
            <div className={styles.fixValue}>
              <span className={styles.label}>Margem</span>
              <span className={styles.value} onClick={() => setOpenModalCopilot(true)}>
                {parameters?.salesProjection?.safetyMargin}%
              </span>
            </div>
          </div>

          <div className={styles.btnSuggestion}>
            <button
              disabled={true}
              onClick={() => {
                setModalSettings(
                  modalSettings.map((modal) =>
                    modal.name === 'copilotConfig' ? { ...modal, open: true } : modal
                  )
                );
              }}
            >
              Abrir Copiloto
            </button>
          </div>
        </div>
        <div className={styles.table}>
          <div className="headerSubtitleDefault">
            <div className="subheader">
              <th width="21%" className="subtitle">
                DATA{' '}
              </th>

              <th width="32%" className="subtitle textAlignRight lineLeft">
                META{' '}
              </th>

              <th width="30%" className="subtitle textAlignRight lineLeft">
                REALIZADO{' '}
              </th>

              <th width="25%" className="subtitle textAlignRight lineLeft">
                %{' '}
              </th>
            </div>
          </div>
          <table
            className="tableDefault"
            width="100%"
            cellPadding="0"
            cellSpacing="0"
            border="0"
          >
            <thead>
              <tr className="headerTitle">
                <th
                  className="subtitle"
                  colSpan={4}
                  style={{ paddingLeft: '15px' }}
                >
                  HISTÓRICO 4 ÚLTIMAS SEMANAS{' '}
                </th>
              </tr>
            </thead>
            <tbody>
              {lastFourSales.map((sale, index) => (
                <tr className={`list ${styles.trDefault}`} key={index}>
                  <td width="19.5%" className="tdParagraph pb0">
                    {moment(sale.date).format('DD/MM/YYYY')}
                  </td>
                  <td
                    width="29%"
                    className="lineLeft tdParagraph textAlignRight"
                  >
                    <div className={styles.projectionTd}>
                      {sale?.holiday && (
                        <span>
                          <Tooltip
                            TransitionProps={{ timeout: 600 }}
                            placement="right"
                            title={sale?.holiday?.name}
                          >
                            <img src={calenderIcon} alt="Feriado" />
                          </Tooltip>
                        </span>
                      )}
                      <span style={{ width: '100%', textAlign: 'right' }}>
                        R${' '}
                        {sale.projection.toLocaleString('pt-br', {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2
                        })}
                      </span>
                    </div>
                  </td>
                  <td
                    width="27%"
                    className="lineLeft tdParagraph textAlignRight"
                  >
                    {sale?.sales
                      ? `R$ ${sale?.sales.toLocaleString('pt-br', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}`
                      : '-'}
                  </td>
                  <td
                    width="23%"
                    className={`lineLeft tdParagraph textAlignRight ${calcDifferencePercent(sale?.sales, sale.projection) < 0
                      ? styles.negativeValue
                      : styles.positiveValue
                      }`}
                  >
                    {calcDifferencePercent(sale?.sales, sale.projection) > 0
                      ? '+'
                      : ''}
                    {sale?.sales
                      ? `${calcDifferencePercent(
                        sale?.sales,
                        sale.projection
                      ).toLocaleString('pt-br', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}%`
                      : '-'}
                  </td>
                </tr>
              ))}
              <tr>
                <td colSpan="4">
                  <hr className="m0" />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <th className="subtitle">MÉDIA</th>
                <th className="subtitle textAlignRight lineLeft">
                  R${' '}
                  {averageProjection.toLocaleString('pt-br', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}
                </th>
                <th className="subtitle lineLeft textAlignRight">
                  R${' '}
                  {averageSales.toLocaleString('pt-br', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}
                </th>
                <th
                  className={`subtitle textAlignRight lineLeft ${calcDifferencePercent(averageSales, averageProjection) < 0
                    ? styles.negativeValue
                    : styles.positiveValue
                    }`}
                  style={{ paddingRight: '19px' }}
                >
                  {calcDifferencePercent(averageSales, averageProjection) > 0
                    ? '+'
                    : ''}
                  {calcDifferencePercent(
                    averageSales,
                    averageProjection
                  ).toLocaleString('pt-br', {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}
                  %
                </th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>

      <Dialog
        open={openModalCopilot}
        onClose={() => setOpenModalCopilot(false)}
        className="defaultModal certificate-modal"
        style={{ margin: '10%' }}
      >
        <div>
          <DialogTitle
            id="draggable-dialog-title"
            style={{ padding: '0 10px', background: '#5062F0' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: 0
              }}
            >
              <Typography
                className="bold textAlignStart"
                style={{
                  fontSize: '18px',
                  color: '#fff'
                }}
              >
                PROJEÇÃO DE VENDAS
              </Typography>

              <IconButton edge="start" color="inherit" onClick={() => setOpenModalCopilot(false)}>
                <img
                  style={{ width: '16px', height: '16px' }}
                  src={CloseIconNavy}
                  alt="CloseIconNavy"
                />
              </IconButton>
            </div>
          </DialogTitle>

          <DialogContent style={{ overflowY: 'unset', padding: 30 }}>
            <div style={{
              display: 'flex',
              background: '#fff',
              boxShadow: '0px 0px 8px 0px rgba(4, 6, 26, 0.25)',
              padding: 25,
              borderRadius: 4
            }}>
              <div style={{ width: 70, marginRight: "40px" }}>
                <CurrencyTextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  minimumValue="0"
                  outputFormat="string"
                  decimalCharacter=","
                  defaultValue={marginValue}
                  onChange={(e) => {
                    setMarginValue(e.target.value);
                  }}
                  currencySymbol="%"
                  digitGroupSeparator="."
                  label="Margem"
                  decimalPlaces={2}
                  decimalPlacesShownOnFocus={2}
                  disabled={!dayCopilotValid}
                  type="text"
                  name="safetyMargin"
                  className="textFieldSmall right"
                  placeholder="00%"
                />
              </div>
              <div style={{ width: 110 }}>
                <CurrencyTextField
                  fullWidth
                  variant="outlined"
                  size="small"
                  value={confirmValue}
                  onChange={(event, value) => setConfirmValue(value)}
                  disabled={!dayCopilotValid}
                  currencySymbol="$"
                  outputFormat="string"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  label="Projeção de vendas"
                  type="text"
                  name="confirmValue"
                  className="textFieldSmall right"
                  placeholder="R$ 0,00"
                />

              </div>

              <div
                style={{ marginLeft: "20px", marginTop: '5px', cursor: 'pointer' }}
                onClick={handleResetToOriginal}
              >
                <Tooltip
                  placement="bottom-start"
                  title={
                    <span style={{ padding: 5 }}>
                      Voltar ao valor original
                    </span>
                  }
                >
                  <img
                    src={RotateLeftIcon}
                    alt="Back to default"
                  />
                </Tooltip>

              </div>

            </div>
            <div style={{ color: '#F53D4C', textAlign: "center", marginTop: '10px' }}>
              <p>
                <b>Atenção:</b><br />
                Havendo mudança de valor ou margem de segurança serão necessários alguns minutos para reprocessar as sugestões do Copiloto
              </p>
            </div>
            <div style={{ display: 'flex', justifyContent: "flex-end", gap: 5 }}>
              <MaterialButton
                className="defaultButton backButton"
                design="outlined"
                onClick={() => setOpenModalCopilot(false)}
              >
                Voltar
              </MaterialButton>
              <MaterialButton
                design="contained"
                className="defaultButton submitButton"
                onClick={sendConfirm}
                disabled={!dayCopilotValid}
              >
                Confirmar e Gerar Copiloto
              </MaterialButton>
            </div>
          </DialogContent>
        </div>
      </Dialog>

      <CommonAlert
        open={showAlert}
        onClose={() => {
          setShowAlert(false);
        }}
        severity={severity}
        indexMessage={messageError}
        statusCode={statusCode}
        width="50%"
      />
    </>
  );
}

export default SalesProjectionTab;
