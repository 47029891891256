/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-no-bind */
import { memo, useState } from 'react';

import { FormControl, IconButton, Tooltip } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { format } from 'date-fns';

import CurrencyTextFieldSumeValues from 'components/Inputs/CurrencyTextFieldSumeValues';
import configPackageCount from 'images/icons/objects/boxesAndPackages/configPackageCount.svg';
import HasNotImage from 'images/icons/objects/cams/camIcon.svg';
import infoRedIcon from 'images/icons/signals/alerts/infoRedIcon.svg';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';

import { handleFocus } from '../count/MainFunctions';
import { TdCount, TdEmb } from '../count/styles';
import ValuesList from '../ValuesList';

const environments = Environment(process.env.REACT_APP_ENV);

function ProductListItem({
  disabledConfirmCount,
  countId,
  getId,
  setModalStates,
  modalStates,
  setModalConfirmCountObj,
  setParameters,
  setProductModal,
  setProductDescription,
  providers,
  product,
  confirmModalAction,
  showProductDescription,
  skuIndex,
  parametersInfos,
  hasCountedOthersStocks,
  setCurrentCountDivergentDialogData,
  isSelectedRealDiff,
  skus,
  sku,
  productIndex,
  orderedSkus,
  renderProductAdditional,
  KeyCheck,
  handleBlur,
  isLoadingAssortment,
  id,
  internalProduct,
  countDate,
  setNewData,
  lastProductCounted,
  userInfo,
  setLastProductCounted,
  countGroupInfos,
  showColumns,
  setUpdateValueByModal,
  updateValueByModal,
  setCurrentInput,
  openConfigPackageCount,
  alreadyListed
}) {
  const [extractItems, setExtractItem] = useState([]);

  async function fetchExtracts(product) {
    const fetchParameters = await fetch(
      `${environments.restaurants}/companies/${getId}/parameters`
    );
    const parameters = await fetchParameters.json();
    setParameters(parameters.product.type);

    let date = format(new Date(), 'yyyy-MM-dd');

    if (product.lastCount) {
      date = format(new Date(product.lastCount).setHours(12), 'yyyy-MM-dd');
    }

    const response = await fetch(
      `${environments.catalog}/extracts?originId=${getId}&productId=${product.id}&date=${date}`
    );
    const extracts = await response.json();

    if (Array.isArray(extracts)) setExtractItem(extracts);
    else setExtractItem([]);

    setProductModal(product);
    setProductDescription(product.description);
    setModalStates((prevState) => ({ ...prevState, modalProduct: true }));
  }

  return (
    providers?.map((provider, providerIndex) => {
      const packageFormatted = `E${provider?.quantity || 0}x${sku?.quantity || 0}${sku?.unitsMeasurements?.abbreviation || ''}` 

      return (
        <tr className={`firstLine ${provider?.ignoreBrands && 'dNone'}`}>
          <td
            colSpan={product.type === 'INTERNAL' ? 2 : 1}
            width="20"
            className="description"
            style={{
              background:
                showProductDescription[skuIndex] && providerIndex === 0
                  ? '#F6F6F8'
                  : 'transparent',
              paddingTop: 3,
              paddingLeft: 10
            }}
          >
            <div
              className={`second-line dFlex ${showProductDescription[skuIndex] && providerIndex === 0
                ? 'justifyBetween'
                : 'justifyEnd'
                }`}
            >
              {showProductDescription[skuIndex] && providerIndex === 0 ? (
                <>
                  {ValidationLength(
                    product.description,
                    product.type === 'INTERNAL' ? 48 : 30
                  )}
                </>
              ) : (
                ''
              )}

              <div className="dFlex">
                {product.type !== 'INTERNAL' && (
                  <div className="dFlex justifyBetween alignCenter ml10 mr5">
                    {product.hasDiffTheoreticalRealStock && skuIndex === 0 && (
                      <Tooltip
                        title={
                          <div>
                            <p>DIVERGÊNCIA</p>
                            <p>Clique para resolver</p>
                          </div>
                        }
                      >
                        <img
                          src={infoRedIcon}
                          alt="Info Icon"
                          style={{
                            marginRight: 5,
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            setCurrentCountDivergentDialogData({
                              title: product.description,
                              productId: product.id,
                              countDate
                            });
                            setModalStates((prevState) => ({
                              ...prevState,
                              countDivergentDialog: true
                            }));
                          }}
                        />
                      </Tooltip>
                    )}

                    {showProductDescription[skuIndex] &&
                      providerIndex === 0 ? (
                      <img
                        src={configPackageCount}
                        alt="configPackageCount"
                        onClick={() => openConfigPackageCount(product)}
                        style={{
                          width: '18px',
                          cursor: 'pointer'
                        }}
                      />
                    ) : (
                      ''
                    )}
                  </div>
                )}

                {skuIndex === 0 && providerIndex === 0 && (
                  <Tooltip title="Lançar descarte">
                    <IconButton
                      className="mr5"
                      size="small"
                      onClick={() => {
                        setModalStates((prevState) => ({
                          ...prevState,
                          modalDiscard: true
                        }));
                        setNewData((prevState) => ({
                          ...prevState,
                          data: {
                            description: product?.description,
                            originId: getId,
                            unitsMeasurementsId:
                              product?.consumptionUnitsPrimary?.id,
                            unitsMeasurementsAbbreviation:
                              product?.consumptionUnitsPrimary?.abbreviation,
                            unitsMeasurementsSecondaryId:
                              product?.consumptionUnitsSecondary?.id,
                            unitsMeasurementsSecondaryAbbreviation:
                              product?.consumptionUnitsSecondary?.abbreviation
                          },
                          discard: [
                            {
                              id: product?.id,
                              typeEvent: 'DISCARD',
                              cost: product?.cost,
                              originId: getId,
                              quantity: product?.quantity,
                              eventDate: format(
                                new Date(countDate),
                                'yyyy-MM-dd'
                              ),
                              un: product?.consumptionUnitsPrimary?.abbreviation,
                              unSecondary: product?.consumptionUnitsSecondary?.abbreviation,
                              user: userInfo.user
                            }
                          ]
                        }));
                      }}
                    >
                      <Delete
                        style={{
                          width: 15,
                          height: 15,
                          color: '#313347'
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            </div>
            <div className='h100' />
          </td>

          {/* <td width="2" className="description">
                          {sku?.images?.url ? (
                            <Tooltip
                              arrow
                              placement="right"
                              title={
                                <div
                                  className={`skuImgPreview cropDataSmallBox mr0 ${
                                    sku?.skuBloom?.images?.url && 'bgWhite'
                                  } largeSize`}
                                >
                                  <img
                                    src={sku?.images?.url}
                                    alt="Sku Preview"
                                  />
                                </div>
                              }
                            >
                              <img
                                className="auto"
                                src={sku?.images?.url}
                                alt="HasImage"
                                style={{ width: '24px' }}
                              />
                            </Tooltip>
                          ) : (
                            <img
                              className="auto"
                              src={HasNotImage}
                              alt="No Sku Preview"
                            />
                          )}
                        </td> */}

          {!provider.isAdditional ? (
            <>
              {product.type !== 'INTERNAL' && (
                <td
                  className="description p8"
                  style={{
                    verticalAlign: 'middle',
                    borderTop:
                      !skuIndex || !providerIndex ? '1px solid #ccc' : null
                  }}
                >
                  <div className="dFlex alignCenter">
                    {product?.ignoreBrands ? 
                      '' 
                    : 
                      <>
                        <Tooltip
                          arrow
                          disableHoverListener={!sku?.images?.url}
                          placement="right"
                          title={
                            <div
                              className={`skuImgPreview cropDataSmallBox mr0 ${sku?.skuBloom?.images?.url && 'bgWhite'
                                } largeSize`}
                                style={{ background: "#fff" }}
                            >
                              <img src={sku?.images?.url} alt="Sku Preview" />
                            </div>
                          }
                        >
                          <div className="dFlex alignCenter justifyCenter" style={{ height: 24, width: 24 }}>
                            <img
                              src={sku?.images?.url || HasNotImage}
                              alt={sku?.images?.url ? 'HasImage' : 'No Sku Preview'}
                              style={{
                                width: 'auto',
                                margin: 'auto',
                                height: 'auto',
                                maxHeight: '100%',
                                maxWidth: '100%'
                              }}
                            />
                          </div>
                        </Tooltip>

                        <Tooltip
                          title={
                            <p className="mb0" style={{ padding: '2px 6px' }}>
                              {sku.description}
                            </p>
                          }
                          placement="right"
                          >
                          <div className={sku?.images?.url ? 'ml5' : 'ml8'}>
                            {sku.infoToShow
                              ? ValidationLength(sku.infoToShow, 18)
                              : null}
                          </div>
                        </Tooltip>
                      </>
                    }
                  </div>
                </td>
              )}

              <TdEmb
                width="3"
                className="description"
                style={{
                  borderTop:
                    !skuIndex || !providerIndex ? '1px solid #ccc' : null,
                  padding: '0 10px'
                }}
              >
                {packageFormatted}

                <FormControl fullWidth>
                  <CurrencyTextFieldSumeValues
                    setCurrentInput={setCurrentInput}
                    updatedValue={updateValueByModal}
                    setUpdateValueByModal={setUpdateValueByModal}
                    inputMode="numeric"
                    confirmModalAction={confirmModalAction}
                    sourcePage="countPage"
                    id={`countInput-${productIndex}-${skuIndex}-${providerIndex}-tablet`}
                    isLast={
                      skuIndex >= orderedSkus.length - 1 &&
                      providerIndex >= providers.length - 1 &&
                      !renderProductAdditional
                    }
                    key={id}
                    name="contagem"
                    disabled={isLoadingAssortment || disabledConfirmCount}
                    placeholder="Contagem"
                    onKeyDown={KeyCheck}
                    parametersInfos={parametersInfos}
                    product={product}
                    products={internalProduct}
                    modalStates={modalStates}
                    setModalConfirmCountObj={setModalConfirmCountObj}
                    setModalStates={setModalStates}
                    countObj={{
                      product,
                      inputType: 'count',
                      sku,
                      quantity: product.additional,
                      additional: false,
                      skuQuantity: sku.unityAdditional,
                      skuAdditional: false,
                      provider,
                      locationProps: {
                        skus: skus.length,
                        skuIndex
                      }
                    }}
                    handleBlurCountObj={
                      {
                        product,
                        inputType: 'count',
                        sku,
                        quantity: product.additional,
                        additional: false,
                        skuQuantity: sku.unityAdditional,
                        skuAdditional: false,
                        provider,
                        locationProps: {
                          skus: skus.length,
                          skuIndex
                        }
                      }
                    }
                    onBlur={(
                      stringSummableValues,
                      replacedValue,
                      e,
                      isLast
                    ) => {
                      const obj = {
                        product,
                        inputType: 'count',
                        sku,
                        quantity: product.additional,
                        additional: false,
                        skuQuantity: sku.unityAdditional,
                        skuAdditional: false,
                        provider,
                        locationProps: {
                          skus: skus.length,
                          skuIndex
                        }
                      };

                      setLastProductCounted({
                        productId: product.id,
                        productDescription: product.description,
                        realStock: product.realStock,
                        theoreticalStock: product.theoricalStock,
                        historical: product.historical
                      });
                      handleBlur(
                        stringSummableValues,
                        replacedValue,
                        obj,
                        e,
                        isLast
                      );
                    }}
                    onFocus={() =>
                      handleFocus(
                        product,
                        lastProductCounted,
                        parametersInfos,
                        countId,
                        getId,
                        userInfo
                      )
                    }
                    onFocusProps={{
                      product
                    }}
                    defaultValue={
                      provider?.labelQuantity
                        ? provider?.labelQuantity
                        : provider?.stock
                    }
                  />
                </FormControl>
              </TdEmb>

              <TdCount
                width="3"
                className="description"
                style={{
                  borderTop:
                    !skuIndex || !providerIndex ? '1px solid #ccc' : null
                }}
              >
                {`E${provider?.quantity || 0}x${sku?.quantity || 0}${sku?.unitsMeasurements?.abbreviation || ''}`}
              </TdCount>

              <TdCount
                width="8"
                className="description right p8"
                style={{
                  borderTop:
                    !skuIndex || !providerIndex ? '1px solid #ccc' : null,
                  borderRight: '1px solid #ccc'
                }}
              >
                <FormControl fullWidth>
                  <CurrencyTextFieldSumeValues
                    setCurrentInput={setCurrentInput}
                    updatedValue={updateValueByModal}
                    setUpdateValueByModal={setUpdateValueByModal}
                    confirmModalAction={confirmModalAction}
                    inputMode="numeric"
                    sourcePage="countPage"
                    id={`countInput-${productIndex}-${skuIndex}-${providerIndex}`}
                    isLast={
                      skuIndex >= orderedSkus.length - 1 &&
                      providerIndex >= providers.length - 1 &&
                      !renderProductAdditional
                    }
                    key={id}
                    name="contagem"
                    placeholder="Contagem"
                    parametersInfos={parametersInfos}
                    product={product}
                    products={internalProduct}
                    modalStates={modalStates}
                    setModalConfirmCountObj={setModalConfirmCountObj}
                    setModalStates={setModalStates}
                    countObj={{
                      product,
                      inputType: 'count',
                      sku,
                      quantity: product.additional,
                      additional: false,
                      skuQuantity: sku.unityAdditional,
                      skuAdditional: false,
                      provider,
                      locationProps: {
                        skus: skus.length,
                        skuIndex
                      }
                    }}
                    handleBlurCountObj={
                      {
                        product,
                        inputType: 'count',
                        sku,
                        quantity: product.additional,
                        additional: false,
                        skuQuantity: sku.unityAdditional,
                        skuAdditional: false,
                        provider,
                        locationProps: {
                          skus: skus.length,
                          skuIndex
                        }
                      }
                    }
                    onKeyDown={KeyCheck}
                    onBlur={(
                      stringSummableValues,
                      replacedValue,
                      e,
                      isLast
                    ) => {
                      const obj = {
                        product,
                        inputType: 'count',
                        sku,
                        quantity: product.additional,
                        additional: false,
                        skuQuantity: sku.unityAdditional,
                        skuAdditional: false,
                        provider,
                        locationProps: {
                          skus: skus.length,
                          skuIndex
                        }
                      };

                      setLastProductCounted({
                        productId: product.id,
                        productDescription: product.description,
                        realStock: product.realStock,
                        theoreticalStock: product.theoricalStock,
                        historical: product.historical
                      });
                      handleBlur(
                        stringSummableValues,
                        replacedValue,
                        obj,
                        e,
                        isLast
                      );
                    }}
                    onFocus={() =>
                      handleFocus(
                        product,
                        lastProductCounted,
                        parametersInfos,
                        countId,
                        getId,
                        userInfo
                      )
                    }
                    disabled={isLoadingAssortment || disabledConfirmCount}
                    onFocusProps={{
                      product
                    }}
                    defaultValue={
                      provider?.labelQuantity
                        ? provider?.labelQuantity
                        : isNaN(provider?.stock) ? '' : provider?.stock
                    }
                  />
                </FormControl>
              </TdCount>
            </>
          ) : !product?.ignoreBrands 
            ? (
              <>
                <td width="30" className="description p8">
                  {!product?.ignoreBrands ? 
                    `${sku?.quantity || 0}${sku?.unitsMeasurements?.abbreviation || ''}`
                  : ''}
                </td>

                <TdEmb width="3" className="description" style={{ padding: '0 10px' }}>
                  {`E1x${sku?.quantity || 0}${sku?.unitsMeasurements?.abbreviation || ''}`}

                  <FormControl fullWidth>
                    <CurrencyTextFieldSumeValues
                      setCurrentInput={setCurrentInput}
                      updatedValue={updateValueByModal}
                      confirmModalAction={confirmModalAction}
                      setUpdateValueByModal={setUpdateValueByModal}
                      inputMode="numeric"
                      sourcePage="countPage"
                      id={`countInput-${productIndex}-${skuIndex}-${providerIndex}-tablet`}
                      isLast={
                        skuIndex >= orderedSkus.length - 1 &&
                        providerIndex >= providers.length - 1 &&
                        !renderProductAdditional
                      }
                      key={skuIndex}
                      name="contagem"
                      disabled={isLoadingAssortment || disabledConfirmCount}
                      placeholder="Adicional em SKU"
                      onKeyDown={KeyCheck}
                      parametersInfos={parametersInfos}
                      product={product}
                      products={internalProduct}
                      modalStates={modalStates}
                      setModalConfirmCountObj={setModalConfirmCountObj}
                      setModalStates={setModalStates}
                      countObj={{
                        product,
                        sku,
                        inputType: 'skuAdd',
                        quantity: sku?.providers[0]?.stock,
                        additional: false,
                        skuQuantity: sku.unityAdditional,
                        skuAdditional: true,
                        provider: sku?.providers?.length
                          ? sku.providers[0]
                          : null,
                        locationProps: {
                          skus: skus.length,
                          skuIndex: 'addSKU'
                        }
                      }}
                      handleBlurCountObj={
                        {
                          product,
                          sku,
                          inputType: 'skuAdd',
                          quantity: sku?.providers[0]?.stock,
                          additional: false,
                          skuQuantity: sku.unityAdditional,
                          skuAdditional: true,
                          provider: sku?.providers?.length
                            ? sku.providers[0]
                            : null,
                          locationProps: {
                            skus: skus.length,
                            skuIndex: 'addSKU'
                          }
                        }
                      }
                      onBlur={(
                        stringSummableValues,
                        replacedValue,
                        e,
                        isLast
                      ) => {
                        const obj = {
                          product,
                          sku,
                          inputType: 'skuAdd',
                          quantity: sku?.providers[0]?.stock,
                          additional: false,
                          skuQuantity: sku.unityAdditional,
                          skuAdditional: true,
                          provider: sku?.providers?.length
                            ? sku.providers[0]
                            : null,
                          locationProps: {
                            skus: skus.length,
                            skuIndex: 'addSKU'
                          }
                        };

                        setLastProductCounted({
                          productId: product.id,
                          productDescription: product.description,
                          realStock: product.realStock,
                          theoreticalStock: product.theoricalStock,
                          historical: product.historical
                        });
                        handleBlur(
                          stringSummableValues,
                          replacedValue,
                          obj,
                          e,
                          isLast
                        );
                      }}
                      onFocus={() =>
                        handleFocus(
                          product,
                          lastProductCounted,
                          parametersInfos,
                          countId,
                          getId,
                          userInfo
                        )
                      }
                      onFocusProps={{
                        product
                      }}
                      defaultValue={
                        sku?.labelUnityAdditional
                          ? sku?.labelUnityAdditional
                          : sku?.unityAdditional
                      }
                    />
                  </FormControl>
                </TdEmb>

                <TdCount width="3" className="description" style={{ padding: '0 10px' }}>
                  {`E1x${sku?.quantity || 0}${sku?.unitsMeasurements?.abbreviation || ''}`}
                </TdCount>

                <TdCount
                  width="8"
                  className="description right p8"
                  style={{
                    borderTop: '1px solid #ccc',
                    borderRight: '1px solid #ccc'
                  }}
                >
                  <FormControl fullWidth>
                    <CurrencyTextFieldSumeValues
                      setCurrentInput={setCurrentInput}
                      updatedValue={updateValueByModal}
                      confirmModalAction={confirmModalAction}
                      setUpdateValueByModal={setUpdateValueByModal}
                      inputMode="numeric"
                      sourcePage="countPage"
                      id={`countInput-${productIndex}-${skuIndex}-${providerIndex}`}
                      isLast={
                        skuIndex >= orderedSkus.length - 1 &&
                        providerIndex >= providers.length - 1 &&
                        !renderProductAdditional
                      }
                      key={skuIndex}
                      name="contagem"
                      disabled={isLoadingAssortment || disabledConfirmCount}
                      placeholder="Adicional em SKU"
                      onKeyDown={KeyCheck}
                      parametersInfos={parametersInfos}
                      product={product}
                      products={internalProduct}
                      modalStates={modalStates}
                      setModalConfirmCountObj={setModalConfirmCountObj}
                      setModalStates={setModalStates}
                      countObj={{
                        product,
                        sku,
                        inputType: 'skuAdd',
                        quantity: sku?.providers[0]?.stock,
                        additional: false,
                        skuQuantity: sku.unityAdditional,
                        skuAdditional: true,
                        provider: sku?.providers?.length
                          ? sku.providers[0]
                          : null,
                        locationProps: {
                          skus: skus.length,
                          skuIndex: 'addSKU'
                        }
                      }}
                      handleBlurCountObj={
                        {
                          product,
                          sku,
                          inputType: 'skuAdd',
                          quantity: sku?.providers[0]?.stock,
                          additional: false,
                          skuQuantity: sku.unityAdditional,
                          skuAdditional: true,
                          provider: sku?.providers?.length
                            ? sku.providers[0]
                            : null,
                          locationProps: {
                            skus: skus.length,
                            skuIndex: 'addSKU'
                          }
                        }
                      }
                      onBlur={(
                        stringSummableValues,
                        replacedValue,
                        e,
                        isLast
                      ) => {
                        const obj = {
                          product,
                          sku,
                          inputType: 'skuAdd',
                          quantity: sku?.providers[0]?.stock,
                          additional: false,
                          skuQuantity: sku.unityAdditional,
                          skuAdditional: true,
                          provider: sku?.providers?.length
                            ? sku.providers[0]
                            : null,
                          locationProps: {
                            skus: skus.length,
                            skuIndex: 'addSKU'
                          }
                        };

                        setLastProductCounted({
                          productId: product.id,
                          productDescription: product.description,
                          realStock: product.realStock,
                          theoreticalStock: product.theoricalStock,
                          historical: product.historical
                        });
                        handleBlur(
                          stringSummableValues,
                          replacedValue,
                          obj,
                          e,
                          isLast
                        );
                      }}
                      onFocus={() =>
                        handleFocus(
                          product,
                          lastProductCounted,
                          parametersInfos,
                          countId,
                          getId,
                          userInfo
                        )
                      }
                      onFocusProps={{
                        product
                      }}
                      defaultValue={
                        sku?.labelUnityAdditional
                          ? sku?.labelUnityAdditional
                          : sku?.unityAdditional
                      }
                    />
                  </FormControl>
                </TdCount>
              </>
          ) : null }

          {internalProduct.length && (
            <ValuesList
              skuIndex={skuIndex}
              providerIndex={providerIndex}
              productIndex={productIndex}
              internalProduct={internalProduct}
              fetchExtracts={fetchExtracts}
              countDate={countDate}
              hasCountedOthersStocks={hasCountedOthersStocks}
              unitMeasurements={product?.consumptionUnitsPrimary?.abbreviation}
              isSelectedRealDiff={isSelectedRealDiff}
              parametersInfos={parametersInfos}
              countGroupInfos={countGroupInfos}
              showColumns={showColumns}
            />
          )}
        </tr>
      )
    })
  );
}

export default memo(ProductListItem);
