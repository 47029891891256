export function companiesActive(id, name, index, segment) {
  return {
    type: 'SET_COMPANIES_ACTIVE',
    id,
    name,
    index, 
    segment
  };
}

export function companiesSet(
  user,
  companies,
  profile,
  keycloakId,
  systemType,
  configs
) {
  return {
    type: 'SET_COMPANIES',
    user,
    configs,
    companies,
    profile,
    keycloakId,
    systemType
  };
}

export function companyFirstSelect(firstSelectRestaurant) {
  return {
    type: 'SET_FIRST_RESTAURANT',
    firstSelectRestaurant
  };
}

export function updateCountConfig(configs) {
  return {
    type: 'UPDATE_COUNT_CONFIG',
    configs
  };
}
