/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useRef } from 'react';

import {
  Fade,
  Grid,
  IconButton,
  Popover,
  TextField,
  Tooltip,
  Typography,
  DialogContent,
  Dialog,
  DialogTitle,
} from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import axios from 'axios';
import moment from 'moment';
import { useParams } from 'react-router-dom';

import Button from 'components/Button/button';
import DownloadIcon from 'images/icons/downloadUpload/downloadIconGray.svg';
import UploadIcon from 'images/icons/downloadUpload/uploadIconGray.svg';
import Report from 'images/icons/files/fileNavyGrayOutline.svg';
import warningDiffPositive from 'images/icons/signals/addsAndCreates/warningDiffPositive.svg';
import ErrorIcon from 'images/icons/signals/alerts/error.svg';
import WarningYellowIcon from 'images/icons/signals/alerts/warningYellowIcon.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import EllipsisIcon from 'images/icons/signals/moreInfos/ellipsisIconSimple.svg';
import warningDiffEquals from 'images/icons/signals/pausesAndStops/warningDiffEquals.svg';
import PlayIconBlue from 'images/icons/signals/playsAndStarts/playIconOutlineBlue.svg';
import PlayIconBlueBold from 'images/icons/signals/playsAndStarts/playIconOutlineBlueBold.svg';
import warningDiffNegative from 'images/icons/signals/removesAndDeletes/warningDiffNegative.svg';
import makeGet from 'services/useFetch/makeGet';
import convertToBase64 from 'utils/convertToBase64';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';

import {
  DownloadReport,
  Td,
  TdIconFiles,
  TdIconTrash,
  TdLastCount,
  TdLastCountTablet,
  TdProgressStatus
} from './style';

import { InputFile } from '../../../quotesUpload/style';
import DeleteCountDialog from '../dialogs/DeleteCountDialog';
import { BorderLinearProgress } from '../styles';

const environments = Environment(process.env.REACT_APP_ENV);

function CountTable({
  countData,
  handlePlayCounts,
  refreshCount,
  userInfo,
  removePermission,
  handleProductDialog,
  setToastOpen,
  setToastProps
}) {
  const { id: paramsID } = useParams();
  const inputRef = useRef();

  const [countIdToDelete, setCountIdToDelete] = useState();
  const [openDeleteCountDialog, setOpenDeleteCountDialog] = useState(false);
  const [getId, setGetId] = useState(paramsID);
  const [anchorEl, setAnchorEl] = useState(null);
  const [popoverData, setPopoverData] = useState();
  const popoverOpen = Boolean(anchorEl);
  const [assortmentsOptions, setAssortmentsOptions] = useState([]);
  const [openInfo, setOpenInfo] = useState(false);
  const [assortmentDate, setAssortmentDate] = useState();
  const [downloadButtonsState, setDownloadButtonsState] = useState({
    date: 'disabled',
    alphabetical: 'enabled'
  });
  const [infoCount, setInfoCount] = useState({
    lastCount: '',
    user: ''
  });
  const [playButtonStatusEnable, setPlayButtonStatusEnable] = useState({});
  const [divergentItensTooltip, setDivergentItensTooltip] = useState([]);
  const [differenceInCountTooltip, setDifferenceInCountTooltip] =
    useState(null);
  const [differenceInCountTooltipTotal, setDifferenceInCountTooltipTotal] =
    useState([]);

  const statusConversion = {
    WAITING: 'Não iniciada',
    'IN PROGRESS': 'Parcial',
    FINISHED: 'Finalizada',
    PROCESSING: 'Processando'
  };

  useEffect(() => {
    setGetId(paramsID);
  }, [paramsID]);

  const toLocaleDateLastCount = (date) => {
    if (date === null || date === undefined) return '';
    const newDate = new Date(date).toLocaleDateString('pt-BR', {
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    });
    return `${newDate}hs`;
  };

  const onOpenDeleteDialog = (count) => {
    setCountIdToDelete(count.id);
    setOpenDeleteCountDialog(true);
  };

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setAssortmentDate(null);
    setAssortmentsOptions([]);
    setDownloadButtonsState({ date: 'disabled', alphabetical: 'enabled' });
  };

  const onCloseDeleteDialog = () => {
    setOpenDeleteCountDialog(false);
    refreshCount();
  };

  const fetchAssortmentOptions = (count) => {
    axios
      .get(
        `${environments.catalog}/inventories/assortment?originId=${getId}&setupId=${count.setupId}&countDate=${count.date}`
      )
      .then((response) => {
        setAssortmentsOptions(response.data);
      })
      .catch((error) => {
        console.log(error);
        setAssortmentsOptions([]);
      });
  };

  const handleDivergentItens = (count) => {
    const { id, restaurantId } = count;

    const handleResponse = (data) => {
      const { content } = data;

      if (content) {
        setDivergentItensTooltip(content);
      }
    };

    makeGet(
      'countsDivergentDiferences',
      [],
      handleResponse,
      `difference/${id}/${restaurantId}`
    );
  };

  const handleDifferenceInCountTotal = (count, period) => {
    const { date } = count;
    const { id } = period;

    const handleResponse = (data) => {
      if (data) {
        setDifferenceInCountTooltipTotal(data);
      }
    };

    makeGet(
      'countsDifferencesPeriodicity',
      [],
      handleResponse,
      `${id}/differences/${date}`
    );
  };

  const canRemove = () => {
    if (!removePermission) return true;
    return removePermission.includes(userInfo.profile[0][0].name);
  };

  const handleDifferenceInCount = (count) => {
    const { id, date } = count;

    const handleResponse = (data) => {
      if (data) {
        setDifferenceInCountTooltip(data);
      }
    };

    makeGet(
      'countsDifferences',
      [{ urlParam: `date=${date}` }],
      handleResponse,
      `${id}/differences`
    );
  };

  const downloadSpreadsheetFromCount = (count, order) => {
    let uri = `${environments.catalog}/inventories/counts/spreadsheet/${count.id}?originId=${getId}&orderBy=${order}`;

    if (order === 'date') {
      uri += `&assortmentDate=${assortmentDate}`;
    }

    const link = document.createElement('a');
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFile = async (inputRef, count, event) => {
    const endpoint = `${environments.catalog}/inventories/counts/spreadsheet`;
    const spreadsheetBase64 = await convertToBase64(event.target.files[0]);
    const body = {
      countId: count.id,
      restaurantId: count.restaurantId,
      setupId: count.setup.id,
      user: userInfo.user,
      spreadsheet: spreadsheetBase64
    };
    const { id } = count;
    setPlayButtonStatusEnable({ ...playButtonStatusEnable, [id]: true });

    axios
      .post(endpoint, body)
      .then(() => {
        setToastProps({
          severity: 'success',
          message:
            'Sua planilha foi importada. O processamento pode levar algum tempo. Volte em breve!'
        });

        setToastOpen(true);
      })
      .catch((err) => {
        if (err.response.data.type == 'Incorrect format') {
          setToastProps({
            severity: 'error',
            message:
              'A planilha não está no formato correto para ser lida. As colunas necessárias para o sucesso da importação podem estar faltando.'
          });
        } else if (err.response.data.type == 'Does not match') {
          let message = null;

          if (!err.response.data.countCorresponds) {
            message =
              'A planilha que está sendo importada não corresponde a contagem selecionada';
          }

          if (!err.response.data.restaurantCorresponds) {
            message = message
              ? `${message} e pertence a outro restaurante`
              : 'A planilha que está sendo importada pertence a outro restaurante.';
          }

          setToastProps({
            severity: 'error',
            message
          });
        } else if (err.response.data.type === 'Incorrect order level') {
          setToastProps({
            severity: 'error',
            message:
              'Não foi possível importar a planilha pois há números com mais de um nível na coluna "ORDEM". Exemplo: "1.1.1".'
          });
        } else {
          setToastProps({
            severity: 'error',
            message:
              'Não foi possível importar a planilha. Tente novamente mais tarde.'
          });
        }

        setToastOpen(true);
      });

    const { current: { value } = {} } = inputRef;
    if (value) {
      inputRef.current.value = null;
    }
  };

  const setCountStatus = (count) => {
    const { processingSpreadsheet } = count;
    if (processingSpreadsheet || playButtonStatusEnable[count.id]) {
      return statusConversion.PROCESSING;
    }
    if (count.hasError) {
      return 'Erro';
    }
    return statusConversion[count.status] || statusConversion.default;
  };

  const downloadReportConsolidated = (periodicity, date) => {
    const {
      periodicity: { id: periodicityId }
    } = periodicity;

    let url = undefined
    const canUseV2Counts = false

    if(canUseV2Counts){
      url = `${environments.catalogV2}/inventories/counts/consolidated/${periodicityId}/${date}/${getId}?outdata=xlsx`
    } else {
      url = `${environments.catalog}/inventories/counts/reports/consolidated/${periodicityId}/${date}/${getId}`
    }

    axios({
      url,
      method: 'GET',
      responseType: 'blob',
      timeout: 10000000
    }).then(() => {
      const href = url
  
      const link = document.createElement('a');
      link.href = href;
      document.body.appendChild(link);
      link.click();
  
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  }

  const deleteCountblock = (value) => {
    if (value === 'IN PROGRESS') {
      return {
        disabled: true,
        message: 'Essa contagem não poderá ser excluída, pois já foi iniciada!'
      };
    }
    if (value === 'FINISHED') {
      return {
        disabled: true,
        message:
          'Essa contagem não poderá ser excluída, pois já foi finalizada!'
      };
    }

    return {
      disabled: false
    };
  };

  const periodTypeTranslation = (type) => {
    let translatedType = 'MENSAL LIVRE';

    if (type === 'DIARY') {
      translatedType = 'DIÁRIA';
    }

    if (type === 'MONTHLY') {
      translatedType = 'MENSAL';
    }

    if (type === 'BIMONTHLY') {
      translatedType = 'BIMENSAL';
    }

    if (type === 'WEEKLY') {
      translatedType = 'SEMANAL';
    }

    if (type === 'BIWEEKLY') {
      translatedType = 'BISSEMANAL';
    }

    if (type === 'FREE_WEEKLY') {
      translatedType = 'SEMANAL LIVRE';
    }

    return translatedType;
  };

  return (
    <>
      <DeleteCountDialog
        openState={openDeleteCountDialog}
        onCloseProductDialog={onCloseDeleteDialog}
        countIdToDelete={countIdToDelete}
        setToastProps={setToastProps}
        setToastOpen={setToastOpen}
      />

      {countData?.length ? (
        countData?.map((item) => {
          const { periodicities } = item;
          const isCurrentDayCount =
            moment(item?.date).format('DD/MM/YYYY') ===
            moment().format('DD/MM/YYYY');
          return periodicities?.length && periodicities
            ? periodicities.map((period, index) =>
                period.counts.map((count, countIndex) => {
                  const storageLocationDescription =
                    count?.setup?.storageLocation?.description;
                  const lastCount = count?.lastCount;
                  const user = count?.user;
                  const productsCount =
                    Number(count?.nExternalProducts) +
                    Number(count?.nInternalProducts);

                  return (
                    <tr
                      style={{
                        background: isCurrentDayCount
                          ? 'rgba(80, 98, 240, .1)'
                          : 'transparent',
                        borderBottom:
                          countIndex === period.counts.length - 1 &&
                          index === periodicities.length - 1
                            ? `${
                                isCurrentDayCount ? '2px' : '1px'
                              } solid #010311`
                            : null
                      }}
                    >
                      <td
                        className="description pl12"
                        style={{ borderRight: 'solid 2px #010311' }}
                      >
                        <div className="dFlex justifyBetween alignCenter">
                          <p className={`m0 ${isCurrentDayCount && 'bold'}`}>
                            {!countIndex && !index
                              ? moment(item.date).format('DD/MM')
                              : ''}
                          </p>

                          <DownloadReport
                            className="alignCenter"
                            style={{
                              display: 'flex',
                              borderBottom:
                                index === periodicities.length - 1 &&
                                countIndex === period.counts.length - 1
                                  ? null
                                  : countIndex === period.counts.length - 1
                                  ? isCurrentDayCount
                                    ? '2px solid #010311'
                                    : '1px solid rgba(146, 150, 172, 1)'
                                  : null,
                              minHeight: 34.5,
                              minWidth: 26,
                              marginBottom: isCurrentDayCount ? '-2.4px' : '-1px',
                            }}
                          >
                            {countIndex < 1 ? (
                              <p
                                className="m0 cursorPointer dFlex justifyEnd"
                                style={{
                                  color: 'rgba(80,98,240,1)',
                                  textDecoration: 'underline',
                                  padding: '0 6px'
                                }}
                                onClick={() =>
                                  downloadReportConsolidated(period, item.date)
                                }
                              >
                                <Tooltip
                                  placement="top"
                                  title={
                                    <p
                                      className="mb0"
                                      style={{ padding: '4px 2px' }}
                                    >
                                      Download do relatório
                                    </p>
                                  }
                                >
                                  <img src={Report} alt="Report" />
                                </Tooltip>
                              </p>
                            ) : null}
                          </DownloadReport>
                        </div>
                      </td>

                      <Td
                        className="description"
                        style={{
                          borderBottom:
                            index === periodicities.length - 1 &&
                            countIndex === period.counts.length - 1
                              ? '1px solid #313347'
                              : countIndex === period.counts.length - 1
                              ? isCurrentDayCount
                                ? '2px solid #010311'
                                : '1px solid rgba(146, 150, 172, 1)'
                              : null
                        }}
                      >
                        <div className="dFlex">
                          {/* <div
                          className="alignCenter contentPeriod"
                          style={{ margin: '0 6px', width: 20, display: 'flex' }}
                        >
                          {period.diff !== 0 && countIndex === 0 ? (
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              onOpen={() =>
                                handleDifferenceInCountTotal(
                                  count,
                                  period.periodicity
                                )
                              }
                              onClose={() =>
                                setDifferenceInCountTooltip(null)
                              }
                              title={
                                <div
                                  className="dFlex flexColumn"
                                  style={{
                                    width: '220px',
                                    paddingTop: 5
                                  }}
                                >
                                  {differenceInCountTooltipTotal.length ? (
                                    differenceInCountTooltipTotal.map(
                                      (differenceInCountTotal) => (
                                        <>
                                          <p className="textAlignRight">
                                            DIFERENÇA NA CONTAGEM
                                          </p>

                                          <ul>
                                            <p className="textAlignRight">
                                              SOBRA DE MERCADORIA:{' '}
                                              {differenceInCountTotal.positiveTotal >
                                                0
                                                ? '+'
                                                : ''}{' '}
                                              R$
                                              {numberToReal(
                                                differenceInCountTotal.positiveTotal
                                              )}
                                            </p>
                                          </ul>

                                          <ul>
                                            <p className="textAlignRight">
                                              FALTA DE MERCADORIA:{' '}
                                              {differenceInCountTotal.negativeTotal <
                                                0
                                                ? '-'
                                                : ''}{' '}
                                              R$
                                              {numberToReal(
                                                Math.abs(
                                                  differenceInCountTotal.negativeTotal
                                                )
                                              )}
                                            </p>
                                          </ul>
                                          <ul>
                                            <p className="textAlignRight bold">
                                              DIFERENÇA:{' '}
                                              {differenceInCountTotal.difference >
                                                0
                                                ? '+'
                                                : differenceInCountTotal.difference <
                                                  0
                                                  ? '-'
                                                  : ''}{' '}
                                              R$
                                              {numberToReal(
                                                differenceInCountTotal.difference
                                              )}
                                            </p>
                                          </ul>
                                        </>
                                      )
                                    )
                                  ) : (
                                    <div className="countsCircularProgressBar dFlex justifyCenter">
                                      <CircularProgress />
                                    </div>
                                  )}
                                </div>
                              }
                            >
                              {period?.diff === 0 ? (
                                <img
                                  style={{ cursor: 'pointer' }}
                                  src={warningDiffEquals}
                                  alt="Warning diff equals Icon"
                                />
                              ) : period?.diff > 0 ? (
                                <img
                                  style={{ cursor: 'pointer' }}
                                  src={warningDiffPositive}
                                  alt="Warning diff positive Icon"
                                />
                              ) : (
                                <img
                                  style={{ cursor: 'pointer' }}
                                  src={warningDiffNegative}
                                  alt="Warning diff negative Icon"
                                />
                              )}
                            </Tooltip>
                          ) : null}
                        </div> */}

                          <div className="dFlex justifyBetween w100 pl6">
                            <div
                              className={`dFlex ${isCurrentDayCount && 'bold'} playCountBox`}
                            >
                              {countIndex === 0 && (
                                <IconButton
                                  className="mr5"
                                  aria-label="playIcon"
                                  disabled={
                                    !count.id
                                      ? true
                                      : playButtonStatusEnable[count.id]
                                      ? true
                                      : !!count.processingSpreadsheet
                                  }
                                  onClick={() =>
                                    handlePlayCounts(
                                      count,
                                      count.status,
                                      item,
                                      true,
                                      period,
                                      period?.counts?.map(
                                        (count) =>
                                          count?.setup?.storageLocationId
                                      )
                                    )
                                  }
                                  style={{
                                    color: 'eaeaea',
                                    padding: 0,
                                    outline: 0
                                  }}
                                >
                                  <img
                                    className="playCount"
                                    style={{
                                      cursor: 'pointer',
                                      boxShadow: '0px 4px 4px 0px #00000040',
                                      borderRadius: 4
                                    }}
                                    width="20"
                                    src={
                                      isCurrentDayCount
                                        ? PlayIconBlueBold
                                        : PlayIconBlue
                                    }
                                    alt="PlayCountGroup"
                                  />
                                </IconButton>
                              )}

                              {countIndex < 1
                                ? ValidationLength(
                                    period.periodicity.description,
                                    25
                                  )
                                : ''}
                            </div>

                            {/* <div
                              className="alignCenter contentPeriodAfter"
                              style={{
                                margin: '0 6px',
                                width: 20,
                                display: 'none'
                              }}
                            >
                              {period.diff !== 0 && countIndex === 0 ? (
                                <Tooltip
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 600 }}
                                  onOpen={() =>
                                    handleDifferenceInCountTotal(
                                      count,
                                      period.periodicity
                                    )
                                  }
                                  onClose={() =>
                                    setDifferenceInCountTooltip(null)
                                  }
                                  title={
                                    <div
                                      className="dFlex flexColumn"
                                      style={{
                                        width: '220px',
                                        paddingTop: 5
                                      }}
                                    >
                                      {differenceInCountTooltipTotal.length ? (
                                        differenceInCountTooltipTotal.map(
                                          (differenceInCountTotal) => (
                                            <>
                                              <p className="textAlignRight">
                                                DIFERENÇA NA CONTAGEM
                                              </p>

                                              <ul>
                                                <p className="textAlignRight">
                                                  SOBRA DE MERCADORIA:{' '}
                                                  {differenceInCountTotal.positiveTotal >
                                                  0
                                                    ? '+'
                                                    : ''}{' '}
                                                  R$
                                                  {numberToReal(
                                                    differenceInCountTotal.positiveTotal
                                                  )}
                                                </p>
                                              </ul>

                                              <ul>
                                                <p className="textAlignRight">
                                                  FALTA DE MERCADORIA:{' '}
                                                  {differenceInCountTotal.negativeTotal <
                                                  0
                                                    ? '-'
                                                    : ''}{' '}
                                                  R$
                                                  {numberToReal(
                                                    Math.abs(
                                                      differenceInCountTotal.negativeTotal
                                                    )
                                                  )}
                                                </p>
                                              </ul>

                                              <ul>
                                                <p className="textAlignRight bold">
                                                  DIFERENÇA:{' '}
                                                  {differenceInCountTotal.difference >
                                                  0
                                                    ? '+'
                                                    : differenceInCountTotal.difference <
                                                      0
                                                    ? '-'
                                                    : ''}{' '}
                                                  R$
                                                  {numberToReal(
                                                    differenceInCountTotal.difference
                                                  )}
                                                </p>
                                              </ul>
                                            </>
                                          )
                                        )
                                      ) : (
                                        <div className="countsCircularProgressBar dFlex justifyCenter">
                                          <CircularProgress />
                                        </div>
                                      )}
                                    </div>
                                  }
                                >
                                  {period?.diff === 0 ? (
                                    <img
                                      style={{ cursor: 'pointer' }}
                                      src={warningDiffEquals}
                                      alt="Warning diff equals Icon"
                                    />
                                  ) : period?.diff > 0 ? (
                                    <img
                                      style={{ cursor: 'pointer' }}
                                      src={warningDiffPositive}
                                      alt="Warning diff positive Icon"
                                    />
                                  ) : (
                                    <img
                                      style={{ cursor: 'pointer' }}
                                      src={warningDiffNegative}
                                      alt="Warning diff negative Icon"
                                    />
                                  )}
                                </Tooltip>
                              ) : null}
                            </div> */}
                          </div>
                        </div>
                      </Td>

                      <Td
                        width="100"
                        className="description"
                        style={{
                          borderBottom:
                            index === periodicities.length - 1 &&
                            countIndex === period.counts.length - 1
                              ? '1px solid #313347'
                              : countIndex === period.counts.length - 1
                              ? isCurrentDayCount
                                ? '2px solid #010311'
                                : '1px solid rgba(146, 150, 172, 1)'
                              : null
                        }}
                      >
                        {countIndex < 1 ? (
                          <span className={`${isCurrentDayCount && 'bold'}`}>
                            {ValidationLength(
                              periodTypeTranslation(period?.periodicity?.type),
                              20
                            )}
                          </span>
                        ) : (
                          ''
                        )}
                      </Td>

                      {/* <TdDetour
                        width="43"
                        className={`pr5 ${isCurrentDayCount && 'bold'}`}
                        style={{
                          fontSize: '12px',
                          borderBottom:
                            index === periodicities.length - 1 &&
                            countIndex === period.counts.length - 1
                              ? '1px solid #313347'
                              : countIndex === period.counts.length - 1
                              ? isCurrentDayCount
                                ? '2px solid #010311'
                                : '1px solid rgba(146, 150, 172, 1)'
                              : null,
                          textAlign: 'right',
                          background: 'rgba(255, 242, 204, .4)'
                        }}
                      >
                        {countIndex < 1 &&
                          period?.diversion.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}
                      </TdDetour> */}

                      <Td
                        width="170"
                        className="description"
                        style={{
                          borderBottom:
                            index === periodicities.length - 1 &&
                            countIndex === period.counts.length - 1
                              ? '1px solid #313347'
                              : countIndex === period.counts.length - 1
                              ? isCurrentDayCount
                                ? '2px solid #010311'
                                : '1px solid rgba(146, 150, 172, 1)'
                              : '1px solid #D0D3E2',
                          borderLeft: 'solid 1px #D0D3E2'
                        }}
                      >
                        <div className="dFlex alignCenter pl6">
                          {/* <div
                          className="alignCenter contentPeriod"
                          style={{
                            display: 'flex',
                            margin: '0 6px',
                            width: 20
                          }}
                        >
                          {count?.hasDiffInCount ? (
                            <Tooltip
                              TransitionComponent={Fade}
                              TransitionProps={{ timeout: 600 }}
                              onOpen={() => handleDifferenceInCount(count)}
                              onClose={() =>
                                setDifferenceInCountTooltip(null)
                              }
                              title={
                                <div
                                  className="dFlex flexColumn"
                                  style={{ width: '220px', paddingTop: 5 }}
                                >
                                  {differenceInCountTooltip !== null ? (
                                    <>
                                      <p className="textAlignRight">
                                        DIFERENÇA NA CONTAGEM
                                      </p>

                                      <ul>
                                        <p className="textAlignRight">
                                          SOBRA DE MERCADORIA:{' '}
                                          {differenceInCountTooltip.positiveTotal >
                                            0
                                            ? '+'
                                            : ''}{' '}
                                          R$
                                          {numberToReal(
                                            differenceInCountTooltip.positiveTotal
                                          )}
                                        </p>
                                      </ul>

                                      <ul>
                                        <p className="textAlignRight">
                                          FALTA DE MERCADORIA:{' '}
                                          {differenceInCountTooltip.negativeTotal <
                                            0
                                            ? '-'
                                            : ''}{' '}
                                          R$
                                          {numberToReal(
                                            Math.abs(
                                              differenceInCountTooltip.negativeTotal
                                            )
                                          )}
                                        </p>
                                      </ul>

                                      <ul>
                                        <p className="textAlignRight bold">
                                          DIFERENÇA:{' '}
                                          {differenceInCountTooltip.difference >
                                            0
                                            ? '+'
                                            : differenceInCountTooltip.difference <
                                              0
                                              ? '-'
                                              : ''}{' '}
                                          R$
                                          {numberToReal(
                                            differenceInCountTooltip.difference
                                          )}
                                        </p>
                                      </ul>
                                    </>
                                  ) : (
                                    <div className="countsCircularProgressBar dFlex justifyCenter">
                                      <CircularProgress />
                                    </div>
                                  )}
                                </div>
                              }
                            >
                              {count?.diffInCount == 0 ? (
                                <img
                                  style={{ cursor: 'pointer' }}
                                  src={warningDiffEquals}
                                  alt="Warning diff equals Icon"
                                />
                              ) : count?.diffInCount > 0 ? (
                                <img
                                  style={{ cursor: 'pointer' }}
                                  src={warningDiffPositive}
                                  alt="Warning diff positive Icon"
                                />
                              ) : (
                                <img
                                  style={{ cursor: 'pointer' }}
                                  src={warningDiffNegative}
                                  alt="Warning diff negative Icon"
                                />
                              )}
                            </Tooltip>
                          ) : null}
                        </div> */}
                          <div className="dFlex justifyBetween w100">
                            <div className="dFlex playCountBox">
                              <IconButton
                                aria-label="playIcon"
                                disabled={
                                  !count.id
                                    ? true
                                    : playButtonStatusEnable[count.id]
                                    ? true
                                    : !!count.processingSpreadsheet
                                }
                                onClick={() =>
                                  handlePlayCounts(
                                    count,
                                    count.status,
                                    item,
                                    true,
                                    period,
                                    count?.setup?.storageLocation?.id
                                  )
                                }
                                style={{
                                  color: 'eaeaea',
                                  padding: 0,
                                  outline: 0
                                }}
                              >
                                <img
                                  className="playCount"
                                  style={{
                                    cursor: 'pointer',
                                    boxShadow: '0px 4px 4px 0px #00000040',
                                    borderRadius: 4
                                  }}
                                  width="20"
                                  src={
                                    isCurrentDayCount
                                      ? PlayIconBlueBold
                                      : PlayIconBlue
                                  }
                                  alt="PlayCount"
                                />
                              </IconButton>

                              <p
                                className={`${isCurrentDayCount && 'bold'}`}
                                style={{ margin: '0px', paddingLeft: '6px' }}
                              >
                                {ValidationLength(
                                  storageLocationDescription,
                                  30
                                ) || 'Avulsa'}
                              </p>
                            </div>

                            {/* <div
                              className="alignCenter contentPeriodAfter"
                              style={{
                                display: 'none',
                                margin: '0 6px',
                                width: 20
                              }}
                            >
                              {count?.hasDiffInCount ? (
                                <Tooltip
                                  TransitionComponent={Fade}
                                  TransitionProps={{ timeout: 600 }}
                                  onOpen={() => handleDifferenceInCount(count)}
                                  onClose={() =>
                                    setDifferenceInCountTooltip(null)
                                  }
                                  title={
                                    <div
                                      className="dFlex flexColumn"
                                      style={{ width: '220px', paddingTop: 5 }}
                                    >
                                      {differenceInCountTooltip !== null ? (
                                        <>
                                          <p className="textAlignRight">
                                            DIFERENÇA NA CONTAGEM
                                          </p>

                                          <ul>
                                            <p className="textAlignRight">
                                              SOBRA DE MERCADORIA:{' '}
                                              {differenceInCountTooltip.positiveTotal >
                                              0
                                                ? '+'
                                                : ''}{' '}
                                              R$
                                              {numberToReal(
                                                differenceInCountTooltip.positiveTotal
                                              )}
                                            </p>
                                          </ul>

                                          <ul>
                                            <p className="textAlignRight">
                                              FALTA DE MERCADORIA:{' '}
                                              {differenceInCountTooltip.negativeTotal <
                                              0
                                                ? '-'
                                                : ''}{' '}
                                              R$
                                              {numberToReal(
                                                Math.abs(
                                                  differenceInCountTooltip.negativeTotal
                                                )
                                              )}
                                            </p>
                                          </ul>

                                          <ul>
                                            <p className="textAlignRight bold">
                                              DIFERENÇA:{' '}
                                              {differenceInCountTooltip.difference >
                                              0
                                                ? '+'
                                                : differenceInCountTooltip.difference <
                                                  0
                                                ? '-'
                                                : ''}{' '}
                                              R$
                                              {numberToReal(
                                                differenceInCountTooltip.difference
                                              )}
                                            </p>
                                          </ul>
                                        </>
                                      ) : (
                                        <div className="countsCircularProgressBar dFlex justifyCenter">
                                          <CircularProgress />
                                        </div>
                                      )}
                                    </div>
                                  }
                                >
                                  {count?.diffInCount == 0 ? (
                                    <img
                                      style={{ cursor: 'pointer' }}
                                      src={warningDiffEquals}
                                      alt="Warning diff equals Icon"
                                    />
                                  ) : count?.diffInCount > 0 ? (
                                    <img
                                      style={{ cursor: 'pointer' }}
                                      src={warningDiffPositive}
                                      alt="Warning diff positive Icon"
                                    />
                                  ) : (
                                    <img
                                      style={{ cursor: 'pointer' }}
                                      src={warningDiffNegative}
                                      alt="Warning diff negative Icon"
                                    />
                                  )}
                                </Tooltip>
                              ) : null}
                            </div> */}
                          </div>
                        </div>
                      </Td>

                      <td
                        width="100"
                        className="description hasDiffTheoreticalBox"
                        style={{
                          borderBottom:
                            index === periodicities.length - 1 &&
                            countIndex === period.counts.length - 1
                              ? '1px solid #313347'
                              : countIndex === period.counts.length - 1
                              ? isCurrentDayCount
                                ? '2px solid #010311'
                                : '1px solid rgba(146, 150, 172, 1)'
                              : '1px solid #D0D3E2'
                        }}
                      >
                        <div className="dFlex alignCenter justifyEnd">
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            title={
                              <p
                                className="dFlex mb0"
                                style={{
                                  padding: '2px 5px'
                                }}
                              >
                                Gerenciar os Produtos desta Contagem
                              </p>
                            }
                          >
                            <p
                              style={{
                                margin: count?.hasDiffTheoreticalRealStock
                                  ? 0
                                  : '0 12px 0 0'
                              }}
                            >
                              <a
                                style={{
                                  color:
                                    count?.hasChange === 'true'
                                      ? '#F53D4C'
                                      : '#5062F0'
                                }}
                                onClick={() =>
                                  handleProductDialog(
                                    count,
                                    period.periodicity.description,
                                    count?.setup?.storageLocation.description
                                  )
                                }
                              >
                                {productsCount}
                              </a>
                            </p>
                          </Tooltip>

                          {/* {count?.hasDiffTheoreticalRealStock && (
                            <div
                              className="dFlex alignCenter hasDiffTheoreticalIcon"
                              style={{ margin: '0 12px 0 6px' }}
                            >
                              <Tooltip
                                TransitionComponent={Fade}
                                TransitionProps={{ timeout: 600 }}
                                onOpen={() => handleDivergentItens(count)}
                                onClose={() => setDivergentItensTooltip([])}
                                title={
                                  <div
                                    className="dFlex flexColumn"
                                    style={{
                                      width: '200px',
                                      paddingTop: 5
                                    }}
                                  >
                                    {divergentItensTooltip.length ? (
                                      <>
                                        <p style={{ textAlign: 'center' }}>
                                          Itens com divergência:
                                        </p>

                                        <ul>
                                          {divergentItensTooltip.map(
                                            (itens) => (
                                              <li style={{ marginTop: '3px' }}>
                                                - {itens?.description}
                                              </li>
                                            )
                                          )}
                                        </ul>
                                      </>
                                    ) : (
                                      <div className="countsCircularProgressBar dFlex justifyCenter">
                                        <CircularProgress />
                                      </div>
                                    )}
                                  </div>
                                }
                              >
                                <img
                                  style={{ cursor: 'pointer' }}
                                  src={WarningYellowIcon}
                                  alt="Warning divergent Icon"
                                />
                              </Tooltip>
                            </div>
                          )} */}
                        </div>
                      </td>

                      {/* <TdDetour
                        width="43"
                        className={`pr5 ${isCurrentDayCount && 'bold'}`}
                        style={{
                          fontSize: '12px',
                          borderBottom:
                            index === periodicities.length - 1 &&
                            countIndex === period.counts.length - 1
                              ? '1px solid #313347'
                              : countIndex === period.counts.length - 1
                              ? isCurrentDayCount
                                ? '2px solid #010311'
                                : '1px solid rgba(146, 150, 172, 1)'
                              : '1px solid #D0D3E2',
                          textAlign: 'right',
                          background: 'rgba(255, 242, 204, .4)'
                        }}
                      >
                        {count?.diversion.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </TdDetour> */}

                      <TdProgressStatus
                        width="80"
                        id={count.percentage == 100 ? 'finished' : ''}
                        className="description"
                        style={{
                          borderLeft: '2px solid #010311',
                          borderBottom:
                            index === periodicities.length - 1 &&
                            countIndex === period.counts.length - 1
                              ? '1px solid #313347'
                              : countIndex === period.counts.length - 1
                              ? isCurrentDayCount
                                ? '2px solid #010311'
                                : '1px solid rgba(146, 150, 172, 1)'
                              : '1px solid #D0D3E2'
                        }}
                      >
                        <Grid
                          container
                          alignItems="center"
                          style={{ marginLeft: 6 }}
                        >
                          <Grid conteiner item xs={12} alignItems="center">
                            <BorderLinearProgress
                              variant="determinate"
                              color="secondary"
                              className={
                                count.hasError &&
                                !playButtonStatusEnable[count.id]
                                  ? isCurrentDayCount
                                    ? 'withErrorBold'
                                    : 'withError'
                                  : isCurrentDayCount
                                  ? 'withoutErrorBold'
                                  : 'withoutError'
                              }
                              value={count.percentage}
                            />
                          </Grid>
                        </Grid>
                      </TdProgressStatus>

                      <td
                        width="40"
                        className="description pr6"
                        style={{
                          borderBottom:
                            index === periodicities.length - 1 &&
                            countIndex === period.counts.length - 1
                              ? '1px solid #313347'
                              : countIndex === period.counts.length - 1
                              ? isCurrentDayCount
                                ? '2px solid #010311'
                                : '1px solid rgba(146, 150, 172, 1)'
                              : '1px solid #D0D3E2'
                        }}
                      >
                        <p
                          className={`textAlignRight ${
                            isCurrentDayCount && 'bold'
                          }`}
                          style={{ margin: '0px' }}
                        >
                          {/* <span style={{ marginRight: '3px' }}>
                          {setCountStatus(count)}
                        </span> */}

                          {!count.hasError
                            ? setCountStatus(count) === 'Parcial' ||
                              setCountStatus(count) === 'Processando'
                              ? `${
                                  count?.percentage <=
                                  Math.round(count?.percentage)
                                    ? Math.ceil(count?.percentage || 0)
                                    : Math.floor(count?.percentage || 0)
                                }%`
                              : setCountStatus(count) === 'Finalizada'
                              ? '100%'
                              : '0%'
                            : 'Erro'}

                          {count.hasError &&
                          !playButtonStatusEnable[count.id] ? (
                            <Tooltip title="Houve um erro no processamento da contagem, revise os dados da planilha enviada">
                              <img src={ErrorIcon} alt="Error Icon" />
                            </Tooltip>
                          ) : null}
                        </p>
                      </td>

                      <TdLastCount
                        width="180"
                        className={`description ${isCurrentDayCount && 'bold'}`}
                        style={{
                          borderBottom:
                            index === periodicities.length - 1 &&
                            countIndex === period.counts.length - 1
                              ? '1px solid #313347'
                              : countIndex === period.counts.length - 1
                              ? isCurrentDayCount
                                ? '2px solid #010311'
                                : '1px solid rgba(146, 150, 172, 1)'
                              : '1px solid #D0D3E2',
                          borderLeft: '1px solid #D0D3E2',
                          paddingLeft: 6
                        }}
                      >
                        <p style={{ margin: '0px' }}>
                          {toLocaleDateLastCount(lastCount) || ''}
                        </p>
                      </TdLastCount>

                      <TdLastCount
                        width="70"
                        className="description"
                        style={{
                          borderBottom:
                            index === periodicities.length - 1 &&
                            countIndex === period.counts.length - 1
                              ? '1px solid #313347'
                              : countIndex === period.counts.length - 1
                              ? isCurrentDayCount
                                ? '2px solid #010311'
                                : '1px solid rgba(146, 150, 172, 1)'
                              : '1px solid #D0D3E2',
                          paddingLeft: 6
                        }}
                      >
                        <p
                          className={`${isCurrentDayCount && 'bold'}`}
                          style={{ margin: '0px' }}
                        >
                          {ValidationLength(user, 15) || ''}
                        </p>
                      </TdLastCount>

                      <TdLastCountTablet
                        width="20"
                        style={{
                          padding: '0 10px',
                          borderBottom:
                            index === periodicities.length - 1 &&
                            countIndex === period.counts.length - 1
                              ? '1px solid #313347'
                              : countIndex === period.counts.length - 1
                              ? isCurrentDayCount
                                ? '2px solid #010311'
                                : '1px solid rgba(146, 150, 172, 1)'
                              : '1px solid #D0D3E2',
                          borderLeft: 'solid 1px #010311'
                        }}
                      >
                        <div className="dFlex justifyCenter">
                          <img
                            width="25px"
                            src={EllipsisIcon}
                            onClick={() => {
                              setInfoCount({
                                lastCount:
                                  toLocaleDateLastCount(lastCount) || '',
                                user
                              });
                              setOpenInfo(!openInfo);
                            }}
                            alt="PlayCount"
                          />
                        </div>
                      </TdLastCountTablet>

                      <TdIconFiles
                        className="description"
                        style={{
                          borderLeft: '2px solid #010311',
                          borderBottom:
                            index === periodicities.length - 1 &&
                            countIndex === period.counts.length - 1
                              ? '1px solid #313347'
                              : countIndex === period.counts.length - 1
                              ? isCurrentDayCount
                                ? '2px solid #010311'
                                : '1px solid rgba(146, 150, 172, 1)'
                              : '1px solid #D0D3E2'
                        }}
                      >
                        <Grid className="dFlex justifyCenter">
                          <Tooltip title="Download da planilha desta contagem">
                            <IconButton
                              aria-label="downloadIcon"
                              onClick={(event) => {
                                setPopoverData(count);
                                fetchAssortmentOptions(count);
                                handlePopoverClick(event, count);
                              }}
                              disabled={!count.id}
                              style={{
                                color: 'eaeaea',
                                padding: 0,
                                outline: 0
                              }}
                            >
                              <img src={DownloadIcon} alt="Download Icon" />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </TdIconFiles>

                      <TdIconFiles
                        width="30"
                        className="description"
                        style={{
                          borderBottom:
                            index === periodicities.length - 1 &&
                            countIndex === period.counts.length - 1
                              ? '1px solid #313347'
                              : countIndex === period.counts.length - 1
                              ? isCurrentDayCount
                                ? '2px solid #010311'
                                : '1px solid rgba(146, 150, 172, 1)'
                              : '1px solid #D0D3E2'
                        }}
                      >
                        <Grid className="dFlex justifyCenter">
                          <Tooltip title="Upload da planilha desta contagem">
                            <IconButton
                              aria-label="uploadIcon"
                              disabled={!count.id}
                              style={{
                                color: 'eaeaea',
                                padding: 0,
                                outline: 0,
                                margin: 0
                              }}
                            >
                              <img src={UploadIcon} alt="Upload Icon" />

                              <InputFile
                                type="file"
                                id="spreadsheetImport"
                                name="spreadsheetImport"
                                onChange={(event) =>
                                  handleFile(inputRef, count, event)
                                }
                                accept=".xlsx"
                                ref={inputRef}
                                aria-label="upload"
                              />
                            </IconButton>
                          </Tooltip>
                        </Grid>
                      </TdIconFiles>

                      <TdIconTrash
                        width="30"
                        className="description"
                        style={{
                          borderLeft: 'solid 1px #D0D3E2',
                          borderBottom:
                            index === periodicities.length - 1 &&
                            countIndex === period.counts.length - 1
                              ? '1px solid #313347'
                              : countIndex === period.counts.length - 1
                              ? isCurrentDayCount
                                ? '2px solid #010311'
                                : '1px solid rgba(146, 150, 172, 1)'
                              : '1px solid #D0D3E2',
                          padding: '5px'
                        }}
                      >
                        {deleteCountblock(count.status).disabled
                          ? canRemove() && (
                              <Tooltip
                                title={deleteCountblock(count.status).message}
                              >
                                <Grid className="dFlex justifyCenter">
                                  <IconButton
                                    aria-label="playIcon"
                                    disabled={
                                      !count.id ||
                                      deleteCountblock(count.status).disabled
                                    }
                                    onClick={() => onOpenDeleteDialog(count)}
                                    style={{
                                      color: 'eaeaea',
                                      padding: 0,
                                      outline: 0
                                    }}
                                  >
                                    <DeleteOutline />
                                  </IconButton>
                                </Grid>
                              </Tooltip>
                            )
                          : canRemove() && (
                              <Grid className="dFlex justifyCenter">
                                <IconButton
                                  aria-label="playIcon"
                                  disabled={!count.id}
                                  onClick={() => onOpenDeleteDialog(count)}
                                  style={{
                                    color: 'eaeaea',
                                    padding: 0,
                                    outline: 0
                                  }}
                                >
                                  <DeleteOutline />
                                </IconButton>
                              </Grid>
                            )}
                      </TdIconTrash>
                    </tr>
                  );
                })
              )
            : null;
        })
      ) : (
        <tr>
          <th colSpan="13" className="m10 textAlignCenter">
            Não há nenhuma contagem
          </th>
        </tr>
      )}

      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: { padding: '10px', borderRadius: '4px', maxWidth: '400px' }
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Grid
          container
          xs={12}
          justify="center"
          spacing={1}
          style={{ padding: '10px', margin: 0, borderRadius: '8px' }}
        >
          <Grid item xs={12} className="mb10">
            <Typography className="bold textAlignCenter">
              Escolha a ordenação dos produtos na planilha
            </Typography>
          </Grid>

          <Grid item xs={12} className="mb10">
            <Typography className="bold textAlignCenter fontSizeDefault">
              Selecione uma data ou baixe em ordem alfabética
            </Typography>

            <Autocomplete
              fullWidth
              size="small"
              getOptionSelected={(option, value) => option.date === value.date}
              getOptionLabel={(option) =>
                `${moment(option.date).format('DD/MM/YYYY')}    ${option.user}`
              }
              options={assortmentsOptions}
              value={assortmentDate}
              onChange={(e, value) => {
                if (value) {
                  setAssortmentDate(moment(value.date).format('YYYY-MM-DD'));
                  setDownloadButtonsState({
                    date: 'enabled',
                    alphabetical: 'disabled'
                  });
                } else {
                  setDownloadButtonsState({
                    date: 'disabled',
                    alphabetical: 'enabled'
                  });
                }
              }}
              className="autocompleteSmall"
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  id={params.id}
                  name="typeFilter"
                  required
                  placeholder="Digite aqui..."
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              )}
              renderOption={(params) => (
                <Grid container spacing={2}>
                  <Grid item xs={3}>
                    <Typography className="bold textAlignCenter fontSizeDefault">
                      {moment(params.date).format('DD/MM/YYYY')}
                    </Typography>
                  </Grid>

                  <Grid item xs={9}>
                    <Typography
                      className="bold textAlignCenter fontSizeDefault italic"
                      style={{
                        color: '#666770'
                      }}
                    >
                      {params.user}
                    </Typography>
                  </Grid>
                </Grid>
              )}
            />
          </Grid>

          <Grid item xs={6} className="dFlex justifyCenter">
            <Button
              autoFocus
              style={{ width: '40px', borderRadius: '8px', outline: 0 }}
              design="contained"
              onClick={(e) => {
                downloadSpreadsheetFromCount(popoverData, 'date');
                handleClose(e);
              }}
              label="Data"
              color="primary"
              disabled={downloadButtonsState.date === 'disabled'}
            />
          </Grid>

          <Grid item xs={6} className="dFlex justifyCenter">
            <Button
              autoFocus
              style={{ width: '40px', borderRadius: '8px', outline: 0 }}
              design="contained"
              onClick={(e) => {
                downloadSpreadsheetFromCount(popoverData, 'alphabetical');
                handleClose(e);
              }}
              label="Alfabética"
              color="primary"
              disabled={downloadButtonsState.alphabetical === 'disabled'}
            />
          </Grid>
        </Grid>
      </Popover>
      <Dialog
        open={openInfo}
        onClose={() => {
          setOpenInfo(false);
        }}
        // PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="defaultModal mH80"
        maxWidth="lg"
      >
        <DialogTitle
          className="modalHeader navyBlue bold textAlignCenter cursorMove"
          id="draggable-dialog-title"
        >
          <div className="dFlex justifyBetween alignCenter w100">
            <Typography style={{ margin: '12px' }}>
              Informações da contagem
            </Typography>
          </div>
          <img
            style={{ width: '16px' }}
            onClick={() => setOpenInfo(false)}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </DialogTitle>

        <DialogContent className="modalContent onlyTable pb0">
          <Grid container>
            <table
              className="contagem"
              width="100%"
              height="70%"
              cellPadding="0"
              cellSpacing="0"
              border="0"
            >
              <thead>
                <tr>
                  <th align="bottom" width="200" className="subtitle">
                    ÚLTIMA CONTAGEM
                  </th>

                  <th width="70" className="subtitle">
                    USUÁRIO
                  </th>
                </tr>

                <tr>
                  <td colSpan="5">
                    <hr className="titleLineBorder p0 m0" />
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="200" className="description">
                    {infoCount.lastCount ? infoCount.lastCount : '-'}
                  </td>
                  <td width="200" className="description">
                    {infoCount.user ? infoCount.user : '-'}
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CountTable;
