/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Paper,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import axios from 'axios';
import { capitalize } from 'lodash';
import Draggable from 'react-draggable';
import { useParams } from 'react-router';

import ImageWrapper from 'components/ImageWrapper';
import CurrencyTextFieldSumeValues from 'components/Inputs/CurrencyTextFieldSumeValues';
import InputNumber from 'components/Inputs/ManufactureInputs/InputNumber';
import selectorInputArrow from 'images/icons/arrows/selectorInputArrow.svg';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import AddDisabledIcon from 'images/icons/signals/addsAndCreates/addDisabled.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import Environment from 'utils/environments';
import numberToReal from 'utils/numberToReal';

import {
  CardIngredientsDrillDown,
  CardIngredientsDrillDownHead,
  CardIngredientsDrillDownContent,
  DrilDownContentData,
  ArrowDown,
  DrilDownContentTitle,
} from './style';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function CardIngredients({
  handleItemDiscard,
  updateQuantityInitialValue,
  manufactures,
  setManufactures,
  totalItems,
  content,
  manufacture,
  optionsDiscard,
  manufactureStatus,
  handleDiscardInfo,
  handleCardQuantity,
  cardIndex,
  itemIndex,
  newProductDuration,
  durationData,
  setDurationData,
  itemss,
  setProductDialogModalStates,
  mainModalStates,
  noActionMode
}) {
  const isPhone = useMediaQuery('(max-width: 767px)');
  const [openDrilDown, setOpenDrilDown] = useState(false);
  const [updateValue, setUpdateValue] = useState(false);
  const [discardQuantity, setDiscardQuantity] = useState();
  const [discardJustification, setDiscardJustification] = useState();
  const environments = Environment(process.env.REACT_APP_ENV);
  const { id: paramsID } = useParams();
  const [modalStates, setModalStates] = useState({
    modalItemLabelQuantity: false,
    modalDuration: false
  });
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('sm');

  const handleCloseModal = () => {
    setModalStates({
      modalItemLabelQuantity: false,
      modalDuration: false
    });
  };

  const handleDrilDown = () => {
    setOpenDrilDown(!openDrilDown);
  };

  const handleItemQuantity = (value, labelQuantity = '') => {
    if (!value) {
      return;
    }

    handleCardQuantity(
      cardIndex,
      manufacture.quantity,
      itemIndex,
      parseFloat(value),
      undefined,
      true,
      labelQuantity,
      '',
      noActionMode ? manufacture : ''
    );
  };

  const {
    item,
    un,
    stock,
    itemProcess,
    main,
    duration,
    quantity,
  } = content;
  const { abbreviation } = un;

  const inputDisabledStatus =
    noActionMode ? false : manufactureStatus === 'CANCELED' || item.quantity == 0;
  const inputQuantityDisabled = noActionMode ? false : manufactureStatus !== 'WAITING';
  const discardReason =
    content?.discard &&
    (content?.discard?.length
      ? content?.discard[0]?.justification
      : content?.discard?.justification) &&
    (content?.discard?.length
      ? content?.discard[0]?.quantity
      : content?.discard?.quantity) !== 0
      ? capitalize(
          content?.discard?.length
            ? content?.discard[0]?.justification?.description
            : content?.discard?.justification?.description
        )
      : null;

  const getDuration = (productId, stock, product, type, newDuration) => {
    axios
      .get(
        `${environments.engine}/rupture?productId=${productId}&originId=${paramsID}&days=8&stock=${stock}`
      )
      .then((response) => {
        const items = response.data;
        setDurationData({
          product,
          data: items,
          type,
          newDuration
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const replaceDayDurationData = (data) => {
    if (data === 'D0') return 'Hoje';
    return data;
  };

  useEffect(() => {
    if (!discardJustification || !discardQuantity) return;
    const newDiscard = {
      justification: {
        description: discardJustification.description,
        id: discardJustification.id
      },
      quantity: discardQuantity
    };

    content.discard = newDiscard;

    handleDiscardInfo();
  }, [discardJustification, discardQuantity]);

  useEffect(() => {
    setTimeout(() => {
      if (
        modalStates.modalItemLabelQuantity &&
        document.querySelector('.sumeValuesProductionDialog input')
      ) {
        document.querySelector(`.sumeValuesProductionDialog input`).select();
      }
    }, 100);
  }, [modalStates.modalItemLabelQuantity]);

  return (
    <>
      <CardIngredientsDrillDown isLast={itemIndex >= totalItems.length - 1}>
        <CardIngredientsDrillDownHead>
          <div className="dFlex w100 justifyBetween">
            <div className="dFlex">
              <span className={`title-item ${manufacture.type !== 'RECIPE' && 'transformationItemTitle'}`} style={{ color: main && "#0B1A8E" }} onClick={handleDrilDown}>
                {manufacture.type !== 'RECIPE' ? (
                  <span className="mr10">{itemIndex + 1}</span>
                ) : null}

                {`${item} ${itemProcess?.description ? `- ${itemProcess?.description}` : ''}`}
              </span>

              <div className="itemImage">
                {content?.image &&
                  <ImageWrapper
                    width="38px"
                    height="38px"
                    url={content?.image}
                    customClass="productionImagePopover"
                  />
                }
              </div>
            </div>

            <div className="dFlex alignCenter">
              {manufacture.type === 'RECIPE' ? (
                <span
                  className="dFlex alignCenter mr10"
                  style={{
                    color: content?.labelQuantity ? 'green' : 'gray',
                    fontSize: 20
                  }}
                  onClick={() => {
                    if (inputQuantityDisabled) return;

                    setModalStates({
                      ...modalStates,
                      modalItemLabelQuantity: true
                    });
                  }}
                >
                  <img
                    style={{ width: '30px' }}
                    src={content?.labelQuantity ? AddIcon : AddDisabledIcon}
                    alt="AddIcon"
                  />
                </span>
              ) : null}

              <div className="qtde-Ingredients">
                <span
                  className="qtde-item dFlex alignCenter bold uppercase textAlignRight"
                  style={{
                    background: 'transparent',
                    fontSize: isPhone ? '12px' : '18px',
                    color: manufacture.type === 'RECIPE' ? '#75C7F0' : '#F7D56E'
                  }}
                >
                  QTDE
                </span>

                <span
                  className="qtde-number"
                  onClick={() => {
                    if (!content?.labelQuantity || inputQuantityDisabled)
                      return;

                    setModalStates({
                      ...modalStates,
                      modalItemLabelQuantity: true
                    });
                  }}
                >
                  <InputNumber
                    updateQuantityInitialValue={updateQuantityInitialValue}
                    setModalStates={setProductDialogModalStates}
                    modalStates={mainModalStates}
                    setUpdateValue={setUpdateValue}
                    updateValue={updateValue}
                    id={`manufacture-${itemIndex}`}
                    initialValue={quantity?.toFixed(3)}
                    unity={abbreviation}
                    handleValue={handleItemQuantity}
                    onKeyDown={(event) => {
                      if (event.keyCode === 9 || event.keyCode === 13) {
                        document.querySelector(`#manufacture-${itemIndex + 1}`)
                          ? setTimeout(() => {
                              document
                                .querySelector(`#manufacture-${itemIndex + 1}`)
                                .select();
                            }, 100)
                          : setTimeout(() => {
                              document
                                .querySelector('#yieldCardField input')
                                .select();
                            }, 100);
                      }
                    }}
                    disabled={
                      manufactureStatus === 'CANCELED' ||
                      content?.labelQuantity ||
                      inputQuantityDisabled
                    }
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="item-arrow" onClick={handleDrilDown}>
            <ArrowDown
              openDrilDown={openDrilDown}
              src={selectorInputArrow}
              id="CardIngredientInputArrow"
            />
          </div>
        </CardIngredientsDrillDownHead>

        <CardIngredientsDrillDownContent openDown={openDrilDown}>
          <div
            className="Ingredients-container"
            style={{
              width: `calc(${isPhone ? '100%' : '45%'} + ${
                isPhone ? '5px' : '13px'
              })`,
              background: '#E6E7F0',
              marginLeft: `${isPhone ? '0' : '-3px'}`,
              padding: '6px 0'
            }}
          >
            <div>
              <DrilDownContentTitle
                style={{
                  fontSize: isPhone ? '9px' : '14px',
                  lineHeight: '1.4',
                  textAlign: 'end',
                  color: '#454A67'
                }}
              >
                ESTOQUE PROD.
              </DrilDownContentTitle>

              <DrilDownContentData>
                <span
                  className="item-stock"
                  style={{
                    fontSize: isPhone ? '12px' : '18px',
                    lineHeight: '20px'
                  }}
                >
                  {numberToReal(stock)}
                  {manufacture?.un?.abbreviation}
                </span>
              </DrilDownContentData>
            </div>

            <div>
              <DrilDownContentTitle
                style={{
                  fontSize: isPhone ? '9px' : '14px',
                  lineHeight: '1.4',
                  color: '#454A67'
                }}
              >
                DURAÇÂO
              </DrilDownContentTitle>

              <DrilDownContentData>
                <span
                  className="special-date-result"
                  style={{ fontSize: '12px', lineHeight: '16px' }}
                  onClick={(e) => {
                    if (!duration) return;

                    getDuration(
                      itemss.id ? itemss.id : null,
                      itemss.stock,
                      itemss
                    );
                    setModalStates({ ...modalStates, modalDuration: true });
                  }}
                >
                  {duration || '-'}

                  {duration && duration !== '-'
                    ? duration > 1 || duration === 0
                      ? 'dias'
                      : 'dia'
                    : null}
                </span>
              </DrilDownContentData>
            </div>

            <div className="duration">
              <DrilDownContentTitle
                style={{
                  fontSize: isPhone ? '9px' : '14px',
                  lineHeight: '1.4',
                  color: '#454A67'
                }}
              >
                NOVA DUR.
              </DrilDownContentTitle>

              <DrilDownContentData>
                <span
                  className="special-date-result"
                  style={{ fontSize: '12px', lineHeight: '16px' }}
                  onClick={() => {
                    if (!newProductDuration || newProductDuration === '-')
                      return;

                    getDuration(
                      itemss.id ? itemss.id : null,
                      (itemss.stock || 0) + itemss.quantity,
                      itemss,
                      'transformationItem',
                      true
                    );
                    setModalStates({ ...modalStates, modalDuration: true });
                  }}
                >
                  {newProductDuration || '-'}
                </span>
              </DrilDownContentData>
            </div>
          </div>

          {/* <div
            className="dFlex"
            style={{
              width: `calc(${isPhone ? '100%' : '55%'} + ${
                isPhone ? '5px' : '16px'
              })`,
              background: '#F7D4D7',
              marginRight: '-13px',
              padding: `6px ${isPhone ? '0px' : '35px'} 6px 13px`
            }}
          >
            <div className="dFlex flexColumn" style={{ width: '51%' }}>
              <DrilDownContentTitle
                style={{
                  fontSize: isPhone ? '9px' : '14px',
                  lineHeight: '1.4',
                  color: '#454A67'
                }}
              >
                DESCARTE
              </DrilDownContentTitle>

              <AutoCompleteWrapper
                className="pRelative"
                style={{ width: '100%' }}
              >
                <Autocomplete
                  size="small"
                  className="autocompleteSmall"
                  style={{
                    fontSize: '12px',
                    lineHeight: '16px',
                    width: '130px'
                  }}
                  id="asynchronous-demo"
                  onChange={(option, value) => {
                    if (value) {
                      handleItemDiscard(
                        manufacture._id,
                        itemIndex,
                        content?.discard?.length
                          ? content?.discard[0]?.quantity
                          : content?.discard?.quantity,
                        value,
                        (content?.discard?.length
                          ? content?.discard[0]?.quantity
                          : content?.discard?.quantity) && value,
                        content
                      );
                    } else {
                      handleItemDiscard(
                        manufacture._id,
                        itemIndex,
                        content?.discard?.length
                          ? content?.discard[0]?.quantity
                          : content?.discard?.quantity,
                        '',
                        false,
                        content
                      );
                    }
                  }}
                  getOptionLabel={(option) => option.description}
                  options={optionsDiscard}
                  disable={inputDisabledStatus}
                  value={
                    optionsDiscard
                      .map((option) => ({
                        ...option,
                        description: capitalize(option?.description)
                      }))
                      .filter(
                        (option) => option.description === discardReason
                      )[0]
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      type="text"
                      required
                      placeholder="Justifique..."
                      InputLabelProps={{
                        style: { fontFamily: 'Lato', fontSize: '12px' }
                      }}
                      style={{ width: '100%', height: 15 }}
                    />
                  )}
                />
              </AutoCompleteWrapper>
            </div>

            <div
              className="pRelative dFlex alignCenter"
              style={{
                width: isPhone ? '53%' : '72%',
                marginLeft: isPhone ? 22 : 15
              }}
            >
              <ProductInputWrapper
                bgColor="#AA0816"
                label="QTDE."
                initialValue={
                  content?.discard?.length
                    ? content?.discard[0]?.quantity
                    : content?.discard?.quantity
                }
                disabled={!openDrilDown}
                handleValue={handleDiscardQuantity}
                onBlur={handleItemDiscard}
                content={{
                  _id: manufacture._id,
                  itemIndex,
                  value: content?.discard?.length
                    ? content?.discard[0]?.quantity
                    : content?.discard?.quantity,
                  discardReason: optionsDiscard
                    .map((option) => ({
                      ...option,
                      description: capitalize(option?.description)
                    }))
                    .filter(
                      (option) => option.description === discardReason
                    )[0],
                  item: content
                }}
              />

              <span
                className="pAbsolute bold"
                style={{
                  right: isPhone ? '37px' : '11px',
                  fontSize: isPhone ? 12 : 18
                }}
              >
                {manufacture?.un?.abbreviation}
              </span>
            </div>
          </div> */}
        </CardIngredientsDrillDownContent>
      </CardIngredientsDrillDown>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={modalStates.modalDuration}
        onClose={handleCloseModal}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="defaultModal bigModal"
        transitionDuration={0}
        style={{ width: '100%' }}
      >
        <DialogTitle className="modalHeader navyBlue bold textAlignCenter cursorMove">
          <Typography
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '250px',
              marginRight: '30px'
            }}
          >
            {durationData.product.description || durationData.product.item} -
            Estoque produto
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseModal}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '8px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent className="modalContent">
          <div
            width="100%"
            cellPadding="3"
            cellSpacing="0"
            border="0"
            className="dFlex flexRow justifyBetween"
            style={{
              boxShadow: 'none'
            }}
          >
            <div className="dFlex flexColumn justifyBetween w100">
              <div
                width="60"
                className="subtitle center white"
                style={{
                  background: '#7583F0',
                  borderTopLeftRadius: '8px',
                  borderBottomLeftRadius: '8px',
                  padding: '12px'
                }}
              >
                Atual
              </div>

              {durationData.data.days && durationData.data.days.length
                ? durationData.data.days.map((day) => (
                    <div
                      width="60"
                      className="subtitle p12 left dFlex alignCenter justifyCenter mb8"
                    >
                      {replaceDayDurationData(day.numberDay)}
                    </div>
                  ))
                : null}
            </div>

            <div>
              <div
                className="p0"
                colSpan={
                  durationData.data.days && durationData.data.days.length
                    ? durationData.data.days.length + 1
                    : 0
                }
              >
                <hr className="m0" />
              </div>
            </div>

            <div className="dFlex flexColumn justifyBetween w100">
              {!!durationData.newDuration && (
                <div
                  className="description right white dFlex justifyCenter alignCenter"
                  style={{
                    background: '#7583F0',
                    borderTopRightRadius: '8px',
                    borderBottomRightRadius: '8px',
                    padding: '12px'
                  }}
                >
                  <b
                    className="dBlock textAlignCenter"
                    style={{ width: 'auto' }}
                  >
                    {durationData.type === 'recipe'
                      ? durationData.newDuration
                        ? parseFloat(
                            durationData.product.stock +
                              durationData.product.manufactured
                          ).toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })
                        : durationData.product.stock
                        ? parseFloat(durationData.product.stock).toLocaleString(
                            'pt-br',
                            { minimumFractionDigits: 3 }
                          )
                        : '0,000'
                      : durationData.newDuration
                      ? durationData.type === 'transformationItem'
                        ? parseFloat(
                            (durationData.product.stock || 0) +
                              durationData.product.quantity
                          ).toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })
                        : parseFloat(
                            durationData.product.stock -
                              durationData.product.quantity
                          ).toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })
                      : durationData.product.stock
                      ? parseFloat(durationData.product.stock).toLocaleString(
                          'pt-br',
                          { minimumFractionDigits: 3 }
                        )
                      : '0,000'}
                  </b>

                  {/* {durationData.product.un && durationData.product.un.abbreviation} */}
                </div>
              )}
              {durationData.data.days && durationData.data.days.length
                ? durationData.data.days.map((day) => (
                    <div
                      width="60"
                      className="subtitle p12 right dFlex alignCenter justifyCenter"
                    >
                      <span>
                        {day.quantity
                          ? day.quantity.toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })
                          : '0,00'}
                      </span>

                      <br />

                      {durationData?.product?.un?.abbreviation}
                    </div>
                  ))
                : null}
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={modalStates.modalItemLabelQuantity}
        onClose={handleCloseModal}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="defaultModal bigModal sumeValuesProductionDialog w100"
        transitionDuration={0}
      >
        <DialogTitle className="modalHeader navyBlue bold cursorMove">
          <Typography style={{ fontSize: 20, width: 300 }}>
            Soma de quantidades
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseModal}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '12px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent className="modalContent">
          <FormControl fullWidth>
            <CurrencyTextFieldSumeValues
              id="sumeValuesDialogField"
              key="sumeValuesDialogField"
              name="Quantity"
              placeholder="Digite aqui..."
              onBlur={(value) => {
                if (value) {
                  handleItemQuantity(
                    value
                      .split('+')
                      .map((item) => item.replace(',', '.'))
                      .reduce(
                        (total, value) =>
                          value
                            ? parseFloat(value) + parseFloat(total)
                            : total + 0,
                        0
                      ),
                    value
                  );
                } else {
                  setManufactures(
                    manufactures.map((manufacture, manufactureIndex) => {
                      if (cardIndex === manufactureIndex) {
                        return {
                          ...manufacture,
                          items: manufacture.items.map((item, elementIndex) => {
                            if (elementIndex === itemIndex) {
                              return {
                                ...item,
                                labelQuantity: ''
                              };
                            }
                            return {
                              ...item
                            };
                          })
                        };
                      }
                      return manufacture;
                    })
                  );
                }
              }}
              onFocus={() => {
                setTimeout(() => {
                  document
                    .querySelector(`.sumeValuesProductionDialog input`)
                    .select();
                }, 100);
              }}
              defaultValue={content?.labelQuantity}
              updateQuantityInitialValue={updateQuantityInitialValue}
              modalStates={mainModalStates}
              setModalStates={setProductDialogModalStates}
            />
          </FormControl>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default CardIngredients;
