/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
  Tooltip,
  LinearProgress
} from '@material-ui/core';

import Button from 'components/Button/button';
import PaperComponent from 'components/PaperComponent';

function UnifySkusPlatform({
  handleClosePopover,
  unifySkusPopover,
  setUnifySkusPopover,
  handleUnifySkus,
  validationLength,
  isLoading,
  handleSelectedSku
}) {
  return (
    <Dialog
      open={unifySkusPopover?.open}
      onClose={() => handleClosePopover()}
      aria-labelledby="responsive-dialog-title"
      className="unifySkusPopoverDialog defaultModal fakePopover"
      PaperComponent={(props) => (
        <PaperComponent {...props} handleId="#unifySkusPopover" />
      )}
    >
      {isLoading ? (
        <LinearProgress
          style={{ height: '7px' }}
          id="loading"
          variant="query"
        />
      ) : null}

      <DialogTitle
        className="modalHeader transparent bold textAlignCenter cursorMove"
        id="unifySkusPopover"
      >
        <Typography className="dFlex black justifyCenter bold initialTransform w100">
          Tem certeza que deseja unificar o cadastro destes{' '}
          {unifySkusPopover?.skus?.length} SKUs?
        </Typography>
      </DialogTitle>

      <DialogContent className="modalContent bgWhite pb0">
        <div className="optionsList dFlex flexColumn">
          <span className="bold mb12 textAlignCenter">
            Selecione o SKU que será a referência dos dados desta unificação:
          </span>

          {unifySkusPopover?.skus?.map((sku) => (
            <div className="optionBox dFlex alignCenter">
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="type"
                  name="type"
                  value={unifySkusPopover?.refSku}
                  onChange={() => {
                    setUnifySkusPopover({
                      ...unifySkusPopover,
                      refSku: sku?.id
                    });
                  }}
                  style={{ flexDirection: 'row' }}
                >
                  <FormControlLabel
                    value={sku?.id}
                    control={<Radio required size="small" />}
                    label="SKU:"
                  />
                </RadioGroup>
              </FormControl>

              <span
                className="cursorPointer"
                onClick={() => handleSelectedSku(sku)}
              >
                {validationLength(sku?.description, 35)}
              </span>
            </div>
          ))}
        </div>

        <Typography
          className="dFlex flexColumn uppercase textAlignCenter mt20"
          style={{ color: '#F53D4C' }}
        >
          <span className="bold">Atenção!</span>

          <span>Esta ação alterará também a estrutura de catálogo dos</span>

          <span>restaurantes relacionados.</span>

          <span>Esta ação não poderá ser desfeita.</span>
        </Typography>

        <Tooltip
          placement="bottom"
          title={
            <div style={{ padding: '7px 7px' }}>
              {[
                ...new Set(
                  unifySkusPopover?.skus
                    ?.map((sku) => sku?.restaurantsDescriptions?.split(','))
                    .flat()
                )
              ].map((restaurant) => (
                <p className="mb3">{restaurant}</p>
              ))}
            </div>
          }
        >
          <Typography className="dFlex flexColumn textAlignCenter mt20 bold">
            Restaurantes relacionados:{' '}
            {
              [
                ...new Set(
                  unifySkusPopover?.skus
                    ?.map((sku) => sku?.restaurantsDescriptions?.split(','))
                    .flat()
                )
              ].length
            }
          </Typography>
        </Tooltip>
      </DialogContent>

      <div className="dFlex pt12 pb12 pl16 pr16">
        <Grid item xs={6} className="dFlex justifyEnd mr20">
          <Button
            design="outlined"
            className="defaultButton backButton"
            onClick={() => {
              handleClosePopover();
            }}
            label="Cancelar"
          />
        </Grid>

        <Grid item xs={6} className="dFlex justifyStart">
          <Button
            className="defaultButton submitButton"
            disabled={!unifySkusPopover?.refSku || isLoading}
            autoFocus
            design="contained"
            onClick={() => {
              handleUnifySkus();
            }}
            label={isLoading ? 'Processando...' : 'Unificar'}
          />
        </Grid>
      </div>
    </Dialog>
  );
}

export default UnifySkusPlatform;
