/* eslint-disable no-restricted-syntax */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, { useState, useCallback, useEffect } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Snackbar,
  Typography
} from '@material-ui/core';
import axios from 'axios';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Alert } from 'reactstrap';

import ConfirmModal from 'components/Dialogs/ConfirmModal/ConfirmModal';
import UpdateDiscard from 'components/Dialogs/Discard/UpdateDiscard';
import PaperComponent from 'components/PaperComponent';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import Environment from 'utils/environments';

import DrillDownDialog from './DrillDownGroup';
import TheoryStockBody from './TheoryStockBody';
import TheoryStockHead from './TheoryStockHead';
import TheoryStockMenu from './TheoryStockMenu';

const environments = Environment(process.env.REACT_APP_ENV);

function TheoryStockDialog({
  productExtractDescription,
  productAge,
  modalProduct,
  modalStates,
  checkConsume,
  defaultStartDate,
  extractEndDate,
  extractType,
  discardId,
  productModal,
  parameters,
  originPage,
  setProductModal,
  getId,
  handleClose,
  setExtractEndDate,
  setExtractType,
  fetchCards,
  extractDateAndDescription,
  handleGetDiffs,
  differenceInExtractTooltip,
  setDifferenceInExtractTooltip,
  autocompleteDescription,
  setAutocompleteDescription,
  weekDays,
  setRangeDateItems,
  setStartDate,
  setEndDate,
  setSelectedItem,
  setAnalytic
}) {
  const user = useSelector((currState) => currState.user);

  const [autocompleteOptions, setAutocompleteOptions] = useState([]);
  const [filterSelectedProduct, setFilterSelectedProduct] = useState();
  const [drilldownInfo, setDrillDownInfo] = useState({
    info: '',
    productsIds: ''
  })
  const [isLoading, setIsLoading] = useState(false);

  const isOriginPageWithLastCount =
    originPage === 'productPage' ||
    originPage === 'stocksPage' ||
    originPage === 'countPage';

  const initialStartDate = 
    isOriginPageWithLastCount
      ? productModal?.lastCount
        ? Math.abs(moment(productModal?.lastCount).diff(new Date(), 'days')) > 30 
          ? moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD') 
          : moment(productModal.lastCount).format('YYYY-MM-DD')
        : defaultStartDate || moment(new Date()).subtract(7, 'days').format('YYYY-MM-DD') 
      : defaultStartDate || new Date();

  const [cardsRelatedInitialDate, setCardsRelatedInitialDate] = useState(moment(initialStartDate).add(1, 'day'));
  const [extractStartDate, setExtractStartDate] = useState(weekDays ? moment(extractEndDate).subtract(7, 'days') : initialStartDate);
  const [extractItems, setExtractItem] = useState([]);
  const [analyticFilter, setAnalyticFilter] = useState(true);
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });

  useEffect(() => {
    setAnalytic(analyticFilter)
  }, [analyticFilter])
  
  const [modalSettings, setModalSettings] = useState([
    {
      id: 1,
      name: 'updateDiscard',
      open: false,
      fullScreen: null,
      maxWidth: 'sm',
      discard: '',
      product: '',
      date: ''
    },
    {
      id: 2,
      name: 'confirmDialog',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      currentUser: null
    },
    {
      id: 3,
      name: 'drillDownDialog',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      currentUser: null
    }
  ]);

  const [filtersExtractModal, setFiltersExtractModal] = useState(
    () => [
      {
        param: 'type',
        value: extractType || '',
        urlParam: `type=${extractType || ''}`
      },
      {
        param: 'date',
        value: moment(extractStartDate).format('YYYY-MM-DD'),
        urlParam: `date=${moment(extractStartDate).format('YYYY-MM-DD')}`
      },
      {
        param: 'dateEnd',
        value: moment(extractEndDate).format('YYYY-MM-DD'),
        urlParam: `dateEnd=${moment(extractEndDate).format('YYYY-MM-DD')}`
      },
      {
        param: 'discardId',
        value: discardId || '',
        urlParam: `discardId=${discardId || ''}`
      }
    ],
    [extractEndDate, extractStartDate]
  );

  const setFinalValues = (obj, product) => {
    const { extracts } = obj;
    const productParameters = product?.parameters || 'LAST_PURCHASE';
    const filteredExtracts = extracts.filter(
      (extract) =>
        !(
          extract.type === 'STOCK' &&
          moment(extract.date).isSame(moment().subtract(1, 'day'), 'day')
        )
    ); 

    const realExtracts = filteredExtracts.map((extract) => {
      const { quantity } = extract;
      const expressedQuantity =
        quantity *
        (extract.type === 'SALES' ||
        extract.type === 'DISCARD' ||
        extract.type === 'CONSUMED_PRODUCTION' ||
        extract.type === 'RETURNED'
          ? -1
          : 1);
      const value = parseFloat(extract?.cost) * expressedQuantity;

      const diffPercentCalc = (100 * parseFloat(extract?.quantity)) / (extract?.product?.yield || 1);
      const diffPercentResult = diffPercentCalc - 100;

      return {
        ...extract,
        diffPercent: extract?.type === "PRODUCTION_PRODUCED" ? extract?.totalLossPercentage : diffPercentResult,
        variationPercentage: extract?.product?.variationPercentage,
        quantity: expressedQuantity,
        value
      };
    });

    const extractsWithFinalValues = realExtracts.map((extract, index) => {
      if (index) {
        const subArray = realExtracts
          .filter((ext, currentIndex) => currentIndex <= index)
          .reverse();
        const previousCountExtract = subArray.find(
          (ext) => ext.type === 'COUNT'
        );
        const previousCountExtractIndex = previousCountExtract
          ? realExtracts.indexOf(previousCountExtract)
          : -1;
        const extractsAfterCount = realExtracts.filter(
          (item, currentIndex) =>
            currentIndex >= previousCountExtractIndex && currentIndex <= index
        );

        if (previousCountExtractIndex > -1) {
          return {
            ...extract,
            finalQuantity: extractsAfterCount.reduce(
              (total, item, currentIndex) =>
                currentIndex <= index
                  ? item.type === "TRANSFER_OUT" ? parseFloat(total) - parseFloat(item.quantity) : parseFloat(item.quantity) + parseFloat(total)
                  : total,
              0
            ),
            finalValue: extractsAfterCount.reduce(
              (total, item, currentIndex) =>
                currentIndex <= index
                  ? item.type === "TRANSFER_OUT" ? parseFloat(item.value) - parseFloat(total) : parseFloat(item.value) + parseFloat(total)
                  : total,
              0
            )
          };
        }
        return {
          ...extract,
          finalQuantity: realExtracts.reduce(
            (total, item, currentIndex) =>
              currentIndex <= index
                ? item.type === "TRANSFER_OUT" ? parseFloat(total) - parseFloat(item.quantity) : parseFloat(item.quantity) + parseFloat(total)
                : total,
            0
          ),
          finalValue: realExtracts.reduce(
            (total, item, currentIndex) =>
              currentIndex <= index
                ? item.type === "TRANSFER_OUT" ? parseFloat(total) - parseFloat(item.value)  : parseFloat(item.value) + parseFloat(total)
                : total,
            0
          )
        };
      }
      return extract;
    });

    const allCountExtracts = extractsWithFinalValues.filter(
      (ext) => ext.type === 'COUNT'
    );

    allCountExtracts.forEach((extract) => {
      const countExtractIndex = extractsWithFinalValues.indexOf(extract);

      if (countExtractIndex > -1) {
        const previousFinalQuantity =
          extractsWithFinalValues[countExtractIndex - 1]?.finalQuantity;
        const difference =
          parseFloat(extract?.quantity) -
          parseFloat(previousFinalQuantity);

        const item = {
          averageCost: 0,
          cardsId: null,
          date: extract.date,
          lastCost: 0,
          productId: product.id,
          product: extract?.product,
          quantity: difference,
          salesPrice: 0,
          salesQuantity: 0,
          type: 'Diferença de estoque',
          finalQuantity: extract.finalQuantity,
          value: parseFloat(difference) * parseFloat(extract?.cost),
          diffPercent: extract?.product?.historical?.some((el) => el?.consumed)
          ? parseFloat(
              (parseFloat(difference) * 100) /
                extract?.product?.historical[0]?.consumed
            )
          : '',
          finalValue: extract.finalValue
        };
        extractsWithFinalValues.splice(countExtractIndex, 0, item);
      }

      const splitArrayByCount = (array) => {
        const resultArrays = []
        let currentArray = []
      
        for (const arr of array) {
          currentArray.push(arr)
      
          if (arr.type === "COUNT") {
            resultArrays.push([...currentArray])
            currentArray = []
          }
        }
      
        if (currentArray.length > 0) {
          resultArrays.push([...currentArray])
        }
      
        return resultArrays
      }

      if (extract.type === 'COUNT') {
        const currentArray = splitArrayByCount(extractsWithFinalValues)?.find(el => el?.map(item => item?.date).includes(extract?.date))

        const totalExtractsCostCalc = currentArray?.map(el => el.quantity * el.cost).reduce(
          (total, item) =>
            item
              ? parseFloat(item) + parseFloat(total)
              : total,
          0
        )

        return {
          ...extract,
          finalQuantity: extract.quantity,
          finalValue: extract.value,
          value: totalExtractsCostCalc
        };
      }
    });

    return extractsWithFinalValues;
  };

  const handleFirstLine = (obj, product, date, typeParam) => {
    const productParameters = product?.parameters || 'LAST_PURCHASE';
    const cost = obj?.cost
      ? obj?.cost 
      : product.cost
        ? product.cost
        : productParameters
          ? product.lastCost || product.lastCostStock
          : product.averageCost || product.averageCostStock;
    const lastOrAverageCostStockValue = parseFloat(cost);

    if (typeParam === '' || typeParam === 'ALL') {
      const countInInitialDateIndex = obj.extracts[0]?.type === 'COUNT';

      if (countInInitialDateIndex) {
        obj.extracts.splice(0, 1);
      }

      const stock =
        obj.realStock !== null && obj.realStock !== undefined
          ? obj.realStock
          : obj.theoreticalStock || 0;

      const valueAndFinalValueCalc =
        parseFloat(stock) * lastOrAverageCostStockValue;

      const item = {
        averageCost: 0,
        cardsId: null,
        date,
        lastCost: 0,
        cost,
        productId: product.id,
        quantity: stock,
        salesPrice: 0,
        salesQuantity: 0,
        type:
          obj.realStock !== null && obj.realStock !== undefined
            ? 'Fechamento do dia'
            : 'Estoque teórico',
        finalQuantity: stock,
        value: valueAndFinalValueCalc,
        finalValue: valueAndFinalValueCalc
      };

      obj.extracts.splice(0, 0, item);
    } else {
      obj.extracts[0].value =
        parseFloat(obj.extracts[0].quantity) * lastOrAverageCostStockValue;
    }
  };

  const handleLastLine = (extracts, typeParam) => {
    if (typeParam === '' || typeParam === 'ALL') {
      const lastIndex = extracts.length - 1;
      const lastExtract = extracts[lastIndex];
      if (lastExtract.type !== 'COUNT') {
        const item = {
          averageCost: 0,
          cardsId: null,
          date: lastExtract.date,
          lastCost: 0,
          productId: lastExtract.productId,
          quantity: lastExtract.finalQuantity,
          salesPrice: 0,
          salesQuantity: 0,
          type: lastExtract.type === 'Fechamento do dia' ? 'Fechamento do dia' : 'Estoque teórico',
          finalQuantity: lastExtract.finalQuantity,
          value: lastExtract.finalValue,
          finalValue: lastExtract.finalValue
        };
        extracts.push(item);
      }
    }
  };

  const getExtracts = useCallback(
    (product) => {
      (async () => {
        setIsLoading(true);
        const params = filtersExtractModal
          .map((filter) => filter.urlParam)
          .join('&');

        const originCardIdParam = product?.originIdCard ? `&cardId=${product?.originIdCard}` : ''

        const response = await fetch(
          `${environments.catalogV2}/extracts?originId=${getId}&productId=${product?.id}&allExtracts=true${originCardIdParam}&${params}`
        );
        const responseJson = await response.json();

        if (responseJson?.extracts?.length) {
          const extracts = responseJson?.extracts
          const hasOnlyOneCountExtract = extracts?.length === 1 && extracts[0]?.type === "COUNT"

          const date =
            hasOnlyOneCountExtract ? extracts[0]?.date : filtersExtractModal.find((filter) => filter.param === 'date')
              ?.value || new Date();

          const typeParam =
            filtersExtractModal.find((filter) => filter.param === 'type')
              ?.value || '';

          handleFirstLine(responseJson, product, date, typeParam);

          const extractsWithFinalValues = setFinalValues(responseJson, product);
          
          if (!hasOnlyOneCountExtract) {
            handleLastLine(extractsWithFinalValues, typeParam);
          }

          let parentId = null;

          const finalObj = extractsWithFinalValues.map((extract, index) => {
            const isHeader =
              extract.type === 'Estoque teórico' ||
              extract.type === 'Fechamento do dia' ||
              extract.type === 'COUNT';

            if (index === 0 || index === extractsWithFinalValues?.length - 1) {
              parentId = `parent-${index + 1}`;

              return {
                ...extract,
                isParent: parentId
              };
            }

            return {
              ...extract,
              isChildren: true,
              parentItem: parentId,
              hideItem: true
            };
          });

          setStartDate(extractStartDate)
          setEndDate(extractEndDate)
          setRangeDateItems(finalObj)
          setExtractItem(finalObj);
        } else {
          setExtractItem([]);
          setIsLoading(false);
        }
        setIsLoading(false);
      })();
    },
    [filtersExtractModal, getId]
  );

  const updateDiscardFetch = (body, isDelete) =>{
    if (isDelete) {
      axios
        .delete(
          `${environments.catalogV2}/extracts/${body.extractId}?originId=${getId}`,
          { data: body }
        )
        .then(() => {
          setMessage({
            description: 'Descarte excluido com sucesso.',
            status: 'success'
          });

          setModalSettings((modal) =>
            modal.map((item) =>
              item.name === 'updateDiscard'
                ? {
                    ...item,
                    open: false
                  }
                : item
            )
          );

          getExtracts(productModal);
        })
        .catch((error) => {
          console.log(error);
          setMessage({
            description: 'Erro ao excluir descarte.',
            status: 'error'
          });
        });
    } else {
      axios
        .put(
          `${environments.catalogV2}/extracts/${body.extractId}?originId=${getId}`,
          body
        )
        .then(() => {
          setMessage({
            description: 'Descarte atualizado com sucesso.',
            status: 'success'
          });

          setModalSettings((modal) =>
            modal.map((item) =>
              item.name === 'updateDiscard'
                ? {
                    ...item,
                    open: false
                  }
                : item
            )
          );

          getExtracts(productModal);
        })
        .catch((error) => {
          console.log(error);
          setMessage({
            description: 'Erro ao atualizar descarte.',
            status: 'error'
          });
        });
    }
  }

  const handleUpdateDiscard = (obj, isDelete = false) => {
    if(Array.isArray(obj)){
      obj.map(itemObj => {
        const body = {
          extractId: itemObj?.staticID || itemObj?.extract?.id,
          discardId: itemObj?.discard?.id,
          productId: itemObj?.product?.id,
          quantity: itemObj?.quantity,
          originId: getId,
          user: user?.user
        };

        updateDiscardFetch(body, isDelete);
      })
    } else {
      const body = {
        extractId: obj?.staticID || obj?.extract?.id,
        discardId: obj?.discard?.id,
        productId: obj?.product?.id,
        quantity: obj?.quantity,
        originId: getId,
        user: user?.user
      };

      updateDiscardFetch(body, isDelete);
    }
  };

  const handleAutocompleteChange = useCallback(
    (_, value) => {
      const currentSelectedProduct = autocompleteOptions.find(
        (option) => option?.id === value?.id
      );

      if (value){
        getExtracts(currentSelectedProduct);
        setFilterSelectedProduct(value);
        setProductModal(value);
      } else {
        setExtractItem([]);
      }
    },
    [autocompleteOptions, getExtracts]
  );

  useEffect(() => {
    if (modalProduct) {
      getExtracts(productModal);
    }
  }, [filtersExtractModal, modalProduct, productModal]);

  useEffect(() => {
    setExtractItem((prevState) =>
      prevState.map((el) => ({
        ...el,
        opened: analyticFilter
      }))
    );
  }, [analyticFilter]);

  return (
    <>
      <Dialog
        fullWidth
        maxWidth={false}
        open={modalProduct}
        onClose={() => handleClose('modalProduct')}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="defaultModal extractModal"
        style={{
          zIndex:
            modalStates.modalCards || modalStates.modalTechnicalSheet
              ? 1299
              : 1300
        }}
        transitionDuration={0}
      >
        <DialogTitle
          className={`modalHeader bold textAlignCenter cursorMove ${
            !extractStartDate && 'navyBlue'
          }`}
          id="draggable-dialog-title"
        >
          <Typography>DETALHAMENTO DE ESTOQUE</Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => handleClose('modalProduct')}
          >
            <img
              style={{ width: '16px', height: '16px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent
          className={`modalContent ${extractStartDate ? 'p16' : 'p0 pb10'}`}
          style={{ background: extractStartDate ? '#F6F6F8' : '#FFF' }}
        >
          <TheoryStockMenu
            extractStartDate={extractStartDate}
            extractEndDate={extractEndDate}
            extractType={extractType}
            filtersExtractModal={filtersExtractModal}
            productModal={productModal}
            setFiltersExtractModal={setFiltersExtractModal}
            setExtractStartDate={setExtractStartDate}
            setCardsRelatedInitialDate={setCardsRelatedInitialDate}
            setExtractEndDate={setExtractEndDate}
            setExtractType={setExtractType}
            getExtracts={getExtracts}
            analyticFilter={analyticFilter}
            setAnalyticFilter={setAnalyticFilter}
            productExtractDescription={productExtractDescription}
            productAge={productAge}
            autocompleteOptions={autocompleteOptions}
            setAutocompleteOptions={setAutocompleteOptions}
            user={user}
            getId={getId}
            handleAutocompleteChange={handleAutocompleteChange}
            filterSelectedProduct={filterSelectedProduct}
            autocompleteDescription={autocompleteDescription}
            setAutocompleteDescription={setAutocompleteDescription}
          />

          <div
            className={`dFlex flexColumn pt0 ${
              extractStartDate && 'cardTable'
            }`}
          >
            <table className="realStock noBoxStyle">
              <TheoryStockHead extractItems={extractItems} />

              {isLoading ? (
                <td colSpan="7">
                  <LinearProgress
                    style={{ height: '7px' }}
                    id="loading"
                    variant="query"
                  />
                </td>
              ) : (
                <TheoryStockBody
                  analyticFilter={analyticFilter}
                  checkConsume={checkConsume}
                  setAnalyticFilter={setAnalyticFilter}
                  setExtractItem={setExtractItem}
                  extractItems={extractItems}
                  fetchCards={fetchCards}
                  modalStates={modalStates}
                  productModal={productModal}
                  extractDateAndDescription={extractDateAndDescription}
                  extractType={extractType}
                  parameters={parameters}
                  handleGetDiffs={handleGetDiffs}
                  differenceInExtractTooltip={differenceInExtractTooltip}
                  setDifferenceInExtractTooltip={setDifferenceInExtractTooltip}
                  getId={getId}
                  extractStartDate={cardsRelatedInitialDate || extractStartDate}
                  extractEndDate={extractEndDate}
                  modalSettings={modalSettings}
                  setModalSettings={setModalSettings}
                  setDrillDownInfo={setDrillDownInfo}
                  drilldownInfo={drilldownInfo}
                  setSelectedItem={setSelectedItem}
                />
              )}
            </table>
          </div>
        </DialogContent>
      </Dialog>

      {modalSettings.find((modal) => modal.name === 'updateDiscard').open && (
        <UpdateDiscard
          fullScreen={
            modalSettings.find((modal) => modal.name === 'updateDiscard')
              .fullScreen
          }
          extractItems={extractItems}
          maxWidth={
            modalSettings.find((modal) => modal.name === 'updateDiscard')
              .maxWidth
          }
          open={
            modalSettings.find((modal) => modal.name === 'updateDiscard').open
          }
          onSubmit={handleUpdateDiscard}
          product={
            modalSettings.find((modal) => modal.name === 'updateDiscard')
              .product
          }
          discard={
            modalSettings.find((modal) => modal.name === 'updateDiscard')
              .discard
          }
          date={
            modalSettings.find((modal) => modal.name === 'updateDiscard').date
          }
          quantity={
            modalSettings.find((modal) => modal.name === 'updateDiscard')
              .quantity
          }
          unity={
            modalSettings.find((modal) => modal.name === 'updateDiscard').unity
          }
          extract={
            modalSettings.find((modal) => modal.name === 'updateDiscard')
              .extract
          }
          modalSettings={modalSettings}
          setModalSettings={setModalSettings}
          onClose={() => {
            setModalSettings((modal) =>
              modal.map((item) =>
                item.name === 'updateDiscard'
                  ? {
                      ...item,
                      open: false
                    }
                  : item
              )
            );
          }}
        />
      )}

      {modalSettings.find((modal) => modal.name === 'confirmDialog')?.open && (
        <ConfirmModal
          open={
            modalSettings.find((modal) => modal.name === 'confirmDialog').open
          }
          title={
            modalSettings.find((modal) => modal.name === 'confirmDialog')?.title
          }
          content={
            <Typography className="bold">
              Deseja realmente excluir esse descarte?
            </Typography>
          }
          onClose={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'confirmDialog'
                  ? {
                      ...modal,
                      open: false,
                      currentUser: null,
                      title: '',
                      body: ''
                    }
                  : modal
              )
            );
          }}
          cancelText="Não"
          onConfirm={() => {
            const bodyUpdated = {
              ...modalSettings.find((modal) => modal.name === 'confirmDialog')
                ?.body,
              quantity: 0
            };

            handleUpdateDiscard(bodyUpdated, true);

            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'confirmDialog'
                  ? {
                      ...modal,
                      open: false,
                      currentUser: null,
                      title: '',
                      body: ''
                    }
                  : modal
              )
            );
          }}
          confirmText="Sim"
        />
      )}

      {modalSettings.find((modal) => modal.name === 'drillDownDialog')?.open && (
        <DrillDownDialog
          openModal={modalSettings}
          handleClose={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'drillDownDialog'
                  ? {
                      ...modal,
                      open: false,
                      currentUser: null,
                      title: '',
                      body: ''
                    }
                  : modal
              )
            );
          }}
          info={drilldownInfo}
          productModal={productModal}
          getId={getId}
        />
      )}

      <Snackbar
        open={!!message.description}
        autoHideDuration={2000}
        onClose={() => setMessage({ description: '', status: '' })}
      >
        <Alert
          onClose={() => setMessage({ description: '', status: '' })}
          severity={message.status}
          color={message.status}
        >
          {message.description}
        </Alert>
      </Snackbar>
    </>
  );
}

export default TheoryStockDialog;
