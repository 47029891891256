import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography,
  Paper,
  FormControlLabel,
  Switch,
  LinearProgress
} from '@material-ui/core';
import format from 'date-fns/format';
import Draggable from 'react-draggable';

import CheckBoxIconEmpty from 'images/icons/empties/checkboxIconEmpty.svg';
import selectAllEmpty from 'images/icons/empties/selectAllIconEmpty.svg';
import CheckBoxIcon from 'images/icons/signals/checks/checkboxIcon.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import selectAll from 'images/icons/signals/removesAndDeletes/selectAllIcon.svg';
import makePut from 'services/useFetch/makePut';
import { ShortenStringTooltip } from 'utils/shortenString';

import {
  PackageTop,
  SelectAllItem,
  PackageListItem,
  SaveButtonWrapper,
  SaveButton
} from './index.style';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function ConfigPackageDialog({
  openModal,
  handleClose,
  configPackageSkuItems,
  countDate,
  getId,
  internalProducts,
  setInternalProduct,
  setToastOpen,
  setToastProps,
  setupId,
  mobileBehavior = false,
  getMainList,
}) {
  const { modalConfigPackage } = openModal;
  const currentProduct = internalProducts.find(
    (product) => product.id === configPackageSkuItems[0].productId
  )
  const [selectAllItems, setSelectAllItems] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ignoreBrands, setIgnoreBrands] = useState(currentProduct?.ignoreBrands);
  const [hasChangedIgnoreBrands, setHasChangedIgnoreBrands] = useState(false);
  const [packageList, setPackageList] = useState(() =>
    Array.from(
      new Set(configPackageSkuItems.map((item) => JSON.stringify(item)))
    ).map((item) => JSON.parse(item))
  );
  
  const handleAllItemsSelect = () => {
    const newPackageList = packageList.map((packageItem, index) => {
      if (index === 0) {
        packageItem.showOnCount = true;
        return packageItem;
      }

      if (!packageItem.canChangeSelection) {
        return packageItem;
      }

      packageItem.showOnCount = !selectAllItems;
      return packageItem;
    });
    setPackageList(newPackageList);
    setSelectAllItems(!selectAllItems);
  };

  const handleSelectItem = (providerId, isAdditional) => {
    const newPackageList = packageList.map((packageItem) => {
      if (
        packageItem.providerId === providerId &&
        packageItem.isAdditional === isAdditional
      ) {
        packageItem.showOnCount = !packageItem.showOnCount;
      }
      return packageItem;
    });

    const findUnSelectItens = newPackageList.filter((packageItem) => {
      if (!packageItem.showOnCount) {
        return packageItem;
      }
    });

    const findSelectItens = newPackageList.filter((packageItem) => {
      if (packageItem.showOnCount) {
        return packageItem;
      }
    });

    if (findSelectItens.length === newPackageList.length) {
      setSelectAllItems(true);
    }
    if (findSelectItens.length !== newPackageList.length) {
      setSelectAllItems(false);
    }

    if (findUnSelectItens.length === newPackageList.length) {
      newPackageList[0].showOnCount = true;
      setPackageList(newPackageList);
      return;
    }

    setPackageList(newPackageList);
  };

  const setBody = (packagesList) => {
    const packs = packagesList.filter((pack) => !pack.isAdditional);
    const additionals = packagesList.filter((pack) => pack.isAdditional);

    return packs.map((pack) => {
      const { productId, providerId, skuId, showOnCount, isFake } = pack;
      const [additional = []] = additionals.filter(
        (additional) =>
          additional.productId === productId &&
          additional.providerId === providerId &&
          additional.skuId === skuId
      );
      const showAdditionalOnCount =
        additional instanceof Array ? true : additional.showOnCount;

      return {
        productId,
        skuId,
        providerId,
        restaurantId: getId,
        isFake,
        date: format(new Date(countDate), 'yyyy-MM-dd'),
        showOnCount,
        showAdditionalOnCount,
        setupId
      };
    });
  };

  const handleSaveAction = () => {
    setLoading(true)

    const body = {
      brands: {
        productId: currentProduct?.id,
        restaurantId: getId,
        setupId,
        ignore: ignoreBrands
      },
      packages: setBody(packageList)
    }

    const handleResponsePost = (status) => {
      if (mobileBehavior) {
        mobileBehavior();
        handleClose();
        return;
      }

      let newArr = []

      const formattedProducts = 
      internalProducts &&
      internalProducts.length &&
      internalProducts?.map((product, i) => {
        if (product.id === body?.packages[0].productId) {
          return {
            ...product,
            ignoreBrands,
            fakeAdditional: body.packages?.find(el => el?.isFake)?.showOnCount,
            skus: product.skus.map((sku, i) => {
              const providers = sku.providers.map((provider, i) => {
                const packageFormatted = `E${provider?.quantity || 0}x${sku?.quantity || 0}${sku?.unitsMeasurements?.abbreviation || ''}` 
                const showOnCount = body?.packages?.find(
                  (item) =>
                    item.skuId === sku.id &&
                    item.providerId === provider.id
                )?.showOnCount
                const showAdditionalOnCount = body?.packages?.find(
                  (item) =>
                    item.skuId === sku.id &&
                    item.providerId === provider.id
                )?.showAdditionalOnCount
                const hidePackage = packageList?.filter(el => el?.package === packageFormatted)?.every(item => item?.showOnCount)
                
                if (ignoreBrands && newArr?.includes(packageFormatted)) {
                  return {
                    ...provider,
                    ignoreBrands,
                    showOnCount: ignoreBrands || showOnCount,
                    showAdditionalOnCount: ignoreBrands || showAdditionalOnCount
                  };
                }

                if (hidePackage) {
                  newArr = [...newArr, packageFormatted]
                }

                return {
                  ...provider,
                  ignoreBrands: ignoreBrands ? !ignoreBrands : ignoreBrands,
                  showOnCount: hasChangedIgnoreBrands ? provider?.showOnCount : showOnCount,
                  showAdditionalOnCount: hasChangedIgnoreBrands ? provider?.showAdditionalOnCount : showAdditionalOnCount 
                };
              });

              return {
                ...sku,
                providers
              };
            })
          };
        }
        return {
          ...product,
        };
      })

      setInternalProduct(
        formattedProducts
      );

      if (status) {
        setToastProps({
          severity: 'success',
          message: 'Embalagens salvas com sucesso',
          timeOutOff: false
        });
        setToastOpen(true);
        setLoading(false)
        handleClose('modalConfigPackage');
        // getMainList()
        return;
      }

      setToastProps({
        severity: 'error',
        message: 'Erro ao salvar',
        timeOutOff: false
      });
      setToastOpen(true);
      setLoading(false)
      handleClose('modalConfigPackage');
    };

    makePut('saveDisplaysettings', body, handleResponsePost);
  };

  useEffect(() => {
    const findSelectItens = packageList.filter((packageItem) => {
      if (packageItem.showOnCount) {
        return packageItem;
      }
    });

    if (findSelectItens.length === packageList.length) {
      setSelectAllItems(true);
    }
  }, [packageList]);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={modalConfigPackage}
      onClose={() => {
        handleClose('modalConfigPackage');
      }}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      className="defaultModal"
      style={mobileBehavior ? { width: '320px' } : { width: '580px' }}
      transitionDuration={0}
    >
      <DialogTitle
        className="modalHeader navyBlue cursorMove bold textAlignCenter"
        id="draggable-dialog-title"
      >
        <Typography>
          {mobileBehavior
            ? internalProducts?.description
            : currentProduct?.description}
        </Typography>

        <FormControlLabel
          className="toggleDefaultProcess withWhiteBorder m0 dFlex"
          control={
            <Switch
              checked={ignoreBrands}
              onChange={() => {
                setIgnoreBrands(!ignoreBrands)
                setHasChangedIgnoreBrands(true)
              }}
              name="Ignore Brands on Count"
            />
          }
          label="Contar desconsiderando marcas"
        />

        <IconButton
          edge="start"
          color="inherit"
          onClick={() => handleClose('modalConfigPackage')}
        >
          <img
            style={{ width: '16px', height: '16px' }}
            src={CloseIconNavy}
            alt="CloseIconNavy"
          />
        </IconButton>
      </DialogTitle>

      <div>
        {loading ?
          <LinearProgress
          style={{ height: '5px' }}
          id="loading"
          variant="query"
          /> 
        : null}

        {packageList?.length ?
          <>
            <PackageTop>
              <div>
                <SelectAllItem
                  onClick={handleAllItemsSelect}
                  src={selectAllItems ? selectAll : selectAllEmpty}
                />
                SKU
              </div>

              <div>EMBALAGEM</div>
            </PackageTop>

            {packageList?.map((packageItem) => (
              <PackageListItem>
                <div>
                  {packageItem.canChangeSelection ? (
                    <SelectAllItem
                      onClick={() =>
                        handleSelectItem(
                          packageItem.providerId,
                          packageItem.isAdditional
                        )
                      }
                      src={
                        packageItem.showOnCount ? CheckBoxIcon : CheckBoxIconEmpty
                      }
                    />
                  ) : (
                    <Tooltip title="Não é possível desabilitar esta embalagem">
                      <SelectAllItem
                        src={
                          packageItem.showOnCount ? CheckBoxIcon : CheckBoxIconEmpty
                        }
                      />
                    </Tooltip>
                  )}

                  <ShortenStringTooltip
                    string={packageItem.description}
                    length={35}
                  />
                </div>

                <div>{packageItem.package}</div>
              </PackageListItem>
            ))}
          </>
        :
          <PackageListItem>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', fontWeight: 'bold'}}> 
              Não existem variações de embalagens para serem administradas
            </div>
          </PackageListItem>
        }

        <SaveButtonWrapper>
          <SaveButton className={`${loading ? 'backButton' : 'submitButton'}`} disabled={loading} onClick={handleSaveAction}>
            Salvar
          </SaveButton>
        </SaveButtonWrapper>
      </div>
    </Dialog>
  );
}

export default ConfigPackageDialog;
