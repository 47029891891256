/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  Paper,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import axios from 'axios';
import format from 'date-fns/format';
import Draggable from 'react-draggable';
import { useParams } from 'react-router';

import ImageWrapper from 'components/ImageWrapper';
import CurrencyTextFieldSumeValues from 'components/Inputs/CurrencyTextFieldSumeValues';
import InputNumber from 'components/Inputs/ManufactureInputs/InputNumber';
import RefreshWhiteIcon from 'images/icons/arrows/refreshWhite.svg';
import selectorInputArrow from 'images/icons/arrows/selectorInputArrow.svg';
import EngineIconWhite from 'images/icons/configsAndSetups/configIconWhite.svg';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import AddDisabledIcon from 'images/icons/signals/addsAndCreates/addDisabled.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import Environment from 'utils/environments';
import { increaseDate } from 'utils/formatDate';
import numberToReal from 'utils/numberToReal';
import ValidationLength from 'utils/utils';

import {
  ModalContent,
  CardInfoHeaderDrillDownContent,
  CardIngredientsContainer,
  ArrowDown
} from './style';

import { productionCardStatus } from '../../utils';
import CardIngredients from '../CardIngredients/index';
import { CardIngredientsDrillDownHead } from '../CardIngredients/style';
import {
  CardInfoHeader,
  CardInfoContent,
  CardContentTitle,
  CardContentElement,
  ProductionPlay,
  CardsIngredientsWrapper,
  IngredientLossWrapper,
  IngredientCount,
  LossCount
} from '../style';
import { setPopoverDataStatus } from '../utils';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function MobileCardDetail({
  handleItemDiscard,
  forceEnable,
  setModal,
  mainModalStates,
  updateQuantityInitialValue,
  manufactures,
  setManufactures,
  noActionMode = false,
  transformationInfos,
  index,
  description,
  manufactureYield,
  newDuration,
  limiteProd,
  manufactureQuantity,
  manufactureTotalYield,
  manufactureStatus,
  dateStatus,
  items,
  actions,
  manufacture,
  optionsDiscard,
  manufactureStock,
  manufactureDuration,
  lossManufactured,
  manufactured,
  totalItemsQuantity,
  cardYield,
  type,
  income,
  setCardDate,
  setCheckSumeItems,
  setCurrentTransformationCardItems
}) {
  const StatusIcon = productionCardStatus[manufactureStatus]?.iconFull;
  const [updateValue, setUpdateValue] = useState(false);
  const [openDrillDown, setOpenDrillDown] = useState(false);
  const isPhone = useMediaQuery('(max-width: 767px)');
  const { id: paramsID } = useParams();
  const environments = Environment(process.env.REACT_APP_ENV);
  const fullWidth = true;
  const fixedInitialYield = (
    manufacture?.initialYield || income
  )?.toLocaleString('pt-br', { minimumFractionDigits: 3 });
  const maxWidth = 'sm';
  const [durationData, setDurationData] = useState({
    product: {},
    data: {},
    type: '',
    newDuration: false
  });
  const [modalStates, setModalStates] = useState({
    modalYieldLabelQuantity: false,
    modalManufactureDuration: false
  });

  useEffect(() => {
    setTimeout(() => {
      if (
        modalStates.modalYieldLabelQuantity &&
        document.querySelector('.sumeValuesProductionDialog input')
      ) {
        document.querySelector(`.sumeValuesProductionDialog input`).select();
      }
    }, 100);
  }, [modalStates.modalYieldLabelQuantity]);

  const handleCloseModal = () => {
    setModalStates({
      modalYieldLabelQuantity: false,
      modalInvalidQuantity: false,
      modalManufactureDuration: false
    });
  };

  const getDuration = (productId, stock, product, type, newDuration) => {
    axios
      .get(
        `${environments.engine}/rupture?productId=${productId}&originId=${paramsID}&days=8&stock=${stock}`
      )
      .then((response) => {
        const responseItems = response.data;
        setDurationData({
          product,
          data: responseItems,
          type,
          newDuration
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const replaceDayDurationData = (data) => {
    if (data === 'D0') return 'Hoje';
    return data;
  };

  const handleStatusButton = (e, backStep = false) => {
    const { statusButton } = actions;
    const { type, status } = manufacture;

    setCardDate(manufacture.date);

    if (backStep) {
      actions.handleUpdate(manufacture, backStep);
      return;
    }

    if (status === 'WAITING' || status === 'PAUSE') {
      setTimeout(() => {
        actions.handleUpdate(manufacture, 'START');
      }, 100);
    } else {
      setCurrentTransformationCardItems({
        ...manufacture,
        items: manufacture?.items?.map((el) => ({
          ...el,
          quantity: null
        }))
      });
      statusButton.setManufacture(manufacture);
      statusButton.setPopoverData(setPopoverDataStatus(type, status));
      statusButton.handlePopoverClick(e);
      type === 'TRANSFORMATION' && setCheckSumeItems(true);
    }
  };

  const handleDiscardInfo = () => {
    const { statusButton } = actions;
    statusButton.setManufacture(manufacture);
  };

  const handleQuantity = (value) => {
    actions.handleCardQuantity(
      index,
      parseFloat(value?.replace(',', '.')),
      undefined,
      undefined,
      undefined,
      '',
      '',
      '',
      noActionMode ? manufacture : ''
    );
  };

  const handleYield = (value) => {
    actions.handleCardQuantity(
      index,
      manufactureQuantity,
      undefined,
      undefined,
      parseFloat(value.replace(',', '.'))
    );
  };

  const inputDisabledStatus = noActionMode ? false : manufactureStatus !== 'WAITING' && !forceEnable;

  const total = manufacture.items.reduce(
    (total, value) =>
      value
        ? parseFloat(total) + parseFloat(value.quantity)
        : parseFloat(total),
    0
  );
  const cardQuantity = manufacture.quantity || 1;

  return (
    <ModalContent>
      <div className="dFlex pRelative" style={{ boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.5)', zIndex: 999 }}>
        <div id="draggable-dialog-card-production-dialog" style={{ width: type === 'RECIPE' ? "90%" : '100%' }}>
          <div className="header">
            <div
              className="dFlex justifyBetween"
              style={{
                borderBottom: 'solid 2px #FFF',
                boxShadow: '0px 4px 4px #000000'
              }}
            >
              <div
                className="dFlex justifyBetween alignStart"
                style={{
                  width: '100%',
                  background: '#1F2445',
                  padding: 8
                }}
              >
                <h5
                  className="mb0 white bold"
                  wrap="hard"
                  style={{
                    width: isPhone && '170px',
                    fontSize: isPhone ? '12px' : '22px' 
                  }}
                >
                  {description}
                </h5>
              </div>
            </div>
          </div>

          <div
            className="dFlex justifyBetween"
            style={{
              width: '100%',
              padding: '3px 8px',
              margin: '0',
              background: '#1F2445'
            }}
          >
            <div className="dFlex alignCenter">
              <span
                className="bold"
                style={{
                  fontSize: isPhone ? 9 : 13,
                  color: '#FFF',
                  lineHeight: '1'
                }}
              >
                DATA PRODUÇÃO
              </span>

              <span
                className="bold white ml10"
                style={{ fontSize: isPhone ? 12 :20 }}
              >
                {increaseDate(manufacture.date)}
              </span>
            </div>

            <div className="dFlex alignCenter">
              <span
                className="bold"
                style={{
                  fontSize: isPhone ? 9 : 13,
                  color: '#FFF',
                  lineHeight: '1'
                }}
              >
                HORÁRIO LIMITE
              </span>

              <span
                className="bold white ml10"
                style={{ fontSize: isPhone ? 12 : 22 }}
              >
                {manufacture.limit
                  ? format(new Date(manufacture.limit), 'HH:m')
                  : '-'}
              </span>
            </div>
          </div>

          <div
            className="subHeader dFlex justifyBetween pRelative"
            style={{
              padding: '3px 0px 3px 8px',
              background: type === 'TRANSFORMATION' ? '#F2B90D' : '#1D8FC9',
              zIndex: 99
            }}
          >
            <p
              style={{
                fontSize: isPhone ? 12 : 22,
                color: '#FFF'
              }}
              className="mb0 bold uppercase withStroke"
            >
              {type === 'RECIPE' ? `PRODUZIR:` : 'TRANSFORMAR:'}
            </p>

            {manufacture?.origin === 'S' && (
              <img
                src={EngineIconWhite}
                alt={EngineIconWhite}
                style={{ width: '24px', margin: '0 7px 0 auto' }}
              />
            )}

            <div
              className="dFlex justifyCenter alignCenter mr8"
              style={{
                width: 128,
                background: type === 'RECIPE' ? '#1D8FC9' : '#F2B90D',
                border: '1px solid #000000'
              }}
            >
              <p
                style={{
                  fontSize: isPhone ? 12 : type === 'RECIPE' ? 20 : 13
                }}
                className="mb0 bold uppercase white withStroke"
              >
                {type === 'RECIPE' ? `RECEITA` : 'TRANSFORMAÇÃO'}
              </p>
            </div>
          </div>
        </div>
        
        {type === 'RECIPE' &&
          <ImageWrapper
            width="135px"
            height="135px"
            url={manufacture?.image}
            customClass="productionImagePopover"
            placementMain="center"
            placement="center"
          />
        }
      </div>

      <div
        className="pRelative"
        style={{
          marginBottom: '-8px',
          background: type === 'RECIPE' ? '#D9EAF2' : '#FFF2CC'
        }}
      >
        <div
          className="dFlex alignCenter"
          style={{ padding: `12px ${isPhone ? '0' : '30px'} 12px 8px` }}
        >
          {type === 'RECIPE' ? (
            <CardIngredientsDrillDownHead
              justifyContent="initial"
              width={isPhone ? '50%' : '49%'}
            >
              <span
                className="qtde-item pRelative"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: isPhone ? '41px' : '55px',
                  fontWeight: 'bold',
                  background: 'rgba(31, 36, 69, .9)',
                  border: `1px solid ${
                    inputDisabledStatus ? '#9296AC' : '#1F2445'
                  }`,
                  width: '52%',
                  marginRight: '-16px',
                  paddingRight: isPhone ? '16px' : '30px',
                  fontSize: isPhone ? '12px' : '16px',
                  lineHeight: 1.4,
                  color: type === 'RECIPE' ? '#75C7F0' : '#F7D56E',
                  textAlign: 'right'
                }}
              >
                RECEITAS À PRODUZIR
              </span>

              <span
                className="qtde-number qtde-card"
                style={{
                  border: `1px solid ${
                    inputDisabledStatus ? '#9296AC' : '#1F2445'
                  }`,
                  width: '48%',
                  zIndex: 999
                }}
              >
                <InputNumber
                  // onBlur={(e, value) => {
                  //   actions.handleCardQuantity(manufacture.id, manufacture.quantity, index, value, undefined)
                  // }}
                  updateQuantityInitialValue={updateQuantityInitialValue}
                  setModalStates={setModal}
                  modalStates={mainModalStates}
                  setUpdateValue={setUpdateValue}
                  updateValue={updateValue}
                  minimumValue="0"
                  maximumValue="999999999"
                  initialValue={manufactureQuantity?.toFixed(3)}
                  disabled={inputDisabledStatus}
                  handleValue={handleQuantity}
                />
              </span>
            </CardIngredientsDrillDownHead>
          ) : (
            <div
              className="bgWhite pAbsolute dFlex alignCenter"
              style={{
                width: '57%',
                height: 55,
                border: 'solid 1px #000',
                borderRight: 'none',
                lineHeight: 1.2
              }}
            >
              <p
                className="mb0 uppercase bold ml5 childTdFontSize22"
                style={{
                  color: '#010311',
                  fontSize: isPhone ? '12px' : '22px',
                  paddingRight: 60,
                  lineHeight: 1.2
                }}
              >
                {ValidationLength(transformationInfos?.description || '-', 20)}
              </p>
            </div>
          )}

          <CardIngredientsDrillDownHead
            justifyContent="flex-end"
            width={isPhone ? '55%' : '47%'}
          >
            <span
              className="dFlex alignCenter mr10"
              onClick={() => {
                if (inputDisabledStatus) return;

                setModalStates({
                  ...modalStates,
                  modalYieldLabelQuantity: true
                });
              }}
            >
              <img
                style={{ width: '30px' }}
                src={
                  manufacture?.yieldLabelQuantity ? AddIcon : AddDisabledIcon
                }
                alt="AddIcon"
              />
            </span>

            <span
              className="qtde-item"
              style={{
                display: 'flex',
                alignItems: 'center',
                height: isPhone ? '41px' : '55px',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                width: isPhone ? '48%' : '53%',
                marginRight: '-16px',
                paddingRight: '30px',
                fontSize: isPhone ? '12px' : '16px',
                lineHeight: 1.4,
                color: type === 'RECIPE' ? '#75C7F0' : '#F7D56E',
                textAlign: 'right',
                background: 'rgba(31, 36, 69, .9)'
              }}
            >
              {type === 'RECIPE'
                ? 'QTDE DESTA RECEITA'
                : 'QTDE INICIAL DO PRODUTO'}
            </span>

            <span
              id="yieldCardField"
              className="qtde-number yield-qtde-number pRelative alignCenter"
              style={{ width: isPhone ? '50%' : '44%', paddingRight: 58 }}
              onClick={() => {
                if (!manufacture?.yieldLabelQuantity || inputDisabledStatus)
                  return;

                setModalStates({
                  ...modalStates,
                  modalYieldLabelQuantity: true
                });
              }}
            >
              <InputNumber
                updateQuantityInitialValue={updateQuantityInitialValue}
                setModalStates={setModal}
                modalStates={mainModalStates}
                setUpdateValue={setUpdateValue}
                updateValue={updateValue}
                minimumValue="0"
                maximumValue="9999999999999999999999"
                initialValue={
                  type === 'RECIPE'
                    ? parseFloat(income?.replace(',', '.'))
                    : manufactureQuantity?.toFixed(3)
                }
                onKeyDown={(event) => {
                  if (
                    (event.keyCode === 9 || event.keyCode === 13) &&
                    items.length
                  ) {
                    setTimeout(() => {
                      document.querySelector(`#manufacture-0`).select();
                    }, 100);
                  }
                }}
                disabled={
                  inputDisabledStatus || manufacture?.yieldLabelQuantity
                }
                handleValue={(value) => {
                  type === 'RECIPE'
                    ? actions.handleCardQuantity(
                        index,
                        manufacture.quantity || 1,
                        undefined,
                        undefined,
                        parseFloat(value),
                        '',
                        '',
                        '',
                        noActionMode ? manufacture : ''
                      )
                    : actions.handleCardQuantity(
                        index,
                        parseFloat(value),
                        undefined,
                        undefined,
                        undefined,
                        '',
                        '',
                        '',
                        noActionMode ? manufacture : ''
                      );
                }}
              />

              <span
                className="pAbsolute bold"
                style={{
                  right: isPhone ? 7 : 13,
                  fontSize: isPhone ? 12 : 16
                }}
              >
                {manufacture.un.abbreviation}
              </span>
            </span>
          </CardIngredientsDrillDownHead>

          <div
            className="item-arrow pAbsolute"
            style={{ right: 5 }}
            onClick={() => setOpenDrillDown(!openDrillDown)}
          >
            <ArrowDown
              openDrillDown={openDrillDown}
              src={selectorInputArrow}
              id="CardIngredientInputArrow"
            />
          </div>
        </div>

        <CardInfoHeaderDrillDownContent openDown={openDrillDown}>
          <CardInfoHeader
            className="card-header-modal w100 pt0"
            style={{ background: type === 'RECIPE' ? '#D9EAF2' : '#FFF2CC' }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                lineHeight: 1.4
              }}
            >
              <div className="dFlex" style={{ width: isPhone ? '47%' : '40%' }}>
                <CardInfoContent style={{ marginRight: '20%', width: '70%' }}>
                  <CardContentTitle
                    style={{
                      fontSize: isPhone ? '9px' : '14px',
                      lineHeight: '1.4',
                      color: '#454A67',
                      textAlign: 'end'
                    }}
                  >
                    ESTOQUE PRODUTO
                  </CardContentTitle>

                  <CardContentElement style={{ justifyContent: 'flex-end' }}>
                    <span
                      style={{
                        fontSize: isPhone ? '12px' : '22px',
                        lineHeight: '1.4',
                        fontWeight: 'bold'
                      }}
                    >
                      {numberToReal(manufactureStock)}
                      {manufacture.un.abbreviation}
                    </span>
                  </CardContentElement>
                </CardInfoContent>

                <CardInfoContent style={{ width: '20%' }}>
                  <CardContentTitle
                    style={{
                      fontSize: isPhone ? '9px' : '14px',
                      lineHeight: '1.4',
                      color: '#454A67'
                    }}
                  >
                    DURAÇÃO
                  </CardContentTitle>

                  <CardContentElement
                    onClick={(e) => {
                      if (manufactureDuration) {
                        getDuration(
                          manufacture.productId ? manufacture.productId : null,
                          manufacture.stock,
                          manufacture
                        );
                        setModalStates({
                          ...modalStates,
                          modalManufactureDuration: true
                        });
                      }
                    }}
                  >
                    <span className="special-date-result">
                      <span>{manufactureDuration || '-'}</span>

                      {!!manufactureDuration &&
                        (manufactureDuration > 1 || manufactureDuration === 0
                          ? 'dias'
                          : 'dia')}
                    </span>
                  </CardContentElement>
                </CardInfoContent>
              </div>

              <div
                className="dFlex justifyEnd"
                style={{ width: '60%', paddingRight: '30px' }}
              >
                <CardInfoContent>
                  <CardContentTitle
                    style={{
                      fontSize: isPhone ? '9px' : '14px',
                      lineHeight: '1.4',
                      color: '#454A67'
                    }}
                  >
                    NOVA DUR.
                  </CardContentTitle>

                  <CardContentElement
                    onClick={(e) => {
                      if (!newDuration || newDuration === '-') return;

                      if (type === 'RECIPE') {
                        getDuration(
                          manufacture.productId ? manufacture.productId : null,
                          manufacture.stock + manufacture.manufactured,
                          manufacture,
                          'recipe',
                          true
                        );
                      } else {
                        getDuration(
                          manufacture.productId ? manufacture.productId : null,
                          manufacture.stock - manufacture.quantity,
                          manufacture,
                          '',
                          true
                        );
                      }
                      setModalStates({
                        ...modalStates,
                        modalManufactureDuration: true
                      });
                    }}
                  >
                    <span className="special-date-result">
                      {newDuration || '-'}
                    </span>
                  </CardContentElement>
                </CardInfoContent>
              </div>
            </div>
          </CardInfoHeader>
        </CardInfoHeaderDrillDownContent>

        <p
          className="mb0 dFlex justifyEnd w100 bold"
          style={{
            color: type === 'RECIPE' ? '#004466' : '#664C00',
            fontSize: 18,
            padding: '0 42px 12px 0'
          }}
        >
          {type === 'RECIPE'
            ? `1 RECEITA RENDE ${fixedInitialYield} ${manufacture.un.abbreviation}`
            : `PERDA ESPERADA ${manufacture.loss}% ou ${(
                1 -
                (1 - (manufacture.loss * (manufacture.quantity || 1)) / 100)
              )?.toLocaleString('pt-br', { minimumFractionDigits: 3 })}
              ${
                manufacture?.items?.[0]?.un
                  ? manufacture?.items?.[0]?.un?.abbreviation
                  : ''
              }`}
        </p>
      </div>

      <div>
        <CardIngredientsContainer>
          <CardsIngredientsWrapper open maxHeight={noActionMode && "255px"}>
            <IngredientLossWrapper>
              <IngredientCount
                className="withStroke"
                color={type === 'RECIPE' ? '#75C7F0' : '#F7D56E'}
              >
                {items.length > 1 || items.length === 0
                  ? type === 'TRANSFORMATION'
                    ? `EM ${items.length} PRODUTOS:`
                    : 'INGREDIENTES:'
                  : type === 'TRANSFORMATION'
                  ? `EM ${items.length} PRODUTO:`
                  : 'INGREDIENTE:'}
              </IngredientCount>

              <LossCount>
                {/* {type === "TRANSFORMATION" &&
                    <>
                      PERDA FICHA:
                      <p
                        className="uppercase mb0"
                        style={{
                          marginLeft: 5,
                          color: "#D0D3E2",
                        }}
                      >
                        {(1 - (1 * (manufacture.loss / 100)))?.toFixed(3)}
                        {manufacture?.items?.[0]?.un ? manufacture?.items?.[0]?.un?.abbreviation : ""}
                      </p>

                      <p
                        className="uppercase mb0 bold dBlock"
                        style={{
                          marginRight: isPhone ? 0 : 30,
                          marginLeft: 'auto',
                          color: cardLostTotal?.toFixed(3) > ((1 - (1 * (manufacture.loss / 100)))?.toFixed(3))
                            ? '#F76E79'
                            : '#8CF295'
                        }}
                      >
                        {produced ?
                          <>
                            {cardLostTotal?.toFixed(3)}
                            {manufacture?.items?.[0]?.un ? manufacture?.items?.[0]?.un?.abbreviation : ""}
                          </>
                        : null}
                      </p>
                    </>
                  } */}

                {/* {productionCardStatus[manufactureStatus].statusText === 'Finalizado' ? total === 0 ? (<>0%</>) : (<>{loss}</>) : null}
                  {productionCardStatus[manufactureStatus].statusText === 'Finalizado' ? '/' : null}
                  {productionCardStatus[manufactureStatus].statusText === 'Finalizado' ? produced : null} */}
              </LossCount>
            </IngredientLossWrapper>

            {items.map((item, itemIndex) => (
              <CardIngredients
                handleItemDiscard={handleItemDiscard}
                updateQuantityInitialValue={updateQuantityInitialValue}
                setProductDialogModalStates={setModal}
                mainModalStates={mainModalStates}
                manufactures={manufactures}
                setManufactures={setManufactures}
                totalItems={items}
                durationData={durationData}
                setDurationData={setDurationData}
                content={item}
                itemss={item}
                manufacture={manufacture}
                optionsDiscard={optionsDiscard}
                manufactureStatus={manufactureStatus}
                handleDiscardInfo={handleDiscardInfo}
                handleCardQuantity={actions?.handleCardQuantity}
                cardIndex={index}
                itemIndex={itemIndex}
                newProductDuration={newDuration}
                noActionMode={noActionMode}
              />
            ))}
          </CardsIngredientsWrapper>
        </CardIngredientsContainer>
      </div>

      {/* {manufacture.type === "TRANSFORMATION" ?
          <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "14px", marginBottom: "12px", padding: "0px 8px" }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <h1 style={{ color: "#fff", fontSize: isPhone ? "9px" : "14px", fontFamily: 'Lato', marginBottom: "2px", marginTop: "0px" }}>/</h1>
              <h1 style={{ color: "#454A67", fontSize: isPhone ? "12px" : "22px", fontFamily: 'Lato', marginBottom: "2px", marginTop: "0px" }}>Perda</h1>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <h1 style={{ color: "#454A67", fontSize: isPhone ? "9px" : "14px", fontFamily: 'Lato', fontWeight: "bold", marginBottom: "2px", marginTop: "0px", textAlign: "end" }}>ESPERADA</h1>
              <h1 style={{ color: "#454A67", fontSize: isPhone ? "12px" : "22px", fontFamily: 'Lato', marginBottom: "2px", marginTop: "0px", textAlign: "end" }}>{manufacture.loss ? parseFloat(manufacture.loss).toLocaleString('pt-br', { minimumFractionDigits: 3 }) : "0,000"}%</h1>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <h1 style={{ color: "#454A67", fontSize: isPhone ? "9px" : "14px", fontFamily: 'Lato', fontWeight: "bold", marginBottom: "2px", marginTop: "0px", textAlign: "end" }}>QTDE.</h1>
              <h1 style={{ color: cardQuantity && total ? parseFloat(cardQuantity) - parseFloat(cardYield) < cardLostTotal ? '#F76E79' : '#454A67' : '#454A67', fontSize: isPhone ? "12px" : "22px", fontFamily: 'Lato', marginBottom: "2px", marginTop: "0px", textAlign: "end", textTransform: "uppercase" }}>
                {cardQuantity && total ? (cardLostTotal).toLocaleString('pt-br', { minimumFractionDigits: 3 }) : (parseFloat(cardQuantity) - parseFloat(cardYield)).toLocaleString('pt-br', { minimumFractionDigits: 3 })}{manufacture?.items?.[0]?.un ? manufacture?.items?.[0]?.un?.abbreviation : ""}
              </h1>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <h1 style={{ color: "#454A67", fontSize: isPhone ? "9px" : "14px", fontFamily: 'Lato', fontWeight: "bold", marginBottom: "2px", marginTop: "0px", textAlign: "end" }}>PERDA X PRODUÇÂO</h1>
              <h1 style={{ color: parseFloat(100 * cardLostTotal) / parseFloat(cardQuantity) > parseFloat(loss) ? '#F76E79' : '#454A67', fontSize: isPhone ? "12px" : "22px", fontFamily: 'Lato', marginBottom: "2px", marginTop: "0px", textAlign: "end" }}>
              {total === 0 ? (
                <>
                  &nbsp;
                </>
              ) : (
                <>
                  {(parseFloat(100 * cardLostTotal) / parseFloat(cardQuantity)).toLocaleString('pt-br', { minimumFractionDigits: 3 }) + '%'}
                </>
              )}
              </h1>
            </div>
          </div>
        : null} */}

      {manufacture.status === 'START' && !noActionMode && (
        <div
          className="dFlex alignCenter pAbsolute"
          style={{
            height: 70,
            background: '#454A67',
            zIndex: 9999,
            borderRadius: '12px 0px 0px 12px',
            border: '3px solid rgb(0, 0, 0)',
            borderRightWidth: '0px'
          }}
          onClick={(e) => {
            if (
              manufacture.status === 'FINISH' ||
              manufacture.status === 'CANCELED'
            )
              return;
            handleStatusButton(e, 'WAITING');
          }}
        >
          <div
            className="dFlex alignCenter justifyCenter"
            style={{
              borderRight: '2px solid #1F2445',
              width: 70,
              height: 70
            }}
          >
            <img
              style={{ width: 22 }}
              className="cursorPointer"
              src={RefreshWhiteIcon}
              alt="RefreshIcon"
            />
          </div>

          <p
            className="mb0 uppercase white bold dFlex alignCenter justifyCenter"
            style={{
              fontSize: 18,
              borderRight: '2px solid #1F2445',
              padding: '0 15px',
              height: 70
            }}
          >
            Voltar
          </p>
        </div>
      )}

      {!noActionMode ?
        <div
          className="dFlex justifyBetween alignCenter iconButtonFullContainer pRelative"
          onClick={(e) => {
            if (
              manufacture.status === 'START' ||
              manufacture.status === 'FINISH' ||
              manufacture.status === 'CANCELED'
            )
              return;

            handleStatusButton(e);
          }}
          style={{
            padding:
              manufacture.status === 'START' ? '8px 16px 8px 0' : '8px 16px',
            border: `solid 3px #000000`,
            // border: `solid 2px ${productionCardStatus[manufactureStatus].borderBackgroundColor}`,
            background:
              productionCardStatus[manufactureStatus]?.labelBackGroundColor,
            margin: '0 0 0 0',
            overflow: 'hidden'
          }}
        >
          <div
            className="dFlex alignStart justifyBetween"
            style={{ marginRight: 40, width: '91%', height: 70 }}
            onClick={(e) => {
              if (
                manufacture.status !== 'START' ||
                manufacture.status === 'FINISH' ||
                manufacture.status === 'CANCELED'
              )
                return;
              handleStatusButton(e);
            }}
          >
            <p
              className="bold mb0 uppercase dFlex alignEnd justifyEnd flexColumn mt10"
              style={{
                fontSize: isPhone ? '18px' : '20px',
                color:
                  productionCardStatus[manufactureStatus]?.labelStatusDialogColor,
                margin: '0px auto 0px 0x'
              }}
            >
              {manufacture?.cancellationReason ? (
                <span
                  className="dFlex bold uppercase"
                  style={{ margin: '0px 0 0 3px' }}
                >
                  {ValidationLength(manufacture?.cancellationReason, 20)}
                </span>
              ) : null}
            </p>

            <p
              className="bold mb0 dFlex flexColumn mt10"
              style={{
                color:
                  productionCardStatus[manufactureStatus]?.labelStatusDialogColor,
                marginRight: 10,
                fontSize: 18
              }}
            >
              <span className="uppercase withStroke">
                {`${productionCardStatus[manufactureStatus]?.statusTextRight} `}
              </span>

              <span style={{ color: '#FFFFFF' }}>
                {manufacture.status === 'PAUSE' && 'Pausado '}
                {manufacture.status === 'START' && 'Iniciado '}

                {dateStatus && (
                  <>
                    às {format(new Date(dateStatus?.date), 'kk:mm')}{' '}
                    {dateStatus?.user && `(${dateStatus?.user})`}
                  </>
                )}
              </span>
            </p>
            
            <div
              className="iconButtonFull dFlex pAbsolute alignCenter justifyCenter"
              style={{
                height: 70,
                width: 70,
                background:
                  productionCardStatus[manufactureStatus]?.borderBackgroundColor,
                right: 0,
                border: 'solid 2px #1F2445',
                borderRight: 'none'
              }}
            >
              {StatusIcon.$$typeof ? (
                <StatusIcon
                  id="MobilePlayProductionButton"
                  style={{ color: '#000', width: '30px', height: '30px' }}
                />
              ) : (
                <ProductionPlay
                  width={manufactureStatus === 'WAITING' ? '40px' : '20px'}
                  height={manufactureStatus === 'WAITING' ? '40px' : '20px'}
                  marginRight="0px"
                  src={StatusIcon}
                  id="MobilePlayProductionButton"
                />
              )}
            </div>
          </div>
        </div>
      : null}

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={modalStates.modalManufactureDuration}
        onClose={handleCloseModal}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="defaultModal bigModal w100"
        transitionDuration={0}
      >
        <DialogTitle className="modalHeader navyBlue bold textAlignCenter cursorMove">
          <Typography
            id="draggable-dialog-title"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '250px',
              marginRight: '30px'
            }}
          >
            {durationData.product.description || durationData.product.item} -
            Estoque atual
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseModal}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '8px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent className="modalContent">
          <div
            width="100%"
            cellPadding="3"
            cellSpacing="0"
            border="0"
            className="dFlex flexRow justifyBetween"
            style={{
              boxShadow: 'none'
            }}
          >
            <div className="dFlex flexColumn justifyBetween w100">
              <div
                width="60"
                className="subtitle center white"
                style={{
                  background: '#7583F0',
                  borderTopLeftRadius: '8px',
                  borderBottomLeftRadius: '8px',
                  padding: '12px'
                }}
              >
                Atual
              </div>

              {durationData.data.days && durationData.data.days.length
                ? durationData.data.days.map((day) => (
                    <div
                      width="60"
                      className="subtitle p12 left dFlex justifyCenter alignCenter mb8"
                    >
                      {replaceDayDurationData(day.numberDay)}
                    </div>
                  ))
                : null}
            </div>

            <div>
              <div
                className="p0"
                colSpan={
                  durationData.data.days && durationData.data.days.length
                    ? durationData.data.days.length + 1
                    : 0
                }
              >
                <hr className="m0" />
              </div>
            </div>

            <div className="dFlex flexColumn justifyBetween w100">
              <div
                className="description right white dFlex alignCenter justifyCenter"
                style={{
                  background: '#7583F0',
                  borderTopRightRadius: '8px',
                  borderBottomRightRadius: '8px',
                  padding: '12px'
                }}
              >
                <b className="dBlock textAlignCenter" style={{ width: 'auto' }}>
                  {durationData.type === 'recipe'
                    ? durationData.newDuration
                      ? parseFloat(
                          durationData.product.stock +
                            durationData.product.manufactured
                        ).toLocaleString('pt-br', {
                          minimumFractionDigits: 3
                        })
                      : durationData.product.stock
                      ? parseFloat(durationData.product.stock).toLocaleString(
                          'pt-br',
                          { minimumFractionDigits: 3 }
                        )
                      : '0,000'
                    : durationData.newDuration
                    ? durationData.type === 'transformationItem'
                      ? parseFloat(
                          (durationData.product.stock || 0) +
                            durationData.product.quantity
                        ).toLocaleString('pt-br', {
                          minimumFractionDigits: 3
                        })
                      : parseFloat(
                          durationData.product.stock -
                            durationData.product.quantity
                        ).toLocaleString('pt-br', {
                          minimumFractionDigits: 3
                        })
                    : durationData.product.stock
                    ? parseFloat(durationData.product.stock).toLocaleString(
                        'pt-br',
                        { minimumFractionDigits: 3 }
                      )
                    : '0,000'}
                </b>

                {durationData.product.un &&
                  durationData.product.un.abbreviation}
              </div>

              {durationData.data.days && durationData.data.days.length
                ? durationData.data.days.map((day) => (
                    <div
                      width="60"
                      className="subtitle p12 right dFlex alignCenter justifyCenter"
                    >
                      <span>
                        {day.quantity
                          ? day.quantity.toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })
                          : '0,00'}
                      </span>

                      {durationData.product.un.abbreviation}
                    </div>
                  ))
                : null}
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={modalStates.modalYieldLabelQuantity}
        onClose={handleCloseModal}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="defaultModal bigModal sumeValuesProductionDialog"
        transitionDuration={0}
        style={{ width: '100%' }}
      >
        <DialogTitle className="modalHeader navyBlue cursorMove bold">
          <Typography style={{ fontSize: 20, width: 300 }}>
            Soma de rendimento
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={handleCloseModal}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '12px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>
        
        <DialogContent className="modalContent">
          <FormControl fullWidth>
            <CurrencyTextFieldSumeValues
              id="sumeValuesDialogField"
              key="sumeValuesDialogField"
              name="Quantity"
              placeholder="Digite aqui..."
              onBlur={(value) => {
                const sumeValue = value
                  .split('+')
                  .map((item) => item?.replace(',', '.'))
                  .reduce(
                    (total, value) =>
                      value ? parseFloat(value) + parseFloat(total) : total + 0,
                    0
                  );

                if (value && sumeValue > 0) {
                  type === 'RECIPE'
                    ? actions.handleCardQuantity(
                        index,
                        manufacture.quantity || 1,
                        undefined,
                        undefined,
                        value
                          .split('+')
                          .map((item) => item?.replace(',', '.'))
                          .reduce(
                            (total, value) =>
                              value
                                ? parseFloat(value) + parseFloat(total)
                                : total + 0,
                            0
                          ),
                        false,
                        '',
                        value,
                        noActionMode ? manufacture : ''
                      )
                    : actions.handleCardQuantity(
                        index,
                        value
                          .split('+')
                          .map((item) => item?.replace(',', '.'))
                          .reduce(
                            (total, value) =>
                              value
                                ? parseFloat(value) + parseFloat(total)
                                : total + 0,
                            0
                          ),
                        undefined,
                        undefined,
                        undefined,
                        false,
                        '',
                        value,
                        noActionMode ? manufacture : ''
                      );
                } else {
                  setModal({
                    ...mainModalStates,
                    modalInvalidQuantity: true
                  });

                  setManufactures(
                    manufactures.map((manufacture) => ({
                      ...manufacture
                    }))
                  );
                }
              }}
              onFocus={() => {
                setTimeout(() => {
                  document
                    .querySelector(`.sumeValuesProductionDialog input`)
                    .select();
                }, 100);
              }}
              defaultValue={manufacture?.yieldLabelQuantity}
              updateQuantityInitialValue={updateQuantityInitialValue}
              setModalStates={setModal}
              modalStates={mainModalStates}
            />
          </FormControl>
        </DialogContent>
      </Dialog>
    </ModalContent>
  );
}

export default MobileCardDetail;
