/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-no-bind */
import { memo, useEffect, useState } from 'react';

import { CircularProgress, Fade, Tooltip } from '@material-ui/core';
import axios from 'axios';
import { format } from 'date-fns';
import moment from 'moment/moment';

import InfoIcon from 'images/icons/signals/infos/info.svg';
import Environment from 'utils/environments';
import ValidationLength from 'utils/utils';

const environments = Environment(process.env.REACT_APP_ENV);

function ReportTableBody({
  getId,
  setModalStates,
  modalStates,
  setParameters,
  setProductModal,
  setProductDescription,
  products,
  parametersInfos,
  isSelectedRealDiff,
  countDate,
  showColumns,
  isSelectedCost,
  setRealStockModal,
  setModalSettings,
  modalSettings
}) {
  const filteredProducts = products?.filter((el) => !el?.hide);
  const [extractItems, setExtractItem] = useState([]);
  const [hovered, setHovered] = useState(false);
  const [hoveredOut, setHoveredOut] = useState(false);
  const [realStock, setRealStock] = useState([]);
  const [currentProduct, setCurrentProduct] = useState();
  const mouseEnterHandler = (product) => {
    setHovered(true);
    setCurrentProduct(product);
  };

  const mouseLeaveHandler = () => {
    setHovered(false);
    setHoveredOut(true);
    setCurrentProduct();
    setRealStock([]);
  };

  const onTimeout = (id) => {
    axios
      .get(
        `${environments.catalog}/inventories/counts/${currentProduct?.id || id}/stocks/${countDate}`
      )
      .then((response) => {
        setRealStock(response.data.content);
        setRealStockModal(response.data.content)
      })
      .catch((error) => {
        console.log(error);
      })
  };
  const realStockItemValues = (product) => (
    <div style={{ width: '20px' }}>
      {product?.stocks?.totalStocks > 0 && (
        <div
          className="dFlex alignCenter"
          onMouseOver={() => mouseEnterHandler(product)}
          onMouseLeave={mouseLeaveHandler}
        >
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            title={
              <div
                className="dFlex flexColumn"
                style={{ width: '380px', paddingTop: 5 }}
              >
                {realStock?.length ? (
                  realStock.map((item) => (
                    <div className="dFlex justifyBetween">
                      <p
                        style={{
                          width: '50%',
                          padding: '0 8px 0 0',
                          margin: '0px 5px 5px 0px',
                          border: 'none',
                          fontFamily: 'Lato',
                          fontSize: 12
                        }}
                      >
                        {item.stock}
                      </p>

                      <p
                        style={{
                          width: '25%',
                          padding: '0 8px 0 0',
                          margin: 0,
                          border: 'none',
                          fontFamily: 'Lato',
                          fontSize: 12,
                          textAlign: 'right'
                        }}
                      >
                        {item.quantity
                          ? parseFloat(item.quantity).toLocaleString('pt-br', {
                            minimumFractionDigits: 3
                          })
                          : '0,000'}
                      </p>

                      <p
                        style={{
                          width: '25%',
                          padding: '0 8px 0 0',
                          margin: 0,
                          border: 'none',
                          fontFamily: 'Lato',
                          fontSize: 12,
                        }}
                      >
                        {item.user ? item.user : '-'}
                      </p>
                    </div>
                  ))
                ) : (
                  <div className="countsCircularProgressBar dFlex justifyCenter">
                    <CircularProgress />
                  </div>
                )}
              </div>
            }
          >
            <img src={InfoIcon} alt="Info Icon" style={{ marginLeft: 5 }} />
          </Tooltip>
        </div>
      )}
    </div>
  );

  const setColor = (percentage, lossPercentage, isFirst, diff, consumed) => {
    const range = [parseFloat(lossPercentage * -1), parseFloat(lossPercentage)];
    let color;

    if (isFirst)
      color =
        percentage >= range[0] && percentage <= range[1]
          ? '#DFF7E8'
          : percentage >= lossPercentage
          ? '#FCF5E3'
          : '#F7DFDF';
    else
      color =
        percentage >= range[0] && percentage <= range[1]
          ? '#3DCC6E'
          : percentage >= lossPercentage
          ? '#FFBF3E'
          : '#D94141';

    if (!consumed) {
      if (isFirst)
        color = diff == 0 ? '#DFF7E8' : diff > 0 ? '#FCF5E3' : '#F7DFDF';
      else color = diff == 0 ? '#3DCC6E' : diff > 0 ? '#FFBF3E' : '#D94141';
    }

    return color;
  };

  const previousAndBeforeDate = () => {
    if (moment(countDate).isSameOrAfter(moment(), 'day')) {
      return moment(countDate).isSameOrAfter(moment(), 'day');
    }
    return moment(countDate).isBefore(moment(), 'day');
  };

  function validateNumber(number) {
    if (number === '-0,000') number = number.replace('-', '');

    return number;
  }

  async function fetchExtracts(product) {
    const fetchParameters = await fetch(
      `${environments.restaurants}/companies/${getId}/parameters`
    );
    const parameters = await fetchParameters.json();
    setParameters(parameters.product.type);

    let date = format(new Date(), 'yyyy-MM-dd');

    if (product.lastCount) {
      date = format(new Date(product.lastCount).setHours(12), 'yyyy-MM-dd');
    }

    const response = await fetch(
      `${environments.catalog}/extracts?originId=${getId}&productId=${product.id}&date=${date}`
    );
    const extracts = await response.json();

    if (Array.isArray(extracts)) setExtractItem(extracts);
    else setExtractItem([]);

    setProductModal(product);
    setProductDescription(product.description);
    setModalStates((prevState) => ({ ...prevState, modalProduct: true }));
  }

  useEffect(() => {
    const timer = hovered && setTimeout(onTimeout, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [hovered]);

  return filteredProducts?.map((product, productIndex) => {
    const lossPercentage = product?.lossPercentage
      ? parseFloat(product?.lossPercentage)
      : 0;
    const theoreticalStock =
      product?.theoreticalStock || product?.theoricalStock || 0;
    const difference =
      product?.realStock !== null || product?.realStock !== undefined
        ? (product?.realStock ? parseFloat(Number(product?.realStock)) : 0) -
          (theoreticalStock ? parseFloat(Number(theoreticalStock)) : 0)
        : null;

    const percentage = product?.consumed
      ? (difference / parseFloat(Number(product?.consumed))) * 100
      : 0;
    const percent = percentage
      ? percentage.toLocaleString('pt-br', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })
      : '0,00';
    const differenceInReal =
      typeof parseFloat(product?.realStock) === 'number'
        ? Number(difference) * Number(product?.cost)
        : null;
    const parsedDifferenceInReal = differenceInReal
      ? differenceInReal.toLocaleString('pt-br', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })
      : '0,000';
    const diff = difference
      ? difference.toLocaleString('pt-br', {
          maximumFractionDigits: 3,
          minimumFractionDigits: 3
        })
      : '0,000';

    return (
      <>
        <tr className="firstLine">
          <td
            colSpan={product.type === 'INTERNAL' ? 2 : 1}
            width="20"
            className="description"
            style={{
              paddingTop: 3,
              paddingLeft: 10
            }}
          >
            <p style={{ display: 'flex', gap: 2}}>{productIndex + 1} - {ValidationLength(product.description, 48)}</p>

            <div className="h100 " />
          </td>

          <td
            width="5"
            id={`otherStock${productIndex}`}
            className={`description right p8 pl12 ${
              !showColumns?.includes('OUTROS ESTOQUES') && 'dNone'
            }`}
            style={{
              borderLeft: 'solid 1px #BFC0CB',
              color: '#77798B',
              maxWidth: '10px',
              background: 'rgba(0, 19, 115, 0.03)'
            }}
          >
            <div className="dFlex justifyBetween">
              <div className="dFlex alignCenter">
                <div className="othersStocksVolume">
                  <span>
                    {product?.stocks?.totalStocks > 0
                      ? `${product.stocks.countedStocks}/${product.stocks.totalStocks}`
                      : product?.stocks?.countedStocks}
                  </span>
                  <div onClick={() => {
                      onTimeout(product?.id)
                      setModalSettings(
                        modalSettings.map((modal) =>
                          modal.name === 'stockModal' ? { ...modal, open: true } : modal
                        )
                      );
                    }} className='iconInfo'>
                    <img src={InfoIcon} alt="Info Icon" style={{ marginLeft: 5 }} />
                  </div>
                </div>
                <span className="dFlex alignCenter reportSpan">
                  {realStockItemValues(product)}
                </span>
              </div>
            </div>
          </td>

          <td
            width="5"
            id={`realStock${productIndex}`}
            className={`description pr8 bold ${
              !showColumns?.includes('REAL') && 'dNone'
            }`}
            style={{
              background: 'transparent',
              maxWidth: '10px'
            }}
          >
            <div className="dFlex justifyEnd content-report-desk">
              {typeof parseFloat(product?.realStock) === 'number' &&
              product?.realStock !== null
                ? (product?.realStock)?.toLocaleString('pt-br', {
                    maximumFractionDigits: 3,
                    minimumFractionDigits: 3
                  })
                : '0,000'}{' '}
              {product?.consumptionUnity}
            </div>

            <div
              className="dFlex justifyEnd content-report-tablet"
              style={{ textAlign: 'end' }}
            >
              {typeof parseFloat(product?.realStock) === 'number' &&
              product?.realStock !== null
                ? (product?.realStock)?.toLocaleString('pt-br', {
                    maximumFractionDigits: 3,
                    minimumFractionDigits: 3
                  })
                : '0,000'}{' '}
              <br />
              {product?.consumptionUnity}
            </div>
          </td>

          <Tooltip
            id={`theoricalStock${productIndex}`}
            TransitionComponent={Fade}
            disableHoverListener
            TransitionProps={{ timeout: 600 }}
            title={`Consumo: ${
              product?.consumed
                ? product?.consumed.toLocaleString('pt-br', {
                    maximumFractionDigits: 3,
                    minimumFractionDigits: 3
                  })
                : '0,000'
            }`}
          >
            <td
              width="7%"
              className={`description right p8 ${
                !showColumns?.includes('TEÓRICO') && 'dNone'
              }`}
              style={{ background: 'rgba(0, 19, 115, 0.08)' }}
            >
              <p
                id="teoricValue"
                className="materialBlue mb0 cursorPointer content-report-desk"
                onClick={() => {
                  fetchExtracts(product);
                }}
              >
                {theoreticalStock
                  ? `${theoreticalStock?.toLocaleString('pt-br', {
                      maximumFractionDigits: 3,
                      minimumFractionDigits: 3
                    })} ${product?.consumptionUnity} `
                  : !parametersInfos?.closure?.runManually
                  ? '-'
                  : `0,000 ${product?.consumptionUnity}`}
              </p>

              <p
                id="teoricValue"
                className="materialBlue mb0 cursorPointer content-report-tablet"
                onClick={() => {
                  fetchExtracts(product);
                }}
              >
                {theoreticalStock
                  ? `${theoreticalStock?.toLocaleString('pt-br', {
                      maximumFractionDigits: 3,
                      minimumFractionDigits: 3
                    })} `
                  : !parametersInfos?.closure?.runManually
                  ? '-'
                  : `0,000`}
                <br />
                {theoreticalStock
                  ? `${product?.consumptionUnity}`
                  : !parametersInfos?.closure?.runManually
                  ? ''
                  : ''}
              </p>
            </td>
          </Tooltip>

          <td
            width="5"
            id={`lastRealStock${productIndex}`}
            className={`description right p8 bold ${
              !showColumns?.includes('ÚLTIMA') && 'dNone'
            }`}
            style={{
              maxWidth: 20,
              color: '#666770',
              background: '#F2F3F8'
            }}
          >
            <p className="content-report-desk">
              {(product?.historical?.realStock || 0)?.toLocaleString('pt-br', {
                maximumFractionDigits: 3,
                minimumFractionDigits: 3
              })}{' '}
              {product?.consumptionUnity}
            </p>

            <p className="content-report-tablet">
              {(product?.historical?.realStock || 0)?.toLocaleString('pt-br', {
                maximumFractionDigits: 3,
                minimumFractionDigits: 3
              })}{' '}
              <br />
              {product?.consumptionUnity}
            </p>
          </td>

          <td
            width="5"
            id={`consumed${productIndex}`}
            className={`description right p8 bold ${
              !showColumns?.includes('CONSUMO') && 'dNone'
            }`}
            style={{ borderLeft: 'solid 1px #BFC0CB', maxWidth: 20 }}
          >
            <p className="content-report-desk">
              {`${product?.consumed}${product?.consumptionUnity}`
                ? `${product?.consumed?.toLocaleString('pt-br', {
                    maximumFractionDigits: 3,
                    minimumFractionDigits: 3
                  })} ${product?.consumptionUnity}`
                : `0,000 ${product?.consumptionUnity}`}
            </p>

            <p className="content-report-tablet">
              {`${product?.consumed}${product?.consumptionUnity}`
                ? `${product?.consumed?.toLocaleString('pt-br', {
                    maximumFractionDigits: 3,
                    minimumFractionDigits: 3
                  })}`
                : `0,000`}
              <br />
              {product?.consumptionUnity}
            </p>
          </td>

          {isSelectedRealDiff && (
            <td
              width="5"
              id={`difference${productIndex}`}
              className={`description right bold ${
                !showColumns?.includes('DIFERENÇA EM QTDE / R$') && 'dNone'
              } ${!showColumns?.includes('DIFERENÇA EM %') ? 'p8' : 'pl8'}`}
              style={{
                maxWidth: '10px',
                borderLeft: 'solid 1px #BFC0CB',
                background:
                  product?.realStock == null
                    ? 'transparent'
                    : setColor(
                        percentage,
                        lossPercentage,
                        true,
                        difference,
                        product?.consumed
                      ),
                color: setColor(
                  percentage,
                  lossPercentage,
                  false,
                  difference,
                  product?.consumed
                )
              }}
            >
              <p className="content-report-desk">
                {product?.realStock != null
                  ? `R$ ${parsedDifferenceInReal}`
                  : null}
              </p>

              <p className="content-report-tablet">
                {product?.realStock != null ? `R$` : null}
                <br />
                {product?.realStock != null
                  ? `${parsedDifferenceInReal}`
                  : null}
              </p>
            </td>
          )}

          {!isSelectedRealDiff && (
            <td
              width="5"
              id={`difference${productIndex}`}
              className={`description p8 right bold ${
                !showColumns?.includes('DIFERENÇA EM QTDE / R$') && 'dNone'
              } ${!showColumns?.includes('DIFERENÇA EM %') ? 'p8' : 'pl8'}`}
              style={{
                maxWidth: '10px',
                borderLeft: 'solid 1px #BFC0CB',
                background:
                  product?.realStock == null
                    ? 'transparent'
                    : setColor(
                        percentage,
                        lossPercentage,
                        true,
                        difference,
                        product?.consumed
                      ),
                color: setColor(
                  percentage,
                  lossPercentage,
                  false,
                  difference,
                  product?.consumed
                )
              }}
            >
              <p className="content-report-desk">
                {product?.realStock != null
                  ? difference != null
                    ? `${validateNumber(diff)} ${product?.consumptionUnity}`
                    : '-'
                  : null}
              </p>

              <p className="content-report-tablet">
                {product?.realStock != null
                  ? difference != null
                    ? `${validateNumber(diff)}`
                    : '-'
                  : null}
                <br />
                {product?.realStock != null
                  ? difference != null
                    ? `${product?.consumptionUnity}`
                    : ''
                  : null}
              </p>
            </td>
          )}

          <td
            width="7%"
            id={`percentage${productIndex}`}
            className={`description right p8 bold ${
              !showColumns?.includes('DIFERENÇA EM %') && 'dNone'
            }`}
            style={{
              maxWidth: '10px',
              background:
                product?.realStock == null
                  ? 'transparent'
                  : setColor(
                      percentage,
                      lossPercentage,
                      true,
                      difference,
                      product?.consumed
                    ),
              color: setColor(
                percentage,
                lossPercentage,
                false,
                difference,
                product?.consumed
              ),
              borderLeft: 'solid 1px #BFC0CB',
            }}
          >
            <p className="content-report-desk">
              {product?.realStock != null
                ? percentage != null
                  ? product?.consumed
                    ? `${validateNumber(percent)} %`
                    : '-'
                  : null
                : null}
            </p>

            <p className="content-report-tablet">
              {product?.realStock != null
                ? percentage != null
                  ? product?.consumed
                    ? `${validateNumber(percent)}`
                    : '-'
                  : null
                : null}
              <br />
              {product?.realStock != null
                ? percentage != null
                  ? product?.consumed
                    ? `%`
                    : ''
                  : null
                : null}
            </p>
          </td>
            
          <Tooltip
            TransitionProps={{ timeout: 600 }}
            disableHoverListener={!product.date && !product?.user}
            show={false}
            title={
              <p
                className="mb0"
                style={{ padding: '2px 6px' }}
              >
                {product.date ? moment(product.date).format('DD-MM-YYYY HH:mm') : '-'}
              </p>
            }
            placement="right-end"
          >
            <td
              width="7%"
              id={`percentage${productIndex}`}
              className={`description p8 bold ${
                !showColumns?.includes('USUÁRIO') && 'dNone'
              }`}
              style={{borderLeft: 'solid 1px #BFC0CB'}}
            >
              <p className="content-report-desk">
                {product.user ? product.user : '-'}
              </p>
            </td>
          </Tooltip>
        </tr>

        <tr>
          <td colSpan={(showColumns?.length || 0) + 1}>
            <hr style={{ borderTop: 'solid 1px rgb(119, 121, 139)' }} />
          </td>
        </tr>
      </>
    );
  });
}

export default memo(ReportTableBody);
