/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';

import {
  Button,
  Dialog,
  IconButton,
  Input,
  LinearProgress,
  Snackbar,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';

import correctIconNeutral from 'images/icons/signals/checks/correct-icon-neutral.svg';
import TrashTag from 'images/icons/signals/removesAndDeletes/TrashTag.svg';
import makePost from 'services/useFetch/makePost';

import {
  CreateNewTag,
  CreateTagImgDiv,
  DialogTitleTag,
  InputTags,
  TagButton
} from './style';

function CreateNewTagDiaglog({
  openCreateNewTagDialog,
  setOpenCreateNewTagDialog,
  getTagListing,
  handleOpenSucessMessage,
  clearFilterFields
}) {
  const [tagName, setTagName] = useState('');
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(2);
  const [isDescriptionEmpty, setIsDescriptionEmpty] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [toastDuplicateError, setToastDuplicateError] = useState(false);
  const [hasDuplicated, setHasDuplicated] = useState(false);
  const [inputValues, setInputValues] = useState([
    {
      id: 1,
      value: '',
      status: '',
      isAdd: ''
    }
  ]);
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  const handleOpenErrorMessage = () => {
    setErrorMessage(true);
  };

  const isWithoutValue = () => {
    setIsDescriptionEmpty(true);
  };

  const handleClose = () => {
    setOpenCreateNewTagDialog(false);
  };

  const handleOpenDuplicateErrorMassage = () => {
    setToastDuplicateError(true);
  };

  const handleCloseErrorMassage = () => {
    setErrorMessage(false);
    setIsDescriptionEmpty(false);
    setToastDuplicateError(false);
  };

  const addInputField = (inputId, inputDescription) => {
    const sanityInputValue = inputDescription.replace(/\s+/g, ' ').trim();

    if (
      sanityInputValue === '' ||
      sanityInputValue === ' ' ||
      sanityInputValue === null
    )
      return;

    const hasDuplicate = inputValues.filter(
      (el) => inputDescription === el.value
    );
    if (hasDuplicate.length > 1) {
      handleOpenDuplicateErrorMassage();
      return;
    }

    const addNewInput = inputValues.map((input) => {
      if (inputId == input.id) {
        if (!input.value) {
          return input;
        }
        input.status = 'CREATED';
        input.isAdd = 'ADD';
      }
      return input;
    });

    setInputValues([
      ...addNewInput,
      { id: id + 1, value: '', status: '', isAdd: '' }
    ]);
    setId(id + 1);
    setTimeout(() => {
      const inputElement = document?.getElementById(`${id + 1}`);
      if (!inputElement) return;
      inputElement.focus();
    }, 300);
  };

  const handleKeyPress = (event, id, value) => {
    if (event.key === 'Enter') {
      addInputField(id, value);
    }
  };

  const onChangeValues = (id, value) => {
    const hasDuplicate = inputValues.filter(
      (ctag) => ctag?.value.toUpperCase() === value.toUpperCase()
    );

    if (hasDuplicate.length >= 1) {
      setHasDuplicated(true);
      setToastDuplicateError(true);
    } else {
      handleCloseErrorMassage();
      setHasDuplicated(false);
    }

    const newInputs = inputValues.map((item) => {
      if (item.id === id) {
        item.value = value;
        if (value.length) {
          item.status = 'CREATED';
        }
      }
      return item;
    });
    setInputValues([...newInputs]);
  };

  function deleteInputField(id) {
    if (inputValues.length == 1) return;
    const deleteInput = inputValues.filter((el) => el.id !== id);
    setInputValues(deleteInput);
  }

  const handleCreateNewTag = () => {
    if (hasDuplicated) {
      setToastDuplicateError(true);
      return;
    }
    setLoading(true);
    const createdInputValues = inputValues.filter((el) => {
      if (el.status === 'CREATED') {
        return el;
      }
    });
    const tagsDescription = createdInputValues.map((el) => [
      { description: el.value }
    ]);
    tagsDescription.map((el) => delete el?.status);
    const flatArrays = tagsDescription.flat();
    if (flatArrays.length == 1 && flatArrays[0].description == '') {
      setIsDescriptionEmpty(true);
      isWithoutValue();
      setLoading(false);
      return;
    }
    const cleanEditTags = flatArrays.filter((el) => el.description !== '');

    const body = {
      tag: tagName,
      values: cleanEditTags.flat()
    };

    const handleResponse = (status, response) => {
      if (response?.data?.message?.includes('already registered')) {
        handleOpenErrorMessage();
        setLoading(false);
        return;
      }

      if (status) {
        clearFilterFields && clearFilterFields();
        getTagListing();
        setLoading(false);
        handleOpenSucessMessage();
        handleClose();
      }
      setLoading(false);
    };

    makePost('tagCreation', body, handleResponse);
  };

  return (
    <Dialog
      maxWidth="sm"
      open={openCreateNewTagDialog}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
      <Snackbar
        open={errorMessage}
        autoHideDuration={6000}
        onClose={handleCloseErrorMassage}
      >
        <Alert onClose={handleCloseErrorMassage} severity="warning">
          Tag com nome já existente!
        </Alert>
      </Snackbar>

      <Snackbar
        open={toastDuplicateError}
        autoHideDuration={6000}
        onClose={handleCloseErrorMassage}
      >
        <Alert onClose={handleCloseErrorMassage} severity="warning">
          Já existe um valor com esse nome!
        </Alert>
      </Snackbar>

      <Snackbar
        open={isDescriptionEmpty}
        autoHideDuration={6000}
        onClose={handleCloseErrorMassage}
      >
        <Alert onClose={handleCloseErrorMassage} severity="warning">
          Adicione pelo menos um valor
        </Alert>
      </Snackbar>

      <DialogTitleTag>
        <Typography>NOVA TAG</Typography>
        <IconButton
          size="small"
          onClick={() => setOpenCreateNewTagDialog(false)}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitleTag>

      {loading && (
        <div sytle={{ margin: '10px' }}>
          <LinearProgress style={{ height: '7px' }} variant="query" />
        </div>
      )}

      <CreateNewTag>
        <InputTags>
          <Input
            onChange={(e) => setTagName(e.target.value)}
            placeholder="Nome da Tag"
          />
        </InputTags>

        <div className="values">
          <Typography>VALORES</Typography>
        </div>

        {inputValues.map((values) => (
          <div className="addValue">
            <InputTags>
              <Input
                placeholder="Adicionar Valor"
                value={values.value}
                onChange={(e) => onChangeValues(values.id, e.target.value)}
                onKeyPress={(event) => {
                  const alreadyHaveEmptyField = inputValues?.find(
                    (el) => el?.value == ''
                  );
                  if (!alreadyHaveEmptyField) {
                    handleKeyPress(event, values.id, values?.value);
                  }
                }}
                id={values.id}
              />
            </InputTags>

            <CreateTagImgDiv>
              {values.isAdd == 'ADD' ? (
                <img
                  src={TrashTag}
                  alt="Trash"
                  onClick={() => deleteInputField(values.id)}
                />
              ) : (
                <img
                  src={correctIconNeutral}
                  alt="CheckIcon"
                  onClick={() => addInputField(values.id, values?.value)}
                />
              )}
            </CreateTagImgDiv>
          </div>
        ))}

        <TagButton>
          <Button
            onClick={handleCreateNewTag}
            variant="contained"
            color="primary"
          >
            Salvar
          </Button>
        </TagButton>
      </CreateNewTag>
    </Dialog>
  );
}

export default CreateNewTagDiaglog;
