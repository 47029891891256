/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';

import Carousel from 'react-multi-carousel';

import ButtonGroup from '../ButtonGroup';


function CountGroupCarousel({
  tabsDaily,
  tabsValue,
  setTabsDaily,
  salesProjectionValue,
  productionValue,
  countValue,
  closureTotal
}) {

  const totalValueTab = (item) => {
    if (item.id === 1) {
      return {
        color: item.active ? '#F7D56E':'#AA8208',
        value: productionValue?.totalDiffMonetary ? productionValue?.totalDiffMonetary : '0,00'
      }
    }

    if (item.id === 2) {
      return {
        color: item.active ? '#F76E79' : '#F53D4C',
        value: countValue?.sumDifferenceTotal || '0,00'
      }
    }

    if (item.id === 3) {
      return {
        color:item.active ? '#7583F0' : '#5062F0',
        value: salesProjectionValue || '0,00'
      }
    }

    return {
      color: '',
      value: '0,00'
    }
  }

  return (
    <div
      className={`${
        tabsDaily?.length ? 'dFlex' : 'dNone'
      } countTabsContainer sticky`}
    >
      <div
        className="tabsCarouselContainer"
        style={{
          maxWidth: '764px'
        }}
      >
          <Carousel
            customButtonGroup={<ButtonGroup />}
            arrows={false}
            centerMode={false}
            infinite={false}
            shouldResetAutoplay={false}
            containerClass="container"
            dotListClass=""
            focusOnSelect={false}
            itemClass=""
            keyBoardControl
            renderButtonGroupOutside
            renderDotsOutside={false}
            responsive={{
              desktop: {
                breakpoint: {
                  max: 3000,
                  min: 1024
                },
                items: 5,
                partialVisibilityGutter: 40
              },
              mobile: {
                breakpoint: {
                  max: 464,
                  min: 0
                },
                items: 1,
                partialVisibilityGutter: 30
              },
              tablet: {
                breakpoint: {
                  max: 1024,
                  min: 464
                },
                items: 2,
                partialVisibilityGutter: 30
              }
            }}
            showDots={false}
            slidesToSlide={6}
            swipeable={false}
          >
            {tabsDaily.map((tab) => (
              <div
                className={`countTabItem ${tab?.active && 'active'}`}
                onClick={() => {
                  setTabsDaily(tabsDaily.map((item) => {
                    if(item.id === tab.id) {
                      return {
                        ...item, active: true
                      }
                    }

                    return {
                      ...item, active: false
                    }
                  }))
                }}
              >
                <p className="mb0 fontSize10">
                    {tab.name}
                </p>

                <p className='mb0' style={{color: totalValueTab(tab).color, fontSize: '9px'}}>
                  {totalValueTab(tab).value < 0 ? '-' : ''} 

                  R$ {totalValueTab(tab)?.value > 0 
                    ? totalValueTab(tab)?.value?.toLocaleString('pt-br', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      }) 
                    : totalValueTab(tab)?.value?.toLocaleString('pt-br', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      }).toString().replace('-', '')}
                </p>
              </div>
            ))}
          </Carousel>
      </div>
    </div>
  );
}

export default CountGroupCarousel;
