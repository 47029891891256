/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react';

import {
  Button,
  Dialog,
  IconButton,
  Input,
  Snackbar,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Alert } from '@material-ui/lab';

import correctIconNeutral from 'images/icons/signals/checks/correct-icon-neutral.svg';
import TrashTag from 'images/icons/signals/removesAndDeletes/TrashTag.svg';
import makePut from 'services/useFetch/makePut';

import { EditTagContainer, ValuesDiv } from './style';

import {
  CreateTagImgDiv,
  DialogTitleTag,
  InputTags,
  TagButton
} from '../CreateNewTag/style';

function EditTagDialog({
  openEditTagDialog,
  setOpenEditTagDialog,
  tag,
  handleOpenEditSuccessMessage,
  tagData,
  setTagData,
  getTagListing
}) {
  const [currentTag, setCurrentTag] = useState({});
  const [tagName, setTagName] = useState(tag?.description);
  const [toastDuplicateError, setToastDuplicateError] = useState(false);
  const [toastGenericError, setToastGenericError] = useState(false);

  const handleCloseErrorMassage = () => {
    setToastDuplicateError(false);
    setToastGenericError(false);
  };

  const handleOpenDuplicateErrorMessage = () => {
    setToastDuplicateError(true);
  };

  const handleOpenGenericErrorMessage = () => {
    setToastGenericError(true);
  };

  const handleClose = () => {
    setOpenEditTagDialog(false);
  };

  const addInputField = (inputDescription = '', id = null) => {
    const sanityInputValue = inputDescription.replace(/\s+/g, ' ').trim();

    if (
      sanityInputValue === '' ||
      sanityInputValue === ' ' ||
      sanityInputValue === null
    )
      return;

    const withoutDeleteTags = currentTag.values.filter(
      (actualTag) => actualTag.status != 'DELETED'
    );
    const hasDuplicate = withoutDeleteTags.filter(
      (tag) => tag.description.toUpperCase() === inputDescription.toUpperCase()
    );

    if (hasDuplicate.length > 1) {
      handleOpenDuplicateErrorMessage();
      return;
    }
    if (hasDuplicate?.status === 'DELETED') {
      hasDuplicate.status = 'CREATED';
    }

    const newCurrentTagValues = currentTag.values.map((item, index) => {
      if (index === id) {
        item.isAdd = 'ADD';
      }
      return item;
    });

    const newTagValues = [
      ...newCurrentTagValues,
      { description: '', status: '', isAdd: '' }
    ];

    setCurrentTag({
      ...currentTag,
      values: newTagValues
    });

    setTimeout(() => {
      const inputElement = document?.getElementById(`${id + 1}`);
      if (!inputElement) return;
      inputElement.focus();
    }, 350);
  };

  const removeTagValue = (valueIndex) => {
    if (
      currentTag?.values?.filter((item) => item.status !== 'DELETED').length ===
      1
    )
      return;

    setCurrentTag({
      ...currentTag,
      values: currentTag.values.map((item, index) => {
        if (valueIndex === index) {
          return {
            ...item,
            status: 'DELETED'
          };
        }
        return { ...item };
      })
    });
  };

  const onChangeValues = (valueIndex, fieldValue) => {
    const clearTags = currentTag?.values?.filter(
      (vtag) => vtag?.status != 'DELETED'
    );

    const hasDuplicate = clearTags.filter(
      (ctag) => ctag?.description.toUpperCase() === fieldValue.toUpperCase()
    );

    if (hasDuplicate.length >= 1) {
      handleOpenDuplicateErrorMessage();
    } else {
      setToastDuplicateError(false);
    }

    const editValues = currentTag?.values?.map((tag, index) => {
      if (valueIndex == index) {
        tag.description = fieldValue;
        if (tag.id) {
          tag.status = 'CHANGED';
        } else {
          tag.status = 'CREATED';
        }
      }
      return tag;
    });
    setCurrentTag({ ...currentTag, description: editValues });
  };

  const saveUpdatedValues = () => {
    const newTags = currentTag.values;
    const body = {
      tag: tagName,
      values: newTags
    };

    const handleResponse = (status) => {
      if (!status) {
        handleOpenGenericErrorMessage();
        return;
      }
      setTagData(
        tagData.map((item) => {
          if (item.id === tag.id) {
            return {
              ...item,
              description: tagName,
              values: newTags
            };
          }
          return item;
        })
      );
      handleOpenEditSuccessMessage();
      handleClose();
      getTagListing();
    };

    makePut('tagCreation', body, handleResponse, `/${currentTag?.id}`);
  };

  const handleKeyPress = (event, id) => {
    if (event.key === 'Enter') {
      addInputField(event.target.value, id);
    }
  };

  useEffect(() => {
    const { values = [] } = tag;

    const filteredTags = values.filter((el) => el.description != '');

    const newValues = filteredTags.map((item) => {
      item.isAdd = 'ADD';
      return item;
    });

    setCurrentTag({
      ...tag,
      values: [...newValues, { description: '', status: '', isAdd: '' }]
    });
  }, [tag]);

  return (
    <Dialog
      maxWidth="sm"
      open={openEditTagDialog}
      onClose={handleClose}
      aria-labelledby="draggable-dialog-title"
    >
      <Snackbar
        open={toastDuplicateError}
        autoHideDuration={6000}
        onClose={handleCloseErrorMassage}
      >
        <Alert onClose={handleCloseErrorMassage} severity="error">
          Já existe esse valor nesta Tag!
        </Alert>
      </Snackbar>

      <Snackbar
        open={toastGenericError}
        autoHideDuration={6000}
        onClose={handleCloseErrorMassage}
      >
        <Alert onClose={handleCloseErrorMassage} severity="error">
          Ocorreu algum erro ao editar a Tag!
        </Alert>
      </Snackbar>

      <DialogTitleTag type="editTag">
        <Typography
          style={{
            fontSize: '9px',
            fontWeight: '700'
          }}
        >
          EDITAR TAG {tagName}
        </Typography>
        <IconButton size="small" onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>
      </DialogTitleTag>

      <EditTagContainer>
        <InputTags>
          <Input onChange={(e) => setTagName(e.target.value)} value={tagName} />
        </InputTags>

        <ValuesDiv>
          <Typography>VALORES</Typography>
        </ValuesDiv>

        {currentTag?.values?.map((value, index) => {
          if (value && value?.status !== 'DELETED') {
            return (
              <InputTags>
                <InputTags>
                  <Input
                    onChange={(e) => onChangeValues(index, e.target.value)}
                    value={value?.description}
                    placeholder="Adicionar Valor"
                    onKeyPress={(e) => {
                      const alreadyHaveEmptyField = currentTag?.values?.find(
                        (el) => el?.description == ''
                      );
                      if (!alreadyHaveEmptyField) {
                        handleKeyPress(e, index);
                      }
                    }}
                    id={index}
                  />
                </InputTags>

                <CreateTagImgDiv>
                  {value.isAdd == 'ADD' ? (
                    <img
                      src={TrashTag}
                      alt="Trash"
                      onClick={() => removeTagValue(index)}
                    />
                  ) : (
                    <img
                      src={correctIconNeutral}
                      alt="CheckIcon"
                      onClick={() => addInputField(value.description, index)}
                    />
                  )}
                </CreateTagImgDiv>
              </InputTags>
            );
          }
        })}

        <TagButton>
          <Button
            onClick={saveUpdatedValues}
            variant="contained"
            color="primary"
          >
            Salvar
          </Button>
        </TagButton>
      </EditTagContainer>
    </Dialog>
  );
}

export default EditTagDialog;
