/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import ArrowLeftIcon from 'images/icons/arrows/arrowLeft.svg';
import ArrowRightIcon from 'images/icons/arrows/arrowRight.svg';

function ButtonGroup({ next, previous, goToSlide, numberItens, ...rest }) {
  const {
    carouselState: { currentSlide }
  } = rest;

  return (
    <div
      className={`carousel-button-group ${
        rest.carouselState.totalItems > 5 ? 'dFlex' : 'dNone'
      }`}
    >
      <button
        onClick={() => {
          !currentSlide
            ? next()
            : goToSlide(0);
        }}
        className="countTabItem arrowCustomButton"
        disabled={!rest.carouselState.currentSlide}
      >
        <img
          style={{ width: '6px'}}
          src={ArrowLeftIcon}
          alt="ArrowDownIcon"
        />
      </button>
      
      <button
        onClick={() => {
          !currentSlide
            ? next()
            : goToSlide(0);
        }}
        className="countTabItem arrowCustomButton"
        disabled={rest.carouselState.currentSlide}
      >
        <img
          style={{ width: '6px'}}
          src={ArrowRightIcon}
          alt="ArrowDownIcon"
        />
      </button>
    </div>
  );
}

export default ButtonGroup;
