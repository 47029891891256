/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/destructuring-assignment */
import React, { useState, useEffect, Suspense } from 'react';

import {
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { makeStyles } from '@material-ui/core/styles';
import { Delete, CheckRounded, ClearRounded } from '@material-ui/icons';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import Axios from 'axios';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import CommonAlert from 'components/CommonAlert/CommonAlert';
import ConfirmModal from 'components/Dialogs/ConfirmModal/ConfirmModal';
import EanSuggestion from 'components/Dialogs/EanSuggestion';
import ProvidersAssociation from 'components/Dialogs/ProvidersAssociation';
import RestaurantsDialog from 'components/Dialogs/RestaurantsDialog';
import SkuModal from 'components/Dialogs/SkuModal/SkuModal';
import ChangeSkuIcon from 'images/icons/arrows/changeIcon.svg';
import CopyClipBoardIcon from 'images/icons/copyClipboards/copyClipboard.svg';
import ClockNavyBlue from 'images/icons/objects/clocks/clockNavyBlue.svg';
import DeleteIconDisabled from 'images/icons/signals/removesAndDeletes/newTrashLightGray.svg';
import DeleteIcon from 'images/icons/signals/removesAndDeletes/trashNavyBlueOutline.svg';
import RegisterProductModal from 'pages/catalog/products/ProductForm';
import getOptions from 'services/getOptions';
import axiosInstanceCatalog from 'services/middlewares/apis/catalog_validate';
import * as ProductsActions from 'store/actions/products';
import copyClipBoard from 'utils/copyClipboard';
import Environment from 'utils/environments';
import formatDocumentNumber from 'utils/formatDocumentNumber';
import ValidationLength from 'utils/utils';

const environments = Environment(process.env.REACT_APP_ENV);

const SimilarSkusDialog = React.lazy(() =>
  import('components/Dialogs/SimilarSkus')
);

function ModalTable({ 
  data,
  setData,
  editMode,
  userInfo, 
  multipleSkuData,
  setMultipleSkuData,
  getId,
  currentSkuIndex,
  onlyViewMode,
  searchModal,
  quantity,
  unit,
  lastReceivedProvider,
  defaultStoreOtpions = [],
  isAdmin = userInfo?.profile?.[0]?.[0]?.name === 'admin',
  setEditProducts, 
  productInfo,
  setUpdateMainList
}) {
  const editModeSku = useState(editMode);
  const [lastReceipt, setLastReceipt] = useState()
  const [loading, setLoading] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState([]);
  const [actions, setActions] = useState([]);
  const [fieldsSettings, setFieldSettings] = useState([
    { id: 1, name: 'typeAddress', value: null, error: false, errorMessage: '' },
    { id: 2, name: 'zipCode', value: null, error: false, errorMessage: '' },
    { id: 3, name: 'number', value: null, error: false, errorMessage: '' }
  ]);
  const [modalSettings, setModalSettings] = useState([
    {
      id: 1,
      name: 'eanSuggestions',
      open: false,
      fullScreen: false,
      maxWidth: 'sm'
    },
    {
      id: 2,
      name: 'providersAssociation',
      open: false,
      fullScreen: false,
      maxWidth: 'sm',
      sku: null
    },
    {
      id: 3,
      name: 'productsAssociation',
      open: false,
      fullScreen: false,
      maxWidth: 'sm'
    },
    {
      id: 4,
      name: 'confirmDialog',
      open: false,
      fullscreen: false,
      maxWidth: 'sm'
    }
  ]);
  const [storeOptions, setStoreOptions] = useState(defaultStoreOtpions);
  const [selectedStores, setSelectedStores] = useState([]);
  const [copied, setCopied] = useState(false);
  const [providerCnpj, setProviderCnpj] = useState('');
  const [debounceTimeList, setDebounceTimeList] = useState([]);
  const [similarSkusOpen, setSimilarSkusOpen] = useState(false);
  const [similarSkuFilterValue, setSimilarSkuFilterValue] = useState('');
  const [similarSkuProvider, setSimilarSkuProvider] = useState('');
  const [skusList, setSkusList] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [onlyViewProduct, setOnlyViewProduct] = useState(false);
  const [isOnlyViewMode, setIsOnlyViewMode] = useState(false);
  const [canAssociateCurrentSku, setCanAssociateCurrentSku] = useState(false);
  const [selectedEan, setSelectedEan] = useState();
  const [selectedSku, setSelectedSku] = useState();
  const [isEditSku, setIsEditSku] = useState(true);
  const [updateOptions, setUpdateOptions] = useState({
    brands: false,
    packageTypes: false
  });
  const [modalStates, setModalStates] = useState({
    modalSku: false,
  });
  const [skuAssociation, setSkuAssociation] = useState();
  const [dialogRestaurantData, setDialogRestaurantData] = useState([]);
  const [dialogRestaurantSettings, setDialogRestaurantSettings] = useState(false);
  const [brands, setBrands] = useState('');
  const [products, setProducts] = useState([]);
  const [items, setItems] = useState([]);
  const [unitsMeasurements, setUnitsMeasurements] = useState([]);
  const [packageTypes, setPackageTypes] = useState([]);
  const [categories, setCategories] = useState([]);
  const [consumptionUnits, setConsumptionUnits] = useState([]);
  const [storageLocations, setStorageLocations] = useState([]);
  const [periodicities, setPeriodicities] = useState([]);
  const [providers, setProviders] = useState([]);
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });

  const useStyles = makeStyles(() => ({
    headerCell: {
      fontFamily: 'Lato',
      fontWeight: 'bold',
      fontSize: '9px',
      margin: '0 10px',
      whiteSpace: 'nowrap',
      background: '#454A67',
      color: '#FFF',
      padding: '0 5px',
    },
    headerCellEnd: {
      fontFamily: 'Lato',
      fontWeight: 'bold',
      fontSize: '9px',
      margin: '0 10px',
      whiteSpace: 'nowrap',
      background: '#454A67',
      color: '#FFF',
      padding: '0 5px',
      textAlign: 'end'
    },
    bodyCell:
      addMode || editMode || deleteMode?.some((item) => item.deleteMode)
        ? {
            fontFamily: 'Lato',
            fontSize: '12px',
            whiteSpace: 'nowrap',
            color: '#e6e6e6'
          }
        : {
            fontFamily: 'Lato',
            fontSize: '12px',
            whiteSpace: 'nowrap'
          },
    bodyCellAdd: {
      fontFamily: 'Lato',
      fontSize: '12px',
      whiteSpace: 'nowrap',
      padding: '0 5px'
    },
    descriptionSku: {
      display: 'inline-grid',
      gridAutoFlow: 'column'
    },
    copyText: {
      cursor: 'pointer'
    },
    textSku: {
      width: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      border: 'none',
      textOverflow: 'ellipsis'
    },
    headerRow: {
      borderBottom: '1px solid #000'
    },
    bodyRow:
      addMode || editMode || deleteMode?.some((item) => item.deleteMode)
        ? {
            background: '#fafafa'
          }
        : {
            background: '#fff'
          },
    headerActions: {
      width: '7%',
      minWidth: '60px',
      maxWidth: '60px',
      padding: '0',
      textAlign: 'center',
      background: '#454A67'
    },
    bodyActions: {
      whiteSpace: 'nowrap',
      padding: '0 !important'
    },
    actionIcon:
      addMode || editMode || deleteMode?.some((item) => item.deleteMode)
        ? {
            margin: '0',
            color: '#e6e6e6'
          }
        : {
            margin: '0',
            color: '#757575'
          },
    inputRoot: {
      fontFamily: 'Lato',
      fontSize: '12px'
    },
    intput: {
      fontFamily: 'Lato',
      fontSize: '12px'
    },
    option: {
      fontFamily: 'Lato',
      fontSize: '12px'
    }
  }));
  
  const classes = useStyles();

  function deleteRow(index, currentProvider) {
    if (multipleSkuData?.length) {
      multipleSkuData.forEach((skuData) => {
        skuData.providers.forEach((provider) => {
          if (provider.id === currentProvider.id) {
            skuData.providers.splice(index, 1);
            data.splice(index, 1);
          }
        });
      });

      setMultipleSkuData(multipleSkuData);
      setData(data);
    } else {
      data.splice(index, 1);
      setData(data);
    }

    setDeleteMode(deleteMode.map((item) => ({ ...item, deleteMode: false })));
    setLoading(false);
  }

  const handleGetSupplierInfos = async (sku) => {
    if (!providerCnpj) {
      try {
        const response = await Axios({
          method: 'get',
          url: `${environments.providers}/providers/${sku.providerId}`
        });

        const cnpj = response.data?.cnpjNumber;
        setProviderCnpj(cnpj);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleInputValue = (sku) => {
    debounceTimeList.forEach((el) => clearTimeout(el));

    const newTimer = setTimeout(() => {
      handleGetSupplierInfos(sku);
    }, 1500);

    setDebounceTimeList([...debounceTimeList, newTimer]);
  };

  // Função para pegar ultimo fornecedor inserido

  const getMostRecentDate = (data) => {
    return data?.reduce((mostRecent, current) => {
      return current?.recivedDate && new Date(current?.recivedDate) > new Date(mostRecent?.recivedDate)
        ? current
        : mostRecent;
    });
  };

  const getSelectedStores = (sku, isUnified = false) => {
    const restaurantsIds = isUnified ?
      sku.map(el => el?.restaurants.map(
        (restaurant) => restaurant.restaurantId
      )).flat()
      : sku?.restaurants?.map(el => el?.restaurantId)

    const associatedRestaurants = storeOptions.filter((option) =>
      restaurantsIds.includes(option.companyId)
    );

    return associatedRestaurants;
  };

  const handleSelectedSku = (sku, canAssociate = false, onlyViewMode = false) => {
    const associatedRestaurants = getSelectedStores(sku);
    setIsOnlyViewMode(onlyViewMode)
    setSelectedStores(associatedRestaurants);
    getOptions(
      `${environments.catalog}/brands`,
      'brands',
      brands,
      setBrands,
      getId,
      { description: sku?.brands?.description },
      null,
      10
    );
    setCanAssociateCurrentSku(canAssociate);
    setSelectedEan(sku?.ean || '');
    setSelectedSku(sku);
    setIsEditSku(true);
    setUpdateOptions((prevState) => ({
      ...prevState,
      brands: false,
      packageTypes: false
    }));
    setModalStates((prevState) => ({ ...prevState, modalSku: true }));
  };

  const handleTransferSku = (sku, confirmDialog = true) => {
    if (confirmDialog) {
      setModalSettings(
        modalSettings.map((modal) =>
          modal.name === 'confirmDialog'
            ? {
                ...modal,
                open: true,
                currentSku: sku,
                title: '',
                content: 'Deseja realmente trocar a embalagem do sku?'
              }
            : modal
        )
      );

      return;
    }

    axiosInstanceCatalog
      .put(
        `${environments.catalog}/skus/platform/providers/transfer`,
        sku
      )
      .then((response) => {
        setUpdateMainList && setUpdateMainList(true)
        setSimilarSkusOpen(false)
        setMessage({
          description: 'Sku transferido com sucesso.',
          status: 'success'
        });
      })
      .catch((error) => {
        setMessage({
          description: 'Erro ao transferir sku.',
          status: 'error'
        });
      });
  }

  const buildValidWord = (word) => {
    const invalidWords = [
      'SEM',
      'S/',
      'COM',
      'C/',
      '(',
      ')',
      'PRE-',
      'A',
      'E',
      'I',
      'O',
      'U',
      'DA',
      'DE',
      'DO',
      'EM',
      'PARA',
      'POR',
      'SOBRE',
      'SOB',
      'ATE',
      'ENTRE',
      '-',
      '.',
      '/',
      'P/'
    ];

    const validWords = word
      ?.split(' ')
      ?.map((item) => item.toUpperCase())
      ?.filter((el) => !invalidWords.includes(el));

    return validWords?.slice(0, 2).join(' ');
  };

  useEffect(() => {
    if(data?.length){
      const mostRecentObject = getMostRecentDate(data);
      setLastReceipt(mostRecentObject);
    }
  }, [data]);

  useEffect(() => {
    if (!storeOptions.length) {
      getOptions(
        `${environments.restaurants}/companies`,
        'restaurants',
        storeOptions,
        setStoreOptions,
        getId
      );
    }

    if (!modalStates.modalSku) return;

    !brands?.length &&
      getOptions(
        `${environments.catalog}/brands`,
        'brands',
        brands,
        setBrands,
        getId,
        null,
        null,
        10
      );

    !packageTypes.length &&
      getOptions(
        `${environments.catalog}/typePackages`,
        'packageTypes',
        packageTypes,
        setPackageTypes,
        getId
      );
    !unitsMeasurements.length &&
      getOptions(
        `${environments.catalog}/unitsMeasurements`,
        'unitsMeasurements',
        unitsMeasurements,
        setUnitsMeasurements,
        getId
      );
    !providers.length &&
      getOptions(
        `${environments.providers}/providers`,
        'providers',
        providers,
        setProviders,
        getId,
        null,
        null,
        10
      );
    !consumptionUnits.length &&
      getOptions(
        `${environments.catalog}/consumptionUnits`,
        'consumptionUnits',
        consumptionUnits,
        setConsumptionUnits,
        getId
      );
    !storageLocations.length &&
      getOptions(
        `${environments.catalog}/storageLocations`,
        'storageLocations',
        storageLocations,
        setStorageLocations,
        getId
      );
    !periodicities.length &&
      getOptions(
        `${environments.catalog}/periodicities`,
        'periodicities',
        periodicities,
        setPeriodicities,
        getId
      );
    !categories.length &&
      getOptions(
        `${environments.catalog}/categories`,
        'categories',
        categories,
        setCategories,
        getId,
        null,
        null,
        10
      );
  }, [modalStates.modalSku]);

  // Produtos
  useEffect(() => {
    if (
      !modalStates.modalSku &&
      products.length
    )
      return;

    axiosInstanceCatalog
      .get(
        `${environments.catalog}/products/internal/skus?page=1&size=10&originId=${getId}`
      )
      .then((response) => {
        const productsJson = response.data.content;
        setProducts(productsJson);
      })
      .catch((error) => { });
  }, [getId, modalStates.modalSku]);

  useEffect(() => {
    setEditProducts({
      id: selectedProduct
    });
  }, [selectedProduct, setEditProducts]);

  return (
    <>
      {!isAdmin &&
        <Grid
          className='mb5'
          container
          xs={12}
          justify="center"
        >
          {/* Título da tabela */}
          <Grid
            container
            item
            xs={8}
            justify="flex-start"
            alignItems="center"
          >
            <Typography className="bold fontSizeDefault">
              FORNECEDORES / EMBALAGENS CADASTRADOS PARA ESTE RESTAURANTE
            </Typography>
          </Grid>
          <Grid container item xs={4} />
        </Grid>
      }

      <ClickAwayListener
        onClickAway={() => {
          setActions(actions.map((action) => ({ ...action, action: 'list' })));
          setAddMode(false);
          setDeleteMode(
            deleteMode.map((item) => ({ ...item, deleteMode: false }))
          );
          setFieldSettings(
            fieldsSettings.map((field) => ({
              ...field,
              error: false,
              errorMessage: ''
            }))
          );
        }}
      >
        <TableContainer
          component={Paper}
          style={{
            borderRadius: 4,
            boxShadow: '0px 4px 4px 0px #00000080'

          }}
        >
          <Table size="small">
            <TableHead>
              <TableRow classes={{ head: classes.headerRow }}>
                <TableCell width="22%" className={classes.headerCell}>
                  FORNECEDOR
                </TableCell>

                <TableCell width="5%" className={classes.headerCell}>
                  UF
                </TableCell>

                <TableCell width="10%" className={classes.headerCell}>
                  CÓD. FORNEC.
                </TableCell>

                <TableCell width="41%" className={classes.headerCell}>
                  DESCRIÇÃO FORNECEDOR
                </TableCell>

                <TableCell width="6%" className={`${classes.headerCellEnd} p10`}>
                  R$ EMB.
                </TableCell>

                <TableCell width="4%" className={`${classes.headerCell} p20h`}>
                  EMBALAGEM
                </TableCell>

                <TableCell width="6%" className={classes.headerCellEnd}>
                  QTD. EMB.
                </TableCell>

                <TableCell width="10%" className={classes.headerCellEnd}>
                  R$ SKU
                </TableCell>

                <TableCell className={classes.headerActions} />
                <TableCell className={classes.headerActions} />
              </TableRow>
            </TableHead>

            <TableBody>
              {data.length ? data?.map((sku, index) => {
                deleteMode.push({ tableIndex: index, deleteMode: false });

                return (
                  <TableRow hover={false}>
                    <TableCell
                      className={classes.bodyCellAdd}
                      style={{ paddingRight: 0 }}
                    >
                        <div
                        style={{gap: '10px'}}
                          className={`${classes.descriptionSku}`}
                          onMouseEnter={() => handleInputValue(sku)}
                          onMouseLeave={() => setProviderCnpj('')}
                        >
                          <Tooltip
                            title={
                              <div>
                                <div>{`${sku.name || ''} ${providerCnpj ? `- ${providerCnpj}` : ''}`}</div>
                                <div>{sku?.suppliers?.addresses[0]?.city || ''} {`${sku?.suppliers?.addresses[0]?.state ? `- ${sku?.suppliers?.addresses[0]?.state}` : ''}`}</div>
                              </div>
                            }>
                            <div>
                              {sku.name.substring(0, 25)}...
                            </div>
                          </Tooltip>
                        </div>
                    </TableCell>

                    <TableCell className={classes.bodyCellAdd}>
                      {sku?.suppliers?.addresses[0]?.state ? sku?.suppliers?.addresses[0]?.state : '-'}
                    </TableCell>

                    <TableCell className={classes.bodyCellAdd}>
                      {ValidationLength(
                        sku.codeProvider ? sku.codeProvider : '',
                        15
                      )}
                    </TableCell>

                    <TableCell className={`${classes.bodyCellAdd}`}>
                      <div className={`${classes.descriptionSku}`}>
                        {sku?.descriptionSku && sku?.descriptionSku !== null ? (
                          <Tooltip
                            placement="bottom"
                            title={copied ? 'Copiado' : 'Copiar'}
                          >
                            <a
                              onMouseLeave={() => {
                                setTimeout(() => {
                                  setCopied(false);
                                }, 1000);
                              }}
                              onClick={() => {
                                copyClipBoard(
                                  'sku-description',
                                  index,
                                  currentSkuIndex
                                );
                                setCopied(true);
                              }}
                              className={classes.copyText}
                            >
                              <img
                                style={{
                                  marginRight: '5px',
                                  marginBottom: '3px'
                                }}
                                src={CopyClipBoardIcon}
                                alt="CopyClipboard"
                              />
                            </a>
                          </Tooltip>
                        ) : null}

                        <Tooltip
                          placement="bottom"
                          title={sku?.descriptionSku}
                          disableHoverListener={sku?.descriptionSku?.length < 30}
                        >
                          <div
                            className={classes.textSku}
                            id={`sku-description-${index}${currentSkuIndex}`}
                          >
                            {sku?.descriptionSku}
                          </div>
                        </Tooltip>
                      </div>
                    </TableCell>

                    <TableCell className={`${classes.bodyCellAdd} alnright`}>
                      <CurrencyTextField
                        size="small"
                        currencySymbol=""
                        minimumValue="0"
                        outputFormat="string"
                        digitGroupSeparator="."
                        decimalCharacter=","
                        decimalPlaces={3}
                        decimalPlacesShownOnFocus={3}
                        type="text"
                        required
                        disabled={onlyViewMode || searchModal}
                        name="providerPurchaseLast"
                        fullWidth
                        placeholder="R$ Emb."
                        className="textFieldSmall right"
                        value={
                          // eslint-disable-next-line no-nested-ternary
                          (editModeSku || sku.skuId) && sku.purchaseLast
                            ? sku.purchaseLast || 0
                            : sku.purchaseLastUnitary
                            ? sku.purchaseLastUnitary * (sku.quantity || 0)
                            : 0
                        }
                        onBlur={(event, value) => {
                          if (multipleSkuData) {
                            setMultipleSkuData(
                              multipleSkuData.map((item, itemIndex) =>
                                itemIndex === currentSkuIndex
                                  ? {
                                      ...item,
                                      providers: data.map((item) =>
                                        item === sku
                                          ? {
                                              ...item,
                                              purchaseLast: parseFloat(value),
                                              purchaseLastUnitary:
                                                parseFloat(value) /
                                                parseFloat(sku.quantity || '1'),
                                              unity:
                                                parseFloat(value) /
                                                parseFloat(sku.quantity || '1')
                                            }
                                          : item
                                      )
                                    }
                                  : item
                              )
                            );
                          }

                          setData(
                            data.map((item) =>
                              item === sku
                                ? {
                                    ...item,
                                    purchaseLast: parseFloat(value),
                                    purchaseLastUnitary:
                                      parseFloat(value) /
                                      parseFloat(sku.quantity || '1'),
                                    unity:
                                      parseFloat(value) /
                                      parseFloat(sku.quantity || '1')
                                  }
                                : item
                            )
                          );
                        }}
                      />
                    </TableCell>

                    <TableCell className={`${classes.bodyCellAdd} p20h`}>
                      {`E${sku.quantity ? sku.quantity : 0}x${
                        quantity || 0
                      }${unit?.abbreviation || ''}`}
                    </TableCell>

                    <TableCell className={classes.bodyCellAdd}>
                      <CurrencyTextField
                        size="small"
                        currencySymbol=""
                        minimumValue="0"
                        outputFormat="string"
                        digitGroupSeparator="."
                        decimalCharacter=","
                        decimalPlaces={2}
                        decimalPlacesShownOnFocus={2}
                        type="text"
                        required
                        disabled={onlyViewMode || searchModal}
                        name="packageQuantity"
                        fullWidth
                        placeholder="Qtd. Embalagem"
                        className="textFieldSmall right"
                        value={sku.quantity || 0}
                        InputProps={{
                          style: { fontFamily: 'Lato', fontSize: '12px' }
                        }}
                        onBlur={(event, value) => {
                          if (multipleSkuData) {
                            setMultipleSkuData(
                              multipleSkuData.map((item, itemIndex) =>
                                itemIndex === currentSkuIndex
                                  ? {
                                      ...item,
                                      providers: data.map((item) =>
                                        item === sku
                                          ? {
                                              ...item,
                                              quantity: parseFloat(value),
                                              unity:
                                                parseFloat(sku.purchaseLast) /
                                                parseFloat(value),
                                              purchaseLastUnitary:
                                                parseFloat(sku.purchaseLast) /
                                                parseFloat(value)
                                            }
                                          : item
                                      )
                                    }
                                  : item
                              )
                            );
                          }

                          setActions((action) =>
                            action.data === sku
                              ? {
                                  ...action,
                                  data: {
                                    ...action.data,
                                    quantity: parseFloat(value),
                                    unity:
                                      parseFloat(sku.purchaseLast) /
                                      parseFloat(value),
                                    purchaseLastUnitary:
                                      parseFloat(sku.purchaseLast) /
                                      parseFloat(value)
                                  }
                                }
                              : action
                          );

                          setData(
                            data.map((item) =>
                              item === sku
                                ? {
                                    ...item,
                                    quantity: parseFloat(value),
                                    unity:
                                      parseFloat(sku.purchaseLast) /
                                      parseFloat(value),
                                    purchaseLastUnitary:
                                      parseFloat(sku.purchaseLast) /
                                      parseFloat(value)
                                  }
                                : item
                            )
                          );
                        }}
                      />
                    </TableCell>

                    <TableCell
                      className={`${classes.bodyCellAdd} alnright pRelative`}
                    >
                      <Tooltip
                        title={
                          <div
                            className="dFlex flexColumn"
                            style={{ padding: '4px 2px' }}
                          >
                            <p className="mb0">
                              <b>DATA:</b>{' '}
                              {sku?.recivedDate ? moment(
                                sku?.recivedDate
                              ).format('DD/MM/YYYY')
                              : '-'}
                            </p>

                            {/* <p className="mb0">
                              <b>CNPJ:</b>{' '}
                              {formatDocumentNumber(sku?.suppliers?.cnpjNumber) || '-'}
                            </p> */}

                            <p className="mb0">
                              <b>RESTAURANTE:</b>{' '}
                              {storeOptions?.find(el => el?._id === sku?.lastOriginId)?.companyName}
                            </p>
                          </div>
                        }
                        disableHoverListener={!isAdmin}
                        aria-label="add"
                      >
                        <div>
                          <CurrencyTextField
                            size="small"
                            currencySymbol=""
                            minimumValue="0"
                            outputFormat="string"
                            digitGroupSeparator="."
                            decimalCharacter=","
                            decimalPlaces={3}
                            decimalPlacesShownOnFocus={3}
                            type="text"
                            required
                            disabled={onlyViewMode || searchModal}
                            name="skuPurchaseLast"
                            fullWidth
                            placeholder="R$ SKU"
                            className="textFieldSmall right"
                            value={
                              (editModeSku || sku.skuId) &&
                              sku.purchaseLast &&
                              sku.quantity
                                ? sku.purchaseLast / sku.quantity || 0
                                : (sku.purchaseLastUnitary
                                    ? sku.purchaseLastUnitary
                                    : 0) || 0
                            }
                            onBlur={(event, value) => {
                              if (value) {
                                if (multipleSkuData) {
                                  setMultipleSkuData(
                                    multipleSkuData.map((item, itemIndex) =>
                                      itemIndex === currentSkuIndex
                                        ? {
                                            ...item,
                                            providers: data.map((item) =>
                                              item === sku
                                                ? {
                                                    ...item,
                                                    unity: parseFloat(value),
                                                    purchaseLast: sku.quantity
                                                      ? parseFloat(value) *
                                                        sku.quantity
                                                      : 0,
                                                    purchaseLastUnitary:
                                                      parseFloat(value)
                                                  }
                                                : item
                                            )
                                          }
                                        : item
                                    )
                                  );
                                }

                                setData(
                                  data.map((item) =>
                                    item === sku
                                      ? {
                                          ...item,
                                          unity: parseFloat(value),
                                          purchaseLast: sku.quantity
                                            ? parseFloat(value) * sku.quantity
                                            : 0,
                                          purchaseLastUnitary: parseFloat(value)
                                        }
                                      : item
                                  )
                                );
                              } else {
                                setData(
                                  data.map((item, i) => {
                                    if (index === i) {
                                      return {
                                        ...value
                                      };
                                    }
                                    return item;
                                  })
                                );
                              }
                            }}
                          />
                        </div>
                      </Tooltip>
                      
                    </TableCell>

                    <TableCell
                      className={`${classes.bodyCellAdd} alnright pRelative`}
                    >
                      {sku.id === lastReceipt?.id ? (
                        <Tooltip
                          title={
                            <div
                              className="dFlex flexColumn"
                              style={{ padding: '4px 2px' }}
                            >
                              <p className="mb0">
                                <b>DATA:</b>{' '}
                                {lastReceivedProvider?.recivedDate ? moment(
                                  lastReceivedProvider?.recivedDate
                                ).format('DD/MM/YYYY') : moment(
                                  sku?.recivedDate
                                ).format('DD/MM/YYYY')}
                              </p>

                              <p className="mb0">
                                <b>CNPJ:</b>{' '}
                                {formatDocumentNumber(sku?.suppliers?.cnpjNumber)}
                              </p>

                            </div>
                          }
                          disableHoverListener={!lastReceipt}
                          aria-label="add"
                        >
                          <img
                            style={{
                              width: '12px',
                              marginRight: '24px',
                              marginBottom: '5px'
                            }}
                            src={ClockNavyBlue}
                            alt="ClockNavyBlue"
                          />
                        </Tooltip>
                      ) : ''}
                      <Tooltip
                        disableHoverListener={onlyViewMode}
                        title="Trocar Embalagem de SKU"
                      >
                        <IconButton style={{padding: '0', margin: '0'}}>
                          <img
                            style={{
                              width: '12px',
                              cursor: onlyViewMode ? 'auto' : 'pointer'
                            }}
                            src={ChangeSkuIcon}
                            alt="Change Sku"
                            onClick={(_) => {
                              if (onlyViewMode) return;

                              const firstTwoDescriptionWords = buildValidWord(
                                sku?.descriptionSku
                              );

                              setSimilarSkusOpen(true)
                              setSimilarSkuFilterValue(firstTwoDescriptionWords)
                              setModalSettings(
                                modalSettings.map((modal) =>
                                  modal.name === 'confirmDialog'
                                    ? {
                                        ...modal,
                                        oldSkuInfo: sku
                                      }
                                    : modal
                                )
                              );
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </TableCell>

                    {deleteMode.find((item) => item.tableIndex === index)
                      .deleteMode ? (
                      <TableRow
                        className={classes.bodyRow}
                        style={{
                          display: 'flex',
                          height: '36px',
                          borderBottom: '1px solid rgba(224, 224, 224, 1)'
                        }}
                      >
                        <TableCell
                          className={classes.bodyCellAdd}
                          style={{
                            width: '60%',
                            padding: '1px 50px 0 0',
                            border: 'none',
                            display: 'flex',
                            alignItems: 'center'
                          }}
                        >
                          Deseja excluir?
                        </TableCell>

                        <Grid
                          container
                          xs={12}
                          justify="center"
                          style={{ width: '40%', display: 'flex' }}
                        >
                          <Grid container justify="center" xs={6}>
                            <IconButton
                              disabled={onlyViewMode || searchModal}
                              style={{ outline: 0 }}
                              edge="start"
                              size="small"
                              color="inherit"
                              onClick={() => {
                                setLoading(true);
                                deleteRow(index, sku);
                                setDeleteMode(
                                  deleteMode.map((item) =>
                                    item.tableIndex === index
                                      ? { ...item, deleteMode: false }
                                      : item
                                  )
                                );
                              }}
                            >
                              <CheckRounded />
                            </IconButton>
                          </Grid>

                          <Grid container justify="center" xs={6}>
                            <IconButton
                              disabled={onlyViewMode || searchModal}
                              style={{ outline: 0 }}
                              edge="start"
                              size="small"
                              color="inherit"
                              onClick={() => {
                                setActions(
                                  actions.map((action) =>
                                    action.data === sku
                                      ? { ...action, action: 'list' }
                                      : action
                                  )
                                );
                                setDeleteMode(
                                  deleteMode.map((item) =>
                                    item.tableIndex === index
                                      ? { ...item, deleteMode: false }
                                      : item
                                  )
                                );
                              }}
                            >
                              <ClearRounded />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </TableRow>
                    ) : (
                      <TableCell className={classes.bodyActions}>
                        <Grid container xs={12} justify="center">
                          <Grid container item justify="center" xs={12}>
                            <IconButton
                              edge="start"
                              style={{ outline: 0 }}
                              size="small"
                              color="inherit"
                              disabled={
                                addMode ||
                                (editMode && !isAdmin) ||
                                deleteMode.some((item) => item.deleteMode) ||
                                onlyViewMode ||
                                searchModal
                              }
                              className={classes.actionIcon}
                              onClick={(_) => {
                                setActions(
                                  actions.map((action) =>
                                    action.data === sku
                                      ? { ...action, action: 'delete' }
                                      : action
                                  )
                                );
                                setDeleteMode(
                                  deleteMode.map((item) =>
                                    item.tableIndex === index
                                      ? { ...item, deleteMode: true }
                                      : item
                                  )
                                );
                              }}
                            >
                              <img
                                style={{
                                  width: '12px',
                                }}
                                src={
                                  addMode ||
                                  (editMode && !isAdmin) ||
                                  deleteMode.some((item) => item.deleteMode) ||
                                  onlyViewMode ||
                                  searchModal
                                  ? DeleteIconDisabled
                                  : DeleteIcon
                                }
                                alt="Delete Icon"
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </TableCell>
                    )}
                  </TableRow>
                );
              }) || null
              :
                <TableRow hover={false} colSpan="8">
                  <TableCell
                    className={classes.bodyCellAdd}
                    style={{ width: '100%', textAlign: 'center', fontWeight: 'bold', fontSize: '12px' }}
                    colSpan="8"
                  >
                    Nenhum Fornecedor/Embalagem cadastrado para este restaurante
                  </TableCell>
                </TableRow>
              }

              {loading ? (
                <TableRow>
                  <TableCell colSpan="8" sytle={{ margin: '10px' }}>
                    <LinearProgress variant="query" />
                  </TableCell>
                </TableRow>
              ) : null}
            </TableBody>
          </Table>

          {isAdmin &&
            modalSettings.find((modal) => modal.name === 'eanSuggestions').open && (
            <EanSuggestion
              modalSettings={modalSettings}
              setModalSettings={setModalSettings}
              onlyView
              userInfo={userInfo}
            />
          )}
        </TableContainer>
      </ClickAwayListener>

      <CommonAlert
        open={!!message.description}
        severity={message.status}
        onClose={() => {
          setMessage({ ...message, description: '', status: '' });
        }}
        messagePersonal={message.description}
        width="50%"
      />

      {similarSkusOpen && (
        <Suspense fallback={<span />}>
          <SimilarSkusDialog
            open={similarSkusOpen}
            setSimilarSkusOpen={setSimilarSkusOpen}
            skuDescription={similarSkuFilterValue}
            similarSkuProvider={similarSkuProvider}
            skusList={skusList}
            handleSelectedSku={handleSelectedSku}
            setModalSettings={setModalSettings}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            setOnlyViewProduct={setOnlyViewProduct}
            setSkusList={setSkusList}
            setSkuAssociation={setSkuAssociation}
            setDialogRestaurantData={setDialogRestaurantData}
            setDialogRestaurantSettings={setDialogRestaurantSettings}
            handleTransferSku={handleTransferSku}
          />
        </Suspense>
      )}

      {modalStates.modalSku &&
        products.length &&
        unitsMeasurements.length &&
        typeof brands === 'object' &&
        packageTypes.length ? (
        <SkuModal
          canAssociateCurrentSku={canAssociateCurrentSku}
          selectedStores={selectedStores}
          storeOptions={storeOptions}
          updateOptions={updateOptions}
          setUpdateOptions={setUpdateOptions}
          selectedEan={selectedEan}
          fullScreen
          maxWidth={null}
          fullWidth
          open={modalStates.modalSku}
          data={
            selectedSku && selectedSku.providers ? selectedSku.providers : []
          }
          editMode={isEditSku}
          getId={getId}
          sku={selectedSku}
          userInfo={userInfo}
          items={items}
          providers={providers}
          products={products}
          categories={categories}
          brands={brands}
          packageTypes={packageTypes}
          storageLocations={storageLocations}
          periodicities={periodicities}
          unitsMeasurements={unitsMeasurements}
          consumptionUnits={consumptionUnits}
          onlyViewMode={isOnlyViewMode}
          onClose={() => {
            setIsOnlyViewMode(false)
            setSelectedEan('');
            setModalStates({ ...modalStates, modalSku: false });
            setSelectedStores([]);
          }}
          adminCatalogModal
          originPage='catalog'
        />
      ) : null}

      {modalSettings.find((modal) => modal.name === 'providersAssociation').open &&
        <ProvidersAssociation
          skusList={skusList}
          providers={providers}
          skuAssociation={skuAssociation}
          modalSettings={modalSettings}
          setModalSettings={setModalSettings}
          setSkuAssociation={setSkuAssociation}
        />
      }

      {dialogRestaurantSettings&&
        <RestaurantsDialog
          openModal={dialogRestaurantSettings}
          handleClose={() => setDialogRestaurantSettings(false)}
          restaurantData={dialogRestaurantData}
        />
      }

      {modalSettings.find((modal) => modal.name === 'productsAssociation').open && productInfo?.data && (
        <RegisterProductModal
          dialogOpen={
            modalSettings.find(
              (modal) => modal.name === 'productsAssociation'
            ).open
          }
          isModal
          onlyView={onlyViewProduct}
          consumptionUnitOptions={consumptionUnits}
          periodicityOptions={periodicities}
          storageLocationOptions={storageLocations}
          onClose={() => {
            setEditProducts('')
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'productsAssociation'
                  ? { ...modal, open: false }
                  : modal
              )
            );
          }}
        />
      )}

      {modalSettings.find((modal) => modal.name === 'confirmDialog')?.open && (
        <ConfirmModal
          open={
            modalSettings.find((modal) => modal.name === 'confirmDialog').open
          }
          title={
            modalSettings.find((modal) => modal.name === 'confirmDialog')?.title
          }
          content={
            <Typography className="bold">
              {modalSettings?.find(modal => modal?.name === 'confirmDialog')?.content}
            </Typography>
          }
          onClose={() => {
            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'confirmDialog'
                  ? {
                      ...modal,
                      open: false,
                      currentSku: null,
                      title: '',
                      content: ''
                    }
                  : modal
              )
            );
          }}
          cancelText="Não"
          onConfirm={() => {
            const oldSku = modalSettings.find((modal) => modal.name === 'confirmDialog')?.oldSkuInfo
            const newSku = modalSettings.find((modal) => modal.name === 'confirmDialog')?.currentSku
            
            const bodyUpdated = {
              oldSkuId: oldSku?.skuId,
              newSkuId: newSku?.id,
              codeProvider: oldSku?.codeProvider,
              providerId: oldSku?.providerId
            };

            handleTransferSku(bodyUpdated, false);

            setModalSettings(
              modalSettings.map((modal) =>
                modal.name === 'confirmDialog'
                  ? {
                      ...modal,
                      open: false,
                      currentUser: null,
                      title: '',
                      body: ''
                    }
                  : modal
              )
            );
          }}
          confirmText="Sim"
        />
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  productInfo: state.products
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ ...ProductsActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModalTable);