/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-case-declarations */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
/* eslint-disable no-new */
/* eslint-disable radix */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import React from 'react';

import {
  Collapse,
  Fade,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import format from 'date-fns/format';
import capitalize from 'lodash.capitalize';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import InputNumber from 'components/Inputs/ManufactureInputs/InputNumber';
import { PrintLabel } from 'components/PrintLabel';
import EngineIcon from 'images/icons/configsAndSetups/configIconBlue.svg';
import EngineIconGrey from 'images/icons/configsAndSetups/configIconGrey.svg';
import ProductionIcon from 'images/icons/objects/bells/productionIcon.svg';
import storeIconWS from 'images/icons/objects/stores/storeIconWS.svg';
import VirtualProduction from 'images/icons/signals/alerts/virtualProductionIcon.svg';
import CheckedCircleIcon from 'images/icons/signals/checks/checkedCircle.svg';
import CancelProduction from 'images/icons/signals/closesAndCancels/cancelProduction.svg';
import DeleteIcon from 'images/icons/signals/closesAndCancels/deleteBlanc2.svg';
import PauseProductionIcon from 'images/icons/signals/pausesAndStops/pauseProductionIcon.svg';
import PlayIcon from 'images/icons/signals/playsAndStarts/playButton.svg';
import { ButtonStatus } from 'styles/buttons';
import { increaseDate } from 'utils/formatDate';
import { getRestaurantbyId } from 'utils/getRestaurants';
import { checkIsBefore } from 'utils/isBefore';
import ValidationLength from 'utils/utils';

import { useStyles, PercentBox, TooltipFinish } from './styles';
import { handleFields } from './utils';
import ImageWrapper from 'components/ImageWrapper';

function DesktopRows({
  cards,
  setManufacture,
  setCurrentTransformationCardItems,
  setUpdateValue,
  updateValue,
  handleCardQuantity,
  handleItemDiscard,
  optionsDiscard,
  getDuration,
  handleClickOpenModal,
  setLoading,
  manufactures,
  loading,
  handleUpdate,
  handlePopoverClick,
  setCardDate,
  setPopoverData,
  handleOpenItems,
  diffViewMonetary,
  handleDialogClickOpen,
  setDeleteData,
  modalStates,
  setModalStates,
  stores,
  companyActive,
  companyGroup,
  updateQuantityInitialValue,
}) {
  const classes = useStyles();

  function handleRecipe(manufacture, open, cardIndex) {
    return (
      <tr>
        <td colSpan="13" className="tableColorHover">
          <Collapse
            in={open}
            timeout="0"
            unmountOnExit
            className={open && 'expanded'}
          >
            <table
              width="55%"
              cellPadding="0"
              cellSpacing="0"
              border="0"
              style={{ border: 'none' }}
              className="dropDownTable"
            >
              <thead>
                <tr height="25">
                  <th
                    width="133"
                    style={{
                      paddingLeft: '24px',
                      fontSize: '9px',
                      color: '#666770'
                    }}
                    className="subtitle bold"
                  >
                    ITENS
                  </th>
                  <th
                    width="45"
                    className="subtitle right pr3 bold"
                    style={{
                      fontSize: '9px',
                      color: '#666770'
                    }}
                  >
                    QTDE
                  </th>
                  <th
                    width="10"
                    className="subtitle pr8 bold"
                    style={{
                      fontSize: '9px',
                      color: '#666770'
                    }}
                  >
                    UN
                  </th>
                  <th
                    width="70"
                    className="subtitle right pr8 bold"
                    style={{
                      fontSize: '9px',
                      color: '#666770'
                    }}
                  >
                    DESCARTE
                  </th>
                  <th
                    width="90"
                    className="subtitle bold"
                    style={{
                      fontSize: '9px',
                      color: '#666770'
                    }}
                  >
                    JUSTIFICATIVA
                  </th>
                </tr>
              </thead>

              <tbody>
                {manufacture.items?.map((item, index) => {
                  const handleItemQuantity = (value, labelQuantity = '') => {
                    if (!value) {
                      return;
                    }

                    setManufacture(manufacture);

                    handleCardQuantity(
                      cardIndex,
                      manufacture.quantity,
                      index,
                      parseFloat(value),
                      undefined,
                      true,
                      labelQuantity
                    );
                  };

                  const exists = cards[cardIndex].items ? item : null;

                  if (
                    cards[cardIndex].items.length < manufacture.items.length &&
                    !exists
                  ) {
                    cards[cardIndex].items.push({
                      id: index,
                      quantity: item.quantity,
                      initialQuantity: item.quantity,
                      discard: { quantity: 0, reason: null }
                    });
                  }

                  const discardReason = item?.discard?.length
                    ? capitalize(
                        item?.discard[0]?.justification?.description
                      ) || ''
                    : capitalize(item?.discard?.justification?.description) ||
                      '';

                  return (
                    <tr>
                      <td
                        className={`description itemProductionTableDescription ${item?.main && 'productMain'}`}
                        style={{ paddingLeft: '24px' }}
                      >
                        <div className="dFlex justifyBetween alignCenter">
                          {ValidationLength(
                            item.item, 
                            16, 
                            null, 
                            null, 
                            null, 
                            null, 
                            item?.itemProcess?.description ? `- ${item?.itemProcess?.description}` : '',
                          )}

                          <ImageWrapper
                            width="30px"
                            height="28px"
                            url={item?.image}
                          />
                        </div>
                      </td>

                      <td className="description pr3 right">
                        {manufacture.status !== 'WAITING' ? (
                          item.quantity ? (
                            parseFloat(item.quantity).toLocaleString('pt-br', {
                              minimumFractionDigits: 3
                            })
                          ) : (
                            '0,000'
                          )
                        ) : (
                          <>
                            <InputNumber
                              updateQuantityInitialValue={updateQuantityInitialValue}
                              setModalStates={setModalStates}
                              modalStates={modalStates}
                              setUpdateValue={setUpdateValue}
                              updateValue={updateValue}
                              disabled={
                                manufacture?.status !== 'WAITING' &&
                                manufacture?.status !== 'START' &&
                                manufacture?.status !== 'PAUSE'
                              }
                              initialValue={item.quantity}
                              // validUnit={item?.un?.abbreviation === 'un'}
                              handleValue={handleItemQuantity}
                              fontSize="12px"
                              fontWeight="normal"
                              width="100%"
                            />
                            {/* {(item.initialQuantity
                                ? item.quantity == 0
                                : item.quantity * manufacture.quantity == 0
                              ) &&
                                <p className="requiredWarning">
                                  Não pode ser 0
                                </p>
                              } */}
                          </>
                        )}
                      </td>

                      <td className="description pr8">
                        {item?.un?.abbreviation}
                      </td>

                      <td className="description pr8 right">
                        <CurrencyTextField
                          margin="none"
                          variant="standard"
                          size="small"
                          currencySymbol=""
                          minimumValue="0"
                          maximumValue="999999999"
                          outputFormat="string"
                          decimalCharacter=","
                          digitGroupSeparator="."
                          decimalPlaces={3}
                          decimalPlacesShownOnBlur={3}
                          decimalPlacesShownOnFocus={3}
                          disabled={
                            manufacture?.status !== 'WAITING' &&
                            manufacture?.status !== 'START' &&
                            manufacture?.status !== 'PAUSE'
                          }
                          type="text"
                          name="quantity"
                          fullWidth
                          value={
                            item?.discard?.length
                              ? item?.discard[0]?.quantity || ''
                              : item?.discard?.quantity || ''
                          }
                          style={{ width: '100%', height: 15 }}
                          InputProps={{
                            maxLength: 8,
                            classes: { input: classes.input }
                          }}
                          onBlur={(_, value) => {
                            handleItemDiscard(
                              manufacture._id,
                              index,
                              value,
                              discardReason,
                              discardReason && value,
                              item
                            );
                          }}
                        />
                      </td>

                      <td className="description">
                        <Autocomplete
                          size="small"
                          classes={{
                            inputRoot: classes.inputRoot,
                            option: classes.option
                          }}
                          id="asynchronous-demo"
                          className="autocompleteSmall autocompletePtChild0"
                          onChange={(option, value) => {
                            if (value) {
                              handleItemDiscard(
                                manufacture._id,
                                index,
                                item?.discard?.length
                                  ? item?.discard[0]?.quantity
                                  : item?.discard?.quantity,
                                value,
                                (item?.discard?.length
                                  ? item?.discard[0]?.quantity
                                  : item?.discard?.quantity) && value,
                                item
                              );
                            } else {
                              handleItemDiscard(
                                manufacture._id,
                                index,
                                item?.discard?.length
                                  ? item?.discard[0]?.quantity
                                  : item?.discard?.quantity,
                                '',
                                false,
                                item
                              );
                            }
                          }}
                          getOptionLabel={(option) => option.description}
                          options={optionsDiscard.map((option) => ({
                            ...option,
                            description: capitalize(option?.description)
                          }))}
                          value={
                            optionsDiscard
                              .map((option) => ({
                                ...option,
                                description: capitalize(option?.description)
                              }))
                              .filter(
                                (option) => option.description === discardReason
                              )[0]
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              type="text"
                              required
                              style={{ width: '100%', height: 20 }}
                              placeholder="Justifique..."
                              InputLabelProps={{
                                style: { fontFamily: 'Lato', fontSize: '12px' }
                              }}
                            />
                          )}
                        />

                        <Tooltip
                          title="Preencha este campo."
                          placement="bottom"
                          open={
                            (item?.discard?.length
                              ? item?.discard[0]?.quantity || 0
                              : item?.discard?.quantity || 0) !== 0 &&
                            !discardReason
                          }
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                          arrow
                        >
                          <p className="m0 w100" />
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Collapse>
        </td>
      </tr>
    );
  }

  const {companies, companiesActive} = useSelector(state => state.user);

  function handleTransformation(manufacture, open, cardIndex) {
    const cardYield =
      manufacture.type === 'recipe'
        ? manufacture.yield * (manufacture.quantity || 1)
        : (manufacture.quantity || 1) -
          (manufacture.quantity || 1) * (manufacture.loss / 100);
    const { loss } = manufacture;
    const total = manufacture.items.reduce(
      (total, value) =>
        value
          ? parseFloat(total) + parseFloat(value.quantity)
          : parseFloat(total),
      0
    );
    const cardQuantity = manufacture.quantity || 1;
    const cardLostTotal = parseFloat(cardQuantity) - parseFloat(total);
    const disableDurationDialog = checkIsBefore(moment(manufacture?.date).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))
    
    return (
      <tr valign="center">
        <td colSpan="13" className="tableColorHover">
          <Collapse
            in={open}
            timeout="0"
            unmountOnExit
            className={open && 'expanded'}
          >
            <table
              width="73%"
              cellPadding="0"
              cellSpacing="0"
              border="0"
              style={{ border: 'none' }}
              className="dropDownTable"
            >
              <thead>
                <tr height="25">
                  <th
                    width="27.5%"
                    style={{
                      paddingLeft: '24px',
                      fontSize: '9px',
                      color: '#666770'
                    }}
                    className="subtitle bold"
                  >
                    PRODUTOS
                  </th>
                  <th
                    width="11%"
                    style={{
                      fontSize: '9px',
                      color: '#666770'
                    }}
                    className="subtitle right pr8 bold"
                  >
                    ESTOQUE
                  </th>
                  <th
                    width="6%"
                    style={{
                      fontSize: '9px',
                      color: '#666770'
                    }}
                    className="subtitle right pr8 bold"
                  >
                    DURAÇÃO
                  </th>
                  <th
                    width="11%"
                    style={{
                      fontSize: '9px',
                      color: '#666770'
                    }}
                    className="subtitle right pr3 bold"
                  >
                    QTDE
                  </th>
                  <th
                    width="2%"
                    style={{
                      fontSize: '9px',
                      color: '#666770'
                    }}
                    className="subtitle pr8 bold"
                  >
                    UN
                  </th>
                  <th
                    width="7.5%"
                    style={{
                      fontSize: '9px',
                      color: '#666770'
                    }}
                    className="subtitle right bold"
                  >
                    NOVA DUR.
                  </th>
                  <th
                    width="15%"
                    style={{
                      fontSize: '9px',
                      color: '#666770'
                    }}
                    className="subtitle right pr8 bold"
                  >
                    DESCARTE
                  </th>
                  <th
                    width="20%"
                    style={{
                      fontSize: '9px',
                      color: '#666770'
                    }}
                    className="subtitle bold"
                  >
                    JUSTIFICATIVA
                  </th>
                </tr>
              </thead>

              <tbody>
                {manufacture.items.map((item, index) => {
                  const exists = cards[cardIndex].items ? item : null;
                  if (
                    cards[cardIndex].items.length < manufacture.items.length &&
                    !exists
                  ) {
                    cards[cardIndex].items.push({
                      id: index,
                      quantity: item.quantity,
                      initialQuantity: item.quantity,
                      discard: {
                        quantity: 0,
                        reason: {
                          id: null,
                          description: null
                        }
                      }
                    });
                  }

                  const discardReason = item?.discard?.length
                    ? capitalize(
                        item?.discard[0]?.justification?.description
                      ) || ''
                    : capitalize(item?.discard?.justification?.description) ||
                      '';

                  return (
                    <>
                      <tr>
                        <td
                          className={`description ${item?.main && 'productMain'}`}
                          style={{ paddingLeft: '24px' }}
                        >
                          <div className="dFlex alignCenter justifyBetween">
                            <div className="dFlex alignCenter">
                              {ValidationLength(item.item, 23)}

                              {item.suggestionYield
                              ? `(${
                                  item.suggestionYield.toLocaleString('pt-br', {
                                    minimumFractionDigits: 3
                                  }) + (item?.un?.abbreviation || '')
                                })`
                              : ''}
                            </div>

                            <ImageWrapper
                              width="30px"
                              height="28px"
                              url={item?.image}
                            />
                          </div>
                        </td>

                        <td className="description right pr8">
                          {`${(item.stock ? item.stock : 0).toLocaleString(
                            'pt-br',
                            { minimumFractionDigits: 3 }
                          )} ${item?.un?.abbreviation || ''}`}
                        </td>

                        <td className="description right p8">
                          <span
                            className={`lightBlue ${disableDurationDialog ? 'cursorAuto' : 'cursorPointer'}`}
                            onClick={() => {
                              if (disableDurationDialog) return null
                              
                              getDuration(
                                item.id ? item.id : null,
                                item.stock,
                                item
                              );
                              handleClickOpenModal(manufacture);
                            }}
                          >
                            {`${item.duration ? item.duration : 0} ${
                              item.duration > 1 ? 'dias' : 'dia'
                            }`}
                          </span>
                        </td>

                        <td className="description pr3 right">
                          {manufacture.status !== 'WAITING' ? (
                            item.quantity ? (
                              parseFloat(item.quantity).toLocaleString(
                                'pt-br',
                                { minimumFractionDigits: 3 }
                              )
                            ) : (
                              '0,000'
                            )
                          ) : (
                            <CurrencyTextField
                              margin="none"
                              variant="standard"
                              size="small"
                              currencySymbol=""
                              minimumValue="0"
                              maximumValue="999999999"
                              outputFormat="string"
                              decimalCharacter=","
                              digitGroupSeparator="."
                              decimalPlaces={3}
                              decimalPlacesShownOnBlur={3}
                              decimalPlacesShownOnFocus={3}
                              type="text"
                              name="quantity"
                              fullWidth
                              disabled={
                                manufacture?.status !== 'WAITING' &&
                                manufacture?.status !== 'START' &&
                                manufacture?.status !== 'PAUSE'
                              }
                              value={item.quantity}
                              InputProps={{
                                maxLength: 8,
                                classes: { input: classes.input }
                              }}
                              onChange={(e) => {
                                setManufacture(manufacture);
                                setCurrentTransformationCardItems({
                                  ...manufacture,
                                  items: manufacture?.items?.map((el) => ({
                                    ...el,
                                    quantity: null
                                  }))
                                });
                                setUpdateValue(
                                  parseFloat(e.target.value).toFixed(3) !==
                                    parseFloat(item.quantity.toFixed(3))
                                );
                              }}
                              onBlur={(e, value) => {
                                if (
                                  !updateValue &&
                                  parseFloat(item.quantity).toFixed(3) ===
                                    parseFloat(value).toFixed(3)
                                )
                                  return;

                                handleCardQuantity(
                                  cardIndex,
                                  manufacture.quantity,
                                  index,
                                  parseFloat(value),
                                  undefined
                                );
                                setUpdateValue(false);
                              }}
                            />
                          )}
                        </td>

                        <td className="description pr8">
                          {item?.un?.abbreviation}
                        </td>

                        <td className="description right">
                          <Link
                            onClick={() => {
                              getDuration(
                                item.id ? item.id : null,
                                (item.stock || 0) + item.quantity,
                                item,
                                'transformationItem',
                                true
                              );
                              handleClickOpenModal(manufacture);
                            }}
                          >
                            {item.newDuration && item.newDuration > 1
                              ? `${item.newDuration} dias`
                              : item.newDuration
                              ? `${item.newDuration} dia`
                              : null}
                          </Link>
                        </td>

                        <td className="description right pr8">
                          <CurrencyTextField
                            margin="none"
                            variant="standard"
                            size="small"
                            currencySymbol=""
                            minimumValue="0"
                            maximumValue="999999999"
                            outputFormat="string"
                            decimalCharacter=","
                            digitGroupSeparator="."
                            decimalPlaces={3}
                            decimalPlacesShownOnBlur={3}
                            decimalPlacesShownOnFocus={3}
                            type="text"
                            name="quantity"
                            fullWidth
                            value={
                              item?.discard?.length
                                ? item?.discard[0]?.quantity || ''
                                : item?.discard?.quantity || ''
                            }
                            style={{ width: '100%' }}
                            InputProps={{
                              maxLength: 8,
                              classes: { input: classes.input }
                            }}
                            onBlur={(_, value) => {
                              handleItemDiscard(
                                manufacture._id,
                                index,
                                value,
                                discardReason,
                                discardReason && value,
                                item
                              );
                            }}
                          />
                        </td>

                        <td className="description">
                          <Autocomplete
                            size="small"
                            classes={{
                              inputRoot: classes.inputRoot,
                              option: classes.option
                            }}
                            id="asynchronous-demo"
                            className="autocompleteSmall autocompletePtChild0"
                            onChange={(option, value) => {
                              if (value) {
                                handleItemDiscard(
                                  manufacture._id,
                                  index,
                                  item?.discard?.length
                                    ? item?.discard[0]?.quantity
                                    : item?.discard?.quantity,
                                  value,
                                  (item?.discard?.length
                                    ? item?.discard[0]?.quantity
                                    : item?.discard?.quantity) && value,
                                  item
                                );
                              } else {
                                handleItemDiscard(
                                  manufacture._id,
                                  index,
                                  item?.discard?.length
                                    ? item?.discard[0]?.quantity
                                    : item?.discard?.quantity,
                                  '',
                                  false,
                                  item
                                );
                              }
                            }}
                            getOptionLabel={(option) => option.description}
                            options={optionsDiscard.map((option) => ({
                              ...option,
                              description: capitalize(option?.description)
                            }))}
                            value={
                              optionsDiscard
                                .map((option) => ({
                                  ...option,
                                  description: capitalize(option?.description)
                                }))
                                .filter(
                                  (option) =>
                                    option.description === discardReason
                                )[0]
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                type="text"
                                required
                                margin="none"
                                style={{ width: '100%', height: 20 }}
                                placeholder="Justifique..."
                                InputLabelProps={{
                                  style: {
                                    fontFamily: 'Lato',
                                    fontSize: '12px'
                                  }
                                }}
                              />
                            )}
                          />

                          <Tooltip
                            title="Preencha este campo."
                            open={
                              (item?.discard?.length
                                ? item?.discard[0]?.quantity || 0
                                : item?.discard?.quantity || 0) !== 0 &&
                              !discardReason
                            }
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            placement="bottom"
                            arrow
                          >
                            <p className="m0 w100" />
                          </Tooltip>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="8">
                          <hr />
                        </td>
                      </tr>
                    </>
                  );
                })}

                <tr height="5">
                  <td
                    className="description"
                    style={{ paddingLeft: '24px' }}
                    colSpan="3"
                  >
                    Perda (
                    {loss
                      ? parseFloat(loss).toLocaleString('pt-br', {
                          minimumFractionDigits: 3
                        })
                      : '0,000'}
                    %)
                  </td>

                  <td
                    className="description right pr8"
                    style={{
                      color:
                        cardQuantity && total
                          ? parseFloat(cardQuantity) - parseFloat(cardYield) <
                            cardLostTotal
                            ? 'red'
                            : 'black'
                          : 'black'
                    }}
                  >
                    {cardQuantity && total
                      ? cardLostTotal.toLocaleString('pt-br', {
                          minimumFractionDigits: 3
                        })
                      : (
                          parseFloat(cardQuantity) - parseFloat(cardYield)
                        ).toLocaleString('pt-br', {
                          minimumFractionDigits: 3
                        })}
                  </td>

                  <td className="description pl8">
                    {manufacture?.items?.[0]?.un
                      ? manufacture?.items?.[0]?.un?.abbreviation
                      : ''}
                  </td>

                  <td
                    className="description"
                    colSpan="3"
                    style={{
                      color:
                        parseFloat(100 * cardLostTotal) /
                          parseFloat(cardQuantity) >
                        parseFloat(loss)
                          ? 'red'
                          : 'black'
                    }}
                  >
                    {total !== 0 &&
                      `${(
                        parseFloat(100 * cardLostTotal) /
                        parseFloat(cardQuantity)
                      ).toLocaleString('pt-br', {
                        minimumFractionDigits: 3
                      })}%`}
                  </td>
                </tr>
              </tbody>
            </table>
          </Collapse>
        </td>
      </tr>
    );
  }

  if (!manufactures) {
    return (
      <>
        <tr>
          <td colSpan="13" valign="center" align="center">
            {loading ? (
              <Typography
                style={{
                  verticalAlign: 'center',
                  margin: '10px 0'
                }}
              >
                <LinearProgress variant="query" />
              </Typography>
            ) : (
              <Typography
                style={{
                  verticalAlign: 'center',
                  margin: '10px 0'
                }}
              >
                Não há nada para ser exibido
              </Typography>
            )}
          </td>
        </tr>

        <tr>
          <td colSpan="13">
            <hr />
          </td>
        </tr>
      </>
    );
  }
  
  return manufactures.map((manufacture, index) => {
    const lossManufactured = handleFields(manufacture).lossManufactured;
    const lossManufacturedMonetary = handleFields(manufacture).lossManufacturedMonetary;

    const isIncluded = manufacture?.status?.toUpperCase() === 'WAITING'
    const isToProduce = manufacture?.statusAction?.toUpperCase() === 'TO_PRODUCE'
    const isCanceled = manufacture?.status?.toUpperCase() === 'CANCELED'
    const isFinished = manufacture?.status?.toUpperCase() === 'FINISH'
    const actionIncluded = manufacture?.actions?.find(action => action?.type?.toUpperCase() === 'TO_PRODUCE') || manufacture?.actions?.find(action => action?.type?.toUpperCase() === 'INCLUDE') || null
    const actionToProduce = isToProduce ? manufacture?.actions?.find(action => action?.type?.toUpperCase() === 'TO_PRODUCE') : null
    const actionCanceled = isCanceled ? manufacture?.actions?.find(action => action?.type?.toUpperCase() === 'CANCELED') : null
    const actionFinished = isFinished ? manufacture?.actions?.find(action => action?.type?.toUpperCase() === 'FINISH') : null
    const actionStarted = manufacture?.actions?.find(action => action?.type?.toUpperCase() === 'START') || null

    const inclusion = manufacture?.dateAction ? moment(manufacture?.dateAction) : moment(manufacture?.created_at).add(3, 'hour')
    const hourInclusion = moment(inclusion).format('LT');
    const dateInclusion = moment(inclusion).format('DD/MM/YYYY');
    const userInclusion = actionToProduce ? actionToProduce?.user : manufacture?.actions?.find(action => action?.type?.toUpperCase() === 'INCLUDE').user || ''

    let hourStart = actionStarted ? moment(actionStarted.date).format('LT') : null

    if(actionStarted && moment(actionStarted?.date).isBefore(moment(inclusion), 'hour'))
      hourStart = moment(actionStarted.date).add(3, 'hour').format('LT')

    const dateStart = actionStarted ? moment(actionStarted.date).format('DD/MM/YYYY') : null
    const userStart = actionStarted ? actionStarted?.user : null

    const hourFinished = isFinished && actionFinished ? moment(actionFinished.date).format('LT') : null
    const dateFinished = isFinished && actionFinished ? moment(actionFinished.date).format('DD/MM/YYYY') : null
    const userFinished = isFinished && actionFinished ? actionFinished?.user : null

    const hourCanceled = isCanceled && actionCanceled ? moment(actionCanceled.date).format('LT') : null
    const dateCanceled = isCanceled && actionCanceled ? moment(actionCanceled.date).format('DD/MM/YYYY') : null
    const userCanceled = isCanceled && actionCanceled ? actionCanceled?.user : null
 
    const type = manufacture?.type?.toLowerCase();
    const status = manufacture?.status?.toLowerCase();
    let statusColor = '';
    let statusBackground = '';
    let actionButton = null;
    let onClick = null;
    let tooltip = '';
    let action = null;
    let pluralText = '';
    let singularText = '';
    let statusText;
    const exists = cards ? cards.find((x) => x?.id === index) : null;
    const disableDurationDialog = checkIsBefore(moment(manufacture?.date).format('YYYY-MM-DD'), moment().format('YYYY-MM-DD'))

    if (cards && cards.length < manufactures.length && !exists) {
      cards
        .sort((a, b) => (a.status > b.status ? -1 : 1))
        .push({
          id: index,
          open: false,
          quantity: manufacture.quantity,
          type,
          startTime: manufacture.actions.reverse().find((x) => x.type === 'START')
            ? manufacture.actions.reverse().find((x) => x.type === 'START').date
            : null,
          pauseTime: manufacture.actions.reverse().find((x) => x.type === 'PAUSE')
            ? manufacture.actions.reverse().find((x) => x.type === 'PAUSE').date
            : null,
          finishTime: manufacture.actions.reverse().find((x) => x.type === 'FINISH')
            ? manufacture.actions.reverse().find((x) => x.type === 'FINISH').date
            : null,
          manufactured: manufacture.manufactured,
          totalLoss: null,
          loss: manufacture.loss ? manufacture.loss : 0,
          totalYield:
            type === 'recipe'
              ? manufacture.yield * (manufacture.quantity || 1)
              : (manufacture.quantity || 1) - (manufacture.quantity || 1) * (manufacture.loss / 100),
          initialYield:
            type === 'recipe'
              ? manufacture.yield * 1
              : 1 - 1 * (manufacture.loss / 100),
          items: []
        });
    }

    switch (type) {
      case 'recipe':
        pluralText = 'Ingredientes';
        singularText = 'Ingrediente';
        break;
      case 'transformation':
        pluralText = 'Produtos';
        singularText = 'Produto';
        break;
      default:
        pluralText = 'Itens';
        singularText = 'Item';
        break;
    }

    const startTime = manufacture.actions.length
      ? manufacture.actions
          .filter((item) => item.type === 'START')
          .sort((a, b) => (a.date < b.date ? 1 : -1))[0]?.date || new Date()
      : new Date();
    const pauseTime = manufacture.actions.length
      ? manufacture.actions
          .filter((item) => item.type === 'PAUSE')
          .sort((a, b) => (a.date < b.date ? 1 : -1))[0]?.date || new Date()
      : new Date();
    const totalYield =
      cards && cards.find((item, cardIndex) => cardIndex === index)
        ? cards.find((item, cardIndex) => cardIndex === index).totalYield
        : manufacture.yield;
    const quantity =
      cards && cards.find((item, cardIndex) => cardIndex === index)
        ? cards.find((item, cardIndex) => cardIndex === index).quantity
          ? cards.find((item, cardIndex) => cardIndex === index).quantity
          : 1
        : 1;

    const handleActionButton = (status, index, quantity) => {
      switch (status) {
        case 'canceled':
          const dateFormatted = manufacture?.actions?.find(
            (action) => action?.type === 'CANCELED'
          )?.date
            ? new Date(
                manufacture?.actions?.find(
                  (action) => action?.type === 'CANCELED'
                )?.date
              )
            : new Date();
          tooltip = manufacture?.cancellationReason;
          statusText = 'Cancelado';
          statusColor = 'danger';
          actionButton = (
            <img src={CancelProduction} style={{ width: '18px' }} />
          );
          statusBackground = 'danger';
          statusText = (
            <p style={{ margin: '0' }}>
              <b>Cancelado</b>
              {` às ${format(dateFormatted, 'kk:mm')}
                    ${
                      manufacture.actions.find(
                        (action) => action.type === 'CANCELED'
                      )
                        ? `(${
                            manufacture.actions.find(
                              (action) => action.type === 'CANCELED'
                            ).user
                          })`
                        : ''
                    }`}
            </p>
          );
          break;
        case 'waiting':
          if (quantity) {
            statusText = (
              <p style={{ margin: '0' }}>
                <b>Iniciar</b>
              </p>
            );
            statusColor = 'dark';
            statusBackground = 'dark';
            actionButton = <img src={PlayIcon} style={{ width: '18px' }} />;
            action = 'start';
            onClick = (e) => {
              setManufacture(manufacture);
              setCurrentTransformationCardItems({
                ...manufacture,
                items: manufacture?.items?.map((el) => ({
                  ...el,
                  quantity: null
                }))
              });

              setTimeout(() => {
                handleUpdate(manufacture, 'START');
              }, 100);
            };
          } else {
            statusText = (
              <p style={{ margin: '0' }}>
                <b>Iniciar</b>
              </p>
            );
            statusColor = 'dark';
            statusBackground = 'dark';
            actionButton = <img src={PlayIcon} style={{ width: '18px' }} />;
            action = 'start';
            onClick = (e) => {
              handlePopoverClick(e);
              setManufacture(manufacture);
              setCurrentTransformationCardItems({
                ...manufacture,
                items: manufacture?.items?.map((el) => ({
                  ...el,
                  quantity: null
                }))
              });
              setCardDate(manufacture.date);
              setPopoverData(
                <Grid
                  container
                  xs={12}
                  justify="center"
                  spacing={1}
                  style={{ padding: '10px', margin: 0 }}
                >
                  <Grid item xs={12}>
                    <Typography className="bold textAlignCenter">
                      Necessário preencher a quantidade
                    </Typography>
                  </Grid>
                </Grid>
              );
            };
          }

          break;
        case 'start':
          statusText = (
            <p style={{ margin: '0' }}>
              <b>Produzindo...</b>
              {` Iniciado às ${hourStart} ${
                manufacture.actions.find((action) => action.type === 'START')
                  ? `(${
                      manufacture.actions.find(
                        (action) => action.type === 'START'
                      ).user
                    })`
                  : ''
              }`}
            </p>
          );
          statusColor = 'production';
          statusBackground = 'production';
          actionButton = <img src={ProductionIcon} style={{ width: '18px' }} />;
          manufacture.manufactured = null;
          onClick = (e) => {
            handlePopoverClick(e);
            setManufacture(manufacture);
            setCurrentTransformationCardItems({
              ...manufacture,
              items: manufacture?.items?.map((el) => ({
                ...el,
                quantity: null
              }))
            });
            setCardDate(manufacture.date);
            type === 'recipe'
              ? setPopoverData('start')
              : setPopoverData('finishTransformation');
          };
          break;
        case 'pause':
          statusText = (
            <p style={{ margin: '0' }}>
              <b>Pausado...</b>
              {` às ${format(new Date(pauseTime), 'HH:mm')} ${
                manufacture.actions.find((action) => action.type === 'PAUSE')
                  ? `(${
                      manufacture.actions.find(
                        (action) => action.type === 'PAUSE'
                      ).user
                    })`
                  : ''
              }`}
            </p>
          );
          statusColor = 'warning';
          statusBackground = 'warning';
          actionButton = (
            <img src={PauseProductionIcon} style={{ width: '18px' }} />
          );
          onClick = (e) => {
            handlePopoverClick(e);
            setManufacture(manufacture);
            setCurrentTransformationCardItems({
              ...manufacture,
              items: manufacture?.items?.map((el) => ({
                ...el,
                quantity: null
              }))
            });
            setCardDate(manufacture.date);
            setPopoverData('pause');
          };
          break;
        case 'finish':
          let textStart = ''
          let textFinish = ''
          const dateStartedFormatted = moment(actionStarted?.date || manufacture?.created_at).format('DD/MM')

          if (actionStarted || manufacture?.created_at) {
            textStart = hourStart || hourInclusion

            if (dateStart !== dateFinished) {
              textStart = `${dateStartedFormatted} ${hourStart || hourInclusion}`
            }
          } 

          if (actionFinished) {
            textFinish = ` às ${hourFinished} (${actionFinished.user})`

            if (dateStart !== dateFinished) {
              const dateFinishedFormatted = moment(actionFinished.date).format('DD/MM')
              textFinish = ` às ${dateFinishedFormatted} ${hourFinished} (${actionFinished.user})`
            }
          }

          statusText = (
            <p style={{ margin: '0' }}>
              {textStart}

              {textFinish}
            </p>
          );
          statusColor = 'success';
          statusBackground = 'success';
          actionButton = (
            <img src={CheckedCircleIcon} style={{ width: '18px' }} />
          );
          break;
      }
      return (
        <Tooltip
          title={
            <p className="mb0" style={{ padding: '2px 6px' }}>
              {tooltip}
            </p>
          }
          aria-label="filters"
          placement="right-start"
          disableHoverListener={!tooltip}
        >
          <IconButton
            edge="start"
            disabled={status === 'finish'}
            size="small"
            color="inherit"
            onClick={onClick}
            aria-label="close"
            style={{
              outline: '0',
              cursor: status === 'canceled' && 'auto'
            }}
          >
            {actionButton}
          </IconButton>
        </Tooltip>
      );
    };

    const handleQuantityField = (status, initialValue, type) => {
      if (status?.toLowerCase() === 'waiting') {
        return (
          <CurrencyTextField
            margin="none"
            variant="standard"
            size="small"
            currencySymbol=""
            minimumValue="0"
            maximumValue="999999999"
            outputFormat="string"
            decimalCharacter=","
            digitGroupSeparator="."
            decimalPlaces={3}
            decimalPlacesShownOnBlur={3}
            placeholder="Qtd"
            type="text"
            name="quantity"
            fullWidth
            value={initialValue || null}
            textAlign="center"
            align="center"
            style={{
              align: 'center',
              textAlign: 'right',
              marginTop: '5.95px'
            }}
            InputProps={{
              maxLength: 8,
              classes: { input: classes.input },
              style: { textAlign: 'right' }
            }}
            onChange={(e) => {
              setManufacture(manufacture);
              setUpdateValue(
                parseFloat(e.target.value) !==
                  parseFloat(initialValue.toFixed(3))
              );
            }}
            onBlur={(e, value) => {
              if (
                !updateValue &&
                parseFloat(initialValue.toFixed(3)) === parseFloat(value)
              )
                return;

              if (type === 'yield') {
                handleCardQuantity(
                  index,
                  manufacture.quantity || 1,
                  undefined,
                  undefined,
                  parseFloat(value)
                );
              } else {
                handleCardQuantity(
                  index,
                  parseFloat(value),
                  undefined,
                  undefined,
                  undefined
                );
              }

              setUpdateValue(false);
            }}
          />
        );
      }
      return initialValue
        ? parseFloat(initialValue).toLocaleString('pt-br', {
            minimumFractionDigits: 3
          })
        : '0,000';
    };

    const handleLossMonetary = () => {
      if (
        lossManufactured === null ||
        lossManufactured === '' ||
        status !== 'finish'
      ) {
        return `-`;
      }

      if (!diffViewMonetary) {
        return lossManufactured;
      }

      return lossManufacturedMonetary;
    };

    const handlePreparationClick = (url) => {
      const encodedUrl = btoa(url);
      localStorage.setItem('preparationUrl', encodedUrl);
    };

    return (
      <>
        <tr valign="center" className={`${!manufacture?.visible && 'dNone'}`}>
          <td width="200" className="description">
            <List component="nav" className="list">
              <ListItem className="listItem p0">
                <Grid container spacing={0} style={{ padding: 0 }}>
                  <Grid container item xs={11}>
                    <Grid
                      item
                      xs={1}
                      style={{ padding: '0 5px' }}
                      onClick={() => handleOpenItems(manufacture._id)}
                    >
                      {manufactures.find((el) => el._id === manufacture._id)
                        ?.open ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </Grid>

                    <Grid
                      item
                      xs={11}
                      className="justifyBetween dFlex"
                      style={{ padding: '0 5px 0 5px' }}
                      onClick={() => {
                        if (manufacture?.preparationMethod?.url) return;
                        handleOpenItems(manufacture._id);
                      }}
                    >
                      <div className="dFlex alignCenter">
                        <span 
                          style={{
                            color: manufacture?.preparationMethod?.url
                              ? '#5062F0'
                              : '#0F1225'
                          }}
                        >
                          {index + 1}{' - '}
                        </span>

                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 600 }}
                          placement="right"
                          title={
                            <span
                              className="dBlock"
                              style={{ padding: '4px 7px' }}
                            >
                              {manufacture?.cardId} -{' '}
                              {handleFields(manufacture).description}
                            </span>
                          }
                        >
                          {manufacture?.preparationMethod?.url ? (
                            <Link
                              to={`/preparation/${manufacture?.preparationMethod?.name}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span
                                style={{
                                  color: manufacture?.preparationMethod?.url
                                    ? '#5062F0'
                                    : '#0F1225'
                                }}
                                onClick={() =>
                                  handlePreparationClick(
                                    manufacture?.preparationMethod?.url
                                  )
                                }
                              >
                                {handleFields(manufacture).description?.length > 14
                                  ? `${handleFields(
                                      manufacture
                                    ).description?.substring(0, 28)}`
                                  : handleFields(manufacture).description}

                                {handleFields(manufacture).description?.length > 32 && '...'}
                              </span>
                            </Link>
                          ) : (
                            <span
                              style={{
                                color: manufacture?.preparationMethod?.url
                                  ? '#5062F0'
                                  : '#0F1225'
                              }}
                            >
                              {handleFields(manufacture).description?.length > 14
                                ? `${handleFields(
                                    manufacture
                                  ).description?.substring(0, 28)}`
                                : handleFields(manufacture).description}
                              {handleFields(manufacture).description?.length >
                                32 && '...'}
                            </span>
                          )}
                        </Tooltip>
                      </div>
                      
                      {type === 'recipe' &&
                        <ImageWrapper
                          width="30px"
                          height="28px"
                          url={manufacture?.image}
                        />
                      }
                    </Grid>
                  </Grid>

                  <Grid
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {manufacture?.engine?.quantity && manufacture?.engine?.quantity === manufacture?.quantity ? (
                      <Tooltip
                        title={
                          <p style={{ padding: '3px 8px', margin: 0 }}>
                            <b>Sugestão Copiloto:</b><br/>
                            <b>Qtde de acordo com a sugestão</b>
                          </p>
                        }
                        aria-label="filters"
                        placement="right-start"
                      >
                        <img
                          src={EngineIcon}
                          style={{ width: 16, height: 15 }}
                        />
                      </Tooltip> 
                    ) : manufacture.origin === 'S' && manufacture?.engine?.quantity !== manufacture?.quantity || manufacture.origin === 'S' ? (
                      <Tooltip
                        title={
                          <p style={{ padding: '3px 8px', margin: 0 }}>
                            <b>Sugestão Copiloto:</b><br/>
                            <b>Qtde sugerida alterada pelo usuário</b>
                          </p>
                        }
                        aria-label="filters"
                        placement="right-start"
                      >
                        <img
                          src={EngineIconGrey}
                          style={{ width: 16, height: 15 }}
                        />
                      </Tooltip> 
                    ): manufacture.origin === 'M' ? (
                      <Tooltip
                        title={
                          <div style={{ padding: '4px'}}>
                            <p style={{ margin: '0'}}>Produção fora da programação</p>
                          </div>
                        }
                        aria-label="filters"
                        placement="right-start"
                      >
                        <img 
                          src={VirtualProduction}
                          style={{ width: 16, height: 15 }}
                         />
                      </Tooltip>
                    ) : null}
                  </Grid>

                  <Grid style={{position: 'absolute', right: '-10px',    height: '100%',
                    justifyContent: 'center',
                    display: 'flex',
                    }}>
                      <span style={{display: 'flex', alignItems: 'center'}}>
                        {Array.isArray(companiesActive.id) ? (
                          <Tooltip
                            title={
                              <p style={{ padding: '3px 8px', margin: 0 }}>
                                <b>{getRestaurantbyId(manufacture?.restaurantId, companies)?.name}</b>
                              </p>
                            }
                            aria-label="filters"
                            placement="right-start"
                          >
                            <img
                              src={storeIconWS}
                              style={{ width: 16, height: 15, marginLeft: '8px' }}
                            />
                          </Tooltip>
                        ) : null}
                      </span>
                    
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </td>

          <td width="70" className="description right pr8">
            {handleFields(manufacture).stock}
          </td>

          <td width="100" className="description right p8">
            <span
              className={`lightBlue ${disableDurationDialog ? 'cursorAuto' : 'cursorPointer'}`}
              onClick={() => {
                if (disableDurationDialog) return null

                getDuration(
                  manufacture.productId ? manufacture.productId : null,
                  manufacture.stock,
                  manufacture
                );
                handleClickOpenModal(manufacture);
              }}
            >
              {handleFields(manufacture).duration}
            </span>
          </td>

          <td
            width="110"
            className="description right pl8"
            style={{ borderLeft: 'solid 1px #454A67' }}
          >
            <InputNumber
              updateQuantityInitialValue={updateQuantityInitialValue}
              setModalStates={setModalStates}
              modalStates={modalStates}
              setUpdateValue={setUpdateValue}
              updateValue={updateValue}
              disabled={
                status !== 'waiting' && status !== 'start' && status !== 'pause'
              }
              minimumValue="0"
              maximumValue="999999999"
              initialValue={manufacture.quantity?.toFixed(3)}
              // validUnit={manufacture?.un?.abbreviation === 'un'}
              handleValue={(value) => {
                setManufacture(manufacture);

                handleCardQuantity(
                  index,
                  parseFloat(value),
                  undefined,
                  undefined,
                  undefined
                );
              }}
              fontSize="12px"
              fontWeight="normal"
              width="100%"
              height="28px"
            />
          </td>

          <td width="2%" className="description left pl3">
            {manufacture.type === 'TRANSFORMATION'
              ? manufacture?.items?.[0]?.un?.abbreviation || ''
              : ''}
          </td>

          <td width="110" className="description right pr3">
            <div className="dFlex justifyEnd alignCenter">
              <InputNumber
                updateQuantityInitialValue={updateQuantityInitialValue}
                setModalStates={setModalStates}
                modalStates={modalStates}
                setUpdateValue={setUpdateValue}
                updateValue={updateValue}
                disabled={
                  status !== 'waiting' &&
                  status !== 'start' &&
                  status !== 'pause'
                }
                minimumValue="0"
                maximumValue="999999999"
                // validUnit={manufacture?.un?.abbreviation === 'un'}
                initialValue={manufacture?.yield?.toFixed(3)}
                handleValue={(value) => {
                  setManufacture(manufacture);

                  handleCardQuantity(
                    index,
                    manufacture.quantity || 1,
                    undefined,
                    undefined,
                    parseFloat(value)
                  );
                }}
                fontSize="12px"
                fontWeight="normal"
                width="100%"
                height="28px"
              />
            </div>
          </td>

          <td width="2%" className="description left" style={{ borderRight: '1px solid #000'}}>
            {manufacture.type === 'TRANSFORMATION'
              ? manufacture?.items?.[0]?.un?.abbreviation || ''
              : manufacture?.un?.abbreviation || ''}
          </td>

          <td width="110" className="description right pr8 font-weight-bold" style={{ background: 'rgba(218, 221, 243, .2)' }}>
            {handleFields(manufacture).manufactured === null ||
            handleFields(manufacture).manufactured === '' ||
            status !== 'finish'
              ? ``
              : handleFields(manufacture).manufactured}
          </td>

          <td width="120" className="description right" style={{ background: 'rgba(218, 221, 243, .2)' }}>
            {handleFields(manufacture).manufactured === null ||  
            handleFields(manufacture).manufactured === '' ||  
            status !== 'finish' 
              ?``
              : <div
                  className="dflex alignCenter justifyBetween"
                  style={{
                    display: 'flex',
                    height: 20,
                    lineHeight: 2.15,
                    width: '100%',
                    margin: '0 0 0 auto',
                    fontWeight: 700,
                    borderRadius: 10,
                    boxShadow: '0px 4px 4px 0px #00000040',
                    color: handleFields(manufacture)?.lossManufacturedStyle?.textColor,
                    background: handleFields(manufacture)?.lossManufacturedStyle?.bgColor
                  }}
                >
                  {handleLossMonetary() ?
                    <>
                      <div
                        className="dflex alignCenter justifyEnd pr8"
                        style={{
                          display: 'flex',
                          width: '70%',
                          fontWeight: 700,
                          fontSize: '12px',
                          color: handleFields(manufacture)?.lossManufacturedStyle?.textColor,
                        }}>
                        {handleLossMonetary()}
                      </div>
                      <PercentBox background={handleFields(manufacture)?.lossManufacturedStyle?.background}>
                        {handleFields(manufacture).diffPercentage && status === 'finish' ? 
                        handleFields(manufacture).formattedDiffPercentage.replace('-', '') : 
                        '-'}
                        {handleFields(manufacture).diffPercentage && status === 'finish' ? '%' : ''}
                      </PercentBox>
                    </>
                    : '-'}
                </div>
            }
          </td>

          <td width="70" className="description right p8" style={{ background: 'rgba(218, 221, 243, .2)' }}>
            <Link
              onClick={() => {
                if (type === 'recipe') {
                  getDuration(
                    manufacture.productId ? manufacture.productId : null,
                    manufacture.stock + manufacture.manufactured,
                    manufacture,
                    'recipe',
                    true
                  );
                } else {
                  getDuration(
                    manufacture.productId ? manufacture.productId : null,
                    manufacture.stock - manufacture.quantity,
                    manufacture,
                    '',
                    true
                  );
                }
                handleClickOpenModal();
              }}
            >
              {handleFields(manufacture).newDuration}
            </Link>
          </td>

          <td
            width="340"
            className="description pl8"
            style={{ borderLeft: 'solid 1px #454A67' }}
          >
            <div className="dFlex w100 alignCenter">
              {handleActionButton(status, index, quantity)}

              <Tooltip
                title={
                  <TooltipFinish>
                    <b>INCLUSÃO DA FICHA</b>
                    <p>Data: {dateInclusion}</p>
                    <p>Horário: {hourInclusion}hs</p>
                    <p>Usuário: {userInclusion}</p>

                    {actionStarted && <br />}
                    {actionStarted && <b>INÍCIO DA PRODUÇÃO</b>}
                    {actionStarted && <p>Data: {dateStart}</p>}
                    {actionStarted && <p>Horário: {hourStart}hs</p>}
                    {actionStarted && <p>Usuário: {userStart}</p>}

                    {isFinished && actionFinished && <br />}
                    {isFinished && actionFinished && <b>FINALIZAÇÃO</b>}
                    {isFinished && actionFinished && <p>Data: {dateFinished}</p>}
                    {isFinished && actionFinished && <p>Horário: {hourFinished}hs</p>}
                    {isFinished && actionFinished && <p>Usuário: {userFinished}</p>}

                    {isCanceled && actionCanceled && <br />}
                    {isCanceled && actionCanceled && <b>CANCELAMENTO</b>}
                    {isCanceled && actionCanceled && <p>Data: {dateCanceled}</p>}
                    {isCanceled && actionCanceled && <p>Horário: {hourCanceled}hs</p>}
                    {isCanceled && actionCanceled && <p>Usuário: {userCanceled}</p>}
                  </TooltipFinish>
                }
                placement='right-start'
              >
                <ButtonStatus
                  className="productionButtonStatus"
                  color={statusColor}
                  background={statusBackground}
                  style={{ marginLeft: '5px', boxShadow: '0px 4px 4px 0px #00000040' }}
                >
                  {statusText}
                </ButtonStatus>
              </Tooltip>

              <div className="pl8" style={{ width: '33%' }}>
                {increaseDate(manufacture.date)}
              </div>
            </div>
          </td>

          <td width="5" className="description center">
            <div className="dFlex justifyBetween">
              <Tooltip
                  disableHoverListener={status === 'finish'}
                  title="Cancelar produção"
              >
                <div>
                  <IconButton
                    aria-label="delete"
                    disabled={
                      manufacture.status ===
                      'CANCELED' /* || manufacture.status === "FINISH" */
                    }
                   onClick={() => {
                     handleDialogClickOpen();
                     setDeleteData(manufacture);
                    }}
                   style={{ padding: 0, outline: 0 }}
                  >
                    <img src={DeleteIcon} style={{ width: '16px' }} alt="Delete" />
                  </IconButton>
                </div>
              </Tooltip>

              {/* <Tooltip
                disableHoverListener={status === 'finish'}
                title="Finalize a produção para imprimir a etiqueta"
              >
                <div>
                  <PrintLabel
                    title={
                      stores?.flat().length > 1 ? companyActive : companyGroup
                    }
                    item={manufacture}
                    enabled={status === 'finish'}
                    iconSize="20px"
                  />
                </div>
              </Tooltip> */}
            </div>
          </td>
        </tr>

        {type === 'recipe' &&
        cards &&
        cards.find((item, cardIndex) => cardIndex === index)
          ? handleRecipe(
              manufacture,
              manufactures.find((el) => el._id === manufacture._id)?.open,
              index
            )
          : type === 'transformation' &&
            cards &&
            cards.find((item, cardIndex) => cardIndex === index)
          ? handleTransformation(
              manufacture,
              manufactures.find((el) => el._id === manufacture._id)?.open,
              index
            )
          : null}

        <tr>
          <td colSpan="13" className={`${!manufacture?.visible && 'dNone'}`}>
            <hr style={{ backgroundColor: '#666770' }} />
          </td>
        </tr>
      </>
    );
  });
}

export default DesktopRows;
