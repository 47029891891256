import styled, { css } from 'styled-components';

export const CardIngredientsContainer = styled.div`
  margin-top: 7px;
  font-family: Lato !important;
`;

export const CardIngredientsTitle = styled.div`
  margin-bottom: 6px;
  width: 100%;
  padding: ${({ paddingLeft }) =>
    paddingLeft ? `4px 4px 4px ${paddingLeft}` : '4px 4px 4px 8px'};
  font-size: 9px;
  color: #666770;
  background: #f2f2f4;
  text-transform: uppercase;
  font-weight: bold;
  font-family: Lato !important;

  @media only screen and (max-width: 1024px) {
    padding: 0px;
  }
`;

export const CardIngredientsDrillDown = styled.div`
  padding: ${({ isLast }) => (isLast ? '4px 8px 0px 8px' : '4px 8px 0px 8px')};
  border-bottom: ${({ isLast }) => (isLast ? 'none' : '1px solid #1f2445')};
  font-family: Lato !important;
  min-height: 67px;

  @media only screen and (max-width: 480px) {
    padding: ${({ isLast }) =>
      isLast ? '4px 0px 4px 8px' : '4px 0px 4px 8px'};
  }
`;

export const ArrowDown = styled.img`
  width: 13px;
  margin-right: 4px;
  transition: all 250ms ease-out;
  transform: ${({ openDrilDown }) =>
    openDrilDown ? 'rotate(180deg)' : 'none'};
  font-family: Lato !important;
`;

export const CardIngredientsDrillDownHead = styled.div`
  z-index: 9;
  display: flex;
  width: ${({ width }) => (width ? width : 'auto')};
  justify-content: ${({ justifyContent }) =>
    justifyContent ? justifyContent : 'space-between'};
  font-family: Lato !important;
  height: 100%;
  min-height: 41px;
  margin: 0 0 0 auto;
  
  .itemImage {
    margin: auto 10px;
    border-radius: 4px;
    overflow: hidden;
  }

  input {
    color: #010311;
    background-color: transparent;
  }

  div {
    font-size: 12px;
    color: #0f1225;
  }

  .qtde-item {
    display: none;
    font-size: 10px;
    line-height: 12px;
  }

  .qtde-number input {
    border-bottom: none;
    width: 80px;
    padding: 0px;
    font-weight: bold;
    font-size: 20px;

    @media screen and (max-width: 480px) {
      width: 50px !important;
      font-size: 14px;
    }
  }

  .yield-qtde-number input {
    width: 120px !important;
    font-size: 20px;

    @media screen and (max-width: 480px) {
      width: 67px !important;
      font-size: 14px;
    }
  }

  .qtde-Ingredients {
    display: flex;
    width: 197px;
    background: rgba(31, 36, 69, 0.9);
    border-radius: 8px;
    height: 50px;
    margin-top: 5px;

    @media screen and (max-width: 480px) {
      width: 141px !important;
      height: 40px;
    }
  }

  .qtde-card input {
    width: 120px !important;
    font-size: 20px;

    @media screen and (max-width: 480px) {
      width: 67px !important;
      font-size: 14px;
    }
  }

  .qtde-number {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  .item-arrow {
    display: flex;
    align-items: center;
    margin-left: 5px;
  }

  @media screen and (max-width: 480px) {
    .title-item {
      font-size: 12px;
      margin-top: 0px;
      color: #010311;
      width: 142px;
      line-break: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline flow-root list-item;
      margin-right: 3px;
    }

    .qtde-number {
      padding: 5px;
      border: 1px solid #1f2445;
      border-radius: 8px;
      background-color: #fff;
    }

    .qtde-item {
      display: block;
      border: 1px solid #1f2445;
      padding: 5px;
      border-radius: 5px 0px 0px 5px;
      background-color: #1f2445;
      color: #fff;
    }
  }

  @media only screen and (min-width: 768px) {
    .title-item {
      font-size: 20px;
      font-weight: bold;
      color: #010311;
      width: 355px;
      line-break: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline flow-root list-item;

      &.transformationItemTitle {
        width: 392px;
      }
    }

    .qtde-number {
      padding: 5px;
      border: 1px solid #1f2445;
      border-radius: 8px;
      background-color: #fff;
    }

    .qtde-item {
      display: block;
      display: block;
      border: 1px solid #1f2445;
      padding: 5px;
      border-radius: 8px 0px 0px 8px;
      background-color: #1f2445;
      color: #fff;
    }

    .item-arrow {
      margin-left: 9px;
    }
  }
`;

export const CardIngredientsDrillDownContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${({ openDown }) => (openDown ? '5px 5px 0' : 0)};
  height: ${({ openDown }) => (openDown ? 'auto' : 0)};
  overflow: ${({ openDown }) => (openDown ? 'visible' : 'hidden')};
  font-family: Lato !important;

  @media only screen and (max-width: 360px) {
    flex-direction: column;
    .Ingredients-container {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
    }

    .Ingredients-container .justify-select {
      width: 50%;
    }

    .duration {
      width: 20%;
    }

    .justify {
      margin-left: 0px;
    }
  }

  @media only screen and (min-width: 361px) {
    flex-direction: column;
    margin-left: -13px;

    .Ingredients-container {
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: space-evenly;
    }

    .Ingredients-container > div {
      width: auto;
    }

    .Ingredients-container > div > button {
      float: right;
    }

    .duration {
      width: 30%;
    }

    .justify {
      margin-left: 10px;
    }
  }

  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const DrilDownContentTitle = styled.div`
  font-weight: bold;
  font-size: 9px;
  color: #666770;
  font-family: Lato !important;

  @media only screen and (max-width: 1024px) {
    .special-title {
      color: #fff;
      background-color: #9296ac;
      padding: 5px;
      border: 1px solid #9296ac;
      border-radius: 12px 12px 0px 0px;
      width: 95px;
    }

    .special-result {
      display: flex;
      border: 1px solid #9296ac;
      border-radius: 0px 8px 8px 8px;
      justify-content: center;
      align-items: center;
      width: 95px;
    }
  }
`;

export const DrilDownContentData = styled.div`
  font-weight: normal;
  font-size: 12px;
  color: #0f1225;
  margin-top: 4px;
  font-family: Lato !important;

  @media only screen and (min-width: 768px) {
    .item-stock {
      margin-left: 15px;
    }
  }

  @media only screen and (max-width: 1024px) {
    .special-date-result {
      color: #fff;
      font-size: 12px;
      line-height: 16px;
      padding: 5px;
      background-color: #7583f0;
      padding: 4px 9px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .special-result input {
      width: 78px;
      border: 1px solid #9296ac;
      border-radius: 0px 8px 8px 8px;
      background-color: #fff;
      text-align: center;
      padding: 12px;
      border-radius: 8px;
    }

    .item-stock {
      font-size: 14px;
      font-weight: bold;
    }
  }
`;

export const AutoCompleteWrapper = styled.div`
  font-family: Lato !important;

  div {
    margin-right: 0;
  }
  .MuiAutocomplete-inputRoot[class*='MuiInput-root']
    .MuiAutocomplete-input:first-child {
    padding: 3px 0;
  }
`;
