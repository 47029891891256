/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';

import {
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { DeleteOutline, ExpandLess, ExpandMore } from '@material-ui/icons';
import moment from 'moment';
import { Link } from 'react-router-dom';

import VirtualProduction from 'images/icons/signals/alerts/virtualProductionIcon.svg';
import { isEmpty } from 'utils/isEmpty';
import ValidationLength from 'utils/utils';

import * as S from './styles';

import TotalByTypeRow from '../TotalByTypeRow';

function TheoryOrRealStockRow({
  extractItems,
  setExtractItem,
  index,
  isLastIndex,
  item,
  productModal,
  checkConsume,
  extractType,
  fetchCards,
  extractDateAndDescription,
  analyticFilter,
  handleGetDiffs,
  differenceInExtractTooltip,
  setDifferenceInExtractTooltip,
  getId,
  extractStartDate,
  extractEndDate,
  handleClickSales,
  modalSettings,
  setModalSettings,
  setDrillDownInfo,
  drilldownInfo,
  setSelectedItem
}) {
  const itemConsumed = item?.product?.historical?.some((el) => el?.consumed)
    ? parseFloat(item?.product?.historical[0]?.consumed)
    : '';

  const isBetween = (currentValue, min, max) => currentValue >= min && currentValue <= max;

  const stockDifferenceStyle = (lossPercentage, diff, consumed) => {
    const percentage = consumed ? (diff / consumed) * 100 : 0;

    const range = [parseFloat(lossPercentage * -1), parseFloat(lossPercentage)];
    let [textColor, bgColor] = '';

    // bgColor =
    //   percentage >= range[0] && percentage <= range[1]
    //     ? '#D4F7EB'
    //     : percentage >= lossPercentage
    //     ? '#FFF2CC'
    //     : '#F7D4D7';
    // textColor =
    //   percentage >= range[0] && percentage <= range[1]
    //     ? '#089163'
    //     : percentage >= lossPercentage
    //     ? '#AA8208'
    //     : '#F53D4C';

    const diffPer = item.quantity - item?.product?.yield
    const diffPercentCalc = (100 * diffPer) / item?.product?.yield;
    const max = item.variationPercentage || 0;
    const min = item.variationPercentage ? item.variationPercentage * -1 : 0;
    const isBtw = isBetween(diffPercentCalc, min, max);

    if (percentage >= range[0] && percentage <= range[1]) {
      bgColor = '#D4F7EB';
      textColor = '#089163';
    } else if (percentage >= lossPercentage) {
      bgColor = '#FFF2CC';
      textColor = '#AA8208';
    } else {
      bgColor = '#F7D4D7';
      textColor = '#F53D4C';
    }

    if (item?.type === 'Produzido') {
      if (isBtw) {
        bgColor = '#D4F7EB';
        textColor = '#089163';
      } else {
        if (diffPer > 0) {
          bgColor = '#FFF2CC';
          textColor = '#AA8208';
        } else {
          bgColor = '#F7D4D7';
          textColor = '#F53D4C';
        }
      }
    } else if(!consumed) {
      bgColor = diff === 0 ? '#D4F7EB' : diff > 0 ? '#FFF2CC' : '#F7D4D7';
      textColor = diff === 0 ? '#089163' : diff > 0 ? '#AA8208' : '#F53D4C';
    }

    return {
      color: textColor,
      background: bgColor
    };
  };

  const hasActionOnClick = (type) =>
    type === 'Venda' ||
    type === 'Compra' ||
    type === 'Descarte' ||
    type === 'Consumido em produção' ||
    type === 'Produzido';

    const hasActionOnClickLink = (type, validateGroup) =>
    type === 'Venda' && !validateGroup ||
    type === 'Compra' && !validateGroup ||
    type === 'Descarte' ||
    type === 'Consumido em produção' ||
    type === 'Produzido';

  const handleClickDiscard = (item, productModal, extractItems) => {
    setModalSettings((modal) =>
      modal.map((el) =>
        el.name === 'updateDiscard'
          ? {
              ...el,
              open: true,
              extract: item,
              extractItems,
              discard: item?.discard,
              product: item?.product,
              date: item?.date,
              quantity: item?.quantity,
              unity:
                productModal?.consumptionUnitsPrimary?.abbreviation ||
                productModal.consumptionUnitsPrimary
            }
          : el
      )
    );
  };

  const deleteDiscard = () => {
    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'confirmDialog'
          ? {
              ...modal,
              open: true,
              body: {
                extract: item,
                discard: item?.discard,
                product: item?.product,
                quantity: item?.quantity
              }
            }
          : modal
      )
    );
  };

  const isTheoreticalStock = item.type === 'Estoque teórico';

  const handleClickByType = (item, validateGroup) => {
    if (item?.type === 'Venda' && !validateGroup) {
      handleClickSales(item);
    }

    if (item?.type === 'Compra' && !validateGroup) {
      const productIds = !isEmpty(productModal.productIds) ? productModal.productIds : productModal.id
      window.open(
        `/recebimento/${getId}?listReceivedQueryParam=false&productId=${productIds}&startAt=${moment(item?.date).format('YYYY-MM-DD')}&endAt=${moment(
          item?.date
        ).format('YYYY-MM-DD')}&dateType=true&status=RECEIVED`,
        '_blank'
      );
    }

    if (item?.type === 'Descarte') {
      handleClickDiscard(item, productModal);
    }

    if (item?.type === 'Consumido em produção') {
      const productIds = !isEmpty(productModal.productIds) ? productModal.productIds : productModal.id
      window.open(
        `/producao/${getId}?productId=${productIds}&startAt=${moment(
          item?.date
        ).format('YYYY-MM-DD')}&endAt=${moment(item?.date).format(
          'YYYY-MM-DD'
        )}&status=FINISH&deflectType=CONSUMED_PRODUCTION`,
        '_blank'
      );
    }

    if (item?.type === 'Produzido') {
      window.open(
        `/producao/${getId}?cardId=${item?.cards?.id}&cardDescription=${item?.cards?.description}&startAt=${moment(
          item?.date
        ).format('YYYY-MM-DD')}&endAt=${moment(item?.date).format(
          'YYYY-MM-DD'
        )}&status=FINISH&deflectType=PRODUCTION_PRODUCED`,
        '_blank'
      );
    }
  };

  switch (item.type) {
    case 'SHOPPING':
      item.type = 'Compra';
      break;
    case 'SALES':
      item.type = 'Venda';
      break;
    case 'DISCARD':
      item.type = 'Descarte';
      break;
    case 'CONSUMED_PRODUCTION':
      item.type = 'Consumido em produção';
      break;
    case 'PRODUCTION_PRODUCED':
      item.type = 'Produzido';
      break;
    case 'TRANSFERS':
      item.type = 'Transferido';
      break;
    case 'STOCK':
      item.type = `Estoque ${item?.cards?.description || ''}`
      break;
    case 'COUNT':
      item.type = 'Contagem';
      break;
    case 'RETURNED':
      item.type = 'Devolvido';
      break;
    case 'TRANSFER_OUT':
      item.type = 'TRANSFERIDO PARA'
      break;
    case 'TRANSFER_ENTRY':
      item.type = 'TRANSFERIDO DE'
      break;
    default:
      break;
  }

  const isStockDifference = item.type === 'Diferença de estoque';
  const isStockDifferenceColor =
    item.type === 'Diferença de estoque' || item.type === 'Produzido';

  const isHeader =
    item.type === 'Fechamento do dia' ||
    item.type === 'Estoque real' ||
    item.type === 'Estoque teórico' ||
    item.type === 'Contagem';

  const showCollapseButton =
    !analyticFilter &&
    extractItems.filter((el) => el.parentItem === item.isParent).length &&
    !isLastIndex &&
    isHeader;

  const formattedDayOfWeek = useMemo(() => {
    const formattedDate = moment(new Date(item.date), 'D_M_YYYY')
      .add(3, 'hours')
      .format('dddd');

    if (formattedDate.includes('-feira')) {
      return formattedDate.replace(/-feira/g, '');
    }

    return formattedDate;
  }, [item.date]);

  const backgroundStyle = useMemo(() => {
    if (
      (!index && isHeader) ||
      (isLastIndex && isHeader) ||
      item.type === 'Contagem'
    ) {
      return {
        background: isTheoreticalStock ? '#F2F3F8' : '#D0D3E2',
        color: isTheoreticalStock ? '#454A67' : '#0F1225',
        fontWeight: 'bold',
        borderBottom: 'solid 1px #454A67'
      };
    }
    return {
      cursor: 'pointer'
    };
  }, [index, isHeader, isLastIndex, item.type]);

  const itemValue = useMemo(() => {
    let signal = '';
    let value = item.value || 0;

    if ((!isLastIndex || !isHeader) && item.type !== 'Contagem') {
      signal = item.quantity < 0 ? '-' : item.type === 'TRANSFERIDO PARA' ? '-' : '+';
    }

    if (!isLastIndex) {
      value = Math.abs(value);
    }

    return {
      valueFormatted: `
        ${signal} 
        R$ 
        ${parseFloat(value).toLocaleString('pt-br', {
          minimumFractionDigits: 3
        })}
      `,
      costFormatted: `
        ${signal} 
        R$ 
        ${parseFloat(Math.abs(value / item.quantity) || 0).toLocaleString(
          'pt-br',
          {
            minimumFractionDigits: 3
          }
        )}
      `,
      value: parseFloat(value)
    };
  }, [isHeader, isLastIndex, item.quantity, item.type, item.value]);

  const collapseButton = useMemo(() => {
    if (item.opened) {
      return (
        <ExpandLess
          className="cursorPointer"
          onClick={() => {
            setExtractItem(
              extractItems.map((el, elIndex) => {
                if (index === elIndex || el?.parentItem === item.isParent) {
                  return {
                    ...el,
                    opened: !el?.opened,
                    hideItem: !el?.hideItem
                  };
                }

                return {
                  ...el
                };
              })
            );
          }}
        />
      );
    }

    return (
      <ExpandMore
        className="cursorPointer"
        onClick={() => {
          setExtractItem(
            extractItems.map((el, elIndex) => {
              if (index === elIndex || el?.parentItem === item.isParent) {
                return {
                  ...el,
                  opened: !el?.opened,
                  hideItem: !el?.hideItem
                };
              }

              return {
                ...el
              };
            })
          );
        }}
      />
    );
  }, [extractItems, index, item.isParent, item.opened, setExtractItem]);

  const validateGroup = getId?.includes(',');

  if (
    !(
      item.type === 'Estoque' &&
      moment(item.date).isSame(moment().subtract(1, 'day'), 'day')
    )
  ) {
    return (
      <>
        <tr
          className={`withBorder ${
            !analyticFilter &&
            // !isHeader &&
            // item.type !== 'Contagem' &&
            item.hideItem &&
            'dNone'
          }`}
          id={`dialogStock-${`${item.type}-${index}`}`}
        >
          <td style={backgroundStyle} className="description pr12 pl16">
            <div
              role="button"
              tabIndex={0}
              style={{
                cursor: item.type === 'Venda' ? 'pointer' : 'auto'
              }}
            >
              <Grid container spacing={0} className="p0">
                <Grid container item xs={11} spacing={0}>
                  {extractDateAndDescription(item)}
                </Grid>

                <Grid item xs={1}>
                  {item.isVirtual ? (
                    <Tooltip
                      title={
                        <>
                          <p className="p0 m0">
                            <b>PRODUÇÃO VIRTUAL</b>
                          </p>

                          <p className="p0 m0">
                            Produção sem controle de inventário.
                          </p>

                          <p className="p0 m0">
                            Apenas para projeção de entrada e saída do estoque.
                          </p>
                        </>
                      }
                      aria-label="filters"
                      placement="right-start"
                    >
                      <img alt="" src={VirtualProduction} />
                    </Tooltip>
                  ) : null}
                </Grid>
              </Grid>
            </div>
          </td>

          <td
            style={backgroundStyle}
            className={`${
              hasActionOnClick(item.type) && 'materialBlue cursorPointer'
            } description right pr10 pl0`}
          >
            <S.StockDifference
              className={`dFlex justifyBetween alignCenter`}
              background={
                isStockDifference &&
                stockDifferenceStyle(
                  item?.product?.lossPercentage,
                  item?.quantity,
                  itemConsumed
                ).background
              }
              border={isStockDifference}
              // style={{
              //   backgroundColor:
              //     isStockDifference &&
              //     stockDifferenceStyle(
              //       item?.product?.lossPercentage,
              //       item?.quantity,
              //       itemConsumed,
              //     ).background,
              //   borderRadius: isStockDifference && '17px'
              // }}
            >
              <div className="pr10">
                <span
                  className={`${isHeader && 'uppercase'} ${
                    hasActionOnClickLink(item.type, validateGroup) && 'materialBlue cursorPointer'
                  } childTdFontSize14`}
                  style={{
                    color: isTheoreticalStock ? '#454A67' : '#0F1225'
                  }}
                  onClick={() => {
                    handleClickByType(item, validateGroup);
                    setSelectedItem(item)
                  }}
                >
                  {item.type === 'Contagem' ? 'FECHAMENTO DO DIA' : 
                    item?.type !== 'TRANSFERIDO DE' ? item?.type === 'TRANSFERIDO PARA' ? 'Transferido para ' : 
                    item.type : ''} 

                  {item.type === 'TRANSFERIDO PARA' && ValidationLength(
                    ` ${item?.transfer?.toRestaurantName}`,
                    20
                  )}
                  
                  {item.type === 'TRANSFERIDO DE' &&
                    <Link
                      id="catalogProductAddButton"
                      style={{color: '#3f51b5'}}
                      target="_blank"
                      to={{
                        pathname: `/recebimento/${item?.transfer?.toRestaurantId}?orderValidId=1&orderId=${item?.transfer?.orderIds}`,
                        state: { orderId: item?.transfer?.orderId}
                      }}
                      onClick={() => localStorage.setItem('orderIdValid', 1)
                    }
                    >
                      {ValidationLength(
                        `Transferido de ${item?.transfer?.fromRestaurantName}`,
                        35
                      )}
                    </Link>
                  }

                  {isHeader && (
                    <span className="lowercase"> ({formattedDayOfWeek})</span>
                  )}


                  {/* {item.type === 'ESTOQUE' && ValidationLength(
                    `${item?.type} A ${item.cards.description || ''}`,
                    20
                  )} */}
                </span>

                {item?.discard?.description ? (
                  <>
                    <Tooltip
                      TransitionComponent={Fade}
                      TransitionProps={{ timeout: 600 }}
                      onOpen={() => handleGetDiffs(item)}
                      onClose={() => setDifferenceInExtractTooltip(null)}
                      title={
                        <div
                          className="dFlex flexColumn"
                          style={{ width: '220px', paddingTop: 5 }}
                        >
                          {differenceInExtractTooltip !== null ? (
                            differenceInExtractTooltip.map((el) => {
                              const lastIndex = differenceInExtractTooltip?.length - 1;

                              return (
                                <div className={`dFlex flexColumn ${lastIndex ? '' : 'mb5'}`}>
                                  <p className="mb0">{`${el?.quantity?.toLocaleString(
                                    'pt-br',
                                    {
                                      minimumFractionDigits: 3
                                    }
                                    )}${el?.consumptionUnits}: ${
                                      el?.description
                                    }`}
                                  </p>
  
                                  <span>
                                    {moment(el?.date).format('LT')} {el?.user ? `- ${el?.user}` : ''}
                                  </span>
                                </div>
                              )
                            })
                          ) : (
                            <div className="countsCircularProgressBar dFlex justifyCenter">
                              <CircularProgress />
                            </div>
                          )}
                        </div>
                      }
                    >
                      <img
                        alt="Discard Type"
                        src={VirtualProduction}
                        className="ml5"
                      />
                    </Tooltip>

                    <Tooltip title="Excluir descarte">
                      <IconButton
                        color="inherit"
                        onClick={() => {
                          deleteDiscard();
                        }}
                        aria-label="add"
                        size="small"
                        style={{
                          outline: '0',
                          background: 'none'
                        }}
                      >
                        <DeleteOutline style={{ width: 16 }} />
                      </IconButton>
                    </Tooltip>
                  </>
                ) : null}

           
{/* {item.type === 'ESTOQUE' && ValidationLength(
                    `${item?.type} ${item.cards.description || ''}`,
                    20
                  )} */}


                {/* {item.type === 'Estoque' && item.cards ?
                 ` `
                  : ''}  */}
              </div>

              <div className="dFlex alignCenter">
                <div
                  style={{
                    color:
                      (isStockDifferenceColor &&
                        stockDifferenceStyle(
                          item?.product?.lossPercentage,
                          item?.quantity,
                          itemConsumed
                        ).color) ||
                      (item?.type === 'Descarte' && '#F53D4C'),
                    background:
                      item?.type === 'Produzido' &&
                      stockDifferenceStyle(
                        item?.product?.lossPercentage,
                        item?.quantity,
                        itemConsumed
                      ).background,
                    borderRadius: item?.type === 'Produzido' && '17px',
                    padding: item?.type === 'Produzido' && '0 10px',
                    marginRight: item?.type === 'Produzido' && '-10px'
                  }}
                  className={`${!validateGroup && 'colorFont'}`}
                  onClick={() => {
                    if (
                      item?.type !== 'Diferença de estoque' &&
                      item?.type !== 'Fechamento do dia' &&
                      item?.type !== 'Estoque teórico' &&
                      item?.type !== 'Contagem'
                    ) {
                      setDrillDownInfo({ ...drilldownInfo, info: item, productsIds: item?.details?.map(el => el?.productId) });

                      if (validateGroup) {
                        setModalSettings(
                          modalSettings.map((item) =>
                            item.name === 'drillDownDialog'
                              ? {
                                  ...item,
                                  open: true
                                }
                              : item
                          )
                        );
                      }
                    }
                  }}
                >
                  {item.type !== 'Contagem' && item.quantity !== 0
                    ? item.quantity < 0
                      ? '-'
                      : item.type === 'TRANSFERIDO PARA' ? '-' : '+'
                    : null}
                  {parseFloat(
                    Math.abs(item.quantity ? item.quantity : 0)
                  ).toLocaleString('pt-br', { minimumFractionDigits: 3 })}
                  
                  {(productModal?.consumptionUnitsPrimary?.abbreviation)}

                  {item?.diffPercent && item?.type === 'Produzido' ? (
                    <span
                      style={{
                        marginLeft: 10,
                        color: stockDifferenceStyle(
                          item?.product?.lossPercentage,
                          item?.quantity,
                          itemConsumed
                        ).color
                      }}
                    >
                      (
                      {(item?.diffPercent || 0).toLocaleString('pt-br', {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2
                      })}
                      %)
                    </span>
                  ) : null}
                </div>
                
                {item?.diffPercent && isStockDifference ? (
                  <div
                    style={{
                      marginLeft: 10,
                      color: stockDifferenceStyle(
                        item?.product?.lossPercentage,
                        item?.quantity,
                        itemConsumed
                      ).color
                    }}
                  >
                    (
                    {(item?.diffPercent || 0).toLocaleString('pt-br', {
                      maximumFractionDigits: 2,
                      minimumFractionDigits: 2
                    })}
                    %)
                  </div>
                ) : null}
              </div>
            </S.StockDifference>
          </td>

          <td style={backgroundStyle} className="description right pr16 pl0">
            <span>{itemValue?.costFormatted}</span>
          </td>

          <td style={backgroundStyle} className="description right pr16 pl0">
            <span>{itemValue?.valueFormatted}</span>
          </td>

          <td style={backgroundStyle} className="description right pr16 pl0">
            {extractType === 'ALL'
              ? `${parseFloat(
                  item.finalQuantity ? item.finalQuantity : 0
                ).toLocaleString('pt-br', {
                  minimumFractionDigits: 3
                })}
                                ${
                                  (productModal.consumptionUnitsPrimary &&
                                    productModal.consumptionUnitsPrimary
                                      .abbreviation) ||
                                  productModal.consumptionUnitsPrimary
                                }`
              : null}
          </td>

          <td style={backgroundStyle} className="description right pr0 pl0">
            {extractType === 'ALL'
              ? `R$ ${parseFloat(
                  item.finalValue ? item.finalValue : 0
                ).toLocaleString('pt-br', {
                  minimumFractionDigits: 3
                })}`
              : null}
          </td>

          <td style={backgroundStyle} className="description right pr8 pl0">
            {/* {showCollapseButton ? collapseButton : null} */}
          </td>
        </tr>

        {!analyticFilter && index === 0 && (
          <TotalByTypeRow
            extractItems={extractItems}
            productModal={productModal}
            item={item}
            handleClickSales={handleClickSales}
            stockDifferenceStyle={stockDifferenceStyle}
            handleClickDiscard={handleClickDiscard}
            deleteDiscard={deleteDiscard}
            differenceInExtractTooltip={differenceInExtractTooltip}
            setDifferenceInExtractTooltip={setDifferenceInExtractTooltip}
            handleGetDiffs={handleGetDiffs}
          />
        )}
      </>
    );
  }
}

export default TheoryOrRealStockRow;
