import cardsLocalize from './Cards';
import catalogacaoLocalize from './Catalogacao';
import Categories from './Categories';
import common from './Common';
import companiesLocalize from './Companies';
import copilotLocalize from './Copilot';
import fabricantesLocalize from './Fabricantes';
import inventoriesLocalize from './Inventories';
import brandsLocalize from './Marcas';
import menuLocalize from './Menu';
import periodicitiesLocalize from './Periodicities';
import process from './process';
import productions from './Productions';
import produtos from './Produtos';
import providersLocalize from './Providers';
import registerLocalize from './Register';
import SalesProjection from './SalesProjection';
import skuLocalize from './Sku';
import squaresLocalize from './Squares';
import stores from './Stores';
import unitsMeasurement from './UnitsMeasurement';
import userLocalize from './User';

export default {
  translations: {
    ...common,
    ...fabricantesLocalize,
    ...brandsLocalize,
    ...menuLocalize,
    ...catalogacaoLocalize,
    ...companiesLocalize,
    ...copilotLocalize,
    ...productions,
    ...produtos,
    ...process,
    ...SalesProjection,
    ...providersLocalize,
    ...userLocalize,
    ...registerLocalize,
    ...periodicitiesLocalize,
    ...skuLocalize,
    ...inventoriesLocalize,
    ...cardsLocalize,
    ...squaresLocalize,
    ...unitsMeasurement,
    ...stores,
    ...Categories
  }
};
