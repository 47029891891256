import React, { useEffect, useState } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import { CircularProgress, LinearProgress } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import DoneIcon from '@material-ui/icons/Done';
import Alert from '@material-ui/lab/Alert';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { format } from 'date-fns';
import { useParams } from 'react-router';
import PullToRefresh from 'react-simple-pull-to-refresh';

import PageTitleAndFilter from 'components/PageTitleAndFilter';
import Layout from 'containers/layouts/layout';
import ArrowRight from 'images/icons/arrows/arrowRightWhiteOutline.svg';
import makePut from 'services/useFetch/makePut';
import Environment from 'utils/environments';
import history from 'utils/history';
import numberToReal from 'utils/numberToReal';

import ReceiverDetailsCards from './cards';

import AcrescimosDescontos from '../dialogs/AdditionalDiscounts';
import ConfirmationDialog from '../dialogs/ConfirmationDialog';
import DialogCompleteNoteMobile from '../dialogs/DialogCompleteNoteMobile';

import './styles.scss';

function ReceiverDetails() {
  const environments = Environment(process.env.REACT_APP_ENV);
  const { id: paramsID, receiverId } = useParams();
  const [getId, setGetId] = useState(paramsID)
  const [getReceiveId, setGetReceiveId] = useState(receiverId)
  const [receiverData, setReceiverData] = useState([])
  const [orders, setOrders] = useState([])
  const [openModalDescontos, setOpenModalDescontos] = useState(false)
  const [openModalOrder, setOpenModalOrder] = useState(false)
  const [selectedReceiveDate, setSelectedReceiveDate] = useState(new Date())
  const [submitted, setSubmitted] = useState(false)
  const [errMsg, setErrMsg] = useState(false)
  const [contacts, setContacts] = useState(false)
  const [isCompleteNote, setIsCompleteNote] = useState(false)
  const [currentOrder, setCurrentOrder] = useState({})
  const [currentQuantity, setCurrentQuantity] = useState({})
  const [isDivergency, setIsDivergency] = useState(false)
  const [dayOfTheWeek, setDayOfTheWeek] = useState(["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"])
  const [totalReceiver, setTotalReceiver] = useState(0)
  const [totalCards, setTotalCards] = useState([])
  const [loading, setLoading] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [sendReceivedscards, setSendReceivedscards] = useState([])
  const [openFloatButton, setOpenFloatButton] = useState(false)
  const [toastSuccess, setToastSuccess] = useState(false)
  const [toastError, setToastError] = useState(false)
  const [openConfirmatioDialog, setOpenConfirmatioDialog] = useState(false)
  const [receivementDate, setReceivementDate] = useState(new Date())

  async function getData() {
    setLoading(true)
    const response = await fetch(`${environments.orders}/orders/${getReceiveId}/?originId=${getId}`);
    const data = await response.json();
    setReceiverData(data)
    setOrders(data?.orderItems)   
    setLoading(false) 
  }  

  useEffect(() => {
    const allTotalCards = totalCards.reduce(
      (acc, item) => acc + parseFloat(item.value), 0
    )
    setTotalReceiver(allTotalCards)
  }, [totalCards])

  useEffect(() => {
    
    const filteredOrders = orders.filter((order) => order?.sku?.status !== "RECEIVED")
    let count = 0
    const totalList = []

    for (let i = 0; i < filteredOrders.length; i++) {
      count += parseFloat(filteredOrders[i]?.costs?.total)
      const currentValueCard = {id: filteredOrders[i]._id, value: filteredOrders[i]?.costs?.total}
      totalList.push(currentValueCard)
      // setTotalReceiver(count)
    }
    setTotalCards(totalList)
  }, [orders])

  useEffect(() => {
    getData()
  }, [])

  const handleCloseModal = () => {
    setContacts([])
    setSelectedReceiveDate(new Date())
    setOpenModalDescontos(false)
    setOpenModalOrder(false)
    setErrMsg(false)
    setSubmitted(false)
  }

  const handleClose = (event, reason) => {
    setErrMsg(false)
    handleCloseModal()
    history.push(`/recebimento/${paramsID}`)
  }

  function handleReceiveDateChange(date) {
    setSelectedReceiveDate(date)
  }

  function handleChangeStatusCard(status, estado) {
    
  }

  const showTooltipNotes = (order) => {
    for (let i = 0; i < order.length; i++) {
      const { shipping, discount, otherCosts, segCosts } = order[i].sku.provider;
      if(!order[i].sku.provider.hasOwnProperty('shipping') || !order[i].sku.provider.hasOwnProperty('discount') || !order[i].sku.provider.hasOwnProperty('otherCosts') || !order[i].sku.provider.hasOwnProperty('segCosts')) return false
      if (shipping !== 0 || discount !== 0 || otherCosts !== 0 || segCosts !== 0) {
        return true;
      }
      return false 
    }
  }

  function backgroundColorStatus(status, dlvrDate) {
    if (status == "IN PROGRESS") {
      if (Date() > dlvrDate) {
        return "#FFF2CC"
      }
      return "#DADDF3"
    }

    if (status == "RECEIVED") {
      return "#D2F9D6"
    }

    if (status == "RETURNED" || status == "NOT RECEIVED" ) {
      return "#F7D4D7"
    }

    if (status == "PENDING") {
      return "#d3d3d3"
    }
    
  }

  function wordColorStatus(status, dlvrDate) {
    if (status == "IN PROGRESS") {
      if (Date() > dlvrDate) {
        return "#AA8208"
      }
      return "#0B1A8E"
    }

    if (status == "RECEIVED") {
      return "#08AA15"
    }

    if (status == "RETURNED" || status == "NOT RECEIVED") {
      return "#AA0816"
    }

    if (status == "PENDING") {
      return "#0B1A8E"
    }
  }

  function handleTranslateStatus(status, dlvrDate) {
    const stts = {
      "RECEIVED": "Recebido",
      "IN PROGRESS": (Date() > dlvrDate ? "Atrasado" : "Andamento"),
      "RETURNED": "Devolvido",
      "NOT RECEIVED": "Não Recebido",
      "PENDING": "Aprovação" 
    }
    return stts[status]
  }

  const openCompleteNoteModal = () => {
    setOpenModalOrder(true)
    setIsCompleteNote(true)
  }

  const handleBackButtonFilter = () => {
    history.push(`/recebimento/${paramsID}`)
  }

  const handleCloseToast = () => {
    setToastSuccess(false)
  }

  const handleReceivementDate = (date) => {
    setReceivementDate(date)
  }

  const handleSendReceiverRequest = () => {
    setLoading(true)
    makePut("ordersEndPoint", sendReceivedscards, handleResponse, `${receiverId}/skus?receiveDate=${format(receivementDate.valueOf() + 10800000, 'yyyy-MM-dd')}`)
    
    function handleResponse(status, response) {
      if (status === true) {
        setOpenConfirmatioDialog(false)
        setToastSuccess(true)
        setOpenFloatButton(false)
        setModalLoading(false)
        getData()
        setLoading(false)
        history.push(`/recebimento/${paramsID}`)
      }
      else {
        setToastError(true)
        setOpenFloatButton(false)
        setModalLoading(false)
        setLoading(false)
        getData()

      }
    }
  }

  return (
    <>
      {openConfirmatioDialog && 
        <ConfirmationDialog
          sendReceivedscards={sendReceivedscards}
          openConfirmatioDialog={openConfirmatioDialog}
          setOpenConfirmatioDialog={setOpenConfirmatioDialog}
          handleSendReceiverRequest={handleSendReceiverRequest}
          modalLoading={modalLoading}
        />
      }

      {openModalOrder &&
        <DialogCompleteNoteMobile
          openModalOrder={openModalOrder}
          handleCloseModal={handleCloseModal}
          selectedReceiveDate={selectedReceiveDate}
          submitted={submitted}
          handleReceiveDateChange={handleReceiveDateChange}
          setSubmitted={setSubmitted}
          errMsg={errMsg}
          handleClose={handleClose}
          order={currentOrder}
          orders={orders}
          setOrders={setOrders}
          isCompleteNote={isCompleteNote}
          receiverData={receiverData}
          currentQuantity={currentQuantity}
          isDivergency={isDivergency}
          setIsDivergency={setIsDivergency}
          getData={getData}
          handleChangeStatusCard={handleChangeStatusCard}
        />
      }
      
      <AcrescimosDescontos
        openModalOrder={openModalDescontos}
        handleCloseModal={handleCloseModal}
        selectedReceiveDate={selectedReceiveDate}
        submitted={submitted}
        handleReceiveDateChange={handleReceiveDateChange}
        setSubmitted={setSubmitted}
        errMsg={errMsg}
        handleClose={handleClose}
        order={receiverData}
      />

      <Layout>
        <div style={{width: "400px"}} >
          <PageTitleAndFilter
            title={orders[0]?.itemsPaymentDocument?.paymentDocumentNumber == undefined ? "" : `NF ${orders[0]?.itemsPaymentDocument?.paymentDocumentNumber}`}
            settingsOff
            filterOff
            backButton={{ 
                action: handleBackButtonFilter,
                description: `Voltar   Recebimento ${dayOfTheWeek[new Date().getDay()]} ${format(new Date().valueOf() + 10800000, 'dd/MM/yy')}`,
            }}
          />
        </div>

        <Snackbar open={toastSuccess} autoHideDuration={2000} onClose={handleCloseToast}>
          <Alert onClose={handleCloseToast} severity="success" >
            Itens Salvos Com Sucesso
          </Alert>
        </Snackbar> 
        
        <Snackbar open={toastError} autoHideDuration={2000} onClose={handleCloseToast}>
          <Alert onClose={handleCloseToast} severity="error" >
            Erro ao salvar os itens
          </Alert>
        </Snackbar> 

        {loading && <div sytle={{ margin: "10px" }}><LinearProgress style={{ height: "7px" }} variant="query" /></div>}
          
        <PullToRefresh className="customPullToRefresh" onRefresh={() => window.location.reload()}>
          <div className="detailCard" >
            <div className="cardInfo" >
              <p>PEDIDO {receiverData._id} </p>
              
              <div>
                <p>FEITO {receiverData?.createdAt ? format(new Date(receiverData.deliveryDate).valueOf() + 10800000, 'dd/MM') : ""}</p>
                {receiverData?.type == "M" ?
                <p>MANUAL</p> :
                <p>SUGESTÃO</p>
                }
              </div>
            </div>

            <div className="header" >
              <div className="nameAndStatus" >
                <div className="name" >
                  <h1>{receiverData?.providerName}</h1>
                </div>

                <div className="status" >
                  <p>{orders[0]?.itemsPaymentDocument?.paymentDocumentNumber == undefined ? "" : `NF ${orders[0]?.itemsPaymentDocument?.paymentDocumentNumber}`}</p>
                  
                  <div>
                    <p>PREVISÃO {receiverData?.deliveryDate ? format(new Date(receiverData?.deliveryDate).valueOf() + 10800000, 'dd/MM') : ""}</p>
                    <p
                      style={{
                      backgroundColor: backgroundColorStatus(receiverData?.status, receiverData?.deliveryDate),
                      color: wordColorStatus(receiverData?.status, receiverData?.deliveryDate)
                    }}
                    >{handleTranslateStatus(receiverData?.status, receiverData?.deliveryDate)}</p>
                  </div>
                </div>
              </div>

              <div className="values" >
                <div>
                  <p>TOTAL PEDIDO</p>
                  <h1>R${numberToReal(parseFloat(receiverData?.totalOrderAmount))}</h1>
                </div>

                <div>
                  <p>TOTAL RECEBIMENTO</p>
                  <h1>R${numberToReal(orders.length == 0 ? receiverData?.totalPaymentDocument : totalReceiver)}</h1>
                </div>
              </div>

              <div className="buttons" >
                {
                  showTooltipNotes(orders) && <p onClick={() => setOpenModalDescontos(true)} >Pedido com Acréscimos e Descontos</p>
                }
                <button onClick={openCompleteNoteModal} >
                  <DoneIcon style={{color: "#5062F0"}} />
                  Receber Nota completa
                </button>
              </div>
            </div>

            <div className="lengthItens" >
              <p>{orders.length} Itens</p>
            </div>

            {orders.map(order => (
              <ReceiverDetailsCards
                key={order.id}
                cardId={order._id}
                order={order}
                orders={orders}
                loading={loading}
                setOrders={setOrders}
                receiverData={receiverData}
                openModalOrder={openModalOrder}
                setOpenModalOrder={setOpenModalOrder}
                isCompleteNote={isCompleteNote}
                setIsCompleteNote={setIsCompleteNote}
                setCurrentOrder={setCurrentOrder}
                currentQuantity={currentQuantity}
                setCurrentQuantity={setCurrentQuantity}
                isDivergency={isDivergency}
                setIsDivergency={setIsDivergency}
                totalCards={totalCards}
                setTotalCards={setTotalCards}
                openFloatButton={openFloatButton}
                setOpenFloatButton={setOpenFloatButton}
                sendReceivedscards={sendReceivedscards}
                setSendReceivedscards={setSendReceivedscards}
                />
            ))}
          </div>
            
          {openFloatButton ? 
            <div
              className="fabButton"
            >
              {loading ?
                <div style={{display: "flex", justifyContent: "center", width: "100%"}} >
                  <CircularProgress style={{color: "white"}} />
                </div>
              : 
                <>
                  <div className="confirmationButtonDate" >
                    <p>Data</p>
                    <div>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-dialog"
                        format="dd/MM/yyyy"
                        InputAdornmentProps={{ position: "start" }}
                        value={receivementDate}
                        onChange={handleReceivementDate}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                    </div>
                  </div>

                  <div
                    onClick={handleSendReceiverRequest}
                    className="confirmationButtonSend"
                  >
                    <div className="divider"  />
                    <p>Enviar</p>
                    <img src={ArrowRight} alt="seta para direita" />
                  </div>
              </>
              }
            </div>
          : null }
        </PullToRefresh>
      </Layout>
    </>
  )
}

export default ReceiverDetails;
