/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
import React, { useState, useCallback, useMemo, useEffect } from 'react';

import DateFnsUtils from '@date-io/date-fns';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  LinearProgress,
  RadioGroup,
  Snackbar,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
  CircularProgress,
  Divider
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker
} from '@material-ui/pickers';
import axios from 'axios';
import formatISO9075 from 'date-fns/formatISO9075';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import { Alert } from 'reactstrap';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import FullScreenLoading from 'components/FullScreenLoading';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import days from 'data/days.json';
import InfoIcon from 'images/icons/signals/infos/info.svg';
import getOptions from 'services/getOptions';
import * as UserActions from 'store/actions/user';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import history from 'utils/history';

const classes = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  },
  formControl: {
    width: 200,
    margin: theme.spacing(1),
    minWidth: 200
  },
  textField: {
    width: '25ch'
  },
  withoutLabel: {
    marginTop: theme.spacing(3)
  },
  margin: {
    margin: theme.spacing(1)
  }
}));

const environments = Environment(process.env.REACT_APP_ENV);

const LIMIT_SELECTED_PRODUCTS = 10;

function RegisterProducers({ userInfo, producersInfo }) {
  const { id: paramsId } = useParams();
  const { handleSubmit } = useForm();
  const userLocal = JSON.parse(localStorage.getItem('companies'));

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  const editMode = useMemo(
    () =>
      producersInfo.action
        ? producersInfo.action.toLowerCase() === 'edit'
        : false,
    [producersInfo.action]
  );

  const [daysSelect, setDaysSelect] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [alertErr, setAlertErr] = useState(false);
  const [safetyMargin, setSafetyMargin] = useState(
    editMode ? producersInfo.data.safetyMargin : ''
  );
  const [percentageForAlert, setPercentageForAlert] = useState(
    editMode ? producersInfo.data.percentageForAlert : ''
  );
  const [percentageForEmail, setPercentageForEmail] = useState(
    editMode ? producersInfo.data.percentageForEmail : ''
  );
  const [curveA, setCurveA] = useState(
    editMode ? producersInfo.data.curveA : ''
  );
  const [curveB, setCurveB] = useState(
    editMode ? producersInfo.data.curveB : ''
  );
  const [curveC, setCurveC] = useState(
    editMode ? producersInfo.data.curveC : ''
  );
  // const [salesProjection, setSalesProjection] = useState();
  // const [totalCurve, setTotalCurve] = useState();
  // const [getValidation, setGetValidation] = useState('');
  // const [getValidationP, setGetValidationP] = useState('');
  // const [getValidationCount, setGetValidationCount] = useState('');
  const [weeksAgo, setWeeksAgo] = useState();
  const [selectedStartTime, setSelectedStartTime] = useState(new Date());
  const [selectedEndTime, setSelectedEndTime] = useState(new Date());
  const [monthsAhead, setMonthsAhead] = useState();
  const [suggestionDays, setSuggestionDays] = useState();
  const [parameters, setParameters] = useState({});
  const [lastUpdate, setLastUpdate] = useState();
  const [radioValue, setRadioValue] = useState('');
  const [copilotSuggestionType, setCopilotSuggestionType] = useState('');
  const [unifiedSkus, setUnifiedSkus] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [dataConfigUser, setDataConfigUser] = useState({})
  const [radioValueP, setRadioValueP] = useState('');
  const [radioValueCount, setRadioValueCount] = useState('CLOSURE');
  const [maxPercentageDiff, setMaxPercentageDiff] = useState(
    editMode ? producersInfo.data.maxPercentage : ''
  );
  const [productsList, setProductsList] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [isLoadingParameters, setIsLoadingParameters] = useState(false);

  const selectedProductsIds = selectedProducts.map((product) => product.id);

  const getDefaultSelectedProducts = useCallback(
    (productsIds) =>
      productsList?.filter((product) => productsIds?.includes(product.id)) ||
      [],
    [productsList]
  );

  const getParameterInfos = useCallback(() => {
    (async () => {
      setIsLoadingParameters(true);

      const response = await fetch(
        `${environments.restaurants}/companies/${userInfo.companiesActive.id}/parameters`
      );
      const paramsJson = await response.json();

      const defaultSelectedProducts = getDefaultSelectedProducts(
        paramsJson?.salesReprocess?.productIds
      );
      const cardDaysIds = paramsJson?.salesProjection?.noSuggestionsDaysWeek;

      setDaysSelect(days?.filter((day) => cardDaysIds?.includes(day.id)) || []);
      setParameters(paramsJson);
      setLastUpdate(paramsJson?.lastUpdate);
      // setSalesProjection(paramsJson?.salesProjection);
      setSelectedProducts(defaultSelectedProducts);
      setSafetyMargin(paramsJson?.salesProjection.safetyMargin);
      setPercentageForAlert(paramsJson?.manufactures.percentageForAlert);
      setPercentageForEmail(dataConfigUser?.configs[0]?.manufacture?.allowedVariationPercentage);
      setWeeksAgo(paramsJson?.salesProjection.weeksAgo);
      setCurveA(paramsJson?.curve.A);
      setCurveB(paramsJson?.curve.B);
      setCurveC(paramsJson?.curve.C);
      setMonthsAhead(paramsJson?.salesProjection.monthsAhead);
      setSuggestionDays(paramsJson?.salesProjection.daysToSuggestion);
      setRadioValue(paramsJson?.salesProjection.type);
      setCopilotSuggestionType(paramsJson?.engine?.typeSuggestionDays)
      setUnifiedSkus(
        paramsJson?.counts ? paramsJson?.counts.unifiedSkus : false
      );
      setSendEmail(dataConfigUser?.configs[0]?.manufacture?.sendVariationEmail)
      setRadioValueP(paramsJson?.product.type);
      setRadioValueCount(
        paramsJson?.inventory ? paramsJson?.inventory.type : 'CLOSURE'
      );
      setSelectedStartTime(
        paramsJson?.time.opening
          ? new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            parseInt(paramsJson?.time.opening.split(':')[0], 10),
            parseInt(paramsJson?.time.opening.split(':')[1], 10),
            0
          )
          : null
      );
      setSelectedEndTime(
        paramsJson?.time.closing
          ? new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            new Date().getDate(),
            parseInt(paramsJson?.time.closing.split(':')[0], 10),
            parseInt(paramsJson?.time.closing.split(':')[1], 10),
            0
          )
          : null
      );
      setMaxPercentageDiff(paramsJson?.counts.differencePercentage);
      setIsLoadingParameters(false);
    })();
  }, [productsList, paramsId]);

  const onSubmit = () => {
    setSaving(true);

    const checkCurve =
      parseFloat(curveA) + parseFloat(curveB) + parseFloat(curveC);
    
    axios.put(`${environments.auth}/users/${userLocal.user}/config`, {
      ...dataConfigUser,
      configs: [
        {
          ...dataConfigUser?.configs[0],
          manufacture: {
            allowedVariationPercentage: Number(percentageForEmail),
            sendVariationEmail: sendEmail
          }
        }
      ]
    })

    if (checkCurve !== 100) {
      setLoading(false);
      setSaving(false);
      setAlertErr(true);
    } else if (parameters._id) {
      axios
        .put(
          `${environments.restaurants}/parameters/${parameters._id}`,
          {
            method: 'PUT',
            curve: {
              A: curveA,
              B: curveB,
              C: curveC
            },
            salesProjection: {
              safetyMargin,
              type: radioValue,
              weeksAgo,
              monthsAhead,
              daysToSuggestion: suggestionDays,
              noSuggestionsDaysWeek: daysSelect?.map((day) => day?.id)
            },
            engine: {
              typeSuggestionDays: copilotSuggestionType
            },
            salesReprocess: {
              productIds: selectedProductsIds
            },
            product: {
              type: radioValueP
            },
            inventory: {
              type: radioValueCount
            },
            time: {
              opening: formatISO9075(selectedStartTime, {
                representation: 'time'
              }),
              closing: formatISO9075(selectedEndTime, {
                representation: 'time'
              })
            },
            counts: {
              unifiedSkus,
              differencePercentage: maxPercentageDiff
            },
            manufactures: {
              percentageForAlert,
              percentageForEmail
            },
            restaurantId: userInfo.companiesActive.id,
            user: userInfo.user
          },
          config
        )
        .then(() => {
          setOpen(true);
          setLoading(false);
          setSaving(false);
        })
        .catch(() => {
          setLoading(false);
          setSaving(false);
          setAlertErr(true);
        });
    } else {
      axios
        .post(
          `${environments.restaurants}/parameters`,
          {
            method: 'POST',
            curve: {
              A: curveA,
              B: curveB,
              C: curveC
            },
            salesProjection: {
              safetyMargin,
              type: radioValue,
              weeksAgo,
              monthsAhead,
              daysToSuggestion: suggestionDays,
              noSuggestionsDaysWeek: daysSelect?.map((day) => day?.id)
            },
            engine: {
              typeSuggestionDays: copilotSuggestionType
            },
            product: {
              type: radioValueP
            },
            inventory: {
              type: radioValueCount
            },
            time: {
              opening: formatISO9075(selectedStartTime, {
                representation: 'time'
              }),
              closing: formatISO9075(selectedEndTime, {
                representation: 'time'
              })
            },
            counts: {
              unifiedSkus,
              differencePercentage: maxPercentageDiff
            },
            manufactures: {
              percentageForAlert,
              percentageForEmail
            },
            restaurantId: userInfo.companiesActive.id,
            user: userInfo.user
          },
          config
        )
        .then(() => {
          setOpen(true);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          setAlertErr(true);
        });
    }
  };

  function decimal(v) {
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{2,2})$/, '$1,$2');
    return v;
  }

  // Start Time
  function handleStartDateChange(date) {
    setSelectedStartTime(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        parseInt(date.split(':')[0], 10),
        parseInt(date.split(':').slice(-1).pop(), 10),
        0
      )
    );
  }

  // End Time
  function handleEndDateChange(date) {
    setSelectedEndTime(
      new Date(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate(),
        parseInt(date.split(':')[0], 10),
        parseInt(date.split(':').slice(-1).pop(), 10),
        0
      )
    );
  }

  // Radio
  const handleChangeRadio =
    useCallback((event) => {
      setRadioValue(event.target.value);
      // setGetValidation(event.target.value);
    },
      []);

  const handleChangeRadioP = useCallback((event) => {
    setRadioValueP(event.target.value);
    // setGetValidationP(event.target.value);
  }, []);

  const handleChangeRadioCount = useCallback((event) => {
    setRadioValueCount(event.target.value);
    // setGetValidationCount(event.target.value);
  }, []);

  const handleClose = (event, reason) => {
    history.push(`/admin/parametros/${userInfo.companiesActive.id}`);
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const getOptionDisabled = (option) => {
    const isOptionAlreadySelected = selectedProducts.some(
      (product) => product.id === option.id
    );

    if (selectedProducts.length >= LIMIT_SELECTED_PRODUCTS) return true;
    if (isOptionAlreadySelected) return true;
  };

  useEffect(() => {
    axios.get(`${environments.auth}/users/${userLocal?.user}`)
    .then((res) => {
      setDataConfigUser(res?.data);
    })
  }, [paramsId])

  useEffect(() => {
    if (productsList?.length) {
      getParameterInfos();
    } else {
      getOptions(
        `${environments.catalog}/products`,
        'products',
        productsList,
        setProductsList,
        paramsId,
        { autoComplete: true },
        null
      );
    }
  }, [paramsId, productsList]);

  const form = (
    <Container className={classes.root} maxWidth="lg">
      {alertErr ? (
        <Alert
          style={{ width: '30%' }}
          color="danger"
          isOpen={alertErr}
          toggle={() => setAlertErr(false)}
        >
          Erro ao cadastrar
        </Alert>
      ) : null}

      <FullScreenLoading display={saving} description="Salvando" />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div style={{
          display: 'flex',
          width: "100%",
          justifyContent: "space-between",
          backgroundColor: "#fff",
          borderRadius: 4,
          boxShadow: '0px 0px 8px rgba(4, 6, 26, 0.25)'
        }}>
          <div style={{ width: "50%" }}>
            <div className="boxDefault">
              <div className="boxTitle" style={{ borderRadius: '4px 0 0 0' }}>
                <h5> Forecast / Projeção De Vendas </h5>
              </div>

              {loading ? (
                <LinearProgress style={{ height: '8px' }} variant="query" />
              ) : null}

              <div className="item">
                <h7>Projetar Vendas Considerando:</h7>

                <FormControl component="fieldset">
                  <RadioGroup
                    className="flexRow"
                    aria-label="type"
                    name="type"
                    value={radioValue}
                    onChange={(e) => handleChangeRadio(e)}
                  >
                    <FormControlLabel
                      value="MANUAL_PROJECTION"
                      control={<Radio required size="small" />}
                      label="Meta Loja"
                    />
                    <FormControlLabel
                      value="ENGINE_PROJECTION"
                      control={<Radio required size="small" />}
                      label="Projeção Bloom"
                    />
                    <FormControlLabel
                      value="HIGHEST_VALUE"
                      control={<Radio required size="small" />}
                      label="Maior Valor"
                    />
                  </RadioGroup>
                </FormControl>
              </div>

              <div className="item" style={{ width: '30%', justifyContent: 'space-between' }}>
                <h7>Margem de Segurança (%):</h7>

                <Grid item xs={12} sm={1}>
                  <TextField
                    size="small"
                    onChange={(e) => setSafetyMargin(e.target.value)}
                    name="safetyMargin"
                    variant="outlined"
                    value={safetyMargin}
                    required="required"
                    onInput={(e) => {
                      e.target.value = decimal(e.target.value);
                    }}
                    inputProps={{ maxLength: 5 }}
                    style={{ width: 70 }}
                    className="textFieldSmall right"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </div>

              <div className="item" style={{ width: '30%', justifyContent: 'space-between' }}>
                <h7>Semanas Anteriores (qtd):</h7>

                <Grid item xs={12} sm={1}>
                  <TextField
                    size="small"
                    onChange={(e) => setWeeksAgo(e.target.value)}
                    name="weeksAgo"
                    variant="outlined"
                    value={weeksAgo}
                    fullWidth
                    required="required"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                    inputProps={{ maxLength: 2 }}
                    style={{ width: 70 }}
                    className="textFieldSmall right"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </div>

              <div className="item" style={{ width: '30%', justifyContent: 'space-between' }}>
                <h7>Meses Posteriores (qtd):</h7>

                <Grid item xs={12} sm={1}>
                  <TextField
                    size="small"
                    onChange={(e) => setMonthsAhead(e.target.value)}
                    name="monthsAhead"
                    variant="outlined"
                    value={monthsAhead}
                    fullWidth
                    required="required"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                    inputProps={{ maxLength: 2 }}
                    style={{ width: 70 }}
                    className="textFieldSmall right"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </div>

              <div className="item" style={{ width: '30%', justifyContent: 'space-between' }}>
                <h7>Dias de Sugestão (qtd):</h7>

                <Grid item xs={12} sm={1}>
                  <TextField
                    size="small"
                    onChange={(e) => setSuggestionDays(e.target.value)}
                    name="suggestionDays"
                    variant="outlined"
                    value={suggestionDays}
                    fullWidth
                    required="required"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                    inputProps={{ maxLength: 2 }}
                    style={{ width: 70 }}
                    className="textFieldSmall right"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </div>

              <div className="item" style={{ width: '30%', justifyContent: 'space-between' }}>
                <h7>Não gerar sugestões para:</h7>

                <Grid item xs={12} sm={1}>
                  <Autocomplete
                    id="controllable-states-demo"
                    getOptionSelected={(option, value) => option?.id === value?.id}
                    getOptionLabel={(option) => option?.description}
                    options={days}
                    multiple
                    limitTags={1}
                    style={{ width: 140 }}
                    size="small"
                    value={daysSelect}
                    className="autocompleteSmall"
                    onChange={(_, value) => {
                      if (value.find((item) => item.description === 'Todos')) {
                        setDaysSelect(
                          days.filter((item) => item.description !== 'Todos')
                        );
                      } else {
                        setDaysSelect(value);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id={params.id}
                        name="providers"
                        variant="outlined"
                        placeholder="Selecione..."
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                    renderOption={(params) => (
                      <Typography style={{ fontSize: '12px', fontFamily: 'Lato' }}>
                        {params?.description}
                      </Typography>
                    )}
                  />
                </Grid>
              </div>

              <div className="item justifyBetween w100">
                <h7 className="dFlex flexColumn" style={{ width: "26%" }}>
                  <span>
                    Copiloto /
                  </span> 
                  Sugestões de Produção:
                </h7>
                
                <FormControl component="fieldset" className="w100">
                  <RadioGroup
                    className="flexRow w100"
                    aria-label="type"
                    name="type"
                    value={copilotSuggestionType}
                    onChange={(e) => setCopilotSuggestionType(e?.target?.value)}
                  >
                    <FormControlLabel
                      value="NO_DAILY_SCHEDULE"
                      className="dFlex alignStart"
                      style={{ width: '40%' }}
                      control={<Radio required size="small" />}
                      label={
                        <span className="dFlex flexColumn">
                          Gerar previsão de consumo 
                          <span className="bold lightBlue">
                            até o dia anterior
                          </span> 
                          da próxima agenda de produção
                        </span>
                      }
                    />
                    <FormControlLabel
                      value="DAILY_SCHEDULE"
                      className="dFlex alignStart"
                      style={{ width: '40%' }}
                      control={<Radio required size="small" />}
                      label={
                        <span className="dFlex flexColumn">
                          Gerar previsão de consumo 
                          <span className="bold lightBlue">
                            até o dia 
                          </span> 
                          da próxima agenda de produção
                        </span>
                      }
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="boxDefault">
              <div className="boxTitle">
                <h5> Curva ABC (%) </h5>
              </div>

              {loading ? (
                <LinearProgress style={{ height: '8px' }} variant="query" />
              ) : null}

              <div className="item" style={{ gap: 15 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h7>A</h7>

                  <Grid item xs={12} sm={1}>
                    <TextField
                      size="small"
                      onChange={(e) => setCurveA(e.target.value)}
                      name="curveA"
                      variant="outlined"
                      value={curveA}
                      required="required"
                      onInput={(e) => {
                        e.target.value = decimal(e.target.value);
                      }}
                      inputProps={{ maxLength: 5 }}
                      style={{ width: 70 }}
                      className="textFieldSmall right"
                      placeholder="Digite aqui..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h7> B </h7>

                  <Grid item xs={12} sm={1}>
                    <TextField
                      size="small"
                      onChange={(e) => setCurveB(e.target.value)}
                      name="curveB"
                      variant="outlined"
                      value={curveB}
                      required="required"
                      onInput={(e) => {
                        e.target.value = decimal(e.target.value);
                      }}
                      inputProps={{ maxLength: 5 }}
                      style={{ width: 70 }}
                      className="textFieldSmall right"
                      placeholder="Digite aqui..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                </div>

                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h7> C </h7>

                  <Grid item xs={12} sm={1}>
                    <TextField
                      size="small"
                      onChange={(e) => setCurveC(e.target.value)}
                      name="curveC"
                      variant="outlined"
                      value={curveC}
                      required="required"
                      onInput={(e) => {
                        e.target.value = decimal(e.target.value);
                      }}
                      inputProps={{ maxLength: 5 }}
                      style={{ width: 70 }}
                      className="textFieldSmall right"
                      placeholder="Digite aqui..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                </div>
              </div>
            </div>

            <div className="boxDefault" style={{ borderRadius: '0 0 0 4px' }}>
              <div className="boxTitle">
                <h5> Inventário </h5>
              </div>

              {loading ? (
                <LinearProgress style={{ height: '8px' }} variant="query" />
              ) : null}

              <div className="item pb20">
                <h7>Contagem:</h7>

                <FormControl component="fieldset">
                  <RadioGroup
                    className="flexRow"
                    aria-label="type"
                    name="type"
                    value={radioValueCount}
                    onChange={(e) => handleChangeRadioCount(e)}
                  >
                    <FormControlLabel
                      disabled
                      value="CLOSURE"
                      control={<Radio required size="small" />}
                      label="Fechamento"
                    />

                    <Tooltip
                      placement="top"
                      title={
                        <span className="dFlex" style={{ padding: '3px 7px' }}>
                          Em Construção
                        </span>
                      }
                    >
                      <FormControlLabel
                        disabled
                        value="OPENING"
                        control={<Radio required size="small" />}
                        label="Abertura"
                      />
                    </Tooltip>
                  </RadioGroup>
                </FormControl>
              </div>

              <div className="item">
                <h7>Horários:</h7>

                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <Grid item xs={2} sm={1} className="mr20">
                    <KeyboardTimePicker
                      size="small"
                      id="time-picker"
                      label="Horário Inicial"
                      ampm={false}
                      clearable
                      value={selectedStartTime}
                      inputVariant="outlined"
                      className="dateSmall"
                      style={{ width: 80 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        style: { fontFamily: 'Lato', fontSize: '12px' }
                      }}
                      onChange={(_, value) => {
                        if (value) {
                          handleStartDateChange(value);
                        }
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change time'
                      }}
                    />
                  </Grid>

                  <Grid item xs={2} sm={1} className="ml20">
                    <KeyboardTimePicker
                      size="small"
                      id="time-picker"
                      label="Horário Final"
                      ampm={false}
                      clearable
                      value={selectedEndTime}
                      inputVariant="outlined"
                      className="dateSmall"
                      style={{ width: 80 }}
                      InputLabelProps={{
                        shrink: true
                      }}
                      InputProps={{
                        style: { fontFamily: 'Lato', fontSize: '12px' }
                      }}
                      onChange={(_, value) => {
                        if (value) {
                          handleEndDateChange(value);
                        }
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change time'
                      }}
                    />
                  </Grid>
                </MuiPickersUtilsProvider>
              </div>
            </div>
          </div>

          <div style={{ borderRight: '1px solid #000' }} />

          <div style={{ width: "50%" }}>
            <div className="boxDefault">
              <div className="boxTitle" style={{ borderRadius: '0 4px 0 0' }}>
                <h5> Produto </h5>
              </div>

              {loading ? (
                <LinearProgress style={{ height: '8px' }} variant="query" />
              ) : null}

              <div className="item">
                <h7>Considerar valor de Compra:</h7>

                <FormControl component="fieldset">
                  <RadioGroup
                    className="flexRow"
                    aria-label="type"
                    name="type"
                    value={radioValueP}
                    onChange={(e) => handleChangeRadioP(e)}
                  >
                    <FormControlLabel
                      value="LAST_PURCHASE"
                      control={<Radio required size="small" />}
                      label="Última Compra"
                    />
                    <FormControlLabel
                      value="MEDIUM"
                      control={<Radio required size="small" />}
                      label="Valor Médio"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div className="boxDefault">
              <div className="boxTitle">
                <h5> Vendas </h5>
              </div>

              <div className="item">
                <h7>Validar processamento de vendas:</h7>

                <Grid item xs={12} sm={5}>
                  <Autocomplete
                    onFocus={() => {
                      if (!productsList.length) {
                        getOptions(
                          `${environments.catalog}/products`,
                          'products',
                          productsList,
                          setProductsList,
                          paramsId,
                          { autoComplete: true },
                          null
                        );
                      }
                    }}
                    multiple
                    onChange={(_, value) => setSelectedProducts(value)}
                    className="autocompleteSmall"
                    fullWidth
                    limitTags={2}
                    size="small"
                    variant="outlined"
                    getOptionLabel={(option) => option?.description}
                    getOptionDisabled={getOptionDisabled}
                    options={productsList}
                    value={selectedProducts}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id={params.id}
                        name="produtos"
                        style={{
                          fontFamily: 'Lato',
                          fontSize: '12px',
                          paddingLeft: '13px'
                        }}
                      />
                    )}
                    renderOption={(params) => (
                      <Typography
                        style={{
                          fontSize: '12px',
                          fontFamily: 'Lato'
                        }}
                      >
                        {params.description}
                      </Typography>
                    )}
                  />
                </Grid>
              </div>
            </div>
            
            <div className="boxDefault">
              <div className="boxTitle">
                <h5> Contagem </h5>
              </div>

              {loading ? (
                <LinearProgress style={{ height: '8px' }} variant="query" />
              ) : null}

              <div className="item">
                <Grid item xs={12} className="fontSizeDefault">
                  <Checkbox
                    name="unifySkus"
                    checked={unifiedSkus}
                    className="checkBoxSmall"
                    onChange={() => {
                      setUnifiedSkus(!unifiedSkus);
                    }}
                    size="small"
                  />
                  Unificar SKUs
                  <Tooltip
                    TransitionProps={{ timeout: 600 }}
                    title={
                      <p className="mb0" style={{ padding: 2 }}>
                        Unificar SKUs de Fornecedores diferentes com a mesma
                        embalagem
                      </p>
                    }
                    placement="right-end"
                  >
                    <img
                      src={InfoIcon}
                      alt="Info Icon"
                      style={{ margin: '0 0 2px 5px', width: '13px' }}
                    />
                  </Tooltip>
                </Grid>
              </div>

              <div className="item">
                <h7>Diferença aceita (%):</h7>

                <Grid item xs={12} sm={1}>
                  <TextField
                    size="small"
                    onChange={(e) => setMaxPercentageDiff(e.target.value)}
                    name="maxPercentageDiff"
                    variant="outlined"
                    value={maxPercentageDiff}
                    inputProps={{ maxLength: 5 }}
                    style={{ width: 70 }}
                    className="textFieldSmall right"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </div>
            </div>

            <div className="boxDefault">
              <div className="boxTitle">
                <h5> Produção </h5>
              </div>

              {loading ? (<LinearProgress style={{ height: '8px' }} variant="query" />) : null}

              <div className="item" style={{ gap: 5 }}>
                <Checkbox
                  name="sendEmail"
                  checked={sendEmail}
                  className="checkBoxSmall"
                  onChange={() => {
                    setSendEmail(!sendEmail);
                  }}
                  size="small"
                />

                <h7>Enviar e-mail para produções finalizadas com diferenças acima de (%): </h7>

                <Grid item xs={12} sm={1}>
                  <TextField
                    size="small"
                    onChange={(e) => setPercentageForEmail(e.target.value)}
                    name="percentageForEmail"
                    variant="outlined"
                    value={percentageForEmail}
                    required="required"
                    onInput={(e) => {
                      e.target.value = decimal(e.target.value);
                    }}
                    inputProps={{ maxLength: 5 }}
                    style={{ width: 70 }}
                    className="textFieldSmall right"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </div>
            </div>
          </div>
        </div>


        <Snackbar open={open} autoHideDuration={1000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Cadastro realizado com sucesso!
          </Alert>
        </Snackbar>

        <Grid container item xs={12} justify="flex-end" className="mt20">
          <tr>
            <td>
              <Button
                className="defaultButton submitButton"
                type="submit"
                label="Salvar" 
                design="contained"
              />
            </td>
          </tr>
        </Grid>
      </form>
    </Container>
  );

  if (isLoadingParameters) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <CircularProgress size={30} />
      </div>
    );
  }

  return (
    <Layout>
      <TitleHead title="Parâmetros" />

      <GlobalStyle />

      <Container className="parametersPage">
        <Grid container xs={12} style={{ marginBottom: '15px' }}>
          <Grid container item xs={6} justify="flex-start" align="center">
            <Typography
              className="dFlex alignCenter"
              style={{ fontFamily: 'Lato', fontSize: '16px' }}
            >
              <b>Parâmetros</b>
            </Typography>
          </Grid>

          <Grid
            container
            item
            xs={6}
            justify="flex-end"
            align="center"
            className="userInfos"
          >
            <p>
              Usuário:
              <strong>{parameters.user}</strong>
            </p>

            <p>
              Última Atualização:
              <strong>{moment(lastUpdate).format('LLL')}</strong>
            </p>
          </Grid>
        </Grid>

        {form}
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  producersInfo: state.producers
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(RegisterProducers);
