import { Grid } from '@material-ui/core';
import styled from 'styled-components';

export const TotalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  width: 100%;
`;

export const Title = styled.span`
  font-size: 12px;
  font-weight: ${({ bold }) => (bold ? 'bolder' : 200)};
  padding: 2px 5px;
  color: ${({ color }) => color || '#000'};
  background: ${({ bgColor }) => bgColor || 'transparent'};
  border-right: solid 1px #a0a1a7;
  box-shadow: 0px 4px 4px 0px #00000040;
  min-width: calc(100% / 3 - 7.5px);
  border: solid .5px #0B1A8E;
  border-radius: 6px;
  margin: 3px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  &:first-of-type {
    margin-left: 5px;
  }

  &:last-of-type {
    margin-right: 5px;
  }
`;

export const HeaderContainer = styled.div`
  top: ${({ showAlert }) => (showAlert ? "25px" : "0px")};
  display: flex;
  background: rgb(255, 255, 255);
  position: sticky !important;
  z-index: 100;
  width: 100%;
  background: '#fff';
  z-index: 1000;
  margin-bottom: 10px;

  @media (max-width: 870px) and (orientation: portrait) {
    top: ${({ showAlert }) => (showAlert ? "43px" : "0px")};
  }
`;

export const StyledGrid = styled(Grid)`
  width: 100%;
  
  table {
    th {
      font-size: 12px !important;
    }
  }
`;

export const Table = styled.table`
  margin-top: ${({ marginTop }) => marginTop || '0px'};
`;

export const DivButtomExit = styled.div`
  @media (max-width: 870px) and (orientation: portrait) {
    bottom: 50px !important;
  }
`;

export const GridFooter = styled(Grid)`
  @media (max-width: 870px) and (orientation: portrait) {
    padding-top: 60px !important;
  }
`;

export const TdEmb = styled.td`
  @media (min-width: 870px) {
    display: none !important;
  }
`;

export const TdCount = styled.td`
  @media (max-width: 870px) and (orientation: portrait) {
    display: none !important;
  }
`;

export const GroupHeaderContainer = styled.div`
  display: flex;
  position: sticky !important;
  z-index: 999;
  top: ${({ showAlert }) => (showAlert ? "115px" : "91px")};
  
  .spanDesk {
    @media (max-width: 870px) and (orientation: portrait) {
      display: none !important;
    }
  }
  
  .spanTablet {
    @media (min-width: 870px) {
      display: none !important;
    }
  }

  @media (max-width: 870px) and (orientation: portrait) {
    top: ${({ showAlert }) => (showAlert ? "244px" : "201px")};
  }
`;

export const GroupHeader = styled.div`
  display: flex;
  z-index: 999;
  position: relative;
  width: 74%;

  .MarginButton {
    position: absolute;
    right: -32px;
  }

  .noArrows {
    right: 21px;

    @media (max-width: 870px) and (orientation: portrait) {
      right: -19%;
    }
  }

  .withArrows {
    right: -32px;
  }

  .limitCounts{
    right: 12px;

    @media (max-width: 870px) and (orientation: portrait) {
      right: -19%;
    }
  }

  .ReportButton {
    cursor: pointer;
    border: 0.5px solid #1F2445;
    box-shadow: 0 -2px 4px 0px rgba(1, 3, 17, 0.25);
    border-radius: 4px 4px 0px 0px;
    padding: 0 6px;
    height: 20px;
    display: flex;
    align-items: center;

    &.active {
      background: #1F2445;
      border: 0.5px solid transparent;

      p {
        color: #FFF;
      }
    }
    }
`

export const TotalDiscard = styled.div`
  display: flex;
  align-items: center;
  height: 22px;
  padding: 0px 6px;
  z-index: 100;
  background-color: #FFF;
  width: 100%;
  cursor: pointer;
  justify-content: flex-end;
  
  .iconTotal {
    display: flex;
    align-items: center;

    img {
      margin-right: 2px;
    }

    p,
    span {
      font-size: 12px;
      margin-top: 0;
      margin-bottom: 0;
      font-weight: bold;
      color: #AA8208;
    }

    p {
      display: flex;
      align-items: center;
    }

    span {
      margin-left: 5px;
    }
  }

  .valuesDiscard {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 15px;
    padding: 0;
    width: 34%;
    cursor: pointer;

    p {
      font-weight: 700;
      color: #AA8208;
      font-size: 12px;
      margin: 0;
    }
  }
`
