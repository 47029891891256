/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-case-declarations */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-new */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback, useMemo } from 'react';

import {
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Fade,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  Paper,
  Tooltip,
  Typography
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Add, DeleteOutline, ExpandLess, ExpandMore } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import Axios from 'axios';
import moment from 'moment';
import Draggable from 'react-draggable';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { isArray } from 'shineout/lib/utils/is';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import RelatedCards from 'components/Dialogs/RelatedCards';
import TechnicalSheetDialog from 'components/Dialogs/TechnicalSheetDialog/TechnicalSheetDialog';
import UnifyCards from 'components/Dialogs/UnifyCards';
import FullScreenLoading from 'components/FullScreenLoading';
import ImageWrapper from 'components/ImageWrapper';
import ItemsPerPage from 'components/ItemsPerPage';
import OrderBy from 'components/OrderBY';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import Register from 'components/Register';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import AddIcon from 'images/icons/signals/addsAndCreates/add.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import axiosInstanceCatalog from 'services/middlewares/apis/catalog_validate';
import * as CardsActions from 'store/actions/cards';
import { changeFilter, clearFilter } from 'store/reducers/cardsFilterSlice';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import ValidationLength from 'utils/utils';

const environments = Environment(process.env.REACT_APP_ENV);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  input: {
    fontFamily: 'Lato',
    fontSize: 12
  },
  option: {
    fontFamily: 'Lato',
    fontSize: 12
  }
}));

function PaperComponent(props) {
  return (
    <Draggable
      bounds="parent"
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

const DEFAULT_FILTERS = [];

export function CardsList({ userInfo, createCards, setEditCards }) {
  const { id: paramsID, cardId } = useParams();
  const isAdminPage = userInfo.profile[0][0]?.name === 'admin';
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const prevPath = history?.location?.state?.prevPath;

  const storedCardsFilter = useSelector(
    (currState) => currState.cardsFilter.filters
  );

  const cancelTokenSource = Axios.CancelToken.source();

  const shouldUseStoredFilters = useMemo(
    () => storedCardsFilter && prevPath,
    [prevPath, storedCardsFilter]
  );

  const [filtersChanged, setFiltersChanged] = useState([]);
  const [cardAssociation, setCardAssociation] = useState();
  const [modalSettings, setModalSettings] = useState([
    {
      id: 1,
      name: 'cardsAssociation',
      open: false,
      fullScreen: false,
      maxWidth: 'sm'
    },
    {
      id: 2,
      name: 'technicalSheet',
      open: false,
      fullScreen: false,
      maxWidth: null
    }
  ]);
  const [unifyCardsObj, setUnifyCardsObj] = useState({
    open: false,
    cards: [],
    refCard: ''
  });
  const [isGroupUnification, setIsGroupUnification] = useState(false);
  const [unifyCardsLoading, setUnifyCardsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cardsOptions, setCardsOptions] = useState([]);
  const [collapseOpen, setCollapseOpen] = useState([]);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [deleteData, setDeleteData] = useState();
  const [deleteSuccess, handleDeleteSuccess] = useState(false);
  const [deleteError, handleDeleteError] = useState(false);
  const [message, setMessage] = useState('');
  const [statusCode, setStatusCode] = useState();
  const [menuId, setMenuId] = useState();
  const [menuLowAutomaticParams, setMenuLowAutomaticParams] = useState();
  const [openModalMenu, setOpenModalMenu] = useState(false);
  const [hasMenuModalChange, setHasMenuModalChange] = useState(false);
  const [confirmCloseMenuDialog, setConfirmCloseMenuDialog] = useState(false);
  const [showDeleteLoading, setShowDeleteLoading] = useState(false);
  const [salesAverageDiscount, setSalesAverageDiscount] = useState();
  const [selectedCard, setSelectedCard] = useState();
  const [ucPrimarySelectedId, setUcPrimarySelectedId] = useState();

  const [cardDataTable, setCardDataTable] = useState([]);
  const [cardTransformation, setCardTransformation] = useState({
    type: 'product',
    productId: null,
    stock: 0,
    consumptionUnitsPrimaryId: null,
    consumptionUnitsPrimary: null,
    cost: 0,
    averageCost: 0,
    lastCost: 0,
    quantity: 0
  });

  const typeDefaultValue = {
    title: 'Todos',
    value: ['RECIPE','TRANSFORMATION','MENU']
  }
  const [typesOptions, setTypesOptions] = useState(
    [typeDefaultValue, { title: 'Receita', value: 'RECIPE' },
      { title: 'Transformação', value: 'TRANSFORMATION' },
      { title: 'Cardapio', value: 'MENU' }]
  );
  const typeFilter = storedCardsFilter.find((filter) => filter.filterName === 'type')

  const [typeOptionsDefaultValue, setTypeOptionDefaultValue] = useState(
    typeFilter ? typesOptions.filter((filter) => typeFilter.type.includes(filter.value))
 : [typeDefaultValue]
  );
  const [callSettings, setCallSettings] = useState({
    getId: paramsID,
    page: 1,
    totalPages: 0,
    size: 100,
    filters: shouldUseStoredFilters ? storedCardsFilter : DEFAULT_FILTERS,
    numberOfElements: 0,
    totalElements: 0
  });
  const { getId, page, filters, numberOfElements, totalElements } =
    callSettings;
  const isGroupManage = getId?.includes(',');

  const [filterFields, setFilterFields] = useState([
    [
      {
        label: 'Ficha',
        filterName: 'cardDescription',
        filterNameSecond: 'cardId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/cards`,
        key: 'cards',
        optionReference: 'description',
        options: [],
        size: 'medium_plus',
        searchByTermMode: true,
        storedValue:
          (shouldUseStoredFilters &&
            storedCardsFilter.find(
              (filter) => filter.filterName === 'cardDescription'
            )?.cardDescription) ||
          storedCardsFilter.find((filter) => filter.filterName === 'cardId')
            ?.textValue
      },
      {
        label: 'Produto',
        filterName: 'productDescription',
        placeHolder: 'Selecione...',
        filterNameSecond: 'productId',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/products`,
        key: 'products',
        page: 1,
        optionReference: 'description',
        options: [],
        size: 'medium_plus',
        searchByTermMode: true,
        storedValue:
          (shouldUseStoredFilters &&
            storedCardsFilter.find(
              (filter) => filter.filterName === 'productDescription'
            )?.productDescription) ||
          storedCardsFilter.find((filter) => filter.filterName === 'productId')
            ?.textValue
      },
      {
        label: 'Categoria',
        filterName: 'categoryDescription',
        placeHolder: 'Digite aqui...',
        type: 'fieldSearch',
        size: 'medium_plus',
        storedValue:
          shouldUseStoredFilters &&
          storedCardsFilter.find(
            (filter) => filter.filterName === 'categoryDescription'
          )?.categoryDescription
      },
      {
        label: 'Tipo',
        filterName: 'type',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        page: 1,
        key: 'brands',
        optionReference: 'description',
        isSelect: true,
        multiple: true,
        defaultValue: typeOptionsDefaultValue,
        updateDefaultValue: typeOptionsDefaultValue,
        options: typesOptions,
        size: 'medium_plus',
        storedValue:
          shouldUseStoredFilters &&
          storedCardsFilter.find((filter) => filter.filterName === 'type')
            ?.type
      },
      {
        label: 'Curva',
        type: 'multipleCheckbox',
        checkBoxInputs: [{ name: 'A' }, { name: 'B' }, { name: 'C' }],
        searchByTermMode: true,
        storedValue:
          shouldUseStoredFilters &&
          storedCardsFilter
            .find((filter) => filter.filterName === 'curve')
            ?.curve?.split(',')
      }
    ],
    [
      {
        label: '',
        type: 'multipleCheckbox',
        checkBoxInputs: [
          { name: 'Prioritário', referenceName: 'priority' },
          { name: 'Controlar Produção', referenceName: 'controlProduction' },
          { name: 'Rascunho', referenceName: 'draft' },
          { name: 'Ativas', referenceName: 'status', checked: true }
        ],
        storedValue:
          shouldUseStoredFilters &&
          storedCardsFilter.map((item) => {
            const valueKey = item.filterName;

            return item[valueKey] ? valueKey : '';
          })
      }
    ]
  ]);

  const handleModalMenuToggle = (confirmClose = false) => {
    if (hasMenuModalChange && openModalMenu && !confirmClose) {
      setConfirmCloseMenuDialog(true);
    } else {
      setConfirmCloseMenuDialog(false);
      setHasMenuModalChange(false);
      setOpenModalMenu(!openModalMenu);
    }

    if (openModalMenu) {
      const redirectUrl = `${window.location.href}`
        .split(`/${paramsID}/`)[0]
        .concat(`/${paramsID}`);

      window?.history?.replaceState(null, '', redirectUrl);
    }
  };

  const getRelatedCards = (card) => {
    Axios.get(
      `${environments.catalog}/cards/related/${card?.counterId}/${getId}`
    )
      .then((response) => {
        setCardAssociation(
          response?.data?.content?.map(el => (
            {
              cardsOrigin: {
                description: el?.cardDescription
              },
              cardsId: el?.cardId
            }
          ))
        );

        setModalSettings(
          modalSettings.map((modal) =>
            modal.name === 'cardsAssociation'
              ? { ...modal, open: true }
              : modal
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const clearFilterValues = (param) => {
    const FILTER_FIELDS = filterFields.map((filterLine, index) => {
      if (index === 0) {
        return filterLine.map((item) => {
          if (item?.filterName === param) {
            return {
              ...item,
              storedValue: ''
            };
          }

          return item;
        });
      }

      return filterLine;
    });

    setFilterFields(FILTER_FIELDS);
  };

  const handleFilter = useCallback(
    (param, value, filter, paramSecond) => {

      let validValue = value;
      const hasInitialValue = value?.length > 1 && isArray(value[0])
    
      if (param === 'type' && hasInitialValue) {
        validValue = value[1];
      }

      const hasFilterWithFirstName = filtersChanged?.filter(
        (el) => el?.param === param
      )?.length;
      const hasFilterWithSecondName = filtersChanged?.filter(
        (el) => el?.param === paramSecond
      )?.length;

      let formattedFilters = hasFilterWithSecondName
        ? filtersChanged?.filter((el) => el?.param !== paramSecond)
        : filtersChanged;

      if (!value) {
        clearFilterValues(param);

        dispatch(clearFilter());

        if (hasFilterWithFirstName || hasFilterWithSecondName) {
          formattedFilters = filtersChanged?.filter(
            (el) => el?.param !== paramSecond && el?.param !== param
          );
        }
      }

      dispatch(
        changeFilter({
          param,
          value: validValue,
          textValue: filter?.title
        })
      );

      cancelTokenSource.cancel();

      handleFilterOptions(
        param,
        validValue,
        formattedFilters,
        setFiltersChanged,
        null,
        false,
        '1',
        { callSettings, setCallSettings }
      );
    },
    [callSettings, dispatch, filtersChanged]
  );

  const handleOrdernationFilter = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  };

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      paramsID,
      '',
      page
    );
  };

  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  function handleCloseAll(handle) {
    handle(false);
  }

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  function handleDelete() {
    setShowDeleteLoading(true);
    new Promise((resolve, reject) => {
      const baseUrl = `${environments.catalog}/cards`;
      const data = cardsOptions;
      const index = data.indexOf(deleteData);
      cardsOptions.splice(index, 1);

      Axios.delete(`${baseUrl}/${deleteData.id}`, config)
        .then(() => {
          resolve();
          handleDialogClose();
          setShowDeleteLoading(false);
        })
        .catch((error) => {
          console.log(error);
          reject();
          setShowDeleteLoading(false);
          setMessage(error?.response?.data?.message);
          setStatusCode(error?.response?.status);
        });
    });
  }

  const getCardsContent = useCallback(async () => {
    setLoading(true);
    let params = [];

    if (shouldUseStoredFilters) {
      const parsedFilters = storedCardsFilter
        .map((filter) => {
          const key = Object.keys(filter)[0];
          const isFalse = filter[key] === false;

          if (filter[key]) {
            return `${key}=${filter[key]}&`;
          }
          return isFalse ? `${key}=false` : null;
        })
        .join('');

      params = parsedFilters;
    } else {
      params = filtersChanged?.map((filter) => filter?.urlParam).join('&');
    }

    try {
      const response = await axiosInstanceCatalog.get(
        `${environments.catalog}/cards?page=${page}&size=100&originId=${paramsID}&${params}`,
        { cancelToken: cancelTokenSource.token }
      );

      const cards = await response.data;
      setLoading(false);
      setCallSettings((prevState) => ({
        ...prevState,
        totalPages: cards.totalPages,
        numberOfElements: cards.numberOfElements,
        totalElements: cards.totalElements
      }));

      if (!cards.content && !cards.content?.length) return;
      
      setCollapseOpen(
        cards.content.map((card) => ({
          id: card.id,
          open: false
        }))
      );

      cards.content = cards.content.map(card => {
        const orderedItems = []

        // Verifica se há itens para mapear
        if (card?.items && Array.isArray(card?.items) && card?.items.length > 0) {
          // Separa os itens em principais e outros
          const mainItems = card.items.filter(item => item?.main)
          const otherItems = card.items.filter(item => !item?.main)
          
          // Ordena ambos os itens por ordem alfabética
          mainItems.sort((a, b) => a?.products?.description.localeCompare(b?.products?.description))
          otherItems.sort((a, b) => a?.products?.description.localeCompare(b?.products?.description))

          // Adiciona no array de itens ordenados
          orderedItems.push(...mainItems, ...otherItems)
        }

        return {
          ...card,
          items: orderedItems
        }
      })

      setCardsOptions(cards?.content)
    } catch (error) {
      if (Axios.isCancel(error)) {
        console.log('Requisição cancelada:', error.message);
      } else {
        console.error('Erro na requisição:', error);
      }
    }
  }, [
    filtersChanged,
    page,
    paramsID,
    shouldUseStoredFilters,
    storedCardsFilter
  ]);

  const handleSelectedCard = (card) => {
    setUcPrimarySelectedId(
      card.consumptionUnits
        ? card.consumptionUnits
        : card.unitsMeasurementsMenu
        ? card.unitsMeasurementsMenu.unitsMeasurements
        : null
    );
    const arrayProduct = [];
    let notHasSkus = false;

    if (card.type !== 'TRANSFORMATION') {
      card.frequencies = card.frequencies
        ? {
            _id: card.frequencies.frequencyId,
            description: card.frequencies.description
          }
        : null;

      card.storageLocation = card.storageLocation
        ? {
            _id: card.storageLocation.storageLocationId,
            description: card.storageLocation.description
          }
        : null;

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(
            notHasSkus === false && product?.products?.approximateCost
          );

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              consumptionUnitsPrimary: product.unitsMeasurements
                ? product.unitsMeasurements.abbreviation
                : null,
              consumptionUnitsPrimaryId: product.unitsMeasurements
                ? product.unitsMeasurements.id
                : null,
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCostStock &&
                !product?.products?.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product?.products?.approximateCost
                  ? product?.products?.approximateCost?.toLocaleString(
                      'pt-br',
                      {
                        minimumFractionDigits: 3
                      }
                    )
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCostStock &&
                !product?.products?.approximateCost
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product?.products?.approximateCost
                  ? product?.products?.approximateCost?.toLocaleString(
                      'pt-br',
                      {
                        minimumFractionDigits: 9
                      }
                    )
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost?.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost?.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    if (card.type === 'TRANSFORMATION') {
      setCardTransformation({
        type: 'PRODUCT',
        productId: card.itemsTransformations.productId,
        stock: card.itemsTransformations.products.realStock,
        consumptionUnitsPrimaryId:
          card.itemsTransformations.unitsMeasurements.id,
        consumptionUnitsPrimary:
          card.itemsTransformations.unitsMeasurements.abbreviation,
        cost: card.itemsTransformations.cost,
        averageCost: card.itemsTransformations.products.averageCostStock,
        lastCost: card.itemsTransformations.products.lastCostStock
          ? card.itemsTransformations.products.lastCostStock
          : card.itemsTransformations.products.approximateCost
          ? card.itemsTransformations.products.approximateCost
          : '0,000',
        quantity: card.itemsTransformations.quantity,
        product: card.itemsTransformations.products
      });

      if (card.items) {
        card.items.forEach((product) => {
          notHasSkus = !(notHasSkus === false && product.products.skus.length);

          if (product.products) {
            arrayProduct.push({
              id: product.productId || product?.products?.id,
              description: product.products ? product.products.description : '',
              listQuantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              quantity: product.quantity.toLocaleString('pt-br', {
                minimumFractionDigits: 3
              }),
              listCostAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCostLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : product?.products?.approximateCost
                  ? product?.products?.approximateCost?.toLocaleString(
                      'pt-br',
                      {
                        minimumFractionDigits: 3
                      }
                    )
                  : '0,000',
              costAverage:
                product.products && product.products.averageCostStock
                  ? product.products.averageCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : '0,000',
              costLast:
                product.products &&
                product.products.lastCost &&
                product.products.skus.length
                  ? product.products.lastCostStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 9
                    })
                  : product?.products?.approximateCost
                  ? product?.products?.approximateCost?.toLocaleString(
                      'pt-br',
                      {
                        minimumFractionDigits: 9
                      }
                    )
                  : '0,000',
              stock:
                product.products && product.products.realStock
                  ? product.products.realStock.toLocaleString('pt-br', {
                      minimumFractionDigits: 3
                    })
                  : '0,000',
              listCost: product.cost
                ? product.cost?.toLocaleString('pt-br', {
                    minimumFractionDigits: 3
                  })
                : '0,000',
              cost: product.cost
                ? product.cost?.toLocaleString('pt-br', {
                    minimumFractionDigits: 9
                  })
                : '0,000',
              type: product?.products?.type || '',
              originIdCard: product?.products?.originIdCard
            });
          }
        });
      }
    }

    setCardDataTable(arrayProduct.sort((a, b) => (a.id > b.id ? 1 : -1)));

    setModalSettings(
      modalSettings.map((modal) =>
        modal.name === 'technicalSheet' ? { ...modal, open: true } : modal
      )
    );
  };

  const handleSelectCardToUnify = (card, checked) => {
    const isAlreadySelected = unifyCardsObj?.cards?.find(
      (item) => item.id === card.id
    );
    const filteredCards = unifyCardsObj.cards.filter(
      (item) => item.id !== card.id
    );

    if (isAlreadySelected) {
      setUnifyCardsObj({
        ...unifyCardsObj,
        cards: filteredCards
      });

      setCardsOptions(
        cardsOptions.map((el) => {
          if (unifyCardsObj?.cards?.length - 1 !== 0) {
            return {
              ...el,
              disableSelection: card?.type !== el?.type
            };
          }

          return {
            ...el,
            disableSelection: false
          };
        })
      );
    } else {
      setUnifyCardsObj({
        ...unifyCardsObj,
        cards: [...unifyCardsObj.cards, card]
      });

      setCardsOptions(
        cardsOptions.map((el) => {
          if (card?.type !== el?.type) {
            return {
              ...el,
              disableSelection: true
            };
          }

          return {
            ...el,
            disableSelection: false
          };
        })
      );
    }
  };

  const handleUnifyCards = () => {
    setUnifyCardsLoading(true);

    const body = {
      cards:
        unifyCardsObj?.cards?.map((card) => ({
          id: card?.id,
          reference: unifyCardsObj?.refCard === card?.id,
          type: card?.type
        })) || [],
      user: isAdminPage ? 0 : userInfo?.user,
      isGroup: isGroupUnification,
      restaurantId: getId
    };

    Axios.put(`${environments.catalog}/cards/unify`, body, config)
      .then(() => {
        const formattedCards = cardsOptions.map(el => (
          {
            ...el,
            disableSelection: false
          }
        ))
        const removedCardIds = body.cards
          ?.filter((el) => !el.reference)
          ?.map((item) => item?.id);

        setUnifyCardsObj({
          open: false,
          cards: [],
          refCard: ''
        });

        setMessage({
          description: 'Fichas unificadas com sucesso',
          severity: 'success'
        });

        setCardsOptions(
          formattedCards?.filter((el) => !removedCardIds.includes(el?.id))
        );

        setUnifyCardsLoading(false);
      })
      .catch(() => {
        setUnifyCardsLoading(false);
        setMessage({
          description: 'Erro ao unificar fichas',
          severity: 'error'
        });
      });
  };

  const handleSelectTypeOfUnification = (value) => {
    setIsGroupUnification(value);
  };

  const handleClosePopover = () => {
    setUnifyCardsObj({
      ...unifyCardsObj,
      open: false
    });
  };

  const BodyModal = (
    <Register
      userInfo={userInfo}
      menuInfo={{}}
      menuId={menuId}
      handleModalToggle={handleModalMenuToggle}
      replaceCategoryOf
      updateList={getCardsContent}
      salesAverageDiscount={salesAverageDiscount}
      setSalesAverageDiscount={setSalesAverageDiscount}
      openModalMenu={openModalMenu}
      setHasMenuModalChange={setHasMenuModalChange}
      hasMenuModalChange={hasMenuModalChange}
      showInactiveItems={
        filtersChanged.find((el) => el.param === 'status')?.value
      }
      menuLowAutomaticParams={menuLowAutomaticParams}
      setMenuLowAutomaticParams={setMenuLowAutomaticParams}
    />
  );

  const getRedirectUrl = useCallback(
    (selectedCardId) => {
      const redirectUrl = history.location.pathname
        .split(`/${paramsID}`)[0]
        .concat(`/${paramsID}/menu/${selectedCardId}`);

      return redirectUrl;
    },
    [history.location, paramsID]
  );

  const handleToCardEditPageClick = (e, card) => {
    if (card.type === 'MENU') {
      e.preventDefault();

      const dateLessOne = moment().subtract(1, 'day').format('YYYY-MM-DD')
      const dateParams = card?.menu[0]?.lowAutomatic ? `?startDate=${dateLessOne}&endDate=${dateLessOne}` : ''

      const redirectUrl = `${window.location.href}/menu/${card.menu[0].id}${dateParams}`;

      if (e.ctrlKey) {
        window.open(`${redirectUrl}`);
      } else {
        window?.history?.replaceState(null, '', redirectUrl);
        setMenuId(card.menu.length ? card.menu[0].id : null);
        setMenuLowAutomaticParams({
          startDate: dateLessOne,
          endDate: dateLessOne,
          lowAutomatic: card?.menu[0]?.lowAutomatic
        })
        handleModalMenuToggle();
      }
    }

    const arrayProduct = [];
    let notHasSkus = false;
    setSalesAverageDiscount(
      card.menu.length && card.menu[0].salesAverageDiscount
        ? card.menu[0]?.salesAverageDiscount
        : 0
    );

    switch (card.type) {
      case 'RECIPE':
        card.frequencies = card.frequencies
          ? {
              _id: card.frequencies.frequencyId,
              description: card.frequencies.description
            }
          : null;

        card.storageLocation = card.storageLocation
          ? {
              _id: card.storageLocation.storageLocationId,
              description: card.storageLocation.description
            }
          : null;

        if (card.items) {
          card.items.forEach((product, productIndex) => {
            notHasSkus = !(
              notHasSkus === false && product?.products?.approximateCost
            );

            if (product.products) {
              arrayProduct.push({
                productIndex,
                id: product.productId,
                description: product.products
                  ? product.products.description
                  : '',
                consumptionUnitsPrimary: product.unitsMeasurements
                  ? product.unitsMeasurements.abbreviation
                  : null,
                consumptionUnitsPrimaryId: product.unitsMeasurements
                  ? product.unitsMeasurements.id
                  : null,
                listQuantity: product.quantity,
                quantity: product.quantity,
                lastCount: product.products?.lastCount || '0,000',
                listCostAverage:
                  product.products?.averageCostStock ||
                  product.products?.averageCost ||
                  '0,000',
                listCostLast:
                  product.products?.lastCostStock ||
                  product.products?.lastCost ||
                  product.products?.approximateCost ||
                  '0,000',
                costAverage: product.products.averageCost
                  ? product.products.averageCostStock
                  : '0,000',
                costLast:
                  product.products.lastCostStock &&
                  !product?.products?.approximateCost
                    ? product.products.lastCostStock
                    : product.products.type === 'INTERNAL'
                    ? product.products.lastCostStock ||
                      product.products.lastCost ||
                      0
                    : product?.products?.approximateCost || '0,000',
                stock: product.products.realStock
                  ? product.products.realStock
                  : '0,000',
                listCost: product.products ? product.cost : '0,000',
                cost: product.products ? product.cost : '0,000'
              });
            }
          });
        }

        card.items = arrayProduct.sort((a, b) =>
          a.productIndex > b.productIndex ? 1 : -1
        );
        break;

      case 'TRANSFORMATION':
        if (!card.itemsTransformations) return;
        if (!card.itemsTransformations.unitsMeasurements) return;

        const averageCostStock =
          card?.itemsTransformations?.averageCostStock || null;
        const lastCostStock = card?.itemsTransformations?.lastCostStock || null;
        const approximateCost =
          card?.itemsTransformations?.approximateCost || null;
        const realStock = card?.itemsTransformations?.realStock || null;

        const {
          itemsTransformations: {
            productId = '',
            unitsMeasurements: { id = '', abbreviation = '' } = {},
            cost = '',
            quantity = '',
            products = ''
          } = {}
        } = card;

        card.transformation = {
          type: 'PRODUCT',
          productId,
          stock: realStock,
          consumptionUnitsPrimaryId: id,
          consumptionUnitsPrimary: abbreviation,
          cost,
          averageCost: averageCostStock,
          lastCost: lastCostStock || approximateCost || '0,000',
          quantity,
          product: products
        };

        if (card.items) {
          card.items.forEach((product, productIndex) => {
            notHasSkus = !(
              notHasSkus === false && product?.products?.skus?.length
            );

            if (product.products) {
              arrayProduct.push({
                productIndex,
                id: product.productId,
                description: product.products
                  ? product.products.description
                  : '',
                listQuantity: product.quantity,
                quantity: product.quantity,
                lastCount:
                  product.products && product.products.lastCount
                    ? product.products.lastCount
                    : '0,000',
                listCostAverage:
                  product.products && product.products.averageCost
                    ? product.products.averageCostStock
                    : '0,000',
                listCostLast:
                  product.products &&
                  product.products.lastCost &&
                  product.products.skus?.length
                    ? product.products.lastCostStock
                    : product?.products?.approximateCost
                    ? product?.products?.approximateCost
                    : '0,000',
                costAverage:
                  product.products && product.products.averageCost
                    ? product.products.averageCostStock
                    : '0,000',
                costLast:
                  product.products &&
                  product.products.lastCost &&
                  product.products.skus?.length
                    ? product.products.lastCostStock
                    : product.products.type === 'INTERNAL'
                    ? product.products.lastCostStock ||
                      product.products.lastCost ||
                      0
                    : product?.products?.approximateCost || '0,000',
                stock:
                  product.products && product.products.realStock
                    ? product.products.realStock
                    : '0,000',
                listCost: product.products ? product.cost : '0,000',
                cost: product.products ? product.cost : '0,000'
              });
            }
          });
        }

        card.items = arrayProduct;
        break;

      default:
        break;
    }

    card.notHasSkus = notHasSkus;
    setEditCards(card);
  };

  const addCardsButton = () => (
    <Tooltip title="Cadastrar fichas" aria-label="add">
      <Link
        to={`/catalogo/fichas/cadastro/${paramsID}`}
        onClick={() => createCards()}
        id="cardsPageAddButton"
      >
        <IconButton
          className="p0"
          edge="start"
          color="inherit"
          style={{ outline: 0 }}
          aria-label="add"
        >
          <img style={{ width: '25px' }} src={AddIcon} alt="AddIcon" />
        </IconButton>
      </Link>
    </Tooltip>
  );

  useEffect(() => {
    const checkedDefaultItems = filterFields
      .map((item) =>
        item
          .map((el) =>
            el?.checkBoxInputs?.filter((checkbox) => checkbox?.checked)
          )
          .flat()
      )
      .flat()
      .filter((el) => el);

    if (!storedCardsFilter.filter((item) => item.filterName === 'status')) {
      checkedDefaultItems.map((item) =>
        dispatch(
          changeFilter({
            param: item?.referenceName,
            value: item?.checked
          })
        )
      );
    }

    if (!prevPath) {
      checkedDefaultItems.length
        ? checkedDefaultItems.map((item) =>
            dispatch(
              clearFilter({
                param: item?.referenceName,
                value: item?.checked
              })
            )
          )
        : dispatch(clearFilter());
    }
  }, [dispatch, prevPath]);

  useEffect(() => {
    if (
      !storedCardsFilter?.filter((el) => el?.filterName.includes('Id'))?.length
    )
      getCardsContent();
  }, [
    paramsID,
    page,
    filtersChanged,
    storedCardsFilter?.filter((el) => el?.filterName.includes('Id'))?.length
  ]);

  useEffect(() => {
    if (
      storedCardsFilter?.filter((el) => el?.filterName.includes('Id'))?.length
    )
      getCardsContent();
  }, [storedCardsFilter]);

  useEffect(() => {
    document.querySelector('main').scrollTo({ top: 0, behavior: 'smooth' });
  }, [callSettings.page]);

  useEffect(() => {
    if (cardId) {
      setMenuId(cardId);
      handleModalMenuToggle();
    }
  }, [cardId]);

  useEffect(() => {
    setCallSettings((prevState) => ({ ...prevState, paramsID }));
  }, [paramsID]);

  useEffect(() => {
    if (selectedCard) {
      handleSelectedCard(selectedCard);
    }
  }, [selectedCard]);

  return (
    <Layout>
      <GlobalStyle />

      <TitleHead title="Fichas" />

      <Container>
        <PageTitleAndFilter
          title="Fichas Cadastradas"
          activeFilterFilds={filterFields}
          handleFilter={handleFilter}
          additionalbutton={addCardsButton}
          getFilterhandle={getFilterhandle}
        />

        <FullScreenLoading
          description="Excluindo"
          display={showDeleteLoading}
        />

        <table
          className="desktop cardsMainTable"
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
        >
          <tr>
            <td colSpan="19">
              <hr />
            </td>
          </tr>

          <tr className="headerTitle">
            <th width="2%" className="subtitle">
              &nbsp;
            </th>

            <th width="13%" className="subtitle">
              FICHA{' '}
              <OrderBy
                filterName="description"
                handleFilter={handleOrdernationFilter}
              />
            </th>

            <th width="7%" className="subtitle center">
              TIPO{' '}
              <OrderBy
                filterName="type"
                handleFilter={handleOrdernationFilter}
              />
            </th>

            <th width="12%" className="subtitle" style={{ paddingLeft: 5 }}>
              ITENS
            </th>

            <th width="6.8%" className="subtitle right">
              QTDE{' '}
              <OrderBy
                filterName="quantity"
                handleFilter={handleOrdernationFilter}
              />
            </th>

            <th width="2.76%" className="subtitle center">
              UN.
            </th>

            <th
              colSpan="3"
              className="subtitle left p8"
              style={{
                borderLeft: 'solid 2px #73004C',
                backgroundColor: '#73004C'
              }}
            >
              CUSTOS R$
            </th>
            <th
              colSpan="2"
              className="subtitle left p8"
              style={{
                borderLeft: 'solid 2px #4D0073',
                backgroundColor: '#4D0073'
              }}
            >
              ESTOQUE
            </th>
            <th
              colSpan="3"
              className="subtitle left p8"
              style={{
                borderLeft: 'solid 2px #001373',
                backgroundColor: '#001373'
              }}
            >
              VENDAS R$
            </th>

            <th
              colSpan="1"
              className="subtitle left p12"
              style={{ borderLeft: 'solid 2px #313347' }}
              title="Catálogo"
            >
              CAT.
            </th>

            <th colSpan="1">&nbsp;</th>
          </tr>

          <tr className="headerSubtitle">
            <th style={{ borderRight: 'solid 2px #73004C' }} colSpan="6">
              &nbsp;
            </th>

            <th
              className="subtitle right pl8 pr5 itemColumn"
              style={{ borderLeft: 'solid 2px #73004C' }}
            >
              ITEM{' '}
            </th>

            <th className="subtitle right pl8 totalColumn">
              TOTAL{' '}
              <OrderBy
                filterName="costTotal"
                handleFilter={handleOrdernationFilter}
                tinyArrow
              />
            </th>

            <th
              style={{ borderRight: 'solid 2px #4D0073' }}
              className="subtitle right pr8 cmvColumn"
            >
              CMV{' '}
              <OrderBy
                filterName="cmv"
                handleFilter={handleOrdernationFilter}
                tinyArrow
              />
            </th>

            <th
              className="subtitle right pr8 actualColumn"
              style={{ borderLeft: 'solid 2px #4D0073' }}
            >
              ATUAL{' '}
              <OrderBy
                filterName="stockTotal"
                handleFilter={handleOrdernationFilter}
                tinyArrow
              />
            </th>

            <th
              style={{ borderRight: 'solid 2px #001373' }}
              className="subtitle right durationColumn"
            >
              DURAÇÃO{' '}
              <OrderBy
                filterName="duration"
                handleFilter={handleOrdernationFilter}
                tinyArrow
              />
            </th>

            <th
              className="subtitle right p8 priceColumn"
              style={{ borderLeft: 'solid 2px #001373' }}
            >
              PREÇO{' '}
              <OrderBy
                filterName="salesAverageDiscount"
                handleFilter={handleOrdernationFilter}
                tinyArrow
              />
            </th>

            <th className="subtitle right orderWithoutMargin marginColumn">
              MRG R${' '}
              <OrderBy
                filterName="margin"
                handleFilter={handleOrdernationFilter}
                tinyArrow
              />
            </th>

            <th
              style={{ borderRight: 'solid 2px #313347' }}
              className="subtitle right pl8 curveColumn"
            >
              CURVA{' '}
              <OrderBy
                filterName="curve"
                handleFilter={handleOrdernationFilter}
                tinyArrow
              />
            </th>

            <th
              className="subtitle center cardsLengthColumn"
              style={{ borderLeft: 'solid 2px #313347' }}
            >
              FICHA
            </th>

            <th
              className="deleteColumn"
              style={{ borderLeft: 'solid 1px #ECEDF1' }}
            >
              &nbsp;
            </th>
          </tr>

          {loading && (
            <tr>
              <th colSpan="17" style={{ margin: '10px', textAlign: 'center' }}>
                <LinearProgress id="loading" variant="query" />
              </th>
            </tr>
          )}

          {cardsOptions?.length ? (
            cardsOptions.map((card) => {
              const exists = collapseOpen.find((x) => x.id === card.id);
              let type;

              if (collapseOpen.length < cardsOptions.length && !exists) {
                collapseOpen.push({ id: card.id, open: false });
              }

              switch (card.type) {
                case 'MENU':
                  type = 'Cardapio';
                  break;
                case 'RECIPE':
                  type = 'Receita';
                  break;
                case 'TRANSFORMATION':
                  type = 'Transformação';
                  break;
                default:
                  break;
              }

              return (
                <>
                  <tr valign="top">
                    <td
                      className={`description p0 ${
                        (card?.type === 'INTERNAL' || card?.disableSelection) &&
                        'disabledCheckbox'
                      }`}
                    >
                      <Checkbox
                        style={{ padding: '1px 7px 5px 7px' }}
                        checked={unifyCardsObj?.cards
                          ?.map((el) => el.id)
                          .includes(card.id)}
                        disabled={card?.disableSelection}
                        size="small"
                        onChange={(_, checked) => {
                          handleSelectCardToUnify(card, checked);
                        }}
                      />
                    </td>

                    <td
                      className="description pl0 pr0"
                      style={{ maxWidth: '140px', minWidth: '140px' }}
                    >
                      <div className="dFlex justifyBetween alignCenter">
                        <Link
                          to={
                            card.type !== 'MENU'
                              ? {
                                  pathname: `/catalogo/fichas/${paramsID}/${card.id}`,
                                  state: { cardId: card.id }
                                }
                              : getRedirectUrl(
                                  card.menu.length ? card.menu[0].id : null
                                )
                          }
                          onClick={(e) => handleToCardEditPageClick(e, card)}
                        >
                          <Tooltip
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            placement="right"
                            title={
                              <span
                                className="dBlock"
                                style={{ padding: '4px 7px' }}
                              >
                                {card?.id} - {card.description}
                              </span>
                            }
                          >
                            <span>
                              {card?.description?.length > 15
                                ? `${card?.description?.substring(0, 15)}...`
                                : card?.description}
                            </span>
                          </Tooltip>
                        </Link>
                        
                        {card?.type !== 'TRANSFORMATION' &&
                          <ImageWrapper
                            width="30px"
                            height="28px"
                            url={card?.image?.url}
                          />
                        }
                      </div>
                    </td>

                    <td className="description center">{type}</td>

                    <td className="description listTableContainer" colSpan="8">
                      <List
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                        className={classes.root}
                        style={{ display: 'inline' }}
                      >
                        <ListItem
                          button
                          onClick={() =>
                            setCollapseOpen(
                              collapseOpen.map((x) =>
                                x.id === card.id ? { ...x, open: !x.open } : x
                              )
                            )
                          }
                          style={{ padding: 0 }}
                        >
                          {collapseOpen.find((x) => x.id === card.id) ? (
                            collapseOpen.find((x) => x.id === card.id).open ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )
                          ) : (
                            false
                          )}

                          <table
                            width="100%"
                            cellPadding="0"
                            cellSpacing="0"
                            border="0"
                          >
                            <tr>
                              <td className="descriptionSku">
                                {card.items && card.items.length === 1
                                  ? '1 item'
                                  : card.items && card.items.length > 1
                                  ? `${card.items.length} itens`
                                  : '0 items'}
                              </td>

                              <td
                                style={{ maxWidth: '55px' }}
                                className="listQuantity description right"
                              >
                                {card &&
                                card.productions &&
                                card.productions.yield
                                  ? card.productions.yield.toLocaleString(
                                      'pt-br',
                                      { minimumFractionDigits: 3 }
                                    )
                                  : '0,000'}
                              </td>

                              <td
                                style={{ maxWidth: '32px' }}
                                className="listConsumptionUnit description center p8"
                              >
                                {card && card.consumptionUnits
                                  ? card.consumptionUnits.abbreviation
                                  : card && card.unitsMeasurementsMenu
                                  ? card.unitsMeasurementsMenu.unitsMeasurements
                                      .abbreviation
                                  : '-'}
                              </td>

                              <td
                                width="12%"
                                className="description right p8"
                                style={{
                                  borderLeft: 'solid 2px #73004C',
                                  maxWidth: '80px'
                                }}
                              >
                                {card && card.type !== 'RECIPE'
                                  ? '-'
                                  : card && card.costs && card.costs.costUnit
                                  ? card.costs.costUnit?.toLocaleString(
                                      'pt-br',
                                      { minimumFractionDigits: 3 }
                                    )
                                  : '0,000'}
                              </td>

                              <td
                                width="12%"
                                style={{ maxWidth: '80px' }}
                                className="description right p8"
                              >
                                {card && card.costs && card.costs.costTotal
                                  ? card.costs.costTotal.toLocaleString(
                                      'pt-br',
                                      {
                                        maximumFractionDigits: 2,
                                        minimumFractionDigits: 2
                                      }
                                    )
                                  : '0,00'}
                              </td>

                              <td
                                width="9%"
                                style={{ maxWidth: '80x' }}
                                className="description right pr8"
                              >
                                <div className="dFlex justifyEnd">
                                  {card?.type !== 'MENU'
                                    ? '-'
                                    : ValidationLength(
                                        card?.costs?.cmv?.toLocaleString(
                                          'pt-br',
                                          { minimumFractionDigits: 3 }
                                        ) || '0,000',
                                        6
                                      )}
                                </div>
                              </td>

                              <td
                                width="11%"
                                className="description right p8"
                                style={{
                                  borderLeft: 'solid 2px #4D0073',
                                  maxWidth: '80px'
                                }}
                              >
                                {card && card.type !== 'MENU'
                                  ? card.product.length &&
                                    card.product[0].theoreticalStock
                                    ? card.product[0].theoreticalStock.toLocaleString(
                                        'pt-br',
                                        { minimumFractionDigits: 3 }
                                      )
                                    : '0,000'
                                  : card.stocks && card.stocks.stockTotal
                                  ? card.stocks.stockTotal.toLocaleString(
                                      'pt-br',
                                      { minimumFractionDigits: 3 }
                                    )
                                  : '0,000'}
                              </td>

                              <td
                                width="11.3%"
                                style={{ maxWidth: '80px' }}
                                className="description right p8"
                              >
                                {card && card.type !== 'MENU'
                                  ? card.product.length &&
                                    card.product[0].duration &&
                                    card.product[0].duration === 1
                                    ? `${card.product[0].duration} dia`
                                    : card.product.length &&
                                      card.product[0].duration &&
                                      card.product[0].duration > 1
                                    ? `${card.product[0].duration} dias`
                                    : '0 dias'
                                  : card.stocks &&
                                    card.stocks.duration &&
                                    card.stocks.duration === 1
                                  ? `${card.stocks.duration} dia`
                                  : card.stocks &&
                                    card.stocks.duration &&
                                    card.stocks.duration > 1
                                  ? `${card.stocks.duration} dias`
                                  : '0 dias'}{' '}
                              </td>
                            </tr>
                          </table>
                        </ListItem>

                        <Collapse
                          in={
                            collapseOpen.find((x) => x.id === card.id)
                              ? collapseOpen.find((x) => x.id === card.id).open
                              : false
                          }
                          timeout={0}
                        >
                          <List component="div" disablePadding>
                            <ListItem
                              className={classes.nested}
                              style={{ padding: '0 0 0 24px' }}
                            >
                              <table
                                width="100%"
                                cellPadding="0"
                                cellSpacing="0"
                                border="0"
                              >
                                {/* {card && card.items ? card.items.sort((a, b) => a.products && b.products ? a.products.description.localeCompare(b.products.description, 'pt-BR') : ((a.quantity > b.quantity) ? 1 : -1)).map((item, index) => { */}
                                {card && card.items ? (
                                  card.items
                                    .map((item) => {
                                      const cost =
                                        item?.products?.lastCost ||
                                        item?.products?.lastCostStock ||
                                        item?.products?.approximateCost ||
                                        0;
                                      
                                      const costTotal = cost * (item?.quantity || 1);
                                      const isMainItem = item?.main

                                      return (
                                        <>
                                          <tr>
                                            <td colSpan="8">
                                              <hr />
                                            </td>
                                          </tr>

                                          <tr>
                                            <td
                                              style={{ maxWidth: '150px', color: isMainItem ? '#0B1A8E' : '', fontWeight: isMainItem ? '700' : '' }}
                                              className="descriptionSku"
                                            >
                                              {item.products
                                                ?
                                                <div className="dFlex justifyBetween alignCenter" style={{ maxWidth: '100%', gap: '5px' }}>
                                                  {ValidationLength(
                                                    `${item.products.description  }${item?.itemProcess ? `- ${  item?.itemProcess?.processes?.description}` : ''}`, 
                                                    18)
                                                  }

                                                  <ImageWrapper
                                                    width="30px"
                                                    height="28px"
                                                    url={item?.image?.url}
                                                  />
                                                </div> 
                                                : '-'}
                                            </td>

                                            <td
                                              style={{ maxWidth: '80px' }}
                                              className="listQuantity description right"
                                            >
                                              {card &&
                                              card.type === 'TRANSFORMATION'
                                                ? '-'
                                                : item.quantity
                                                ? item.quantity.toLocaleString(
                                                    'pt-br',
                                                    { minimumFractionDigits: 3 }
                                                  )
                                                : '0,000'}
                                            </td>

                                            <td
                                              style={{ maxWidth: '80px' }}
                                              className="listConsumptionUnit description center p8"
                                            >
                                              {item.unitsMeasurements
                                                ? item.unitsMeasurements
                                                    .abbreviation
                                                : '-'}
                                            </td>

                                            <td
                                              width="12%"
                                              style={{
                                                maxWidth: '80px',
                                                borderLeft: 'solid 2px #73004C'
                                              }}
                                              className="description right p8"
                                            >
                                              {card && cost
                                                ? cost?.toLocaleString(
                                                    'pt-br',
                                                    { minimumFractionDigits: 3 }
                                                  )
                                                : '0,000'}{' '}
                                            </td>

                                            <td
                                              width="12%"
                                              style={{ maxWidth: '80px' }}
                                              className="description right p8"
                                            >
                                              {card &&
                                              card.type === 'TRANSFORMATION'
                                                ? '-'
                                                : costTotal
                                                ? costTotal.toLocaleString(
                                                    'pt-br',
                                                    { minimumFractionDigits: 3 }
                                                  )
                                                : '0,000'}{' '}
                                            </td>

                                            <td
                                              width="9%"
                                              style={{ maxWidth: '80px' }}
                                              className="description right pr8"
                                            >
                                              {card.type !== 'MENU'
                                                ? '-'
                                                : item.products &&
                                                  item.products.cmv
                                                ? item.products.cmv.toLocaleString(
                                                    'pt-br',
                                                    { minimumFractionDigits: 3 }
                                                  )
                                                : '0,000'}
                                            </td>

                                            <td
                                              width="11%"
                                              className="description right p8"
                                              style={{
                                                borderLeft: 'solid 2px #4D0073',
                                                maxWidth: '84px'
                                              }}
                                            >
                                              {item.products &&
                                              item.products.realStock
                                                ? item.products.realStock.toLocaleString(
                                                    'pt-br',
                                                    { minimumFractionDigits: 3 }
                                                  )
                                                : '0,000'}
                                            </td>

                                            <td
                                              width="11.3%"
                                              style={{ maxWidth: '80px' }}
                                              className="description right p8"
                                            >
                                              {item.products &&
                                              item.products.duration === 1
                                                ? `${item.products.duration} dia`
                                                : item.products &&
                                                  item.products.duration > 1
                                                ? `${item.products.duration} dias`
                                                : '0 dias'}
                                            </td>
                                          </tr>
                                        </>
                                      );
                                    })
                                ) : (
                                  <tr>
                                    <td className="description center">
                                      <Typography
                                        style={{
                                          margin: '5px',
                                          textAlign: 'center'
                                        }}
                                      >
                                        Não há itens.
                                      </Typography>
                                    </td>
                                  </tr>
                                )}

                                {card &&
                                card.items &&
                                card.type === 'TRANSFORMATION' ? (
                                  <tr>
                                    <td
                                      style={{ maxWidth: '84px' }}
                                      className="descriptionSku"
                                    >
                                      <b>Perda:</b>
                                    </td>

                                    <td
                                      style={{ maxWidth: '80px' }}
                                      className="listQuantity description right"
                                    >
                                      <b>
                                        {card.productions &&
                                        card.productions.loss
                                          ? `${card.productions.loss.toLocaleString(
                                              'pt-br',
                                              { minimumFractionDigits: 3 }
                                            )} %`
                                          : '0,000 %'}
                                      </b>
                                    </td>

                                    <td
                                      style={{ maxWidth: '80px' }}
                                      className="listConsumptionUnit description center p8"
                                    >
                                      &nbsp;
                                    </td>

                                    <td
                                      width="12%"
                                      className="description right p8"
                                      style={{
                                        borderLeft: 'solid 1px #ECEDF1',
                                        maxWidth: '80px'
                                      }}
                                    >
                                      &nbsp;
                                    </td>

                                    <td
                                      width="12%"
                                      style={{ maxWidth: '80px' }}
                                      className="description right p8"
                                    >
                                      &nbsp;
                                    </td>

                                    <td
                                      width="9%"
                                      style={{ maxWidth: '80px' }}
                                      className="description right pr8"
                                    >
                                      &nbsp;
                                    </td>

                                    <td
                                      width="11%"
                                      className="description right p8"
                                      style={{
                                        borderLeft: 'solid 1px #ECEDF1',
                                        maxWidth: '80px'
                                      }}
                                    >
                                      &nbsp;
                                    </td>

                                    <td
                                      width="11.3%"
                                      style={{ maxWidth: '80px' }}
                                      className="description right p8"
                                    >
                                      &nbsp;
                                    </td>
                                  </tr>
                                ) : null}
                              </table>
                            </ListItem>
                          </List>
                        </Collapse>
                      </List>
                    </td>

                    <td
                      className="description right p8"
                      style={{ borderLeft: 'solid 2px #001373' }}
                    >
                      {/* {card.menu.length && card.menu[0].salesAverageDiscount ? card.menu[0].salesAverageDiscount?.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : card.menu.length && card.menu[0].salesAverage ? card.menu[0].salesAverage.toLocaleString('pt-br', { minimumFractionDigits: 2 }) : "0,00"} */}
                      {card.menu.length && card.menu[0].salesAverageDiscount
                        ? card.menu[0]?.salesAverageDiscount?.toLocaleString(
                            'pt-br',
                            {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2
                            }
                          )
                        : '0,00'}
                    </td>

                    <td className="description right p8">
                      {card.menu.length && card.menu[0].margin != null
                        ? card.menu[0].margin.toLocaleString('pt-br', {
                            minimumFractionDigits: 2
                          })
                        : '0,00'}
                    </td>

                    <td className="description center p8">
                      {card.menu.length && card.menu[0].curve
                        ? card.menu[0].curve
                        : '-'}
                    </td>

                    <td
                      className="description center"
                      style={{ borderLeft: 'solid 2px #313347' }}
                    >
                      <span
                        className="fontSizeDefault cursorPointer"
                        style={{ color: '#1890ff' }}
                        onClick={() => {
                          if (!isGroupManage) {
                            setCardAssociation(
                              card?.product?.length &&
                                card?.product[0]?.items?.filter(
                                  (card) => card?.cardsOrigin
                                )
                                ? card?.product[0]?.items?.filter(
                                    (card) => card?.cardsOrigin
                                  )
                                : []
                            );

                            setModalSettings(
                              modalSettings.map((modal) =>
                                modal.name === 'cardsAssociation'
                                  ? { ...modal, open: true }
                                  : modal
                              )
                            );
                          } else {
                            getRelatedCards(card)
                          }
                        }}
                      >
                        {isGroupManage 
                          ? card?.nCards || "0" 
                          : card?.product?.length &&
                            card?.product[0]?.items?.filter((card) => card?.cardsOrigin)
                              ? card?.product[0]?.items?.filter(
                                  (card) => card?.cardsOrigin
                                )?.length
                              : 0
                        }
                      </span>
                    </td>

                    <td
                      style={{
                        borderLeft: 'solid 1px #ECEDF1'
                      }}
                    >
                      <IconButton
                        className="w100"
                        aria-label="delete"
                        onClick={() => {
                          handleDialogClickOpen();
                          setDeleteData(card);
                        }}
                        style={{ padding: 0, outline: 0 }}
                      >
                        <DeleteOutline />
                      </IconButton>
                    </td>
                  </tr>

                  <tr>
                    <td colSpan="19">
                      <hr style={{ marginTop: '-1px' }} />
                    </td>
                  </tr>
                </>
              );
            })
          ) : (
            <tr>
              <th colSpan="16" style={{ margin: '10px', textAlign: 'center' }}>
                Não há nenhuma ficha
              </th>
            </tr>
          )}
        </table>

        <div className="pagination">
          <Pagination
            count={callSettings.totalPages}
            onChange={(event, page) =>
              setCallSettings({ ...callSettings, page })
            }
            variant="outlined"
            shape="rounded"
            size="small"
            color="primary"
            showFirstButton
            showLastButton
          />

          <ItemsPerPage
            numberOfElements={numberOfElements}
            size={totalElements}
          />
        </div>

        <Dialog
          fullScreen={fullScreen}
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="responsive-dialog-title"
          transitionDuration={0}
        >
          <DialogTitle
            className="modalHeader navyBlue"
            style={{ fontWeight: 'bold', cursor: 'move', fontFamily: 'Lato' }}
            id="draggable-dialog-title"
          >
            <Typography>Deseja realmente excluir essa ficha?</Typography>
          </DialogTitle>

          <DialogContent className="modalContent">
            <Typography>
              {deleteData &&
              deleteData.product.length &&
              deleteData.product[0].items.length > 0
                ? `ATENÇÃO: Esta ficha está relacionada a ${deleteData.product[0].items.length} fichas, portanto esta ação impactará a composição e os custos das fichas relacionadas.\nTem certeza que deseja efetuar a exclusão?`
                : 'Esta ação não pode ser desfeita. Deseja continuar?'}
            </Typography>
          </DialogContent>

          <DialogActions className="justifyCenter">
            <Button
              className="defaultButton backButton"
              design="outlined"
              onClick={handleDialogClose}
              label="Cancelar"
              color="primary"
            />
            <Button
              className="defaultButton submitButton"
              autoFocus
              design="contained"
              label="Confirmar"
              onClick={() => handleDelete()}
              color="primary"
            />
          </DialogActions>
        </Dialog>

        <Dialog
          fullScreen={fullScreen}
          open={confirmCloseMenuDialog}
          onClose={() => handleModalMenuToggle(true)}
          aria-labelledby="responsive-dialog-title"
          transitionDuration={0}
        >
          <DialogTitle
            className="modalHeader navyBlue"
            style={{ fontWeight: 'bold', cursor: 'move', fontFamily: 'Lato' }}
            id="draggable-dialog-title"
          />

          <DialogContent className="modalContent">
            <Typography>
              Deseja realmente cancelar a edição desse item?
            </Typography>
          </DialogContent>

          <DialogActions className="justifyCenter">
            <Button
              className="defaultButton backButton"
              design="outlined"
              onClick={() => setConfirmCloseMenuDialog(false)}
              label="Cancelar"
              color="primary"
            />
            <Button
              className="defaultButton submitButton"
              design="contained"
              label="Confirmar"
              onClick={() => handleModalMenuToggle(true)}
              color="primary"
              autoFocus
            />
          </DialogActions>
        </Dialog>

        {modalSettings.find((modal) => modal.name === 'cardsAssociation')
          .open && (
          <RelatedCards
            modalSettings={modalSettings}
            setModalSettings={setModalSettings}
            setCardAssociation={setCardAssociation}
            cardAssociation={cardAssociation}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            getId={getId}
          />
        )}

        {openModalMenu && (
          <Dialog
            open={openModalMenu}
            onClose={() => handleModalMenuToggle(false)}
            className="defaultModal menuModal"
            maxWidth="md"
            fullWidth
            PaperComponent={PaperComponent}
          >
            <DialogTitle
              id="draggable-dialog-title"
              className="modalHeader cursorMove"
            >
              <Typography className="bold textAlignCenter fontSize16">
                CADASTRO DE CARDÁPIO
              </Typography>

              <IconButton
                edge="start"
                color="inherit"
                onClick={() => handleModalMenuToggle(false)}
                aria-label="close"
                style={{ outline: '0', background: 'none' }}
              >
                <img
                  src={CloseIconNavy}
                  alt="CloseIconNavy"
                  style={{ width: 15 }}
                />
              </IconButton>
            </DialogTitle>

            <DialogContent className="modalContent">{BodyModal}</DialogContent>
          </Dialog>
        )}

        {selectedCard && (
          <TechnicalSheetDialog
            maxWidth={
              modalSettings.find((modal) => modal.name === 'technicalSheet')
                .maxWidth
            }
            open={
              modalSettings.find((modal) => modal.name === 'technicalSheet')
                .open
            }
            handleClose={() =>
              setModalSettings(
                modalSettings.map((modal) =>
                  modal.name === 'technicalSheet'
                    ? { ...modal, open: false }
                    : modal
                )
              )
            }
            cardDataTable={cardDataTable}
            cardTransformation={cardTransformation}
            selectedCard={selectedCard}
            setSelectedCard={setSelectedCard}
            ucPrimarySelectedId={ucPrimarySelectedId}
            getCardsContent={getCardsContent}
            salesAverageDiscount={salesAverageDiscount}
            setSalesAverageDiscount={setSalesAverageDiscount}
            openModalMenu={openModalMenu}
            setHasMenuModalChange={setHasMenuModalChange}
            hasMenuModalChange={hasMenuModalChange}
            showInactiveItems={filtersChanged}
          />
        )}

        <Tooltip
          className="floatButtonFirst"
          title={
            unifyCardsObj?.cards.length < 2
              ? 'Selecione ao menos 2 Fichas'
              : 'Unificar Fichas'
          }
        >
          <Fab
            color="primary"
            className={`${
              unifyCardsObj?.cards.length < 2 ? 'cursorAuto' : 'cursorPointer'
            }`}
            aria-label="add"
            size="medium"
            style={{ outline: 0 }}
            onClick={() => {
              if (unifyCardsObj?.cards.length < 2) return;

              setUnifyCardsObj({
                ...unifyCardsObj,
                open: !unifyCardsObj?.open
              });
            }}
          >
            <Add style={{ color: 'white' }} id="unifyCardsIcon" />
          </Fab>
        </Tooltip>

        {unifyCardsObj?.open && (
          <UnifyCards
            isLoading={unifyCardsLoading}
            validationLength={ValidationLength}
            handleClosePopover={handleClosePopover}
            getRedirectUrl={getRedirectUrl}
            unifyCardsObj={unifyCardsObj}
            setUnifyCardsObj={setUnifyCardsObj}
            handleUnifyCards={handleUnifyCards}
            cardAssociation={cardAssociation}
            handleSelectTypeOfUnification={handleSelectTypeOfUnification}
            setCardAssociation={setCardAssociation}
            handleToCardEditPageClick={handleToCardEditPageClick}
          />
        )}

        <CommonAlert
          open={message?.description}
          onClose={() => {
            setMessage({
              description: '',
              severity: ''
            });
          }}
          severity={message?.severity}
          messagePersonal={message?.description}
        />

        <CommonAlert
          open={deleteSuccess}
          onClose={() => handleCloseAll(handleDeleteSuccess)}
          severity="success"
          indexMessage="Ficha deletada com sucesso!"
        />

        {deleteError &&
          <CommonAlert
            open={deleteError}
            onClose={() => handleCloseAll(handleDeleteError)}
            indexMessage={message}
            statusCode={statusCode}
          />
        }
      </Container>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(CardsActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CardsList);
