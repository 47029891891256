import styled, { css } from 'styled-components';

export const PageAndTitleWrapper = styled.div`
    position: relative;
    display: none;
    @media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 15px;
        z-index: 999;
    }
    @media screen and (max-device-width: 480px) {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 60px;
        margin-bottom: 10px;
        z-index: 999;
    }
`;

export const PageTitle = styled.div`
    @media only screen and (max-width: 480px) {
        font-size: 12px;
    }
    color: #010311;
    font-size: 16px;
    font-weight: bold;
    width: 70%;
    p {
        margin: 0 0 4px;
    }
`;

export const SubTitle = styled.p`
    font-size: 16px;
    @media only screen and (max-width: 480px) {
        font-size: 12px;
    }
`;

export const FilterPageContainer = styled.div`
    transition: all 200ms ease-in-out;
    background-color: ${({blue}) => blue ? "#5062F0" : "white"};
    border-radius: 12px;
    border: 1px solid #5062F0;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
`;

export const FilterInputsWrapper = styled.div`
    transition: all 250ms ease-in-out;
    display: ${({ open }) => open ? "flex" : "none"};
    position: absolute;
    background-color: white;
    width: 300px;
    height: auto;
    border-radius: 8px;
    box-shadow: 0px 1px 4px 1px rgba(1, 3, 17, 0.45);
    top: 50px;
    right: 0px;
    justify-content: flex-start;
    flex-flow: wrap;
`;

export const InputFilterCase = styled.div`
    ${({width100}) => width100 && css`
        width: 100%;
    `}
    padding: 8px 20px;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    div {
        margin-right: 0; 
    }
`;

export const BackButtonWrapper = styled.div`
    color: #454A67;
    font-size: 12px;
`;

export const SettingsCard = styled.button`
    border: 1px solid #5062F0;
    background-color: ${({backgroundBlue}) => backgroundBlue ? "#5062F0" : "white"};
    color: ${({backgroundBlue}) => backgroundBlue ? "white" : "#5062F0"};
    border-radius: 12px;
    text-align: center;
    padding: 12px;
    width: 100%;
    margin: 12px;
    font-weight: bold;
    font-size: 16px;
    
    ${({styleType}) => styleType === "link" && css`
        border: none;
        color: #00094C;
        text-align: left;
        background-color: white;
    `}

    ${({bold, styleType}) => !bold && styleType === "link" && css`
        font-weight: 400;
    `}
`;

export const ApplyFilterWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 12px 20px;
`;
