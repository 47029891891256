import styled, { css } from 'styled-components';

export const CardsContainer = styled.section`
  border: ${({ type }) =>
    type === 'RECIPE' ? '6px solid #6DB2D4' : '6px solid #EDCB64'};
  width: 49.5%;
  height: min-content;
  background-color: white;
  margin-bottom: 8px;
  font-family: Lato !important;
  filter: drop-shadow(2px 6px 6px rgba(0, 0, 0, 0.6));
  border-radius: 10px;

  &.loading-card {
    height: auto;
    padding: 8px;
  }

  .div-loading-title-btnPlay {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .div-loading-status {
    margin: 8px 0px;
  }

  .div-loading-info {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 18px;
  }

  .div-loading-quantity {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-right: 20px;
  }

  .div-loading-yield {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0px 20px;
  }

  .div-loading-date {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 20px;
  }

  .loading-info-title {
    margin-bottom: 2px;
  }

  @media only screen and (max-width: 480px) {
    width: 100%;
    margin-right: 0px;
  }

  @media only screen and (min-width: 768px) {
    &.modalProducao {
      width: 0px;
    }
  }
`;

export const CardModal = styled.div`
  border: ${({ cardType }) =>
    cardType === 'TRANSFORMATION'
      ? 'solid 20px #EDCB64'
      : 'solid 20px #6DB2D4'};
  border-radius: 32px;
  position: relative;

  .leftFakeBorder {
    position: absolute;
    left: -22px;
    top: -20px;
    height: calc(100% + 44px);
    width: calc(100% + 44px);
    box-shadow: inset 8px 4px 6px rgba(0, 0, 0, .5);
    border-radius: 32px;
  }

  .rightFakeBorder {
    position: absolute;
    right: 1px;
    top: 0px;
    height: 100%;
    width: 100%;
    border-radius: 14px;
    box-shadow: 5px 4px 7px 5px rgba(0, 0, 0, .5);
  }
`;

export const CardTitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  color: #000;
  padding: 8px;
  font-family: Lato !important;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  border-radius: 4px 4px 0px 0px;

  #MobileDeleteCard {
    width: 20px;
    height: 20px;
  }

  span {
    font-size: 14px;
    width: 60%;
  }

  @media only screen and (max-width: 480px) {
    #MobileDeleteCard {
      width: 18px;
      height: 18px;
    }
  }
`;

export const CardInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 8px 8px 8px;
  font-size: 12px;
  line-height: 12px;
  font-family: Lato !important;

  .card-result-qtde input {
    color: #9296ac;
  }

  @media only screen and (max-width: 480px) {
    font-size: 10px;
    flex-direction: column;

    .qtde-and-yield {
      width: 100%;
    }
  }

  @media only screen and (max-width: 360px) {
    &.card-header-modal {
      display: flex;
      flex-direction: column;
    }

    .qtde-and-yield {
      width: 50%;
    }
  }
`;

export const CardInfoContent = styled.div`
  display: ${({ open }) => (open ? 'none' : 'block')};
  width: ${({ width }) => width};
  font-family: Lato !important;

  &.limitProdCardModalMobile {
    display: none;
  }

  &.limitProdCardModal {
    display: none;
  }

  &.limitProdCardModal .date-modal {
    font-size: 16px;
    line-height: 20px;
  }

  @media only screen and (max-width: 480px) {
    &.limitProdCardModal {
      display: none;
    }

    &.limitProdCardModalMobile {
      display: block;
    }
  }

  @media only screen and (min-width: 768px) {
    &.limitProdCardModalMobile {
      display: none;
    }

    &.limitProdCardModal {
      display: flex;
      margin-left: 10px;
      width: 30%;
      justify-content: center;
      align-items: center;
    }

    &.limitProdCardModal {
      display: flex;
      margin-left: 10px;
      width: 30%;
      justify-content: center;
      align-items: center;
    }
  }

  @media only screen and (max-width: 1024px) {
    &.limitProdCard {
      margin-left: 10px;
      width: 30%;
    }

    &.limitProdCardModal div {
      margin-top: 0px;
      margin-left: 5px;
    }

    &.yieldAndNewDurationCard,
    &.lossAndProducedCard {
      width: 100%;
    }

    &.lossAndProducedCard {
      margin-top: 10px;
    }
  }
`;

export const CardContentTitle = styled.div`
  font-weight: bold;
  font-size: 12px;
  color: #666770;
  font-family: Lato !important;

  @media only screen and (max-width: 480px) {
    .special-title-qtde {
      width: 47px;
    }

    .special-title-yield {
      width: 83px;
    }
  }

  @media only screen and (min-width: 768px) {
    .special-title-qtde {
      width: 76px;
    }

    .special-title-yield {
      width: 95px;
    }
  }

  @media only screen and (max-width: 1024px) {
    .special-title {
      color: #fff;
      background-color: #454a67;
      padding: 5px;
      border: 1px solid #454a67;
      border-radius: 12px 12px 0px 0px;
      display: flex;
    }
  }

  @media only screen and (max-width: 480px) {
    font-size: 10px;

    &.card-title-qtde {
      width: 60px;
    }
  }
`;

export const CardContentElement = styled.div`
  font-size: 12px;
  color: #333541;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: Lato !important;

  @media only screen and (max-width: 1024px) {
    .special-result {
      border: 1px solid #454a67;
      border-radius: 12px;
      margin-top: 0px;
      width: 100%;
      padding: 12px;
    }

    .special-date-result {
      background-color: #7583f0;
      color: #fff;
      font-size: 10px;
      padding: 4px 9px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    input {
      background-color: transparent;
      font-size: 18px;
      line-height: 20px;
      border-bottom: none;
      color: #010311;
      font-weight: bold;
    }

    @media screen and (max-width: 480px) {
      input {
        font-size: 12px;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .special-date-result {
      font-size: 14px;
    }

    &.card-result-qtde .special-result {
      width: 75px;
    }
  }

  @media only screen and (max-width: 480px) {
    .special-result {
      width: auto;
    }
  }

  ${({ backGroundOn }) =>
    !!backGroundOn &&
    css`
      padding: 3px;
      background-color: #dff7e8;
      color: #3dcc6e;
      border-radius: 6px;
      text-align: center;
      justify-content: center;
    `}
`;

export const CardPlayWrapper = styled.div`
  display: flex;
  padding: 0px 8px 8px 8px;
  margin-top: 7px;
  text-align: center;
  align-items: center;
  font-family: Lato !important;

  @media only screen and (max-width: 360px) {
    flex-direction: column;
  }

  @media only screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

export const ProductionPlay = styled.img`
  width: ${({ width }) => width || '40px'};
  height: ${({ height }) => height || '40px'};
  margin-right: ${({ marginRight }) => marginRight || '8px'};
  border-radius: 8px;
  font-family: Lato !important;
`;

export const ProductionLabel = styled.div`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || 'space-between'};
  background: ${({ backgroundColor }) => backgroundColor};
  padding: 4px 8px;
  width: 100%;
  border-radius: 4px;
  font-size: 12px;
  color: ${({ fontColor }) => fontColor};
  font-family: Lato !important;
  align-items: center;
  box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.3);
  border: 1px solid #000000;

  td {
    display: flex;
    align-items: center;
  }
`;

export const CardContainer = styled.div`
  width: 100%;
  display: none;
  font-family: Lato !important;

  @media screen and (min-device-width: 320px) and (max-device-width: 1024px),
    screen and (max-device-width: 1366px) and (min-device-height: 1024px) {
    display: flex;
    justify-content: space-between;
    flex-flow: wrap;
    margin-bottom: 100px;
  }
  @media only screen and (max-width: 420px) {
    display: flex;
  }
`;

export const CardsIngredientsWrapper = styled.div`
  transition: 220ms all ease-in;
  display: ${({ open }) => (open ? 'block' : 'none')};
  font-family: Lato !important;
  max-height: ${({ maxHeight }) => (maxHeight || '430px')};
  overflow: auto;
  overflow-x: hidden;

  @media screen and (min-device-width: 320px) and (max-device-width: 767px) {
    max-height: 210px;
  }
`;

export const IngredientLossWrapper = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  position: sticky;
  top: 0;
  box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.5);
  z-index: 9;
`;

export const IngredientCount = styled.div`
  width: 47%;
  display: flex;
  align-items: center;
  background: rgba(50, 55, 86, 1);
  color: ${({ color }) => color || '#FFF'};
  font-size: 18px;
  font-weight: bold;
  padding: 4px 8px;

  @media screen and (max-width: 480px) {
    font-size: 9px;
  }
`;

export const LossCount = styled.div`
  width: 54%;
  display: flex;
  align-items: center;
  background: rgba(50, 55, 86, 1);
  color: #d0d3e2;
  font-size: 18px;
  padding: 4px 8px;

  @media screen and (max-width: 480px) {
    font-size: 9px;
  }
`;

export const LimmitTime = styled.div`
  display: grid;
  margin-right: 12px;
`;

export const LabelLimitTime = styled.div`
  width: 42px;
  font-size: 9px;
  font-weight: bold;
  color: #454a67;
  text-transform: uppercase;
  text-align: right;
  font-feature-settings: 'tnum' on, 'lnum' on;
`;

export const LimitTimeValue = styled.div`
  font-size: 16px;
  color: #010311;
`;
