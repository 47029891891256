/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-lone-blocks */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
/* eslint-disable no-new */
/* eslint-disable radix */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
import React, { useMemo, useState, useEffect, Suspense, useCallback } from 'react';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  Checkbox,
  Popover,
  FormControl,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import axios from 'axios';
import format from 'date-fns/format';
import formatISO from 'date-fns/formatISO';
import ptLocale from 'date-fns/locale/pt-BR';
import moment from 'moment';
import Draggable from 'react-draggable';
import { connect } from 'react-redux';
import { useHistory, useParams, useLocation, Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import PageTitleAndFilter from 'components/PageTitleAndFilter';
import TreeSelectorFieldSquares from 'components/PageTitleAndFilter/TreeSelectorFieldSquares';
import TitleHead from 'components/TitleHead';
import Layout from 'containers/layouts/layout';
import ProjectionIcon from 'images/icons/arrows/arrowGoalWhiteOutline.svg';
import DoubleArrowWhite from 'images/icons/arrows/doubleArrowDownWhite.svg';
import EngineIcon from 'images/icons/components/engineIcon';
import DownloadIcon from 'images/icons/downloadUpload/download_down.svg'
import ScheduleIcon from 'images/icons/objects/calendars/calendarIcon.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import getOptions from 'services/getOptions';
import axiosInstanceManufactures from 'services/middlewares/apis/manufactures_validate';
import * as UserActions from 'store/actions/user';
import { Container, GlobalStyle } from 'styles/general';
import Environment from 'utils/environments';
import {
  getFilterOptions,
  handleFilterOptions,
  handleMultipleFilters
} from 'utils/handleFilterActions';
import ValidationLength from 'utils/utils';

import DesktopRows from './DesktopRows';
import ConsumeQuantities from './Dialogs/ConsumeQuantities';
import DiffQuantities from './Dialogs/DiffQuantities';
import RecipeConsumeDiffQuantities from './Popovers/RecipeConsumeDiffQuantities';
import RecipeItemsDiffQuantities from './Popovers/RecipeItemsDiffQuantities';
import {
  LocalStyle,
  useStyles,
  InputContainer,
} from './styles';

const environments = Environment(process.env.REACT_APP_ENV);
const DurationDialog = React.lazy(() =>
  import('components/Dialogs/DurationDialog')
);

const TheoryStockDialog = React.lazy(() =>
  import('components/Dialogs/ExtractsDialog')
);

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

function Copilot({ userInfo }) {
  const fullWidth = true;
  const maxWidth = 'sm';
  const todayDate = new Date();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down('xs'));
  const isMobileScreen = useMediaQuery('(max-width: 821px)');
  const isDesktopScreen = useMediaQuery('(min-width:1281px)');
  const [recipeItemsDiffAnchorEl, setRecipeItemsDiffAnchorEl] = useState(null);
  const [recipeConsumeDiffAnchorEl, setRecipeConsumeDiffAnchorEl] =
  useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const recipeItemsDiffPopoverOpen = Boolean(recipeItemsDiffAnchorEl);
  const recipeConsumeDiffPopoverOpen = Boolean(recipeConsumeDiffAnchorEl);
  const popoverOpen = Boolean(anchorEl);
  const [state, setState] = useState({ cards: [] });
  const [currentCard, setCurrentCard] = useState('');
  const [updateValue, setUpdateValue] = useState(false);
  const [discardStock, setDiscardStock] = useState(false);
  const [showCardsWithoutReview, setShowCardsWithoutReview] = useState(false);
  const [showCardsWithScheduleVariation, setShowCardsWithScheduleVariation] =
    useState(false);
  const [quantityStockDiscard, setQuantityStockDiscard] = useState();
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [courtOptions, setCourtOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [durationDialogLoading, setDurationDialogLoading] = useState(false);
  const [manufactures, setManufactures] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState('');
  const [originManufectures, setOriginManufectures] = useState([])
  const [manufacture, setManufacture] = useState();
  const [cardDate, setCardDate] = useState(new Date());
  const [updatedManufacture, setUpdatedManufacture] = useState();
  const [optionsDiscard, setOptionsDiscard] = useState([]);
  const [newProduction, setNewProduction] = useState({});
  const [addConfirmModal, setAddConfirmModal] = useState(false);
  const [controlProductionModal, setControlProductionModal] = useState(false);
  const [checkSumeItems, setCheckSumeItems] = useState(false);
  const [cardProductionDate, setCardProductionDate] = useState(new Date());
  const [alertErr, setAlertErr] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };
  const [courtFilterValue, setCourtFilterValue] = useState(null);
  const [checkConsume, setCheckConsume] = useState(false);
  const [loadingCloseProgramming, setLoadingCloseProgramming] = useState(false);
  const { id: paramsID } = useParams();
  const history = useHistory();
  const queryParams = useLocation()?.search;
  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
    setLoadingCloseProgramming(false)
  };
  const [productIdQueryParam, setProductIdQueryParam] = useState(
    queryParams?.includes('productId')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('productId'))
          ?.split('=')[1]
      : ''
  );
  const [productDescriptionQueryParam, setProductDescriptionQueryParam] =
    useState(
      queryParams?.includes('productDescription')
        ? decodeURI(
            queryParams
              ?.split('&')
              ?.find((el) => el.includes('productDescription'))
              ?.split('=')[1]
          )
        : ''
    );

  const [cardIdQueryParam, setCardIdQueryParam] = useState(
    queryParams?.includes('cardId')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('cardId'))
          ?.split('=')[1]
      : ''
  );

  const [cardDescriptionQueryParam, setCardDescriptionQueryParam] = useState(
    queryParams?.includes('cardDescription')
      ? decodeURI(
          queryParams
            ?.split('&')
            ?.find((el) => el.includes('cardDescription'))
            ?.split('=')[1]
        )
      : ''
  );

  const [statusQueryParam, setStatusQueryParam] = useState(
    queryParams?.includes('status')
      ? decodeURI(
          queryParams
            ?.split('&')
            ?.find((el) => el.includes('status'))
            ?.split('=')[1]
        )
      : ''
  );
  const [differenceQueryParam, setDifferenceQueryParam] = useState(
    queryParams?.includes('difference')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('difference'))
          ?.split('=')[1]
      : ''
  );
  const [typeQueryParam, setTypeQueryParam] = useState(
    queryParams?.includes('type')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('type'))
          ?.split('=')[1]
      : ''
  );
  const [initialDateFilterDefaultValue, setInitialDateFilterDefaultValue] =
    useState(
      queryParams?.includes('startAt')
        ? queryParams
            ?.split('&')
            ?.find((el) => el.includes('startAt'))
            ?.split('=')[1]
        : ''
    );
  const [endDateFilterDefaultValue, setEndDateFilterDefaultValue] = useState(
    queryParams?.includes('endAt')
      ? queryParams
          ?.split('&')
          ?.find((el) => el.includes('endAt'))
          ?.split('=')[1]
      : ''
  );
  const deflectTypeFilterDefaultValue = queryParams?.includes('deflect')
    ? queryParams
        ?.split('&')
        ?.find((el) => el.includes('deflect'))
        ?.split('=')[1]
    : '';

  const statusFilterDefaultValue =
    statusQueryParam === 'WAITING'
      ? { title: 'Espera', value: 'WAITING' }
      : statusQueryParam === 'START'
      ? { title: 'Iniciado', value: 'START' }
      : statusQueryParam === 'PAUSE'
      ? { title: 'Pausado', value: 'PAUSE' }
      : statusQueryParam === 'FINISH'
      ? { title: 'Finalizado', value: 'FINISH' }
      : statusQueryParam === 'CANCELED'
      ? { title: 'Cancelado', value: 'CANCELED' }
      : { title: 'Todos', value: '' };

  const diffFilterDefaultValue =
    differenceQueryParam === 'leftover'
      ? { title: 'Sobra', value: 'leftover' }
      : differenceQueryParam === 'noDiff'
      ? { title: 'Sem diferença', value: 'noDiff' }
      : differenceQueryParam
      ? { title: 'Falta', value: 'lack' }
      : undefined;

  const typeFilterDefaultValue =
    typeQueryParam === 'recipe'
      ? { title: 'Receita', value: 'recipe' }
      : typeQueryParam
      ? { title: 'Transformação', value: 'transformation' }
      : undefined;

  const DEFAULT_FILTERS = useMemo(
    () => [
      initialDateFilterDefaultValue && {
        param: 'initialDate',
        value: initialDateFilterDefaultValue,
        urlParam: `initialDate=${initialDateFilterDefaultValue}`
      },
      {
        param: 'finalDate',
        value: endDateFilterDefaultValue || format(new Date(), 'yyyy-MM-dd'),
        urlParam: `finalDate=${
          endDateFilterDefaultValue || format(new Date(), 'yyyy-MM-dd')
        }`
      },
      productIdQueryParam && {
        param: 'productId',
        value: productIdQueryParam,
        urlParam: `productId=${productIdQueryParam}`
      },
      cardIdQueryParam && {
        param: 'cardId',
        value: cardIdQueryParam,
        urlParam: `cardId=${cardIdQueryParam}`
      },
      statusQueryParam && {
        param: 'status',
        value: statusQueryParam,
        urlParam: `status=${statusQueryParam}`
      },
      diffFilterDefaultValue && {
        param: 'difference',
        value: diffFilterDefaultValue?.value,
        urlParam: `difference=${diffFilterDefaultValue?.value}`
      },
      typeFilterDefaultValue && {
        param: 'type',
        value: typeFilterDefaultValue?.value,
        urlParam: `type=${typeFilterDefaultValue?.value}`
      },
      deflectTypeFilterDefaultValue && {
        param: 'deflect',
        value: deflectTypeFilterDefaultValue,
        urlParam: `deflect=${deflectTypeFilterDefaultValue}`
      }
    ],
    [
      diffFilterDefaultValue,
      endDateFilterDefaultValue,
      initialDateFilterDefaultValue,
      typeFilterDefaultValue
    ]
  );
  const [getId, setGetId] = useState(paramsID);
  const [collapseAll, setCollapseAll] = useState(false);
  const [collapseAllTransformations, setCollapseAllTransformations] =
    useState(true);

  const [filtersChanged, setFiltersChanged] = useState(DEFAULT_FILTERS);
  const [getUser, setUser] = useState('');
  const [durationData, setDurationData] = useState({
    product: {},
    data: {},
    type: '',
    newDuration: false
  });
  const classes = useStyles();
  const [updateManufactureValues, setUpdateManufactureValues] = useState(false);
  const [mobileFocusIndex, setMobileFocusIndex] = useState('');
  const [currentDiscardReason, setCurrentDiscardReason] = useState('');
  const [callSettings, setCallSettings] = useState({
    page: 1,
    totalPages: 0,
    size: 100,
    filters: DEFAULT_FILTERS
  });

  const [modalStates, setModalStates] = useState({
    modalDuration: false,
    modalInvalidQuantity: false,
    modalItemsQuantityGreaterThanCard: false,
    modalConfirmationOfFinish: false,
    modalProduct: false,
    modalDiffQuantities: false,
    modalConsumeQuantities: false
  });
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });
  const [statusCode, setStatusCode] = useState();
  const { page, filters } = callSettings;
  const [lastCountErr, setLastCountErr] = useState(false);
  const [storageLocationOptions, setStorageLocationOptions] = useState([]);
  const [periodicityOptions, setPeriodicityOptions] = useState([]);
  const [parameters, setParameters] = useState('');
  const [salesProjectionInfos, setSalesProjectionInfos] = useState('');
  const [productOptions, setProductOptions] = useState([]);
  const [productFilterValue, setProductFilterValue] = useState('');
  const [lastCountObj, setLastCountObj] = useState([]);
  const [productModal, setProductModal] = useState({});
  const [productDescription, setProductDescription] = useState('-');
  const [activesTagFilter, setActivesTagFilter] = useState(
    [
      {
        active: false,
        id: 'includedNoSchedule',
        description: 'Incluído SEM agenda:',
        value: 0,
      },
      {
        active: false,
        id: 'includedSchedule',
        description: 'Incluído COM agenda:',
        value: 0,
      },
      {
        active: false,
        id: 'noIncludedSchedule',
        description: 'Excluído COM agenda:',
        value: 0,
      },
      {
        active: false,
        id: 'noIncludedNoSchedule',
        description: 'Excluído SEM agenda:',
        value: 0,
      },
    ]
  );

  const handleCloseModal = () => {
    setSelectedProduct('')
    
    setModalStates({
      ...modalStates,
      modalDuration: false,
      modalInvalidQuantity: false,
      modalItemsQuantityGreaterThanCard: false,
      modalConfirmationOfFinish: false,
      modalProduct: false,
      modalDiffQuantities: false,
      modalConsumeQuantities: false
    });
  };

  const [copilotStatus, setCopilotStatus] = useState();

  const [filterFields, setFilterFields] = useState([
    [
      {
        type: 'dateRangeField',
        size: 'small',
        filterNameFirst: 'initialDate',
        filterNameSecond: 'finalDate',
        initialDate: initialDateFilterDefaultValue
          ? moment(initialDateFilterDefaultValue).add(3, 'hours')
          : '',
        endDate: endDateFilterDefaultValue
          ? moment(endDateFilterDefaultValue).add(3, 'hours')
          : new Date()
      },
      {
        label: 'Ficha',
        filterName: 'cardDescription',
        filterNameSecond: 'cardId',
        placeHolder: 'Selecione...',
        type: 'autoComplete',
        urlPath: `${environments.catalog}/cards`,
        key: 'cards',
        optionReference: 'description',
        options: [],
        storedValue: cardDescriptionQueryParam || '',
        size: 'medium',
        searchByTermMode: true
      },
      {
        label: 'Tipo',
        filterName: 'type',
        placeHolder: 'Selecione...',
        size: 'medium',
        type: 'autoComplete',
        key: 'type',
        storedValue: typeFilterDefaultValue?.title || '',
        isSelect: true,
        allowClear: true,
        options: [
          { title: 'Todas', value: '' },
          { title: 'Receita', value: 'recipe' },
          { title: 'Transformação', value: 'transformation' }
        ]
      },
      {
        label: 'Ação',
        filterName: 'action',
        placeHolder: 'Selecione...',
        size: 'medium',
        type: 'autoComplete',
        key: 'providers',
        isSelect: true,
        allowClear: true,
        storedValue: statusFilterDefaultValue?.title || '',
        options: [
          { title: 'Todos', value: '' },
          { title: 'Sem Sugestão', value: 'NOT_PRODUCE' },
          { title: 'Produção Sugerida', value: 'TO_PRODUCE' },
          { title: 'Incluído', value: 'PRODUCE' },
          { title: 'Rejeitado', value: 'REFUSED' }
        ]
      }
    ]
  ]);

  const originDevice = () => {
    let device = '';

    if (isPhone) {
      device = 'phone';
    } else if (isMobileScreen) {
      device = 'tablet';
    } else {
      device = 'desktop';
    }

    return device;
  };

  const handleOpenRecipeItemsDiffPopover = (event, card, filteredItems) => {
    setRecipeItemsDiffAnchorEl(event.currentTarget);
    setSelectedCard({
      ...card,
      items: filteredItems
    });
  };

  const handleCloseRecipeItemsDiffPopover = () => {
    setRecipeItemsDiffAnchorEl(null);
    setSelectedCard();
  };

  const handleOpenRecipeConsumeDiffPopover = (event, card) => {
    setRecipeConsumeDiffAnchorEl(event?.currentTarget || event);
    setSelectedCard(card);
  };

  const handleCloseRecipeConsumeDiffPopover = () => {
    setRecipeConsumeDiffAnchorEl(null);
    setSelectedCard();
  };

  // Filter
  const handleFilter = (param, value, _, paramSecond) => {
    const hasFilterWithFirstName = filtersChanged?.filter(
      (el) => el?.param === param
    )?.length;
    const hasFilterWithSecondName = filtersChanged?.filter(
      (el) => el?.param === paramSecond
    )?.length;

    let formattedFilters = hasFilterWithSecondName
      ? filtersChanged?.filter((el) => el?.param !== paramSecond)
      : filtersChanged;

    if (!value) {
      setDifferenceQueryParam(
        param === 'difference' ? '' : differenceQueryParam
      );
      setTypeQueryParam(param === 'type' ? '' : typeQueryParam);
      setInitialDateFilterDefaultValue(
        param === 'initialDate' ? '' : initialDateFilterDefaultValue
      );
      setEndDateFilterDefaultValue(
        param === 'finalDate' ? '' : endDateFilterDefaultValue
      );
      setProductIdQueryParam(param === 'productId' ? '' : productIdQueryParam);
      setStatusQueryParam(param === 'status' ? '' : statusQueryParam);

      if (hasFilterWithFirstName || hasFilterWithSecondName) {
        formattedFilters = filtersChanged?.filter(
          (el) => el?.param !== paramSecond && el?.param !== param
        );
      }
    }

    const urlParams = new URLSearchParams(queryParams);

    if (urlParams.has(param)) {
      urlParams.delete(param);
      history.replace({
        search: urlParams.toString()
      });
    }

    handleFilterOptions(param, value, formattedFilters, setFiltersChanged);
  };

  const getFilterhandle = (urlPath, label, key, optionReference) => {
    getFilterOptions(
      urlPath,
      label,
      key,
      filterFields,
      setFilterFields,
      optionReference,
      getId,
      filtersChanged,
      page
    );
  };

  const handleOrdernationFilter = (paramsAndValues) => {
    handleMultipleFilters(paramsAndValues, filtersChanged, setFiltersChanged);
  };

  const handleUpdateViewMethod = (value) => {
    const body = {
      _id: userInfo?.user,
      configs: [
        {
          cards: [
            {
              cardId: currentCard?.cardId,
              viewMethod: value
            }
          ]
        }
      ]
    }

    axios
      .put(
        `${environments.auth}/users/${userInfo.user}/config`,
        body
      )
      .then((response) => {
        setManufactures(manufactures?.map(el => {
          if (el?._id === currentCard?._id) {
            return {
              ...el,
              viewMethod: value,
            }
          }
    
          return { ...el }
        }))

        setCurrentCard({
          ...currentCard,
          viewMethod: value
        })
      })
      .catch((error) => {
        setMessage({
          description: 'Erro ao atualizar tipo de visualiação da ficha',
          status: 'error'
        });
        setStatusCode(error?.response?.status);
      });
  }

  const buildTagsFilter = (mainObj, firstLoad = false) => {
    const includedNoSchedule = mainObj?.filter(manufacture => manufacture?.engine && manufacture?.engineScheduleMissing)
    const includedSchedule = mainObj?.filter(manufacture => manufacture?.engine && !manufacture?.engineScheduleMissing)
    const noIncludedSchedule = mainObj?.filter(manufacture => !manufacture?.engine && manufacture?.todaySchedule)
    const noIncludedNoSchedule = mainObj?.filter(manufacture => !manufacture?.engine && !manufacture?.todaySchedule)

    setActivesTagFilter(
      activesTagFilter.map((item) => {
        if (item?.description === 'Incluído SEM agenda:') {
          return {
            ...item,
            value: includedNoSchedule.length,
            active: firstLoad ? false : item?.active
          };
        }
        if (item?.description === 'Incluído COM agenda:') {
          return {
            ...item,
            value: includedSchedule.length,
            active: firstLoad ? false : item?.active
          };
        }
        if (item?.description === 'Excluído COM agenda:') {
          return {
            ...item,
            value: noIncludedSchedule.length,
            active: firstLoad ? false : item?.active
          };
        }
        if (item?.description === 'Excluído SEM agenda:') {
          return {
            ...item,
            value: noIncludedNoSchedule.length,
            active: firstLoad ? false : item?.active
          };
        }
        
        return {
          ...item,
          active: firstLoad ? false : item?.active
        };
      })
    );
  }

  const renderTagsFilter = useMemo(() => {
    const filterByStatus = (filterId, active) => {
      if (activesTagFilter?.filter(el => el.active)?.length === 1 && !active) {
        setManufactures(manufactures?.map(el => (
          {
            ...el,
            hide: false
          }
        )))
  
        return null;
      }
  
      if (active) {
        const activeFilters = [...new Set([activesTagFilter?.filter(el => el.active)?.map(el => el.id), filterId].flat())]
  
        setManufactures(manufactures?.map(el => {
          if (activeFilters.includes(el?.tagFiltertype)) {
            return {
              ...el,
              hide: false,
            }
          }
    
          return {
            ...el,
            hide: true
          }
        }))
      } else {
        const inactiveFilters = [...new Set([activesTagFilter?.filter(el => !el.active)?.map(el => el.id), filterId].flat())]
  
        setManufactures(manufactures?.map(el => {
          if (inactiveFilters.includes(el?.tagFiltertype)) {
            return {
              ...el,
              hide: true,
            }
          }
    
          return {
            ...el,
            hide: false
          }
        }))
      }
    }

    return (
      <div className="copilotTagsFilter">
        {activesTagFilter?.map(filter => (
          <div className={`defaultTagFilter ${filter?.id} ${filter?.active && 'active'}`}>
            <span 
              onClick={() => {
                filterByStatus(filter?.id, !filter?.active);
                setActivesTagFilter(
                  activesTagFilter.map((activeFilter) => {
                    if (activeFilter.id === filter.id) {
                      return {
                        ...activeFilter,
                        active: !activeFilter?.active
                      };
                    }

                    return { ...activeFilter }
                  })
                );
              }}
            >
              {filter?.description} {" "} {filter?.value}
            </span>
          </div>
        ))}
      </div>
    )
  }, [manufactures, activesTagFilter]);

  const cardType = (item) => {
    if (item?.engine && item?.engineScheduleMissing) {
      return 'includedNoSchedule'
    }

    if (item?.engine && !item?.engineScheduleMissing) {
      return 'includedSchedule'
    }

    if (!item?.engine && item?.todaySchedule) {
      return 'noIncludedSchedule'
    }

    if (!item?.engine && !item?.todaySchedule) {
      return 'noIncludedNoSchedule'
    }
  }

  const getMainContent = async () => {
    setLoading(true);

    let currentFilters = filtersChanged;
    const statusFilter = filtersChanged
      ?.filter((filter) => filter)
      ?.find((item) => item?.param === 'status');

    if (statusFilter && !statusFilter?.value) {
      currentFilters = filtersChanged.filter(
        (item) => item?.param !== 'status'
      );
    }

    const params = currentFilters
      ?.filter((filter) => filter)
      ?.map((filter) => filter?.urlParam)
      .join('&');
      
    await axiosInstanceManufactures
      .get(`${environments.manufactures}/copilot/?originId=${getId}&${params}&user=${userInfo?.user}`)
      .then((response) => {
        const formattedItems = response?.data?.length
          ? response.data.map((el) => {
              if (el?.type === 'TRANSFORMATION') {
                return {
                  ...el,
                  open: true,
                  initialQuantity: el?.quantity,
                  items: el?.items?.map((item) => ({
                    ...item,
                    initialQuantity: item?.quantity || 0
                  })),
                  tagFiltertype: cardType(el)
                };
              }

              return {
                ...el,
                initialQuantity: el?.quantity,
                items: el?.items?.map((item) => ({
                  ...item,
                  initialQuantity: item?.quantity || 0
                })),
                tagFiltertype: cardType(el)
              };
            })
          : [];

        if (!formattedItems.length) {
          setManufactures([]);
          buildTagsFilter([], true)
        } else {
          setManufactures(formattedItems);
          setOriginManufectures(formattedItems)
          buildTagsFilter(formattedItems, true)
        }
      })
      .catch((err) => {
        console.log(err);
        buildTagsFilter([], true)
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function InsertData(value) {
    if (!value?.items?.length) {
      setMessage({
        description: 'Esta ficha não possui items',
        status: 'error'
      });
      setLoading(false);

      return;
    }

    if (
      value?.type === 'TRANSFORMATION' &&
      !value?.itemsTransformations?.products
    ) {
      setMessage({
        description: 'Esta ficha não possui produto para ser transformado',
        status: 'error'
      });
      setLoading(false);

      return;
    }

    setLoading(true);

    new Promise((resolve, reject) => {
      setTimeout(() => {
        const { cards } = state;
        const limitHours = value.endTime
          ? value.endTime.split(':').map((item) => parseInt(item))
          : null;
        let newData;
        const repeatedRecords = manufactures.filter(
          (item) => item.cardId === value.id && item.status === 'FINISH'
        );
        const repeatedRecordsLength = repeatedRecords.length;
        const lastRecordedItem =
          repeatedRecordsLength !== 0
            ? repeatedRecords.reduce((a, b) =>
                new Date(a.date) > new Date(b.date) ? a : b
              )
            : 0;

        if (value.type === 'RECIPE') {
          newData = {
            order: 1,
            quantity: 1,
            status: 'WAITING',
            date: moment(cardProductionDate)
              .subtract(3, 'hours')
              .format('YYYY-MM-DD HH:mm:ss'),
            created_at: moment()
              .subtract(3, 'hours')
              .format('YYYY-MM-DD HH:mm:ss'),
            restaurantId: getId,
            cost: value.costs.costUnit,
            court: value.court,
            type: value.type,
            origin: 'M',
            justification: null,
            productId: value.productId,
            quantityValidDays: value?.quantityValidDays,
            cardId: value.id,
            description: value.description,
            stock: lastRecordedItem
              ? parseFloat(lastRecordedItem.stock) +
                parseFloat(lastRecordedItem.manufactured)
              : value.product && value.product.length
              ? value.product[0].theoreticalStock
              : 0,
            newStock: null,
            duration: lastRecordedItem
              ? lastRecordedItem.newDuration
              : value.product && value.product.length
              ? value.product[0].duration
              : '0',
            newDuration: null,
            limit: value.endTime
              ? format(
                  todayDate.setHours(
                    limitHours[0],
                    limitHours[1],
                    limitHours[2]
                  ),
                  'yyyy-MM-dd HH:mm'
                )
              : null,
            manufactured: null,
            lossManufactured: 0,
            yield: value?.productions?.yieldTotal || 0,
            un: value.consumptionUnits
              ? {
                  id: value.consumptionUnits.id,
                  description: value.consumptionUnits.description,
                  abbreviation: value.consumptionUnits.abbreviation
                }
              : null,
            items: value.items.map((item) => ({
              id: item.productId,
              cost: item.cost,
              item: item?.products?.description,
              quantity: item.quantity,
              un: {
                id: item?.unitsMeasurements?.id,
                description: item?.unitsMeasurements?.description,
                abbreviation: item?.unitsMeasurements?.abbreviation
              },
              discard: null
            })),
            variationPercentage: value?.product?.length
              ? value?.product[0].variationPercentage
              : 0,
            preparationMethod: value?.preparationMethod || '',
            statusAction: 'NOT_EVALUATED',
            hideValue: true,
            actions: [],
            user: userInfo.user
          };
        }

        if (value.type === 'TRANSFORMATION') {
          newData = {
            order: 1,
            quantity: 1,
            status: 'WAITING',
            date: moment(cardProductionDate)
              .subtract(3, 'hours')
              .format('YYYY-MM-DD HH:mm:ss'),
            created_at: moment()
              .subtract(3, 'hours')
              .format('YYYY-MM-DD HH:mm:ss'),
            restaurantId: getId,
            type: value.type,
            loss:
              value.productions && value.productions.loss
                ? value.productions.loss
                : 0,
            origin: 'M',
            cardId: value.id,
            court: value.court,
            productId:
              value.itemsTransformations &&
              value.itemsTransformations.products.id,
            quantityValidDays: value?.quantityValidDays,
            description: value.description,
            cost: value.costs.costUnit,
            duration: lastRecordedItem
              ? lastRecordedItem.newDuration || 0
              : value.itemsTransformations &&
                value.itemsTransformations.products &&
                value.itemsTransformations.products.duration
              ? value.itemsTransformations.products.duration
              : '0',
            newDuration: null,
            stock: lastRecordedItem
              ? parseFloat(lastRecordedItem.stock) -
                parseFloat(lastRecordedItem.quantity)
              : value.itemsTransformations &&
                value.itemsTransformations.products &&
                value.itemsTransformations.products.theoreticalStock
              ? value.itemsTransformations.products.theoreticalStock
              : 0,
            newStock: null,
            limit: value.endTime
              ? format(
                  todayDate.setHours(
                    limitHours[0],
                    limitHours[1],
                    limitHours[2]
                  ),
                  'yyyy-MM-dd HH:mm'
                )
              : null,
            manufactured: null,
            lossManufactured: 0,
            yield: value?.productions?.yieldTotal || 0,
            un: value?.itemsTransformations?.unitsMeasurements
              ? {
                  id: value?.itemsTransformations?.unitsMeasurements.id,
                  description:
                    value?.itemsTransformations?.unitsMeasurements.description,
                  abbreviation:
                    value?.itemsTransformations?.unitsMeasurements.abbreviation
                }
              : null,
            lossItems:
              value.productions && value.productions.loss
                ? value.productions.loss
                : 0,
            items: value.items.map((item) => ({
              id: item.productId,
              cost: item.cost,
              item: item.products.description,
              quantity: 0,
              stock: lastRecordedItem
                ? parseFloat(
                    lastRecordedItem.items.find(
                      (element) => element.id === item.productId
                    ).stock
                  ) +
                  parseFloat(
                    lastRecordedItem.items.find(
                      (element) => element.id === item.productId
                    ).quantity
                  )
                : item.products && item.products.theoreticalStock
                ? item.products.theoreticalStock
                : 0,
              duration: lastRecordedItem
                ? lastRecordedItem.items.find(
                    (element) => element.id === item.productId
                  ).newDuration || 0
                : item.products && item.products.duration
                ? item.products.duration
                : 0,
              newDuration: null,
              suggestionYield: null,
              un: {
                id: value?.consumptionUnits?.id,
                description: value?.consumptionUnits?.description,
                abbreviation: value?.consumptionUnits?.abbreviation
              },
              discard: null
            })),
            variationPercentage:
              value?.itemsTransformations?.products?.variationPercentage,
            preparationMethod: value?.preparationMethod || '',
            statusAction: 'NOT_EVALUATED',
            actions: [],
            hideValue: true,
            user: userInfo.user
          };
        }

        axios
          .post(`${environments.manufactures}/copilot`, newData, config)
          .then((response) => {
            setNewProduction(null);

            setManufactures([response?.data, ...manufactures]);
            resolve();
            setLoading(false);
          })
          .catch((error) => {
            setMessage({
              description: 'Erro ao adicionar ficha de produção',
              status: 'error'
            });
            setLoading(false);
            setStatusCode(error?.response?.status);
            reject();
          });
      }, 1000);
    });
  }

  const handleControlProduction = (card) => {
    const isRecipeBody = lastCountObj.filter((item) => !item.lastCount)
      ? {
          controlProduction: true,
          user: userInfo?.user,
          originId: paramsID,
          type: card.type,
          realStock: card.type === 'RECIPE' ? lastCountObj[0].realStock : '',
          storageLocations:
            card.type === 'RECIPE' ? lastCountObj[0].storageLocations : [],
          periodicities:
            card.type === 'RECIPE' ? lastCountObj[0].periodicities : [],
          items:
            card.type === 'RECIPE'
              ? []
              : lastCountObj
                  .filter((item) => !item.lastCount)
                  .map((item) => ({
                    productId: item.id,
                    realStock: item.realStock,
                    storageLocations: item.storageLocations,
                    periodicities: item.periodicities
                  }))
        }
      : {
          controlProduction: true
        };

    const isTransformationBody = lastCountObj.filter((item) => !item.lastCount)
      ? {
          controlProduction: true,
          user: userInfo?.user,
          originId: paramsID,
          type: card.type,
          items: lastCountObj.map((item) => ({
            productId: item.id,
            realStock: item.realStock,
            storageLocations: item.storageLocations,
            periodicities: item.periodicities
          }))
        }
      : {
          controlProduction: true
        };

    if (
      lastCountObj.filter((item) => !item.lastCount)
        ? lastCountObj
            .filter((item) => !item.lastCount)
            .map(
              (item) =>
                !item.periodicities.length ||
                !item.storageLocations.length ||
                !item.realStock
            )
            .includes(true)
        : false
    ) {
      setLastCountErr(true);
    } else {
      axios
        .put(
          `${environments.catalog}/cards/${card.id}/controlProduction`,
          card.type === 'RECIPE' ? isRecipeBody : isTransformationBody,
          config
        )
        .then((response) => {
          setControlProductionModal(false);
        })
        .catch((error) => {
          setMessage({
            description: 'Erro ao adicionar controle de produção',
            status: 'error'
          });
          setStatusCode(error?.response?.status);
        });
    }
  };

  const handleAddManufacture = (value, action) => {
    if (value && action === 'additional') {
      InsertData(value);
    } else if (value && action === 'stockLoss') {
      InsertData(value);
    }
  };

  const handleDiscardManufacture = (value) => {
    new Promise((resolve, reject) => {
      const discard = [];

      if (value.type === 'TRANSFORMATION') {
        value.items.forEach((item) => {
          discard.push({
            id: item.products.id,
            typeEvent: 'DISCARD',
            cost: item.cost,
            salesPrice: 0,
            originId: value.restaurantId,
            quantity: quantityStockDiscard,
            eventDate: format(new Date(), 'yyyy-MM-dd'),
            un: item.unitsMeasurements.abbreviation,
            reason: currentDiscardReason,
            user: userInfo.user
          });
        });
      } else {
        discard.push({
          id: value.productId,
          typeEvent: 'DISCARD',
          cost: value.costs.costUnit,
          salesPrice: 0,
          originId: value.restaurantId,
          quantity: parseFloat(quantityStockDiscard),
          eventDate: format(new Date(), 'yyyy-MM-dd'),
          un: value.consumptionUnits.abbreviation,
          reason: currentDiscardReason,
          user: userInfo.user
        });
      }

      setTimeout(() => {
        axios
          .post(`${environments.catalog}/extracts`, discard, config)
          .then((response) => {
            resolve();
            setLoading(false);
            setCurrentDiscardReason('');
          })
          .catch((error) => {
            setMessage({
              description: 'Erro ao adicionar ficha de produção',
              status: 'error'
            });
            setStatusCode(error?.response?.status);
            setLoading(false);
            reject();
          });
      }, 1000);
    });
  };

  const handleTime = (index, date, status) => {
    setState((state) => ({
      ...state,
      cards: state.cards.map((card) => {
        if (card.id === index) {
          switch (status) {
            case 'START':
              return { ...card, startTime: new Date() };
            case 'PAUSE':
              return { ...card, pauseTime: new Date() };
            case 'FINISH':
              return { ...card, finishTime: new Date() };
          }
        } else {
          return card;
        }
      })
    }));
  };

  // Extracts Modal
  const handleOpenExtract = (product) => {
    setProductDescription(product.description);
    setProductModal(product);
    setModalStates((prevState) => ({ ...prevState, modalProduct: true }));
  };

  function capitalize(value) {
    return value ? value.charAt(0).toUpperCase() + value.slice(1) : null;
  }

  const handleUpdate = (value, action, e, initialYield = false) => {
    const { cards } = state;
    const storedValue = manufactures.find((x) => x._id === value._id);
    const invalidDate =
      !moment(cardDate).isValid() || !moment(value.date).isValid();
    const invalidQuantity =
      value.type.toLowerCase() === 'transformation' &&
      (action === 'START' || action === 'WAITING')
        ? false
        : value.type.toLowerCase() === 'transformation' && action === 'FINISH'
        ? storedValue.items
            .map((item) => item.quantity == 0)
            .every((value) => value === true)
        : action === 'FINISH' || action === 'PAUSE'
        ? false
        : storedValue.items.map((item) => item.quantity == 0).includes(true);
    const invalidDiscartOption =
      value.type.toLowerCase() === 'transformation' &&
      (action === 'START' || action === 'WAITING')
        ? false
        : storedValue.items
            .map((item, index) =>
              item?.discard?.quantity
                ? !item.discard.justification?.description
                : false
            )
            .includes(true);

    if (invalidDate) {
      setModalStates({
        ...modalStates,
        modalConfirmationOfFinish: false
      });
      setMessage({
        description: 'Favor inserir uma data válida',
        status: 'error'
      });
      return;
    }

    if ((invalidQuantity || invalidDiscartOption) && !initialYield) {
      setModalStates({
        ...modalStates,
        modalConfirmationOfFinish: false
      });
      setMessage({
        description: 'Favor preencher o campo de QTDE. do item/ingrediente',
        status: 'error'
      });
    } else {
      const id = value._id;
      const newStatus = {
        user: getUser,
        type: action,
        date: formatISO(new Date()),
        originDevice: originDevice()
      };
      const total = storedValue.items.reduce(
        (total, value) =>
          value
            ? parseFloat(total) + parseFloat(value.quantity)
            : parseFloat(total),
        0
      );
      const cardQuantity = storedValue.quantity || 1;
      const cardLostTotal = parseFloat(cardQuantity) - parseFloat(total);
      value.quantity =
        Number(parseFloat(storedValue.quantity)?.toFixed(3)) || 1;
      value.yieldLabelQuantity = storedValue.yieldLabelQuantity;
      value.yield = Number(parseFloat(storedValue.yield)?.toFixed(3));
      value.actions.push(newStatus);
      value.lossManufactured =
        value.type.toLowerCase() === 'transformation' && action === 'FINISH'
          ? (parseFloat(storedValue.quantity) || 1) -
            storedValue.items.reduce(
              (total, item) =>
                item.quantity ? total + parseFloat(item.quantity) : total + 0,
              0
            )
          : storedValue.yield - (updatedManufacture || 0) > 0
          ? storedValue.yield - (updatedManufacture || 0)
          : 0;
      value.status = action;
      value.lossTotal =
        value.type.toLowerCase() === 'transformation' &&
        action === 'FINISH' &&
        parseFloat(100 * cardLostTotal) / parseFloat(cardQuantity);
      value.items = value.items.map((item, index) => {
        const storedItem = storedValue.items.find((x) => x.id === item.id);
        return {
          ...item,
          id: item.id,
          cost: item.cost,
          item: item.item,
          quantity: parseFloat(storedItem.quantity),
          labelQuantity: storedItem.labelQuantity,
          yield: storedValue.yield,
          manufactured: updatedManufacture || 0,
          un: {
            id: item.un.id,
            description: item.un.description,
            abbreviation: item?.un?.abbreviation
          },
          discard:
            storedItem.discard && storedItem.discard.quantity
              ? {
                  quantity: storedItem.discard.quantity
                    ? storedItem.discard.quantity
                    : null,
                  justification: {
                    description: storedItem.discard.justification
                      ? capitalize(storedItem.discard.justification.description)
                      : null
                  }
                }
              : null
        };
      });
      value.hideValue = false;
      value.manufactured =
        value.type.toLowerCase() === 'transformation' && action === 'FINISH'
          ? Number(
              parseFloat(
                storedValue.items.reduce(
                  (total, value) =>
                    value
                      ? parseFloat(total) + parseFloat(value.quantity)
                      : parseFloat(total),
                  0
                )
              )?.toFixed(3)
            )
          : Number(parseFloat(updatedManufacture || 0)?.toFixed(3));
      value.date =
        action === 'START' || action === 'WAITING' ? value.date : cardDate;

      axios
        .put(`${environments.manufactures}/manufactures/${id}`, value, config)
        .then((response) => {
          const data = manufactures;
          const index = manufactures.indexOf(value);
          data.find((x) => x._id === id).status = action;
          data.find((x) => x._id === id).user = newStatus.user;

          !initialYield && handleTime(index, formatISO(new Date()), action);

          if (action === 'FINISH') {
            value.manufactured = response.data.manufactured;
            value.lossManufactured = response.data.lossManufactured;
            value.newStock = response.data.newStock;
            value.newDuration = response.data.newDuration;
            value.status = action;

            setManufactures(
              manufactures.map((manufacture) => {
                if (id === manufacture._id) {
                  return {
                    ...manufacture,
                    manufactured: response.data.manufactured,
                    lossManufactured: response.data.lossManufactured,
                    newStock: response.data.newStock,
                    newDuration: response.data.newDuration,
                    status: action,
                    date: response.data.date
                  };
                }
                return manufacture;
              })
            );

            setModalStates({
              ...modalStates,
              modalItemsQuantityGreaterThanCard: false,
              modalConfirmationOfFinish: false
            });

            !initialYield && handleTime(index, formatISO(new Date()), action);
          }

          setState({ data, cards });
          setMessage({
            description: '',
            status: ''
          });
          setLoading(false);
          setUpdatedManufacture();
          !initialYield && setUpdatedManufacture(false);

          if (
            mobileFocusIndex !== '' &&
            document.querySelector(`#manufacture-${mobileFocusIndex + 1}`)
          ) {
            setMobileFocusIndex('');
            document
              .querySelector(`#manufacture-${mobileFocusIndex + 1}`)
              .select();
          }
        })
        .catch((error) => {
          console.log(error);
          setModalStates({
            ...modalStates,
            modalConfirmationOfFinish: false
          });
          setLoading(false);
        });
    }
  };

  const handleResetProduction = (item) => {
    const body = {
      user: userInfo?.user,
      originDevice: originDevice()
    };

    axios
      .put(`${environments.manufactures}/copilot/restart/${item._id}?originId=${getId}`, body)
      .then(() => {
        setManufactures(
          manufactures?.map((manufacture) => {
            if (manufacture._id === item._id) {
              return {
                ...manufacture,
                status: "WAITING",
                statusAction: "NOT_EVALUATED",
                actions: [
                  ...manufacture?.actions,
                  {
                    date: new Date(),
                    originDevice: originDevice(),
                    type: "NOT_EVALUATED",
                    user: userInfo?.user
                  }
                ]
              };
            }

            return { ...manufacture };
          })
        );

        setMessage({
          description: "Ficha retornada com sucesso.",
          status: 'success'
        });
      })
      .catch((error) => {
        const errorMessage = error?.response?.data?.message

        setMessage({
          description: errorMessage?.charAt(0).toUpperCase() + errorMessage?.slice(1) || "Erro ao retornar ficha.",
          status: 'error'
        });
      });
  }

  const handleSubmitProduction = (item, action) => {
    const body = {
      originId: getId,
      user: userInfo?.user,
      id: item?._id,
      statusAction: action,
      originDevice: originDevice()
    };

    axios
      .put(`${environments.manufactures}/copilot?originId=${getId}`, body)
      .then(() => {
        const alertMessage =
          action === 'TO_PRODUCE'
            ? 'Ficha incluída com sucesso.'
            : 'Ficha rejeitada com sucesso.';

        setManufactures(
          manufactures?.map((manufacture) => {
            if (manufacture._id === item._id) {
              return {
                ...manufacture,
                statusAction: action,
                actions: [
                  ...manufacture?.actions,
                  {
                    date: new Date(),
                    originDevice: originDevice(),
                    type: action,
                    user: userInfo?.user
                  }
                ]
              };
            }

            return { ...manufacture };
          })
        );

        handleCloseRecipeItemsDiffPopover();
        handleCloseRecipeConsumeDiffPopover();

        setMessage({
          description: alertMessage,
          status: 'success'
        });
      })
      .catch((error) => {
        let alertMessage =
          action === 'TO_PRODUCE'
            ? 'Erro ao incluir ficha.'
            : 'Erro ao rejeitar ficha.';

        if (error?.response?.data?.message === 'cannot update when copilot is running') {
          alertMessage = error?.response?.data?.message
        }

        setMessage({
          description: alertMessage,
          status: 'error'
        });
      });
  };

  // Functions to handle state of cards values (quantities of production and/or discards)
  const handleCardQuantity = (
    index,
    quantity,
    itemIndex,
    itemQuantity,
    totalYield,
    mobileFocusIndex,
    itemLabelQuantity = '',
    labelQuantity = ''
  ) => {
    // RENDIMENTO: handleCardQuantity(index, (manufacture.quantity || 1), undefined, undefined, parseFloat(value))
    // QUANTIDADE: handleCardQuantity(index, parseFloat(value), undefined, undefined, undefined)
    // INTERN QTDE: handleCardQuantity(cardIndex, manufacture.quantity, index, parseFloat(value), undefined)

    // RECEITA -> RENDIMENTO:
    // RENDIMENTO: totalYield
    // QUANTIDADE: totalYield * ((manufacture.quantity || 1) / manufacture.yield)
    // ITENS: item.quantity * totalYield / manufacture.yield

    // RECEITA -> QUANTIDADE:
    // RENDIMENTO: quantity * (manufacture.yield / (manufacture.quantity || 1))
    // QUANTIDADE: quantity
    // ITENS: (quantity * item.quantity) / (manufacture.quantity || 1)

    // RECEITA -> ITENS:
    // RENDIMENTO: itemQuantity * manufacture.yield / (manufacture.items.find((item, elementIndex) => elementIndex === itemIndex).quantity)
    // QUANTIDADE: itemQuantity * manufacture.quantity / (manufacture.items.find((item, elementIndex) => elementIndex === itemIndex).quantity)
    // ITENS: itemQuantity

    // TRANSF -> RENDIMENTO:
    // RENDIMENTO: totalYield
    // QUANTIDADE: totalYield * ((manufacture.quantity || 1) / manufacture.yield)
    // ITENS: item.quantity

    // TRANSF -> QUANTIDADE:
    // RENDIMENTO: quantity - (quantity * (manufacture.loss / 100))
    // QUANTIDADE: quantity
    // ITENS: item.quantity

    // TRANSF -> ITENS:
    // RENDIMENTO: itemQuantity * manufacture.yield / (manufacture.items.find((item, elementIndex) => elementIndex === itemIndex).quantity)
    // QUANTIDADE: quantity
    // ITENS: itemQuantity

    const manufactureItemsCalc = manufactures
      .filter((_, manufactureIndex) => index === manufactureIndex)
      .map((manufacture) => ({
        ...manufacture,
        hideValue: false,
        yieldLabelQuantity:
          itemIndex !== undefined
            ? manufacture.yieldLabelQuantity
            : labelQuantity,
        quantity:
          totalYield !== undefined
            ? manufacture.type.toLowerCase() === 'transformation'
              ? manufacture.loss
                ? (parseFloat(totalYield) / (100 - manufacture.loss)) * 100
                : totalYield
              : (parseFloat(totalYield) * (manufacture.quantity || 1)) /
                manufacture?.yield
            : manufacture.type.toLowerCase() === 'recipe'
            ? itemIndex !== undefined
              ? (itemQuantity * manufacture.quantity) /
                manufacture.items.find(
                  (item, elementIndex) => elementIndex === itemIndex
                )?.quantity
              : quantity
            : itemIndex !== undefined
            ? manufacture.loss
              ? (parseFloat(
                  manufacture.items
                    .filter((item, elementIndex) => elementIndex !== itemIndex)
                    ?.map((item) => item?.quantity || 0)
                    .reduce(
                      (total, value) =>
                        value
                          ? parseFloat(total) + parseFloat(value)
                          : parseFloat(total),
                      0
                    ) + itemQuantity
                ) /
                  (100 - manufacture.loss)) *
                100
              : manufacture.items
                  .filter((item, elementIndex) => elementIndex !== itemIndex)
                  ?.map((item) => item?.quantity || 0)
                  .reduce(
                    (total, value) =>
                      value
                        ? parseFloat(total) + parseFloat(value)
                        : parseFloat(total),
                    0
                  ) + itemQuantity
            : quantity,
        initialYield: manufacture.initialYield
          ? manufacture.initialYield
          : manufacture.type.toLowerCase() === 'recipe'
          ? manufacture?.yield * 1
          : 1 - 1 * (manufacture.loss / 100),
        initialQuantity:
          itemQuantity !== undefined && !manufacture?.loss
            ? manufacture.items
                .filter((item, elementIndex) => elementIndex !== itemIndex)
                ?.map((item) => item?.quantity || 0)
                .reduce(
                  (total, value) =>
                    value
                      ? parseFloat(total) + parseFloat(value)
                      : parseFloat(total),
                  0
                ) + itemQuantity
            : manufacture?.initialQuantity,
        yield:
          totalYield !== undefined
            ? parseFloat(totalYield)
            : itemIndex !== undefined
            ? manufacture.type.toLowerCase() === 'transformation'
              ? manufacture.items
                  .filter((item, elementIndex) => elementIndex !== itemIndex)
                  ?.map((item) => item?.quantity || 0)
                  .reduce(
                    (total, value) =>
                      value
                        ? parseFloat(total) + parseFloat(value)
                        : parseFloat(total),
                    0
                  ) + itemQuantity
              : (itemQuantity * manufacture.quantity) /
                  manufacture.items.find(
                    (item, elementIndex) => elementIndex === itemIndex
                  )?.quantity >=
                  0 && manufacture.type.toLowerCase() === 'recipe'
              ? (itemQuantity * manufacture?.yield) /
                manufacture.items.find(
                  (item, elementIndex) => elementIndex === itemIndex
                )?.quantity
              : manufacture.initialYield
              ? manufacture.initialYield
              : 1 - 1 * (manufacture.loss / 100)
            : quantity >= 0 &&
              manufacture.type.toLowerCase() === 'transformation'
            ? manufacture?.items.some((el) => el?.quantity)
              ? manufacture.items
                  .map(
                    (item) =>
                      quantity * (item?.quantity / manufacture?.quantity) || 0
                  )
                  .reduce(
                    (total, value) =>
                      value
                        ? parseFloat(total) + parseFloat(value)
                        : parseFloat(total),
                    0
                  )
              : quantity * ((100 - manufacture?.loss) / 100)
            : quantity >= 0 && manufacture.type.toLowerCase() === 'recipe'
            ? (quantity * manufacture?.yield) / (manufacture.quantity || 1)
            : manufacture.initialYield
            ? manufacture.initialYield
            : 1 - 1 * (manufacture.loss / 100),
        items: manufacture.items.map((item, elementIndex) =>
          quantity >= 0 && manufacture.type.toLowerCase() === 'recipe'
            ? {
                ...item,
                labelQuantity:
                  itemIndex !== undefined && itemIndex === elementIndex
                    ? itemLabelQuantity || item.labelQuantity
                    : '',
                quantity:
                  itemIndex !== undefined && itemIndex === elementIndex
                    ? itemQuantity
                    : totalYield !== undefined
                    ? (item.quantity * parseFloat(totalYield)) /
                      manufacture?.yield
                    : itemIndex !== undefined
                    ? ((itemQuantity * 1) /
                        manufacture.items.find(
                          (item, elementIndex) => elementIndex === itemIndex
                        )?.quantity) *
                      item.quantity
                    : (quantity * item.quantity) / (manufacture.quantity || 1)
              }
            : {
                ...item,
                labelQuantity:
                  itemIndex !== undefined && itemIndex === elementIndex
                    ? itemLabelQuantity || item.labelQuantity
                    : item.labelQuantity,
                initialQuantity:
                  itemIndex === elementIndex
                    ? itemQuantity !== undefined
                      ? itemQuantity
                      : item?.initialQuantity
                    : item?.initialQuantity,
                quantity:
                  totalYield !== undefined
                    ? manufacture?.loss
                      ? (totalYield / (100 - manufacture?.loss)) *
                          100 *
                          (item?.quantity / manufacture?.quantity) || 0
                      : totalYield * (item?.quantity / manufacture?.quantity) ||
                        0
                    : quantity !== undefined && itemQuantity === undefined
                    ? quantity * (item?.quantity / manufacture?.quantity) || 0
                    : itemIndex !== undefined && itemIndex === elementIndex
                    ? itemQuantity
                    : item.quantity || 0
              }
        )
      }));

    const validateTransformationCard =
      manufactureItemsCalc[0]?.type === 'TRANSFORMATION' &&
      manufactureItemsCalc[0]?.quantity === 0
        ? manufactureItemsCalc[0]?.items.length === 1
        : false;

    const hasInvalidItemsCalculated =
      validateTransformationCard &&
      manufactureItemsCalc[0]?.items?.some(
        (el) => parseFloat(el.quantity?.toFixed(3)) === 0
      );

    setManufactures(
      manufactures.map((manufacture, manufactureIndex) => {
        if (index === manufactureIndex && !hasInvalidItemsCalculated) {
          return manufactureItemsCalc[0];
        }

        return manufacture;
      })
    );

    // if (hasInvalidItemsCalculated) {
    //   setModalStates({
    //     ...modalStates,
    //     modalInvalidQuantity: true
    //   });
    // }

    setUpdateManufactureValues(!updateManufactureValues);
    mobileFocusIndex && setMobileFocusIndex(itemIndex);
  };

  const getDuration = (productId, stock, product, type, newDuration) => {
    setDurationDialogLoading(true);
    axios
      .get(
        `${environments.engine}/rupture?productId=${productId}&restaurantId=${getId}&days=8&stock=${stock}`
      )
      .then((response) => {
        const items = response.data;
        setDurationData({
          product,
          data: items,
          type,
          newDuration
        });
        setDurationDialogLoading(false);
      })
      .catch((error) => {
        setMessage({
          description: 'Erro ao adicionar ficha de produção',
          status: 'error'
        });
        setDurationDialogLoading(false);
        setStatusCode(error?.response?.status);
      });
  };

  const handleClickOpenModal = (data) => {
    if (data?.type === 'TRANSFORMATION') {
      getOptions(
        `${environments.catalog}/products`,
        'products',
        selectedProduct,
        setSelectedProduct,
        getId,
        { productId: data?.productId, autoComplete: true },
        null,
        null,
        true
      );
    }

    setModalStates({
      ...modalStates,
      modalDuration: true
    });
  };

  const handleOpenItems = (id) => {
    setManufactures(
      manufactures?.map((manufacture) => {
        if (manufacture._id === id) {
          return {
            ...manufacture,
            open: !manufacture.open
          };
        }

        return { ...manufacture };
      })
    );
  };

  const onClearFilters = () => {
    const TODAY = new Date();

    const FILTERS = DEFAULT_FILTERS.map((filter) => {
      if (filter?.param === 'initialDate') {
        return {
          param: 'initialDate',
          value: '',
          urlParam: ''
        };
      }

      if (filter?.param === 'finalDate') {
        return {
          param: 'finalDate',
          value: format(TODAY, 'yyyy-MM-dd'),
          urlParam: `finalDate=${format(TODAY, 'yyyy-MM-dd')}`
        };
      }

      return { ...filter, value: '', urlParam: '' };
    });

    const FILTER_FIELDS = filterFields.map((filterLine, index) => {
      if (index === 0) {
        return filterLine.map((filter) => {
          if (filter.type === 'dateRangeField') {
            return {
              type: 'dateRangeField',
              size: 'small',
              filterNameFirst: 'initialDate',
              filterNameSecond: 'finalDate',
              initialDate: '',
              endDate: TODAY
            };
          }

          return filter;
        });
      }

      return filterLine;
    });

    setCallSettings((prevState) => ({
      ...prevState,
      filters: FILTERS
    }));
    setFiltersChanged(FILTERS);
    setFilterFields(FILTER_FIELDS);
    setCourtFilterValue('')
  };

  const renderMidCourt = useMemo(() => {
    const totalManufactures = manufactures?.length > 1 
      ? `${manufactures?.length} Fichas` 
      : `${manufactures?.length} Ficha`
    
    return (
      <div
        style={{
          width: '50%',
          position: 'relative',
          display: 'flex',
          justifyContent: '',
          marginBottom: 5,
          paddingTop: isMobileScreen ? 7 : 3,
          paddingBottom: '',
          color: '#000319',
        }}
      >
        <label
          className="alignEnd bold fontSizeDefault mr5 mb0 ml10"
          style={{
            marginTop: 13,
            lineHeight: 'normal',
            color: '#333541',
            width: 34,
          }}
        >
          Praça:
        </label>

        <InputContainer>
          <TreeSelectorFieldSquares
            filterName="courts"
            style={{ width: '30%', height: '25px' }}
            handleFilter={(_, value) => {
              handleFilter(`courtId`, value?.id || '');
              setCourtFilterValue(value)
              getCopilotStatus(value)
            }}
            allObjValue
            key={(node) => node?.id}
            customSize="200px"
            marginRight="10px"
            getFilterhandle={() => {}}
            options={[]}
            onFormStyle
          />
        </InputContainer> 

        <label
          className="alignEnd bold fontSizeDefault mt12 mr5 mb0 ml10"
          style={{
            color: '#333541',
            width: 100,
          }}
        >
          {totalManufactures}
        </label>

        <label
          className="alignEnd bold fontSizeDefault mr5 mb0 ml10"
          style={{
            marginTop: 13,
            lineHeight: 'normal',
            color: '#333541',
            width: 50,
          }}
        >
          Produto:
        </label>
        
        <Autocomplete
          key="productFilter"
          tabIndex={-1}
          options={productOptions || []}
          style={{ width: '40%' }}
          freeSolo
          forcePopupIcon 
          onKeyDown={(event) => {
            if (event.keyCode === 8 || event.keyCode === 13) {
              handleFilter(`productDescription`, event.target.value || '', null, 'productId');
              setProductFilterValue(event.target.value);
            }
          }}
          onFocus={() => {
            if (productOptions.length) return;

            getOptions(
              `${environments.catalog}/products`,
              'productOptions',
              productOptions,
              setProductOptions,
              getId,
              { autoComplete: true },
              null,
              2000
            );
          }}
          getOptionLabel={(option) => option?.description || option}
          className="autocompleteSmall mt5"
          value={productFilterValue}
          size="small"
          onChange={(_, value) => {
            setProductFilterValue(value || '');
            handleFilter(`productId`, value?.id || '', null, 'productDescription');
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              tabIndex={-1}
              id={params.id}
              type="text"
              name="Products"
              variant="outlined"
              placeholder="Selecione..."
              InputLabelProps={{
                shrink: true
              }}
            />
          )}
          renderOption={(params) => (
            <Typography
              style={{ fontSize: '12px', fontFamily: 'Lato' }}
            >
              {params.description}
            </Typography>
          )}
        />
      </div>
    )
  })

  const renderCloseProgrammingType = useMemo(() => {
    let finalText = courtFilterValue?.description

    if (courtFilterValue?.description === 'TODAS' || !courtFilterValue) {
      finalText = ''
    }

    return finalText
  }, [courtFilterValue])

  const renderScheduleVariations = useMemo(() => {
    const handleChangeStatusView = (checked) => {
      setShowCardsWithScheduleVariation(checked);

      setManufactures(
        checked
          ? manufactures?.filter((el) => el?.engineScheduleMissing || el?.todaySchedule === true)
          : originManufectures?.map((el) => ({
              ...el,
              hide: false
            }))
      );
    };

    const hasScheduleVariations = manufactures?.filter(
      (el) => el?.engineScheduleMissing || el?.todaySchedule
    );

    if (!hasScheduleVariations?.length) return null

    return (
      <div
        className={`${!hasScheduleVariations?.length ? 'dNone' : 'dFlex'} ${
          isDesktopScreen && 'alignCenter cardsWithoutReviewBox mr0 mlAuto'
        }`}
      >
        <Checkbox
          style={{
            marginTop: 4,
            marginLeft: isMobileScreen ? -2 : 0,
            color: '#1F2445'
          }}
          name="View Mode"
          checked={showCardsWithScheduleVariation}
          className="checkBoxSmall"
          onChange={(_, checked) => {
            handleChangeStatusView(checked);
          }}
          size="small"
        />

        <label
          onClick={() =>
            handleChangeStatusView(!showCardsWithScheduleVariation)
          }
          className={`initialTransform fontWeightNormal fontSizeDefault dFlex alignEnd cursorPointer ${
            isMobileScreen ? 'mb5' : 'mb0'
          }`}
          style={{
            lineHeight: 'normal',
            color: '#1F2445'
          }}
        >
          Visualizar variações de agenda
        </label>
      </div>
    );
  }, [
    isDesktopScreen,
    isMobileScreen,
    showCardsWithScheduleVariation,
    manufactures
  ]);

  const renderCardsWithoutReview = useMemo(() => {
    const handleChangeStatusView = (checked) => {
      setShowCardsWithoutReview(checked);

      setManufactures(
        checked
          ? manufactures?.map((el) => {
              if (el?.statusAction && el?.statusAction === 'NOT_EVALUATED') {
                return {
                  ...el,
                  hide: false
                };
              }

              return {
                ...el,
                hide: true
              };
            })
          : manufactures?.map((el) => ({
              ...el,
              hide: false
            }))
      );
    };

    const hasReviewManufactures = manufactures?.filter(
      (el) => el?.statusAction && el?.statusAction === 'NOT_EVALUATED'
    );

    const hasScheduleVariations = manufactures?.filter(
      (el) => el?.engineScheduleMissing || el?.todaySchedule
    );

    if (!hasReviewManufactures?.length) return null

    return (
      <div
        style={{ marginLeft: 40 }}
        className={`${!hasReviewManufactures?.length ? 'dNone' : 'dFlex'} ${
          isDesktopScreen && 'alignCenter cardsWithoutReviewBox mr0'
        } ${!hasScheduleVariations?.length && 'mlAuto'}`}
      >
        <Checkbox
          style={{
            marginTop: 4,
            marginLeft: isMobileScreen ? -2 : 0,
            color: '#1F2445'
          }}
          name="View Mode"
          checked={showCardsWithoutReview}
          className="checkBoxSmall"
          onChange={(_, checked) => {
            handleChangeStatusView(checked);
          }}
          size="small"
        />

        <label
          onClick={() => handleChangeStatusView(!showCardsWithoutReview)}
          className={`initialTransform fontWeightNormal fontSizeDefault dFlex alignEnd cursorPointer ${
            isMobileScreen ? 'mb5' : 'mb0'
          }`}
          style={{
            lineHeight: 'normal',
            color: '#1F2445'
          }}
        >
          Visualizar {hasReviewManufactures?.length} Fichas sem revisão
        </label>
      </div>
    );
  }, [isDesktopScreen, isMobileScreen, showCardsWithoutReview, manufactures]);

  const renderDiffOnSales = () => (
    <article className="copilotDiffOnSales">
      <p className="bold">
        ATENÇÃO! Este restaurante está configurado em Parâmetros para gerar as
        sugestões a partir do cadastro das metas definidas pela loja e não foram
        encontradas informaçõoes para esta data
      </p>

      <div className="diffOnSalesInfos">
        <div className="dFlex flexColumn mr8">
          <span>Projeção de Vendas definido em Parâmetros do sistema:</span>

          {parameters?.salesProjection?.valueSuggestion && (
            <span>
              Sugestões automáticas geradas a partir das Médias de Vendas:
            </span>
          )}

          {parameters?.salesProjection?.safetyMargin && (
            <span>Margem de segurança:</span>
          )}
        </div>

        <div className="dFlex flexColumn">
          <div className="dFlex alignCenter">
            <span className="bold">Meta Loja</span>

            <Tooltip title="Visualizar Parâmetros" aria-label="parameters">
              <Link
                style={{ lineHeight: 0.9 }}
                target="_blank"
                to={`/admin/parametros/${paramsID}`}
              >
                <EngineIcon fill="#FFF" />
              </Link>
            </Tooltip>
          </div>

          <div className="dFlex alignCenter">
            {parameters?.salesProjection?.valueSuggestion && (
              <>
                <span className="bold">
                  R${' '}
                  {parameters?.salesProjection?.valueSuggestion?.toLocaleString(
                    'pt-br',
                    {
                      maximumFractionDigits: 2
                    }
                  )}
                </span>

                <Tooltip
                  title="Visualizar Projeção de Vendas"
                  aria-label="projection"
                >
                  <Link
                    style={{ lineHeight: 0.9 }}
                    target="_blank"
                    to={`/admin/projecao-de-vendas/cadastro/${paramsID}`}
                  >
                    <img src={ProjectionIcon} alt="Projection" />
                  </Link>
                </Tooltip>
              </>
            )}
          </div>

          <div className="dFlex alignCenter">
            {parameters?.salesProjection?.safetyMargin && (
              <>
                <span className="bold">
                  {parseFloat(
                    parameters?.salesProjection?.safetyMargin
                  )?.toLocaleString('pt-br', {
                    minimumFractionDigits: 2
                  })}
                  %
                </span>

                <Tooltip
                  title="Visualizar Projeção de Vendas"
                  aria-label="projection"
                >
                  <Link
                    style={{ lineHeight: 0.9 }}
                    target="_blank"
                    to={`/admin/projecao-de-vendas/cadastro/${paramsID}`}
                  >
                    <img src={ProjectionIcon} alt="Projection" />
                  </Link>
                </Tooltip>
              </>
            )}
          </div>
        </div>
      </div>
    </article>
  );

  const renderLastSuggestionInfo = () => {
    const suggestionType = parameters?.salesProjection?.lastSuggestionType === 'MANUAL_PROJECTION' ? 'Meta Loja' : 'Projeção Bloom';;
    const color = '#5062F0';
    const value = parameters?.salesProjection?.lastSuggestionType === 'MANUAL_PROJECTION' ? salesProjectionInfos?.projection : salesProjectionInfos?.forecastNoSafeMargin

    if (parameters) {
      return (
        <Link style={{ color: "#000000" }} target="_blank" to={`/admin/projecao-de-vendas/cadastro/${paramsID}`}>
          <p className="mb0" style={{ marginRight: 40 }}>
            Sugestões {' '}

            <span className="bold" style={{ color }}>
              {suggestionType}: {' '} 
              R$ {value?.toLocaleString('pt-br', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2
              })}
            </span>
          </p>
        </Link>
      );
    }
  };

  const scheduleButton = () => (
    <Tooltip title="Visualizar agenda" aria-label="filters">
      <Link target="_blank" to={`/agenda/${paramsID}?currentDate=true`}>
        <IconButton
          className="p0 dBlock m0"
          edge="start"
          color="inherit"
          aria-label="add"
        >
          <img style={{ width: '25px' }} src={ScheduleIcon} alt="Schedule" />
        </IconButton>
      </Link>
    </Tooltip>
  );

  const downloadSpreadsheet = (courtDescription, statusMessage = '') => {
    const currentStatusMessage = statusMessage?.message || copilotStatus?.message

    // Se o copiloto estiver com status pendente, exibe a mensagem de que não é permitido o download
    if (currentStatusMessage === "Pendente") {
      setAlertMessage("Para baixar a planilha, a programação do copiloto deve estar encerrada.")
      setAlertErr(true)
      return
    }
    const courtFilter = courtDescription && courtDescription != 'TODAS' ? `courtDescription=${courtDescription}` : ''

    const url = `${environments.manufactures}/copilot/xls?originId=${getId}&date=${moment().format("YYYY-MM-DD")}&user=${userInfo.user}&${courtFilter}`

    const link = document.createElement('a')
    link.href = url
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const getCopilotStatus = useCallback((courtFilterValue, downloadSpreadSheet = false) => {
    const courtFilter = courtFilterValue && courtFilterValue?.description != 'TODAS' ? `courtId=${courtFilterValue?.id}` : ''

    axios
      .get(
        `${environments.manufactures}/copilot/status?originId=${
          Array.isArray(userInfo.companiesActive.id)
            ? userInfo.companiesActive.id[0]
            : userInfo.companiesActive.id
        }&date=${moment().format('YYYY-MM-DD')}&${courtFilter}`
      )
      .then((response) => {
        setCopilotStatus(response?.data || {});
        setTimeout(() => {
          setLoadingCloseProgramming(false)
        }, 3000);

        downloadSpreadSheet && downloadSpreadsheet(courtFilterValue?.description, response?.data)
      })
      .catch((error) => {
        setCopilotStatus();
        setTimeout(() => {
          setLoadingCloseProgramming(false)
        }, 3000);
      });
  }, [userInfo.companiesActive.id]);

  const closeProgramming = (courtDescription) => {
    const courtFilter = courtDescription && courtDescription != 'TODAS' ? `courtDescription=${courtDescription}` : ''
    
    const uri = `${environments.manufactures}/copilot/refused?originId=${getId}&date=${moment().format('YYYY-MM-DD')}&user=${userInfo.user}&${courtFilter}`
    
    const link = document.createElement('a');
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    
    setTimeout(() => {
      getCopilotStatus(courtFilterValue)
      getMainContent();
      handleDialogClose()
    }, 2000);
    
    setLoadingCloseProgramming(true)
  };

  const downloadSpreadsheetButton = () => (
    <Tooltip
      title="Baixar planilha com produtos x produção"
      aria-label="filters"
    >
      <IconButton
        className="p0 mr10"
        edge="start"
        color="inherit"
        aria-label="add"
        onClick={() => { getCopilotStatus(courtFilterValue, true) }}
      >
        <img style={{ width: "25px" }} src={DownloadIcon} alt="Download" />
      </IconButton>
    </Tooltip>
  )

  useEffect(() => {
    !storageLocationOptions.length &&
      getOptions(
        `${environments.catalog}/storageLocations`,
        'storageLocations',
        storageLocationOptions,
        setStorageLocationOptions,
        getId
      );
    !periodicityOptions.length &&
      getOptions(
        `${environments.catalog}/periodicities`,
        'periodicities',
        periodicityOptions,
        setPeriodicityOptions,
        getId
      );
  }, [environments.catalog, paramsID]);

  useEffect(() => {
    if (filtersChanged?.filter(el => el?.param === 'courtId').length) {
      setFiltersChanged(filtersChanged?.filter(el => el?.param !== 'courtId'))
    }
    setCourtFilterValue('')
  }, [paramsID])

  useEffect(() => {
    setGetId(paramsID);
    setUser(userInfo?.user);
    getCopilotStatus(courtFilterValue)
  }, [paramsID, userInfo?.user]);

  useEffect(() => {
    setManufactures([]);
    getMainContent();
  }, [getId, filtersChanged, filters, environments.manufactures]);

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${environments.restaurants}/companies/${getId}/parameters`
      );
      const parametersResponse = await response.json();
      setParameters(parametersResponse || '');
    })();
  }, [getId]);

  useEffect(() => {
    (async () => {
      const response = await fetch(
        `${environments.restaurants}/salesProjection/today?originId=${getId}`
      );
      const salesProjectionResponse = await response.json();
      setSalesProjectionInfos(salesProjectionResponse || '');
    })();
  }, [getId]);

  useEffect(() => {
    (async () => {
      await axios
        .get(
          `${environments.catalog}/discards?type=PRODUCT&size=1000&page=1&originId=${getId}`
        )
        .then((response) => {
          const items = response.data.content;
          setOptionsDiscard(items);
        })
        .catch((error) => {
          console.log(error);
        });
    })();
  }, [getId]);

  useEffect(() => {
    (async () => {
      if (productIdQueryParam) {
        await axios
          .get(
            `${environments.catalog}/products/${productIdQueryParam}?originId=${getId}`
          )
          .then((response) => {
            const items = response.data;
            const newFilters = [...filterFields];

            newFilters[0][1].storedValue = items?.description;

            setFilterFields(newFilters);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    })();
  }, [getId]);

  useEffect(() => {
    (async () => {
      if (cardIdQueryParam) {
        await axios
          .get(
            `${environments.catalog}/cards/${cardIdQueryParam}?originId=${getId}`
          )
          .then((response) => {
            const items = response.data;
            const newFilters = [...filterFields];

            newFilters[0][3].storedValue = items?.description;

            setFilterFields(newFilters);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    })();
  }, [getId]);

  useEffect(() => {
    if (manufacture) {
      handleUpdate(manufacture, manufacture.status, null, true);
    }
  }, [updateManufactureValues]);

  useEffect(() => {
    const sumeManufactureItems = manufacture?.items.reduce(
      (total, value) =>
        value.quantity
          ? parseFloat(total) + parseFloat(value.quantity)
          : total + 0,
      0
    );
    const itemsAreGreaterThanCard =
      sumeManufactureItems > manufacture?.quantity;

    if (
      itemsAreGreaterThanCard &&
      manufacture?.type === 'TRANSFORMATION' &&
      checkSumeItems
    ) {
      setModalStates({
        ...modalStates,
        modalItemsQuantityGreaterThanCard: true
      });
    }
  }, [manufacture?.items, checkSumeItems]);

  return (
    <Layout>
      {/* <Production /> */}
      <TitleHead title="Copiloto" />

      <GlobalStyle />

      <LocalStyle />

      <Container className="productionPage">
        <CommonAlert
          open={!!message.description}
          onClose={() => setMessage({ description: '', status: '' })}
          severity={message.status}
          indexMessage={message.description}
          messagePersonal={message.description}
          statusCode={statusCode}
        />

        <div className="desktopFilterInfo">
          <PageTitleAndFilter
            title={`Copiloto: ${format(new Date(), 'EEEE, dd/MM/yyyy', {
              locale: ptLocale
            })} (${
              manufactures?.filter(
                (item) =>
                item.status !== 'CANCELED' &&
                moment(item?.date).format('DD/MM/YYYY') ===
                moment(new Date()).format('DD/MM/YYYY')
                ).length
              } Preparos)`
            }
            activeFilterFilds={filterFields}
            complementarybutton={renderLastSuggestionInfo}
            extrabutton={downloadSpreadsheetButton}
            additionalbutton={scheduleButton}
            handleFilter={handleFilter}
            handleMultipleFilters={handleOrdernationFilter}
            getFilterhandle={getFilterhandle}
            dynamicJustifyOff
            onClear={onClearFilters}
            filterDefaultStatus={false}
            customClassName="noMarginFilter"
          />
        </div>
        
        {parameters?.salesProjection?.lastSuggestionType ===
          'MANUAL_PROJECTION' &&
          !parameters?.salesProjection?.hasManualValue &&
          renderDiffOnSales()
        }

        {parameters?.engine?.isRunning && (
          <div className="processing">
            <p>
              Atenção! A Projeção de Vendas para a data atual foi alterada e
              as novas sugestões estão sendo geradas, por favor, aguarde....
            </p>
          </div>
        )}

        {renderTagsFilter}

        <div className="dFlex copilotHeader">
          {!parameters?.engine?.isRunning ?
            <>
              {renderMidCourt}

              {renderScheduleVariations}

              {renderCardsWithoutReview}

              {manufactures?.length ?
                <span
                  onClick={() => {
                  setLoadingCloseProgramming(true)
                  if (loadingCloseProgramming || copilotStatus?.message !== 'Pendente') return;

                  handleDialogClickOpen()
                }}
                className={`closeProgrammingButton textAlignCenter mb5 ${!renderScheduleVariations && !renderCardsWithoutReview ? 'mlAuto' : 'ml20'} ${(loadingCloseProgramming || copilotStatus?.message !== 'Pendente') && 'disabledButton'}`}
                disabled={loadingCloseProgramming}
                >
                  Encerrar Programação <br/>
                  {renderCloseProgrammingType}
                </span>
              : null}
            </>
          : null}
        </div>

        <table
          className="desktop productionTable copilotTable"
          width="100%"
          cellPadding="0"
          cellSpacing="0"
          border="0"
          >
          <tr>
            <th colSpan="9" style={{ backgroundColor: '#FFF' }}>
              <hr />
            </th>
          </tr>

          <tbody>
            <tr className="headerTitle">
              <th width="29.5%" className="subtitle" style={{ borderRight: 'solid 1px #D0D3E2' }}>
                <div className="dFlex alignCenter">
                  FICHA{' '}
                  <div
                    className="dFlex alignCenter cursorPointer ml5"
                    onClick={() => {
                      setCollapseAll(!collapseAll);
                      setManufactures(
                        manufactures?.map((el) => ({
                          ...el,
                          open: !collapseAll
                        }))
                      );
                    }}
                  >
                    <img
                      style={{
                        width: '8px',
                        rotate: collapseAll ? '180deg' : '0deg',
                        marginRight: 3
                      }}
                      src={DoubleArrowWhite}
                      alt="CollapseIcon"
                    />

                    {collapseAll ? 'minimizar todos' : 'exibir todos'}
                  </div>

                  <div
                    className="dFlex alignCenter cursorPointer ml10"
                    onClick={() => {
                      setCollapseAllTransformations(
                        collapseAll ? true : !collapseAllTransformations
                      );
                      setManufactures(
                        manufactures?.map((el) => {
                          if (el?.type === 'TRANSFORMATION') {
                            return {
                              ...el,
                              open: collapseAll
                              ? true
                              : !collapseAllTransformations
                            };
                          }
                          
                          return { ...el };
                        })
                      );
                    }}
                  >
                    <img
                      style={{
                        width: '8px',
                        rotate: collapseAllTransformations ? '180deg' : '0deg',
                        marginRight: 3
                      }}
                      src={DoubleArrowWhite}
                      alt="CollapseIcon"
                    />

                    TRANSFORMAÇÕES
                  </div>
                </div>
              </th>

              <th width="7%" className="subtitle p8" style={{ borderRight: 'solid 1px #D0D3E2' }}>
                ESTOQUE{' '}
              </th>

              <th width="5%" className="subtitle center p8" style={{ borderRight: 'solid 1px #D0D3E2' }}>
                DURAÇÃO{' '}
              </th>

              <th width="12%" className="subtitle p8" style={{ borderRight: 'solid 1px #454A67' }}>
                AGENDA{' '}
              </th>

              <th width="13%" className="subtitle center pr3" style={{ borderRight: 'solid 1px #454A67' }}>
                PRODUZIR{' '}
              </th>

              <th
                width="2.5%"
                className="subtitle center"
                style={{ borderLeft: 'solid 1px #454A67' }}
              >
                &nbsp;
              </th>

              <th width="23%" className="subtitle pr8 pl8">
                AÇÃO{' '}
              </th>

              <th width="5.5%">&nbsp;</th>

              <th width="2.5%">&nbsp;</th>
            </tr>

            {loading && (
              <tr>
                <th className="mt10 textAlignCenter" colSpan="9">
                  <LinearProgress
                    style={{ height: '7px' }}
                    id="loading"
                    variant="query"
                    />
                </th>
              </tr>
            )}

            {manufactures.length === 0 ? (
              <tr>
                <th className="mt10 textAlignCenter" colSpan="9">
                  {' '}
                  Nenhuma produção encontrada{' '}
                </th>
              </tr>
            ) : null}

            {!parameters?.engine?.isRunning ?
              <DesktopRows
                setCurrentCard={setCurrentCard}
                setAnchorEl={setAnchorEl}
                cards={state.cards}
                handleOpenExtract={handleOpenExtract}
                setManufacture={setManufacture}
                setUpdateValue={setUpdateValue}
                updateValue={updateValue}
                handleCardQuantity={handleCardQuantity}
                getDuration={getDuration}
                handleClickOpenModal={handleClickOpenModal}
                setLoading={setLoading}
                manufactures={manufactures}
                loading={loading}
                handleOpenItems={handleOpenItems}
                modalStates={modalStates}
                setModalStates={setModalStates}
                handleSubmitProduction={handleSubmitProduction}
                handleResetProduction={handleResetProduction}
                getId={getId}
                userInfo={userInfo}
                setSelectedCard={setSelectedCard}
                handleOpenRecipeItemsDiffPopover={(e, card, filteredItems) =>
                  handleOpenRecipeItemsDiffPopover(e, card, filteredItems)
                }
                handleOpenRecipeConsumeDiffPopover={(e, card) => {
                  handleOpenRecipeConsumeDiffPopover(e, card);
                }}
                statusCopilot={copilotStatus?.message}
              />
            : null}
          </tbody>
        </table>
      </Container>

      {modalStates?.modalDuration && (
        <Suspense fallback={<span />}>
          <DurationDialog
            openModal={modalStates}
            handleClose={() => {
              handleCloseModal();
            }}
            durationData={durationData}
            loading={durationDialogLoading}
            page="Production"
            selectedProduct={selectedProduct}
            setDurationData={setDurationData}
          />
        </Suspense>
      )}

      {/* Production control modal */}
      <Dialog
        open={controlProductionModal}
        onClose={() => setControlProductionModal(false)}
        aria-labelledby="responsive-dialog-title"
        className="defaultModal dialog100 bigModal mobile-dialog-control-production"
      >
        <DialogTitle
          className="modalHeader navyBlue bold cursorMove textAlignCenter"
          id="draggable-dialog-title"
        >
          <Typography className="mobile-title-dialog">
            {lastCountObj.filter((item) => !item.lastCount).length
              ? 'Controlar produção da ficha selecionada?'
              : null}
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setControlProductionModal(false);
            }}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              className="mobile-close-icon"
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Typography align="center" className="mb10 mobile-add-stock-text">
            {lastCountObj.filter((item) => !item.lastCount).length
              ? ''
              : 'Deseja habilitar o controle de produção da ficha selecionada?'}
          </Typography>

          {lastCountObj.filter((item) => !item.lastCount).length ? (
            <>
              <table
                className="desktop desktop-prduction-control mb10"
                width="100%"
                cellPadding="0"
                cellSpacing="0"
                border="0"
              >
                <thead>
                  <tr className="headerTitle">
                    <th width="25%" className="subtitle pr12">
                      &nbsp;
                    </th>
                    <th width="30%" className="subtitle">
                      LOCAIS DE ESTOQUE
                    </th>
                    <th width="30%" className="subtitle pl12">
                      CONTAGENS
                    </th>
                    <th width="13%" className="subtitle pl12">
                      QUANTIDADE
                    </th>
                    <th width="2%" className="subtitle">
                      &nbsp;
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {lastCountObj
                    .filter((item) => !item.lastCount)
                    .map((product, index) => (
                      <tr>
                        <td
                          id={`description${index}`}
                          className="description p12"
                        >
                          {product.description &&
                            ValidationLength(product.description, 35)}
                        </td>

                        <td
                          id={`storageLocations${index}`}
                          className="description pb10"
                        >
                          <Autocomplete
                            options={storageLocationOptions}
                            getOptionLabel={(option) => option.description}
                            className="autocompleteSmall"
                            multiple
                            size="small"
                            limitTags={1}
                            value={
                              product.storageLocations &&
                              storageLocationOptions.filter((item) =>
                                product.storageLocations.includes(item.id)
                              )
                            }
                            onChange={(event, value) => {
                              setLastCountObj(
                                lastCountObj.map((element) => {
                                  if (element.id === product.id) {
                                    return {
                                      ...element,
                                      storageLocations: value.map(
                                        (storageLocation) => storageLocation.id
                                      )
                                    };
                                  }
                                  return element;
                                })
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id={params.id}
                                type="text"
                                name="storageLocation"
                                variant="outlined"
                                required
                                placeholder="Selecione..."
                                InputLabelProps={{
                                  shrink: true
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password'
                                }}
                              />
                            )}
                            renderOption={(params) => (
                              <Typography
                                style={{
                                  fontSize: '12px'
                                }}
                              >
                                {params.description}
                              </Typography>
                            )}
                          />
                        </td>

                        <td
                          id={`periodicity${index}`}
                          className="description p12 pb10"
                        >
                          <Autocomplete
                            options={periodicityOptions}
                            getOptionLabel={(option) => option.description}
                            className="autocompleteSmall"
                            multiple
                            size="small"
                            limitTags={1}
                            value={
                              product.periodicities &&
                              periodicityOptions.filter((item) =>
                                product.periodicities.includes(item.id)
                              )
                            }
                            onChange={(event, value) => {
                              setLastCountObj(
                                lastCountObj.map((element) => {
                                  if (element.id === product.id) {
                                    return {
                                      ...element,
                                      periodicities: value.map(
                                        (periodicity) => periodicity.id
                                      )
                                    };
                                  }
                                  return element;
                                })
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id={params.id}
                                type="text"
                                name="periodicity"
                                variant="outlined"
                                required
                                placeholder="Selecione..."
                                InputLabelProps={{
                                  shrink: true
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password'
                                }}
                              />
                            )}
                            renderOption={(params) => (
                              <Typography
                                style={{
                                  fontSize: '12px'
                                }}
                              >
                                {params.description}
                              </Typography>
                            )}
                          />
                        </td>

                        <td
                          id={`quantity${index}`}
                          className="description p12 pb10"
                        >
                          <CurrencyTextField
                            fullWidth
                            variant="outlined"
                            size="small"
                            currencySymbol="R$"
                            minimumValue="0"
                            outputFormat="string"
                            decimalCharacter=","
                            digitGroupSeparator="."
                            decimalPlaces={3}
                            decimalPlacesShownOnFocus={3}
                            type="text"
                            name="realStockValue"
                            className="textFieldSmall right"
                            placeholder="Digite aqui..."
                            onChange={(e, value) => {
                              setLastCountObj(
                                lastCountObj.map((element) => {
                                  if (element.id === product.id) {
                                    return {
                                      ...element,
                                      realStock: value
                                    };
                                  }
                                  return element;
                                })
                              );
                            }}
                            defaultValue={product.realStock}
                            disabled={false}
                          />
                        </td>

                        <td id={`unit${index}`} className="description p12">
                          {product.un?.abbreviation}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>

              <div
                className="mobile-production-control mb10"
                width="100%"
                cellPadding="0"
                cellSpacing="0"
                border="0"
              >
                <div>
                  {lastCountObj
                    .filter((item) => !item.lastCount)
                    .map((product, index) => (
                      <div>
                        <div className="div-quantity-description">
                          <div
                            id={`description${index}`}
                            className="description mobile-title-product"
                          >
                            {product.description &&
                              ValidationLength(product.description, 35)}
                          </div>

                          <div
                            id={`quantity${index}`}
                            className="description mobile-quantity"
                          >
                            <h4 className="mobile-quantity-text">QUANTIDADE</h4>
                            <div className="div-mobile-quantity-number">
                              <CurrencyTextField
                                fullWidth
                                variant="outlined"
                                size="small"
                                currencySymbol="R$"
                                minimumValue="0"
                                outputFormat="string"
                                decimalCharacter=","
                                digitGroupSeparator="."
                                decimalPlaces={3}
                                decimalPlacesShownOnFocus={3}
                                type="text"
                                name="realStockValue"
                                className="textFieldSmall mobile-quantity-number right"
                                placeholder="Ex: 1.9"
                                onChange={(e, value) => {
                                  setLastCountObj(
                                    lastCountObj.map((element) => {
                                      if (element.id === product.id) {
                                        return {
                                          ...element,
                                          realStock: value
                                        };
                                      }
                                      return element;
                                    })
                                  );
                                }}
                                defaultValue={product.realStock}
                                disabled={false}
                              />
                              <div
                                id={`unit${index}`}
                                className="description mobile-abbreviation"
                              >
                                {product.un?.abbreviation}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div
                          id={`storageLocations${index}`}
                          className="description mobile-storage-location"
                        >
                          <label className="mobile-storage-location-text">
                            LOCAIS DE ESTOQUE
                          </label>
                          <Autocomplete
                            options={storageLocationOptions}
                            getOptionLabel={(option) => option.description}
                            className="autocompleteSmall"
                            multiple
                            size="small"
                            limitTags={1}
                            value={
                              product.storageLocations &&
                              storageLocationOptions.filter((item) =>
                                product.storageLocations.includes(item.id)
                              )
                            }
                            onChange={(event, value) => {
                              setLastCountObj(
                                lastCountObj.map((element) => {
                                  if (element.id === product.id) {
                                    return {
                                      ...element,
                                      storageLocations: value.map(
                                        (storageLocation) => storageLocation.id
                                      )
                                    };
                                  }
                                  return element;
                                })
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id={params.id}
                                type="text"
                                name="storageLocation"
                                variant="outlined"
                                required
                                placeholder="Selecione"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password'
                                }}
                              />
                            )}
                            renderOption={(params) => (
                              <Typography
                                style={{
                                  fontSize: '12px'
                                }}
                              >
                                {params.description}
                              </Typography>
                            )}
                          />
                        </div>

                        <div
                          id={`periodicity${index}`}
                          className="description mobile-periodicity"
                        >
                          <label className="mobile-periodicity-text">
                            CONTAGENS
                          </label>

                          <Autocomplete
                            options={periodicityOptions}
                            getOptionLabel={(option) => option.description}
                            className="autocompleteSmall"
                            multiple
                            size="small"
                            limitTags={1}
                            value={
                              product.periodicities &&
                              periodicityOptions.filter((item) =>
                                product.periodicities.includes(item.id)
                              )
                            }
                            onChange={(event, value) => {
                              setLastCountObj(
                                lastCountObj.map((element) => {
                                  if (element.id === product.id) {
                                    return {
                                      ...element,
                                      periodicities: value.map(
                                        (periodicity) => periodicity.id
                                      )
                                    };
                                  }
                                  return element;
                                })
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                id={params.id}
                                type="text"
                                name="periodicity"
                                variant="outlined"
                                required
                                placeholder="Selecione"
                                InputLabelProps={{
                                  shrink: true
                                }}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password'
                                }}
                              />
                            )}
                            renderOption={(params) => (
                              <Typography
                                style={{
                                  fontSize: '12px'
                                }}
                              >
                                {params.description}
                              </Typography>
                            )}
                          />
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </>
          ) : null}

          <CommonAlert
            open={lastCountErr}
            onClose={() => setLastCountErr(false)}
            messagePersonal="Por favor, preencha todos os campos"
          />
        </DialogContent>

        <DialogActions className="dFlex mobile-div-btn">
          <Button
            className="mobile-btn-no"
            design="outlined"
            onClick={() => setControlProductionModal(false)}
            label="Não"
            color="primary"
          />

          <Button
            autoFocus
            className="mobile-btn-yes"
            design="contained"
            label="Sim"
            onClick={() => handleControlProduction(newProduction)}
            color="primary"
          />
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={addConfirmModal}
        PaperComponent={PaperComponent}
        onClose={() => {
          setAddConfirmModal(false);
          setDiscardStock(false);
        }}
        aria-labelledby="draggable-dialog-title"
        className="defaultModal modal-stock"
      >
        <DialogTitle className="modalHeader navyBlue bold textAlignCenter cursorMove">
          <Typography>
            {discardStock ? 'Quanto deseja descartar dessa ficha?' : ''}
          </Typography>

          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setAddConfirmModal(false);
              setDiscardStock(false);
            }}
            aria-label="close"
            style={{ outline: '0', background: 'none', align: 'right' }}
          >
            <img
              style={{ width: '16px' }}
              src={CloseIconNavy}
              alt="CloseIconNavy"
            />
          </IconButton>
        </DialogTitle>

        <DialogContent className="modalContent">
          {!discardStock && (
            <>
              <span className="dBlock textAlignCenter w100">
                Esta ficha possui estoque suficiente de acordo com a previsão de
                vendas.
              </span>

              <span className="textAlignCenter w100 dBlock">
                O que deseja fazer?
              </span>
            </>
          )}

          {discardStock !== true ? null : (
            <div className="dFlex">
              <Grid
                item
                xs={4}
                md={4}
                sm={4}
                style={{ margin: '6px 20px 0 0' }}
              >
                <CurrencyTextField
                  variant="outlined"
                  size="small"
                  currencySymbol=""
                  minimumValue="0"
                  maximumValue="999999999"
                  outputFormat="string"
                  margin="dense"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  decimalPlaces={3}
                  decimalPlacesShownOnFocus={3}
                  placeholder="Digite aqui..."
                  label="Qtd."
                  type="text"
                  name="quantity"
                  fullWidth
                  className="textFieldSmall"
                  value={quantityStockDiscard}
                  InputProps={{
                    maxLength: 8,
                    classes: { input: classes.input }
                  }}
                  onChange={(_, value) => {
                    setQuantityStockDiscard(value);
                  }}
                />
              </Grid>

              <Grid item xs={4} md={4} sm={4} style={{ marginTop: '13.5px' }}>
                <Autocomplete
                  classes={{
                    inputRoot: classes.inputRoot,
                    input: classes.input,
                    option: classes.option
                  }}
                  size="small"
                  onChange={(value) => {
                    setCurrentDiscardReason(value);
                  }}
                  getOptionLabel={(option) => option.description}
                  options={optionsDiscard}
                  value={
                    optionsDiscard.filter(
                      (option) => option.description === currentDiscardReason
                    )[0]
                  }
                  className="autocompleteSmall"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id={params.id}
                      type="text"
                      name="discardType"
                      label="Tipo de descarte"
                      variant="outlined"
                      required
                      placeholder="Selecione..."
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  )}
                  renderOption={(params) => (
                    <Typography
                      style={{
                        fontSize: '12px',
                        fontFamily: 'Lato'
                      }}
                    >
                      {params.description}
                    </Typography>
                  )}
                />
              </Grid>
            </div>
          )}

          <DialogActions className="justifyCenter p0 mt20">
            <Grid
              container
              xs={12}
              md={12}
              sm={12}
              justify="center"
              spacing={1}
            >
              {discardStock === true ? null : (
                <Grid item xs={6} md={6} sm={6} className="pl0">
                  <Button
                    style={{ width: '100%', fontSize: '12px' }}
                    className="defaultButton submitButton"
                    onClick={(e) => {
                      handleAddManufacture(newProduction, 'additional');
                      setAddConfirmModal(false);
                    }}
                    design="contained"
                    label="Produção Adicional"
                    color="primary"
                  />
                </Grid>
              )}

              {discardStock === true ? null : (
                <Grid item xs={6} md={6} sm={6} className="pr0">
                  <Button
                    autoFocus
                    style={{ width: '100%', fontSize: '12px' }}
                    className="defaultButton backButton"
                    onClick={() => {
                      setDiscardStock(true);
                    }}
                    label="Perda de Estoque"
                    color="primary"
                  />
                </Grid>
              )}

              {discardStock !== true ? null : (
                <Grid item xs={1} md={1} sm={1}>
                  <Button
                    style={{ width: '100%', fontSize: '12px' }}
                    className="defaultButton submitButton"
                    onClick={() => {
                      handleDiscardManufacture(newProduction);
                      handleAddManufacture(newProduction, 'stockLoss');
                      setAddConfirmModal(false);
                      setLoading(true);
                      setDiscardStock(false);
                    }}
                    label="Lançar"
                    color="primary"
                  />
                </Grid>
              )}
            </Grid>
          </DialogActions>
        </DialogContent>
      </Dialog>

      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={modalStates.modalInvalidQuantity}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        className="defaultModal bigModal invalidQuantityDialog w100"
        transitionDuration={0}
      >
        <DialogContent className="modalContent dFlex alignCenter justifyCenter flexColumn">
          <Typography
            className="mb10"
            style={{ fontSize: 16, color: '#010311' }}
          >
            VALOR INFORMADO INVÁLIDO!
          </Typography>

          <Typography
            className="mb20 dFlex flexColumn alignCenter justifyCenter"
            style={{ fontSize: 16, color: '#454A67' }}
          >
            <span className="textAlignCenter mb10">
              O VALOR INFORMADO RESULTA PROPORCIONALMENTE NO VALOR ZERADO DE UM
              DOS ITENS.
            </span>
            INFORME UM VALOR DIFERENTE
          </Typography>

          <Button
            autoFocus
            className="defaultButton submitButton"
            design="contained"
            label="OK"
            onClick={() => {
              handleCloseModal();
            }}
            color="primary"
          />
        </DialogContent>
      </Dialog>

      <Dialog
        style={{
          color: '#FFFFFF'
        }}
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="responsive-dialog- title"
        transitionDuration={0}
      >
        <DialogContent className="modalContent">
          <Grid 
            container
            xs={12}
            spacing={1}
            style={{ margin: 0, borderRadius: '8px', padding: '10px' }}
          >
            <Typography
              className='w100 textAlignCenter'
              style={{ 
                fontWeight: 400,
                fontSize: '20px',
                lineHeight: '24px',
                color: '#333541',
                fontFamily: 'Lato' 
              }}
            >
              Deseja realmente encerrar a programação?
            </Typography>
          </Grid>

          <Grid 
            container
            xs={12}
            spacing={1}
            style={{ borderRadius: '8px', padding: '10px' }}
          >
            <Typography
              className='w100 textAlignCenter'
              style={{ 
                fontWeight: 400,
                fontSize: '20px',
                lineHeight: '24px',
                color: '#5062F0',
              }}
              align='center'
            >
            {courtFilterValue?.description
                ? `Praça selecionada: ${courtFilterValue?.description}`
                : 'Praça selecionada: TODAS'}
            </Typography>
          </Grid>

          <Grid 
            container
            xs={12}
            spacing={1}
            style={{ margin: 0, borderRadius: '8px', padding: '10px' }}
          >
            <Typography
            className='w100 textAlignCenter italic'
            style={{ 
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '20px',
              color: '#1F2445',
              fontFamily: 'Lato' 
            }}
            >
              Esta ação não pode ser desfeita
            </Typography>
          </Grid>
        </DialogContent>

        <DialogActions className="justifyCenter">
          <Button
            className="defaultButton backButton"
            design="outlined"
            onClick={handleDialogClose}
            label="Cancelar"
            color="primary"
          />
          <Button
            className="defaultButton submitButton"
            autoFocus
            design="contained"
            label="Encerrar"
            onClick={() => closeProgramming(courtFilterValue?.description)}
            color="primary"
          />
        </DialogActions>
      </Dialog>

      {modalStates.modalProduct && (
        <Suspense fallback={<span />}>
          <TheoryStockDialog
            getId={getId}
            openModal={modalStates}
            checkConsume={checkConsume}
            setCheckConsume={setCheckConsume}
            handleClose={handleCloseModal}
            productExtractDescription={productDescription}
            productAge={null}
            productModal={productModal}
            setProductModal={setProductModal}
            parameters={parameters?.product?.type}
            originPage="productPage"
          />
        </Suspense>
      )}

      {recipeItemsDiffPopoverOpen && (
        <RecipeItemsDiffQuantities
          anchorEl={recipeItemsDiffAnchorEl}
          popoverOpen={recipeItemsDiffPopoverOpen}
          handleClosePopover={handleCloseRecipeItemsDiffPopover}
          selectedCard={selectedCard}
          loading={false}
          onSubmit={(e) => {
            const isComplementary =
              selectedCard?.type === 'RECIPE'
                ? selectedCard?.isComplementary
                : selectedCard?.items?.some((el) => el?.isComplementary);

            if (
              isComplementary &&
              selectedCard?.engine?.yield &&
              parseFloat(selectedCard?.engine?.yield?.toFixed(3)) !==
                parseFloat(selectedCard?.yield?.toFixed(3))
            ) {
              handleCloseRecipeItemsDiffPopover();
              handleOpenRecipeConsumeDiffPopover(
                recipeItemsDiffAnchorEl,
                selectedCard
              );
            } else {
              handleSubmitProduction(selectedCard, 'TO_PRODUCE');
            }
          }}
        />
      )}

      {recipeConsumeDiffPopoverOpen && (
        <RecipeConsumeDiffQuantities
          anchorEl={recipeConsumeDiffAnchorEl}
          popoverOpen={recipeConsumeDiffPopoverOpen}
          handleClosePopover={handleCloseRecipeConsumeDiffPopover}
          selectedCard={selectedCard}
          loading={false}
          onSubmit={(e) => {
            handleSubmitProduction(selectedCard, 'TO_PRODUCE');
          }}
        />
      )}

      {modalStates?.modalDiffQuantities && (
        <DiffQuantities
          open={modalStates?.modalDiffQuantities}
          handleCloseModal={() => {
            setModalStates({
              ...modalStates,
              modalDiffQuantities: false
            });
            setSelectedCard();
          }}
          selectedCard={selectedCard}
        />
      )}

      {modalStates?.modalConsumeQuantities && (
        <ConsumeQuantities
          open={modalStates?.modalConsumeQuantities}
          handleCloseModal={() => {
            setModalStates({
              ...modalStates,
              modalConsumeQuantities: false
            });
            setSelectedCard();
          }}
          selectedCard={selectedCard}
        />
      )}

      <CommonAlert
        open={alertErr}
        autoHideDuration={2000}
        onClose={() => {
          setAlertErr(false);
        }}
        indexMessage={alertMessage}
        messagePersonal={alertMessage}
        statusCode={statusCode}
      />

      <Popover
        open={popoverOpen}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setCurrentCard('')
        }}
        PaperProps={{
          style: { padding: '10px', borderRadius: '4px', maxWidth: '400px', marginLeft: 60 }
        }}
        className="copilotQuantityOrYieldPopover"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className="dFlex alignStart justifyStart flexColumn">
          <div className="dFlex flexColumn popoverCardInfo">
            <span className="fontSizeDefault bold">
              {currentCard?.description}
            </span>

            <span className="fontSizeDefault">
              1 Receita rende {" "}
              {(currentCard?.yield / currentCard?.quantity)?.toLocaleString('pt-br', {
                minimumFractionDigits: 3,
                maximunFractionDigits: 3
              })} {" "}
              {currentCard?.un?.abbreviation}
            </span>
          </div>

          <div className="dFlex flexColumn popoverCardActions">
            <FormControl className="dFlex flexRow ml5">
              <FormControlLabel
                value={2}
                control={
                  <Radio
                    onChange={() => {
                      handleUpdateViewMethod(2)
                    }}
                    checked={currentCard?.viewMethod === 2}
                    size="small"
                  />
                }
                label={
                  <span className="fontSizeDefault bold">
                    Rendimento
                  </span>
                }
              />
              <FormControlLabel
                value={1}
                control={
                  <Radio
                    onChange={() => {
                      handleUpdateViewMethod(1)
                    }}
                    checked={currentCard?.viewMethod === 1}
                    size="small"
                  />
                }
                label={
                  <span className="fontSizeDefault bold">
                    Quantidade
                  </span>
                }
              />
            </FormControl>

            <div className="popoverCardValues">
              <span className="fontSizeDefault bold lightBlue">
                {currentCard?.viewMethod === 2 ?
                  `${currentCard?.yield?.toLocaleString('pt-br', {
                    minimumFractionDigits: 3,
                    maximunFractionDigits: 3
                  })}
                  ${currentCard?.un?.abbreviation} = 
                  ${currentCard?.quantity?.toLocaleString('pt-br', {
                    minimumFractionDigits: 3,
                    maximunFractionDigits: 3
                  })} receitas`
                :
                  `${currentCard?.quantity?.toLocaleString('pt-br', {
                    minimumFractionDigits: 3,
                    maximunFractionDigits: 3
                  })} Receitas = 
                  ${currentCard?.yield?.toLocaleString('pt-br', {
                    minimumFractionDigits: 3,
                    maximunFractionDigits: 3
                  })} 
                  ${currentCard?.un?.abbreviation}`
                }
              </span>
            </div>
          </div>
        </div>
      </Popover>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(UserActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Copilot);
