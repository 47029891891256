export default function Environment(environment) {
  const local = 'http://localhost';
  const qa = 'https://qa.bloom.group';
  const apiGateway = "https://api-gw.bloom.group"

  switch (environment.toLowerCase()) {
    case 'local':
      return {
        auth: `${local}:3001/api/v1`,
        catalog: `${local}:3000/api/v1`,
        orders: `${local}:3002/api/v1`,
        providers: `${local}:3009/api/v1`,
        restaurants: `${local}:3008/api/v1`,
        sales: `${local}:3003/api/v1`,
        stocks: `${local}:3004/api/v1`,
        manufactures: `${local}:3005/api/v1`,
        manufacturesV2: `${local}/manufactures/api/v2`,
        engine: `${local}:3006/api/v1`,
        dashBoard: `${local}:3007/api/v1`,
        dashBoardV2: `${local}:3007/api/v2`,
        dashBoardV3: `${local}:3007/api/v3`,
        catalogV2: `${local}:3000/api/v2`,
        providersV2: `${local}:3009/api/v2`,
        crawler: `${local}:3030/api/v1`,
        integration: `${local}:3012/api/v1`
      };
    case 'development':
      return {
        auth: `${qa}/auth/api/v1`,
        catalog: `${qa}/catalog/api/v1`,
        orders: `${qa}/orders/api/v1`,
        providers: `${qa}/providers/api/v1`,
        restaurants: `${qa}/restaurants/api/v1`,
        sales: `${qa}/sales/api/v1`,
        stocks: `${qa}/stocks/api/v1`,
        manufactures: `${qa}/manufactures/api/v1`,
        manufacturesV2: `${qa}/manufactures/api/v2`,
        engine: `${qa}/engine/api/v1`,
        dashBoard: `${qa}/dashboard/api/v1`,
        dashBoardV2: `${qa}/dashboard/api/v2`,
        dashBoardV3: `${qa}/dashboard/api/v3`,
        catalogV2: `${qa}/catalog/api/v2`,
        providersV2: `${qa}/providers/api/v2`,
        crawler: `${qa}/crawler/api/v1`,
        integration: `${qa}/integration/api/v1`,
        integrationSefaz: `${qa}/integration/sefaz/api/v1`,
        worker: `${qa}/worker/api/v1`
      };
    case 'production':
      return {
        auth: `${apiGateway}/auth/api/v1`,
        catalog: `${apiGateway}/catalog/api/v1`,
        keycloak: `${apiGateway}/auth/api/v1`,
        orders: `${apiGateway}/orders/api/v1`,
        providers: `${apiGateway}/providers/api/v1`,
        restaurants: `${apiGateway}/restaurants/api/v1`,
        sales: `${apiGateway}/sales/api/v1`,
        stocks: `${apiGateway}/api/v1`,
        manufactures: `${apiGateway}/manufactures/api/v1`,
        manufacturesV2: `${apiGateway}/manufactures/api/v2`,
        engine: `${apiGateway}/engine/api/v1`,
        dashBoard: `${apiGateway}/dashboard/api/v1`,
        dashBoardV2: `${apiGateway}/dashboard/api/v2`,
        dashBoardV3: `${apiGateway}/dashboard/api/v3`,
        catalogV2: `${apiGateway}/catalog/api/v2`,
        providersV2: `${apiGateway}/providers/api/v2`,
        integration: `${apiGateway}/integration/api/v1`,
        crawler: `${apiGateway}/crawler/api/v1`,
        integrationSefaz: `${apiGateway}/sefaz/api/v1`,
        worker: `${apiGateway}/worker/api/v1`
      }
    default:
      return 'This environment does not exist.';
  }
}