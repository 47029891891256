/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';

import 'shineout/dist/theme.antd.css';

import {
  Checkbox,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import CurrencyTextField from '@unicef/material-ui-currency-textfield/dist/CurrencyTextField';
import axios from 'axios';
import countries from 'data/country';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from 'components/Button/button';
import CommonAlert from 'components/CommonAlert/CommonAlert';
import ConfirmModal from 'components/Dialogs/ConfirmModal/ConfirmModal';
import typesAddress from 'data/addressTypes.json';
import AlertIcon from 'images/icons/signals/alerts/yellowAlertRounded.svg';
import CloseIconNavy from 'images/icons/signals/closesAndCancels/closeTransparent.svg';
import UserForm from 'pages/admin/users/UserForm';
import * as ProvidersActions from 'store/actions/fornecedores';
import { Container } from 'styles/general';
import Environment from 'utils/environments';

import StepView from './components/StepView';
import CategoriesInfosDialog from './dialogs/CategoriesInfosDialog';
import SelectCloneStoreDialog from './dialogs/SelectCloneStoreDialog';
import SelectGroupDialog from './dialogs/SelectGroupDialog';
import SelectUserDialog from './dialogs/SelectUserDialog';

function StoreForm({
  providerInfo,
  segmentOptions,
  redirectPath,
  isModal,
  onClose,
  setUpdateList,
  createMode,
  skuModal,
  dialogProvidersSettings,
  multipleSkuData,
  setMultipleSkuData,
  isMultipleSku,
  selectedItems,
  setSelectedItems,
  dataTable,
  userInfo,
  editRestaurant
}) {
  const { t } = useTranslation('translations');
  const userLocal = JSON.parse(localStorage.getItem('companies'));
  const [loading, setLoading] = useState(false);
  const [invalidCnpjMessage, setInvalidCnpjMessage] = useState();
  const [dataInfo, setDataInfo] = useState({
    cnpjNumber: dataTable ? dataTable.cnpjNumber : '',
    webSite: dataTable ? dataTable.webSite : '',
    companyName: dataTable ? dataTable.companyName : '',
    fantasyName: dataTable ? dataTable.fantasyName : '',
    description: dataTable ? dataTable.description : '',
    telephoneNumber: dataTable ? dataTable.telephoneNumber : ''
  });

  const [dataAddress, setDataAddress] = useState({
    city: dataTable?.address?.length ? dataTable?.address[0].city : '',
    complement: dataTable?.address?.length
      ? dataTable?.address[0].complement
      : '',
    number: dataTable?.address?.length ? dataTable?.address[0].number : '',
    states: dataTable?.address?.length ? dataTable?.address[0].states : '',
    street: dataTable?.address?.length ? dataTable?.address[0].street : '',
    zipCode: dataTable?.address?.length ? dataTable?.address[0].zipCode : ''
  });

  const [companyResponsible, setCompanyResponsible] = useState({
    emailAddress: dataTable?.companyResponsible?.length
      ? dataTable.companyResponsible[0].emailAddress
      : '',
    fullName: dataTable?.companyResponsible?.length
      ? dataTable.companyResponsible[0].fullName
      : '',
    telephoneNumber: dataTable?.companyResponsible?.length
      ? dataTable.companyResponsible[0].telephoneNumber
      : ''
  });

  const [additionalInformation, setAddtionalInformation] = useState({
    averageBillingMonthly: dataTable?.additionalInformation?.length
      ? dataTable.additionalInformation[0].averageBillingMonthly
      : '',
    averageTicket: dataTable?.additionalInformation?.length
      ? dataTable.additionalInformation[0].averageTicket
      : '',
    numberOrdersDays: dataTable?.additionalInformation?.length
      ? dataTable.additionalInformation[0].numberOrdersDays
      : '',
    orderVolume: dataTable?.additionalInformation?.length
      ? dataTable.additionalInformation[0].orderVolume
      : ''
  });

  const [invalidDocNumber, setInvalidDocNumber] = useState(false);
  const { handleSubmit, register, control, errors } = useForm();
  const [messageError, setMessageError] = useState('');
  const [selectedGroupObj, setSelectedGroupObj] = useState('');
  const [selectedCloneStoreObj, setSelectedCloneStoreObj] = useState('');
  const [selectedStoreUserObj, setSelectedStoreUserObj] = useState({
    users: []
  });
  const [selectedCategoriesInfosObj, setSelectedCategoriesInfosObj] =
    useState('');
  const [message, setMessage] = useState({
    description: '',
    status: ''
  });
  const [alertErr, setAlertErr] = useState(false);
  const [editMode, setEditMode] = useState(
    createMode !== true
      ? providerInfo.action
        ? providerInfo.action.toLowerCase() === 'edit'
        : false
      : false
  );
  const [typeAddress, setTypeAddress] = useState({
    description: dataTable?.address?.length
      ? dataTable?.address[0].typeAddress
      : ''
  });
  const [invalidFields, setInvalidFields] = useState({
    cnpj: false,
    telephoneNumber: false,
    companyName: false,
    fantasyName: false,
    description: false,
    zipCode: false,
    street: false,
    number: false,
    city: false,
    states: false,
    fullName: false,
    email: false,
    responsibleNumber: false,
    averageBillingMonthly: false,
    numberOrdersDays: false,
    averageTicket: false,
    orderVolume: false
  });
  const [segment, setSegment] = useState(
    segmentOptions?.find((el) => el?.description === dataTable?.segment) || ''
  );
  const [delivery, setDelivery] = useState(
    dataTable ? dataTable.delivery : false
  );
  const [country, setCountry] = useState({
    name: dataTable?.address?.length ? dataTable?.address[0].country : 'Brasil',
    code: 'BR',
    phone_code: '+55'
  });
  const [typeDocumentNumber, setTypeDocumentNumber] = useState(
    editMode ? providerInfo.data.typeDocumentNumber : 'CNPJ'
  );

  const [modalStatesObj, setModalStatesObj] = useState({
    willBeGroupStoreConfirmation: {
      open: false,
      productGroupId: ''
    },
    selectGroupStore: {
      open: false,
      productGroupId: ''
    },
    willCloneStoreInfosConfirmation: {
      open: false,
      productGroupId: ''
    },
    selectCloneStoreInfos: {
      open: false,
      productGroupId: ''
    },
    willUseCategoriesConfirmation: {
      open: false,
      productGroupId: ''
    },
    categoriesInfos: {
      open: false,
      productGroupId: ''
    },
    selectStoreUsers: {
      open: false,
      productGroupId: ''
    },
    createUser: {
      open: false,
      openedByUserSelected: false,
      productGroupId: ''
    },
    cancelStoreCreation: {
      open: false,
      productGroupId: ''
    }
  });

  const environments = Environment(process.env.REACT_APP_ENV);

  const translateProfile = (profileType) => {
    let translatedProfile = '';

    switch (profileType) {
      case 'restaurants':
        translatedProfile = 'Admin Restaurante';
        break;
      case 'admin':
        translatedProfile = 'Admin Plataforma';
        break;
      case 'managers':
        translatedProfile = 'Gestor';
        break;
      case 'providers':
        translatedProfile = 'Fornecedor';
        break;
      case 'stockists':
        translatedProfile = 'Estoquista';
        break;
      case 'producers':
        translatedProfile = 'Produção';
        break;
      case 'buyers':
        translatedProfile = 'Comprador';
        break;
      case 'quoter':
        translatedProfile = 'Cotador';
        break;
      case 'cataloguer':
        translatedProfile = 'Catalogador';
        break;
      case 'nfes':
        translatedProfile = 'NFE';
        break;
      case 'quoterList':
        translatedProfile = 'Cotador Listagem';
        break;
      case 'manufactures':
        translatedProfile = 'Cozinha';
        break;
      default:
        break;
    }

    return translatedProfile;
  };

  const config = {
    headers: {
      User: `${userLocal?.user}`
    }
  };

  const validFields = () => {
    const isAllValid = {
      cnpj: !dataInfo.cnpjNumber,
      telephoneNumber: !dataInfo.telephoneNumber,
      companyName: !dataInfo.companyName,
      fantasyName: !dataInfo.fantasyName,
      zipCode: !dataAddress.zipCode,
      street: !dataAddress.street,
      number: !dataAddress.number,
      city: !dataAddress.city,
      states: !dataAddress.states,
      fullName: !companyResponsible.fullName,
      email: !companyResponsible.emailAddress,
      responsibleNumber: !companyResponsible.telephoneNumber,
      averageBillingMonthly: !additionalInformation.averageBillingMonthly,
      numberOrdersDays: !additionalInformation.numberOrdersDays,
      averageTicket: !additionalInformation.averageTicket,
      orderVolume: !additionalInformation.orderVolume
    };

    setInvalidFields(isAllValid);

    return (
      !isAllValid?.cnpj &&
      !isAllValid?.telephoneNumber &&
      !isAllValid?.companyName &&
      !isAllValid?.fantasyName &&
      !isAllValid?.zipCode &&
      !isAllValid?.street &&
      !isAllValid?.number &&
      !isAllValid?.city &&
      !isAllValid?.states &&
      !isAllValid?.fullName &&
      !isAllValid?.email &&
      !isAllValid?.responsibleNumber &&
      !isAllValid?.averageBillingMonthly &&
      !isAllValid?.numberOrdersDays &&
      !isAllValid?.averageTicket &&
      !isAllValid?.orderVolume
    );
  };

  const updateSkuModalObject = (obj) => {
    if (isMultipleSku) {
      setMultipleSkuData(
        multipleSkuData.map((sku, i) => {
          if (dialogProvidersSettings.currentSku === i) {
            return {
              ...sku,
              providerToAssociate: obj,
              itemToAssociate: {
                ...sku?.itemToAssociate,
                providerId: obj.providerId,
                name: obj.providerName
              }
            };
          }
          return sku;
        })
      );
    } else {
      setSelectedItems({
        ...selectedItems,
        provider: obj,
        item: {
          ...selectedItems.item,
          providerId: obj.providerId,
          name: obj.providerName
        }
      });
    }
  };

  const onSubmitProvider = (fakeSubmit) => {
    if (!validFields()) return;

    if (fakeSubmit) {
      setModalStatesObj({
        ...modalStatesObj,
        willBeGroupStoreConfirmation: {
          open: true
        }
      });
      return;
    }

    setTimeout(() => {
      const body = {
        type: 'Store',
        clone: selectedCloneStoreObj?.store
          ? {
              restaurantId: selectedCloneStoreObj?.store?.storeId
            }
          : null,
        category: selectedCategoriesInfosObj?.id
          ? {
              segmentId: selectedCategoriesInfosObj?.id
            }
          : null,
        users: selectedStoreUserObj?.users?.length
          ? selectedStoreUserObj?.users?.map((el) => el?._id)
          : [],
        group: selectedGroupObj?.group
          ? {
              groupId: selectedGroupObj?.group?._id,
              subGroupId: selectedGroupObj?.subgroup?.subgroupId
            }
          : null,
        cnpjNumber: dataInfo.cnpjNumber,
        companyName: dataInfo.companyName,
        fantasyName: dataInfo.fantasyName,
        telephoneNumber: dataInfo.telephoneNumber,
        segment: segment?.description,
        delivery,
        description: dataInfo.description,
        webSite: dataInfo.webSite,
        address: [
          {
            zipCode: dataAddress.zipCode,
            states: dataAddress.states,
            city: dataAddress.city,
            typeAddress: typeAddress ? typeAddress.description : 'Cobrança',
            street: dataAddress.street,
            number: dataAddress.number,
            complement: dataAddress.complement,
            country: country.name
          }
        ],
        companyResponsible: [
          {
            fullName: companyResponsible.fullName,
            telephoneNumber: companyResponsible.telephoneNumber,
            emailAddress: companyResponsible.emailAddress
          }
        ],
        additionalInformation: [
          {
            averageBillingMonthly:
              typeof additionalInformation?.averageBillingMonthly === 'string'
                ? parseFloat(
                    additionalInformation?.averageBillingMonthly
                      ?.replace(/\./g, '')
                      ?.replace(',', '.')
                  )
                : parseInt(additionalInformation?.averageBillingMonthly),
            numberOrdersDays: parseInt(additionalInformation.numberOrdersDays),
            averageTicket:
              typeof additionalInformation?.averageTicket === 'string'
                ? parseFloat(
                    additionalInformation?.averageTicket
                      ?.replace(/\./g, '')
                      ?.replace(',', '.')
                  )
                : parseInt(additionalInformation?.averageTicket),
            orderVolume: parseInt(additionalInformation.orderVolume)
          }
        ],
        user: userInfo?.user
      };

      if (dataTable) {
        setLoading(true);
        axios
          .put(
            `${environments.restaurants}/companies/${dataTable.companyId}`,
            body,
            config
          )
          .then(() => {
            setMessage({
              description: 'Loja atualizada com sucesso!',
              status: 'success'
            });
            setLoading(false);
            setUpdateList && setUpdateList(true);
            onClose &&
              setTimeout(() => {
                onClose();
                setDataInfo()
            setDataAddress()
            setCompanyResponsible()
            setAddtionalInformation()
              }, 1000);
          })
          .catch((error) => {
            if (error.response.data.message) {
              setMessageError(error.response.data.message);
            } else {
              setMessageError('common.ErrorToRecord');
            }
            setLoading(false);
            setAlertErr(true);
            setUpdateList && setUpdateList(false);
          });
      } else {
        setLoading(true);
        axios
          .post(`${environments.restaurants}/companies`, body, config)
          .then((res) => {
            setLoading(false);
            setModalStatesObj({
              ...modalStatesObj,
              selectStoreUsers: {
                open: false
              },
              willBeGroupStoreConfirmation: {
                open: false
              },
              selectGroupStore: {
                open: false
              },
              willCloneStoreInfosConfirmation: {
                open: false
              },
              selectCloneStoreInfos: {
                open: false
              },
              willUseCategoriesConfirmation: {
                open: false
              },
              categoriesInfos: {
                open: false
              },
              createUser: {
                open: false,
                openedByUserSelected: false
              },
              cancelStoreCreation: {
                open: false
              }
            });

            setSelectedStoreUserObj(null);
            setSelectedGroupObj(null);
            setSelectedCloneStoreObj(null);
            setSelectedCategoriesInfosObj(null);
            setMessage({
              description: body?.clone?.restaurantId
                ? 'Loja criada com sucesso! Processo de clone de restaurante em andamento...'
                : 'Loja criada com sucesso!',
              status: 'success'
            });
            skuModal && updateSkuModalObject(res.data);
            setUpdateList && setUpdateList(true);
            onClose &&
              setTimeout(() => {
                onClose();
                setDataInfo()
                setDataAddress()
                setCompanyResponsible()
                setAddtionalInformation()
              }, 1000);
          })
          .catch((error) => {
            setLoading(false);
            if (error.response.data.message) {
              setMessageError(error.response.data.message);
            } else {
              setMessageError('common.ErrorToRecord');
            }
            setAlertErr(true);
            setUpdateList && setUpdateList(false);
          });
      }
    }, 400);
  };

  const validateCnpjNumber = () => {
    const formattedCnpj = dataInfo?.cnpjNumber?.replace(/\D+/g, '');

    axios
      .get(
        `${environments.restaurants}/companies/validateCompanyInfos/${formattedCnpj}`
      )
      .then(() => {
        setInvalidCnpjMessage();
      })
      .catch((error) => {
        const currentMessage = t(error?.response?.data?.message);

        setInvalidCnpjMessage(currentMessage);
      });
  };

  function setLabelDocumentNumber() {
    return typeDocumentNumber;
  }

  const handleCheckboxChange = (check) => {
    setDelivery(check);
  };

  useEffect(() => {
    const handleKeyPress = (e) => {
      if (isModal && e.key === 'Escape') {
        onClose();
        setDataInfo()
        setDataAddress()
        setCompanyResponsible()
        setAddtionalInformation()
      }
    };

    window.addEventListener('keydown', handleKeyPress);

    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [isModal, onClose]);

  if (!isModal) {
    return null;
  }

  return (
    <div className="defaultModal">
      <Container className={`${!isModal && 'p0 mt20'} registerStoreDialog`}>
        {isModal && (
          <DialogTitle
            className="modalHeader bold textAlignCenter cursorMove"
            style={{
              borderRadius: '4px 4px 0 0'
            }}
            id="draggable-dialog-title"
          >
            <Typography>CADASTRO DE LOJAS</Typography>

            <div style={{ marginLeft: 'auto', marginRight: 30 }}>
              <StepView currentStep="1" />
            </div>

            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                onClose();
                setDataInfo()
            setDataAddress()
            setCompanyResponsible()
            setAddtionalInformation()
              }}
              aria-label="close"
              style={{ outline: '0', background: 'none', align: 'right' }}
            >
              <img
                style={{ width: '16px' }}
                src={CloseIconNavy}
                alt="CloseIconNavy"
              />
            </IconButton>
          </DialogTitle>
        )}

        <CommonAlert
          open={alertErr}
          onClose={() => setAlertErr(false)}
          indexMessage={messageError}
          width="50%"
        />

        <CommonAlert
          open={message?.description}
          onClose={() => {
            setMessage(null);
          }}
          messagePersonal={message?.description}
          severity={message?.status}
          width="50%"
        />

        {loading ? <LinearProgress /> : null}

        <div
          className="cardDefault pt16 mainCardBox"
          style={{ borderRadius: isModal && '0' }}
        >
          <form
            onSubmit={handleSubmit(onSubmitProvider)}
            className="providerForm"
          >
            <div className="cardDefault pt10 pb10 pl10 pr10">
              <Grid container spacing={4} className="mt0 mb0">
                <Grid
                  item
                  xs={2}
                  sm={2}
                  className={`pr10 ${invalidFields?.cnpj && 'labelError'}`}
                >
                  <TextField
                    label={setLabelDocumentNumber()}
                    name="cnpjNumber"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      maxLength: 18
                    }}
                    onInput={(e) => {
                      setDataInfo({ ...dataInfo, cnpjNumber: e.target.value });
                    }}
                    onBlur={(e) => {
                      if (!e?.target?.value) return null
                      
                      validateCnpjNumber();
                    }}
                    inputRef={register({ required: true })}
                    error={!!errors.cnpjNumber}
                    helperText={errors?.cnpjNumber ? '' : ''}
                    size="small"
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={dataInfo.cnpjNumber}
                  />

                  {invalidCnpjMessage ? (
                    <span className="error mt5 dBlock">
                      {invalidCnpjMessage}
                    </span>
                  ) : null}
                </Grid>

                <Grid
                  item
                  xs={5}
                  sm={5}
                  className={`pr10 pl10 ${
                    invalidFields?.companyName && 'labelError'
                  }`}
                >
                  <TextField
                    label="Razão Social"
                    name="providerName"
                    fullWidth
                    variant="outlined"
                    required
                    inputRef={register({ required: true })}
                    error={!!errors.providerName}
                    helperText={errors?.providerName ? '' : ''}
                    inputProps={{ maxLength: 70 }}
                    defaultValue={dataInfo.companyName}
                    value={dataInfo.companyName}
                    onInput={(e) =>
                      setDataInfo({ ...dataInfo, companyName: e.target.value })
                    }
                    size="small"
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={5}
                  sm={5}
                  className={`pl10 ${
                    invalidFields?.fantasyName && 'labelError'
                  }`}
                >
                  <TextField
                    label="Nome Fantasia"
                    name="fantasyName"
                    fullWidth
                    variant="outlined"
                    required
                    inputRef={register({ required: true })}
                    error={!!errors.fantasyName}
                    helperText={errors?.fantasyName ? '' : ''}
                    defaultValue={dataInfo.fantasyName}
                    value={dataInfo.fantasyName}
                    onInput={(e) =>
                      setDataInfo({ ...dataInfo, fantasyName: e.target.value })
                    }
                    inputProps={{ maxLength: 70 }}
                    size="small"
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={4} className="mt0 mb0">
                <Grid item xs={2} sm={2} className="pb0 pr10">
                  <Autocomplete
                    id="asynchronous-demo"
                    getOptionLabel={(option) => option.description}
                    getOptionSelected={(option, value) =>
                      option.description === value.description
                    }
                    options={segmentOptions}
                    value={segment}
                    required={editMode !== true}
                    onChange={(_, value) => {
                      setSegment(value);
                    }}
                    disabled={editMode}
                    className="autocompleteSmall"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Segmento"
                        variant="outlined"
                        name="segment"
                        size="small"
                        type="text"
                        value={segment}
                        required={editMode !== true}
                        placeholder="Selecione..."
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                    renderOption={(params) => (
                      <Typography
                        style={{ fontSize: '12px', fontFamily: 'Lato' }}
                      >
                        {params.description}
                      </Typography>
                    )}
                  />
                </Grid>

                <Grid item xs={5} sm={5} className="pb0 pl10 pr10">
                  <TextField
                    label="Site"
                    name="webSite"
                    fullWidth
                    variant="outlined"
                    defaultValue={dataInfo.webSite}
                    onInput={(e) => {
                      setDataInfo({ ...dataInfo, webSite: e.target.value });
                    }}
                    inputRef={register}
                    size="small"
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                    value={dataInfo.webSite}
                  />
                </Grid>

                <Grid
                  item
                  xs={2}
                  sm={2}
                  className={`pb0 pl10 pr10 ${
                    invalidFields?.telephoneNumber && 'labelError'
                  }`}
                >
                  <TextField
                    label="Telefone"
                    name="telephoneNumber"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      maxLength: 14
                    }}
                    value={dataInfo.telephoneNumber}
                    onChange={(e) =>
                      setDataInfo({
                        ...dataInfo,
                        telephoneNumber: e.target.value
                      })
                    }
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/[^0-9()-]/g, '')
                        .replace(/^(\d{2})(\d)/, '($1)$2')
                        .replace(/(\d{5})(\d)/, '$1-$2');
                    }}
                    inputRef={register({ required: true })}
                    error={!!errors.telephoneNumber}
                    helperText={errors?.telephoneNumber ? '' : ''}
                    size="small"
                    required={editMode !== true}
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid item xs={3} sm={3} className="pb0 pl10 dFlex alignCenter">
                  <Checkbox
                    name="delivery"
                    onClick={() => {
                      handleCheckboxChange(!delivery);
                    }}
                    checked={delivery}
                    className="pb0"
                  />{' '}
                  <span
                    onClick={() => {
                      handleCheckboxChange(!delivery);
                    }}
                    className="fontSizeDefault cursorPointer mt8"
                  >
                    Delivery?
                  </span>
                </Grid>
              </Grid>
            </div>

            <div className="cardTitle bgLightBlue pb3 pl10">
              <span className="white bold fontSizeDefault">ENDEREÇO</span>
            </div>

            <div className="cardDefault pt10 pb10 pl10 pr10">
              <Grid container spacing={4} className="mt0 mb0">
                <Grid item xs={2} sm={2} className="pr10">
                  <Autocomplete
                    id="asynchronous-demo"
                    getOptionLabel={(option) => option.description}
                    getOptionSelected={(option, value) =>
                      option.description === value.description
                    }
                    options={typesAddress}
                    value={typeAddress}
                    required={editMode !== true}
                    onChange={(event, typeAddress) => {
                      setTypeAddress(typeAddress);
                    }}
                    disabled={editMode}
                    className="autocompleteSmall"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tipo de Endereço"
                        variant="outlined"
                        name="typeAddress"
                        size="small"
                        type="text"
                        value={typeAddress}
                        required={editMode !== true}
                        placeholder={editMode ? '' : 'Selecione...'}
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                    renderOption={(params) => (
                      <Typography
                        style={{ fontSize: '12px', fontFamily: 'Lato' }}
                      >
                        {params.description}
                      </Typography>
                    )}
                  />
                </Grid>

                <Grid
                  item
                  xs={2}
                  sm={2}
                  className={`pl10 ${invalidFields?.zipCode && 'labelError'}`}
                >
                  <TextField
                    label="CEP"
                    name="zipCode"
                    fullWidth
                    variant="outlined"
                    required={editMode !== true}
                    onChange={(e) =>
                      setDataAddress({
                        ...dataAddress,
                        zipCode: e.target.value
                      })
                    }
                    inputRef={register({ required: true })}
                    error={!!errors.zipCode}
                    helperText={errors?.zipCode ? '' : ''}
                    defaultValue={dataAddress.zipCode}
                    value={dataAddress.zipCode}
                    inputProps={{ maxLength: 9 }}
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/[^0-9-]/g, '')
                        // .replace(/^(\d{2})\-(\d{3})(\d)/, "$1.$2.$3")
                        .replace(/(\d{5})(\d)/, '$1-$2');
                    }}
                    size="small"
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={4} className="mt0 mb0">
                <Grid
                  item
                  xs={5}
                  sm={5}
                  className={`pr10 ${invalidFields?.street && 'labelError'}`}
                >
                  <TextField
                    name="street"
                    label="Logradouro"
                    fullWidth
                    variant="outlined"
                    required={editMode !== true}
                    inputRef={register({ required: true })}
                    error={!!errors.street}
                    helperText={errors?.street ? '' : ''}
                    value={dataAddress.street}
                    onInput={(e) =>
                      setDataAddress({ ...dataAddress, street: e.target.value })
                    }
                    inputProps={{ maxLength: 50 }}
                    size="small"
                    disabled={editMode}
                    className="textFieldSmall"
                    placeholder={editMode ? '' : 'Digite aqui...'}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={1}
                  sm={1}
                  className={`pl10 pr10 ${
                    invalidFields?.number && 'labelError'
                  }`}
                >
                  <TextField
                    label={isModal ? 'Nº' : 'Número'}
                    name="number"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      maxLength: 6
                    }}
                    value={dataAddress.number}
                    onChange={(e) =>
                      setDataAddress({ ...dataAddress, number: e.target.value })
                    }
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(
                        /[^0-9][^a-zA-Z]/g,
                        ''
                      );
                    }}
                    inputRef={register({ required: true })}
                    error={!!errors.number}
                    helperText={errors?.number ? '' : ''}
                    size="small"
                    required={editMode !== true}
                    disabled={editMode}
                    className="textFieldSmall"
                    placeholder={editMode ? '' : 'Digite aqui...'}
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid item xs={3} sm={3} className="pl10">
                  <TextField
                    label="Complemento"
                    name="complement"
                    fullWidth
                    variant="outlined"
                    inputRef={register}
                    value={dataAddress.complement}
                    onChange={(e) =>
                      setDataAddress({
                        ...dataAddress,
                        complement: e.target.value
                      })
                    }
                    inputProps={{ maxLength: 50 }}
                    size="small"
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={4} className="mt0 mb0">
                <Grid
                  item
                  xs={5}
                  sm={5}
                  className={`pb0 pr10 ${invalidFields?.city && 'labelError'}`}
                >
                  <TextField
                    name="city"
                    label="Cidade"
                    fullWidth
                    variant="outlined"
                    required={editMode !== true}
                    inputRef={register({ required: true })}
                    error={!!errors.city}
                    helperText={errors?.city ? '' : ''}
                    value={dataAddress.city}
                    onChange={(e) =>
                      setDataAddress({ ...dataAddress, city: e.target.value })
                    }
                    inputProps={{ maxLength: 50 }}
                    size="small"
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={1}
                  sm={1}
                  className={`pb0 pl10 pr10 ${
                    invalidFields?.states && 'labelError'
                  }`}
                >
                  <TextField
                    label="UF"
                    name="state"
                    fullWidth
                    variant="outlined"
                    required={editMode !== true}
                    inputRef={register({ required: true })}
                    error={!!errors.state}
                    helperText={errors?.state ? '' : ''}
                    onChange={(e) =>
                      setDataAddress({ ...dataAddress, states: e.target.value })
                    }
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^a-zA-Z]/g, '');
                    }}
                    value={dataAddress.states}
                    inputProps={{ maxLength: 2 }}
                    size="small"
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid item xs={3} sm={3} className="pb0 pl10">
                  <Autocomplete
                    id="asynchronous-demo"
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) =>
                      option.name === value.name
                    }
                    options={countries}
                    value={country}
                    required={editMode !== true}
                    className="autocompleteSmall"
                    onChange={(event, country) => {
                      setCountry(country);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="País"
                        variant="outlined"
                        size="small"
                        type="text"
                        required={editMode !== true}
                        value={country}
                        placeholder="Selecione..."
                        InputLabelProps={{
                          shrink: true
                        }}
                      />
                    )}
                    renderOption={(params) => (
                      <Typography
                        style={{ fontSize: '12px', fontFamily: 'Lato' }}
                      >
                        {params.name}
                      </Typography>
                    )}
                  />
                </Grid>
              </Grid>
            </div>

            <div className="cardTitle bgLightBlue pb3 pl10">
              <span className="white bold fontSizeDefault">CONTATO</span>
            </div>

            <div className="cardDefault pt10 pb10 pl10 pr10">
              <Grid container spacing={4} className="mt0 mb0">
                <Grid
                  item
                  xs={3}
                  sm={3}
                  className={`pb0 pr10 ${
                    invalidFields?.fullName && 'labelError'
                  }`}
                >
                  <TextField
                    label="Nome do Contato"
                    name="fullName"
                    fullWidth
                    variant="outlined"
                    required={editMode !== true}
                    inputRef={register({ required: true })}
                    error={!!errors.fullName}
                    helperText={errors?.fullName ? '' : ''}
                    value={companyResponsible.fullName}
                    onChange={(e) =>
                      setCompanyResponsible({
                        ...companyResponsible,
                        fullName: e.target.value
                      })
                    }
                    inputProps={{ maxLength: 80 }}
                    size="small"
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={3}
                  sm={3}
                  className={`pb0 pl10 pr10 ${
                    invalidFields?.email && 'labelError'
                  }`}
                >
                  <TextField
                    type="email"
                    label="E-mail do Contato"
                    name="emailAddress"
                    fullWidth
                    variant="outlined"
                    required={editMode !== true}
                    inputRef={register({ required: true })}
                    error={!!errors.emailAddress}
                    helperText={errors?.emailAddress ? '' : ''}
                    value={companyResponsible.emailAddress}
                    onChange={(e) =>
                      setCompanyResponsible({
                        ...companyResponsible,
                        emailAddress: e.target.value
                      })
                    }
                    inputProps={{ maxLength: 50 }}
                    size="small"
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={3}
                  sm={3}
                  className={`pb0 pl10 ${
                    invalidFields?.responsibleNumber && 'labelError'
                  }`}
                >
                  <TextField
                    label="Telefone do Contato"
                    name="telephoneNumber"
                    fullWidth
                    variant="outlined"
                    inputProps={{
                      maxLength: 14
                    }}
                    value={companyResponsible.telephoneNumber}
                    onChange={(e) =>
                      setCompanyResponsible({
                        ...companyResponsible,
                        telephoneNumber: e.target.value
                      })
                    }
                    onInput={(e) => {
                      e.target.value = e.target.value
                        .replace(/[^0-9()-]/g, '')
                        .replace(/^(\d{2})(\d)/, '($1)$2')
                        .replace(/(\d{5})(\d)/, '$1-$2');
                    }}
                    inputRef={register({ required: true })}
                    error={!!errors.telephoneNumber}
                    helperText={errors?.telephoneNumber ? '' : ''}
                    size="small"
                    required={editMode !== true}
                    className="textFieldSmall"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>
            </div>

            <div className="cardTitle bgLightBlue pb3 pl10">
              <span className="white bold fontSizeDefault">
                INFORMAÇÕES ADICIONAIS
              </span>
            </div>

            <div className="cardDefault pt10 pb10 pl10 pr10">
              <Grid container spacing={4} className="mt0 mb0">
                <Grid
                  item
                  xs={3}
                  sm={3}
                  className={`pb0 pr10 ${
                    invalidFields?.averageBillingMonthly && 'labelError'
                  }`}
                >
                  <CurrencyTextField
                    variant="standard"
                    size="small"
                    currencySymbol="R$"
                    minimumValue="0"
                    maximumValue="999999999999999"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    decimalPlaces={2}
                    decimalPlacesShownOnFocus={2}
                    type="text"
                    name="averageBillingMonthly"
                    fullWidth
                    placeholder="Digite aqui..."
                    label="Faturamento médio mensal"
                    className="textFieldSmall right currencyTextFieldSmall"
                    value={additionalInformation?.averageBillingMonthly}
                    InputProps={{
                      maxLength: 8,
                      classes: {
                        input: {
                          fontFamily: 'Lato',
                          fontSize: '12px'
                        }
                      }
                    }}
                    onBlur={(e) => {
                      setAddtionalInformation({
                        ...additionalInformation,
                        averageBillingMonthly: e.target.value
                      });
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={3}
                  sm={3}
                  className={`pb0 pl10 pr10 ${
                    invalidFields?.numberOrdersDays && 'labelError'
                  }`}
                >
                  <TextField
                    label="Número de pedidos/dia"
                    name="numberOrdersDays"
                    fullWidth
                    variant="outlined"
                    required={editMode !== true}
                    inputRef={register({ required: true })}
                    error={!!errors.numberOrdersDays}
                    helperText={errors?.numberOrdersDays ? '' : ''}
                    value={additionalInformation?.numberOrdersDays}
                    onChange={(e) =>
                      setAddtionalInformation({
                        ...additionalInformation,
                        numberOrdersDays: e.target.value
                      })
                    }
                    inputProps={{ maxLength: 50 }}
                    size="small"
                    className="textFieldSmall right"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={3}
                  sm={3}
                  className={`pb0 pl10 pr10 ${
                    invalidFields?.averageTicket && 'labelError'
                  }`}
                >
                  <CurrencyTextField
                    variant="standard"
                    size="small"
                    currencySymbol="R$"
                    minimumValue="0"
                    maximumValue="999999999999999"
                    outputFormat="string"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    decimalPlaces={2}
                    decimalPlacesShownOnFocus={2}
                    type="text"
                    name="averageTicket"
                    fullWidth
                    placeholder="Digite aqui..."
                    label="Ticket médio"
                    className="textFieldSmall right currencyTextFieldSmall"
                    value={additionalInformation?.averageTicket}
                    InputProps={{
                      maxLength: 8,
                      classes: {
                        input: {
                          fontFamily: 'Lato',
                          fontSize: '12px'
                        }
                      }
                    }}
                    onBlur={(e) => {
                      setAddtionalInformation({
                        ...additionalInformation,
                        averageTicket: e.target.value
                      });
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={3}
                  sm={3}
                  className={`pb0 pl10 ${
                    invalidFields?.orderVolume && 'labelError'
                  }`}
                >
                  <TextField
                    label="Volume de pedidos/mês"
                    name="orderVolume"
                    fullWidth
                    variant="outlined"
                    required={editMode !== true}
                    inputRef={register({ required: true })}
                    error={!!errors.orderVolume}
                    helperText={errors?.orderVolume ? '' : ''}
                    value={additionalInformation?.orderVolume}
                    onChange={(e) =>
                      setAddtionalInformation({
                        ...additionalInformation,
                        orderVolume: e.target.value
                      })
                    }
                    inputProps={{ maxLength: 50 }}
                    size="small"
                    className="textFieldSmall right"
                    placeholder="Digite aqui..."
                    InputLabelProps={{
                      shrink: true
                    }}
                  />
                </Grid>
              </Grid>
            </div>
          </form>
        </div>

        <Grid
          item
          xs={12}
          sm={12}
          style={{
            borderRadius: '0 0 4px 4px'
          }}
          className="footerActions pb16 pr16 dFlex justifyEnd"
        >
          <Button
            className="defaultButton backButton mr16"
            label="Voltar"
            design="contained"
            onClick={() => {
              onClose();
              setDataInfo()
            setDataAddress()
            setCompanyResponsible()
            setAddtionalInformation()
            }}
          />

          <Button
            className="defaultButton submitButton"
            label="Avançar"
            design="contained"
            onClick={() => handleSubmit(onSubmitProvider(!editRestaurant))}
            disabled={invalidCnpjMessage}
          />
        </Grid>
      </Container>

      {modalStatesObj.willBeGroupStoreConfirmation.open && (
        <ConfirmModal
          customClass="createStoreStepTwo"
          open={modalStatesObj.willBeGroupStoreConfirmation.open}
          content={
            <div className="dFlex flexColumn alignCenter">
              <StepView currentStep="2" />

              <Typography className="bold">
                Esta loja participará de um Grupo?
              </Typography>
            </div>
          }
          onClose={() => {
            setModalStatesObj({
              ...modalStatesObj,
              cancelStoreCreation: {
                open: true
              }
            });
          }}
          cancelText="Não"
          onCancel={() => {
            setModalStatesObj({
              ...modalStatesObj,
              willUseCategoriesConfirmation: {
                open: true
              }
            });
          }}
          onConfirm={() => {
            setModalStatesObj({
              ...modalStatesObj,
              selectGroupStore: {
                open: true
              }
            });
          }}
          confirmText="Sim"
        />
      )}

      {modalStatesObj.willCloneStoreInfosConfirmation.open && (
        <ConfirmModal
          disableBackDrop
          customClass="createStoreStepThree"
          open={modalStatesObj.willCloneStoreInfosConfirmation.open}
          content={
            <div className="dFlex flexColumn alignCenter">
              <StepView currentStep="3" />
              <Typography className="bold">
                Clonar os dados de alguma Loja?
              </Typography>
            </div>
          }
          onClose={() => {
            setModalStatesObj({
              ...modalStatesObj,
              cancelStoreCreation: {
                open: true
              }
            });
          }}
          cancelText="Não"
          onCancel={() => {
            setModalStatesObj({
              ...modalStatesObj,
              willUseCategoriesConfirmation: {
                open: true
              }
            });
          }}
          onConfirm={() => {
            setModalStatesObj({
              ...modalStatesObj,
              selectCloneStoreInfos: {
                open: true
              }
            });
          }}
          confirmText="Sim"
        />
      )}

      {modalStatesObj.willUseCategoriesConfirmation.open && (
        <ConfirmModal
          disableBackDrop
          customClass="createStoreStepFour"
          open={modalStatesObj.willUseCategoriesConfirmation.open}
          content={
            <div className="dFlex flexColumn alignCenter">
              <StepView currentStep="4" />
              <Typography className="bold">
                Utilizar Categorias pré-cadastradas?
              </Typography>
            </div>
          }
          onClose={() => {
            setModalStatesObj({
              ...modalStatesObj,
              cancelStoreCreation: {
                open: true
              }
            });
          }}
          cancelText="Não"
          onCancel={() => {
            if (!selectedGroupObj?.group) {
              setModalStatesObj({
                ...modalStatesObj,
                createUser: {
                  open: true
                }
              });
            } else {
              setModalStatesObj({
                ...modalStatesObj,
                selectStoreUsers: {
                  open: true
                }
              });
            }
          }}
          onConfirm={() => {
            setModalStatesObj({
              ...modalStatesObj,
              categoriesInfos: {
                open: true
              }
            });
          }}
          confirmText="Sim"
        />
      )}

      {modalStatesObj.cancelStoreCreation.open && (
        <ConfirmModal
          customClass="confirmCancelStoreCreationDialog"
          disableBackDrop
          open={modalStatesObj.cancelStoreCreation.open}
          content={
            <div className="dFlex flexColumn alignCenter justifyCenter">
              <img
                className="mb10"
                style={{ width: '33px' }}
                src={AlertIcon}
                alt="AlertIcon"
              />
              <Typography className="bold">
                Deseja cancelar a criação desta loja?
              </Typography>
            </div>
          }
          onClose={() => {
            setModalStatesObj({
              ...modalStatesObj,
              cancelStoreCreation: {
                open: false
              }
            });
          }}
          cancelText="Não"
          onCancel={() => {
            setModalStatesObj({
              ...modalStatesObj,
              cancelStoreCreation: {
                open: false
              }
            });
          }}
          onConfirm={() => {
            setModalStatesObj({
              willBeGroupStoreConfirmation: {
                open: false
              },
              selectGroupStore: {
                open: false
              },
              willCloneStoreInfosConfirmation: {
                open: false
              },
              selectCloneStoreInfos: {
                open: false
              },
              willUseCategoriesConfirmation: {
                open: false
              },
              categoriesInfos: {
                open: false
              },
              selectStoreUsers: {
                open: false
              },
              createUser: {
                open: false,
                openedByUserSelected: false
              },
              cancelStoreCreation: {
                open: false
              }
            });
            setSelectedGroupObj('');
            setSelectedCloneStoreObj('');
            setSelectedStoreUserObj({
              users: []
            });
            setSelectedCategoriesInfosObj('');
          }}
          confirmText="Sim"
        />
      )}

      {modalStatesObj.createUser.open && (
        <UserForm
          open={modalStatesObj.createUser.open}
          openedByUserSelect={modalStatesObj?.createUser?.openedByUserSelected}
          selectedGroupObj={selectedGroupObj}
          translateProfile={translateProfile}
          setMessage={setMessage}
          userObj={selectedStoreUserObj}
          setUserObj={setSelectedStoreUserObj}
          onCancel={() => {
            setModalStatesObj({
              ...modalStatesObj,
              cancelStoreCreation: {
                open: true
              }
            });
          }}
          onClose={() => {
            if (!selectedGroupObj?.group) {
              setModalStatesObj({
                ...modalStatesObj,
                createUser: {
                  open: false,
                  openedByUserSelected: false
                },
                selectStoreUsers: {
                  open: true
                }
              });
            } else {
              setModalStatesObj({
                ...modalStatesObj,
                createUser: {
                  open: false,
                  openedByUserSelected: false
                }
              });
            }
          }}
        />
      )}

      {modalStatesObj.selectGroupStore.open && (
        <SelectGroupDialog
          open={modalStatesObj.selectGroupStore.open}
          userInfo={userInfo}
          selectedGroupObj={selectedGroupObj}
          setSelectedGroupObj={setSelectedGroupObj}
          onClose={() => {
            setModalStatesObj({
              ...modalStatesObj,
              cancelStoreCreation: {
                open: true
              }
            });
          }}
          onCancel={() => {
            setModalStatesObj({
              ...modalStatesObj,
              cancelStoreCreation: {
                open: true
              }
            });
          }}
          onConfirm={() => {
            setModalStatesObj({
              ...modalStatesObj,
              willCloneStoreInfosConfirmation: {
                open: true
              }
            });
          }}
        />
      )}

      {modalStatesObj.selectCloneStoreInfos.open && (
        <SelectCloneStoreDialog
          open={modalStatesObj.selectCloneStoreInfos.open}
          selectedGroupObj={selectedGroupObj}
          selectedCloneStoreObj={selectedCloneStoreObj}
          setSelectedCloneStoreObj={setSelectedCloneStoreObj}
          onClose={() => {
            setModalStatesObj({
              ...modalStatesObj,
              cancelStoreCreation: {
                open: true
              }
            });
          }}
          onCancel={() => {
            setModalStatesObj({
              ...modalStatesObj,
              cancelStoreCreation: {
                open: true
              }
            });
          }}
          onConfirm={() => {
            setModalStatesObj({
              ...modalStatesObj,
              selectStoreUsers: {
                open: true
              }
            });
          }}
        />
      )}

      {modalStatesObj?.selectStoreUsers?.open && (
        <SelectUserDialog
          open={modalStatesObj.selectStoreUsers.open}
          selectedGroupObj={selectedGroupObj}
          selectedStoreUserObj={selectedStoreUserObj}
          setSelectedStoreUserObj={setSelectedStoreUserObj}
          setModalStatesObj={setModalStatesObj}
          modalStatesObj={modalStatesObj}
          onClose={() => {
            setModalStatesObj({
              ...modalStatesObj,
              cancelStoreCreation: {
                open: true
              }
            });
          }}
          onCancel={() => {
            setModalStatesObj({
              ...modalStatesObj,
              cancelStoreCreation: {
                open: true
              }
            });
          }}
          onConfirm={() => {
            handleSubmit(onSubmitProvider());
          }}
        />
      )}

      {modalStatesObj.categoriesInfos.open && (
        <CategoriesInfosDialog
          userInfo={userInfo}
          open={modalStatesObj.categoriesInfos.open}
          setSelectedCategoriesInfosObj={setSelectedCategoriesInfosObj}
          onClose={() => {
            setModalStatesObj({
              ...modalStatesObj,
              selectStoreUsers: {
                open: true
              }
            });
          }}
          onCancel={() => {
            setModalStatesObj({
              ...modalStatesObj,
              selectStoreUsers: {
                open: true
              }
            });
          }}
          onConfirm={() => {
            if (selectedGroupObj?.group) {
              setModalStatesObj({
                ...modalStatesObj,
                selectStoreUsers: {
                  open: true
                },
                categoriesInfos: {
                  open: false
                }
              });
            } else {
              setModalStatesObj({
                ...modalStatesObj,
                createUser: {
                  open: true
                },
                categoriesInfos: {
                  open: false
                }
              });
            }
          }}
        />
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.user,
  providerInfo: state.fornecedores
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(ProvidersActions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StoreForm);
